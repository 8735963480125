import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import api from '../api';
import { Subcategory } from '../api/models';
import { State } from '../store/interfaces';
import {
  EntitiesState,
  loadEntitiesErrorAction,
  loadEntitiesRequestAction,
  loadEntitiesSuccessAction,
} from '../store/reducers/entity';

const useEntities = (): EntitiesState & {
  getSubcategories: (categoryId: string) => Subcategory[];
} => {
  const entities = useSelector((state: State) => state.entities);
  const dispatch = useDispatch();
  const loadEntities = async (refresh?: boolean) => {
    if (!refresh && (entities.isLoading || entities.isLoaded)) {
      return;
    }

    dispatch(loadEntitiesRequestAction());
    try {
      const response = await api.entity.loadAllEntities();

      dispatch(loadEntitiesSuccessAction(response.data));
    } catch (error) {
      dispatch(loadEntitiesErrorAction());
    }
  };
  if (!entities.isLoaded) {
    loadEntities();
  }

  const getSubcategories = (categoryId: string) => {
    return entities.subcategories.filter((sc) => sc.categoryId === categoryId);
  };

  return { ...entities, getSubcategories };
};

export default useEntities;
