import React from 'react';
import { HighlightedFieldsMeta, ProductDetails } from '../../../api/models';

const ProductHighLightInfo: React.FunctionComponent<{
  product: ProductDetails;
  highlightFields: HighlightedFieldsMeta[];
}> = (props) => {
  return (
    <div className="row font-normal mb-4 px-3 px-lg-0">
      {props.highlightFields?.map((hf) => {
        const value =
          props.product[hf.field as keyof ProductDetails] ||
          props.product?.customAttributes?.[hf.field];
        if (value === undefined || value === null) {
          return <></>;
        }

        const transformed =
          typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
        return (
          <>
            <div className="col-3 text-gray-500 mb-3 overflow-wrap">
              {hf.title}
            </div>
            <div className="col-9 text-gray-700 mb-3 font-weight-semibold overflow-wrap">
              {transformed}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ProductHighLightInfo;
