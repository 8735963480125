import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Empty from '../../../components/empty';
import { PageType } from '../../profile';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import {
  AccountType,
  AuctionCatalogFilter,
  AuctionType,
  PaginatedApiResponse,
  Product,
  ProductType,
  RequestCustomPlan,
  Subscribe,
  SubscriptionPlan,
  SubscriptionStatus,
} from '../../../api/models';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import BiddingHistory from './bidding-history';
import MyAuctionProduct from './my-auction-product';
import AdsPagination, {
  AdsPaginationProps,
} from '../../../components/ads-pagination/pagination';
import Modal from 'react-modal';
import { toastr } from 'react-redux-toastr';
import ProductFilter from '../../../components/product-filter/ProductFilter';
import { ProductFilterModel } from '../../../components/product-filter/ProductFilterModel';
import classNames from 'classnames';
import {
  selectProductToBoostBackAction,
  selectProductToSellFasterAction,
} from '../../../store/reducers/catalog';
import {
  getBoostBackLink,
  getTopFeaturedPlansLink,
} from '../../../utils/link-utils';

import ChoosePlan from '../../choose-plan/components/ChoosePlan';
import { usePerformAction } from 'react-remote-data-hooks';
import Payment, { PaymentType } from '../../payment/components/payment';
import ThankYou from '../../create/components/ThankYou';
import { scrollTop } from '../../../utils/window-utils';

const initialConstants = {
  status: '',
  query: '',
  startDate: '',
  endDate: '',
  customerName: '',
  page: 1,
  perPage: 10,
};

const initialFilter = {
  [AuctionType.Buying]: {
    ...initialConstants,
    type: AuctionType.Buying,
  },
  [AuctionType.Selling]: {
    ...initialConstants,
    type: AuctionType.Selling,
  },
  [AuctionType.Favorite]: {
    ...initialConstants,
    type: AuctionType.Favorite,
  },
};

interface PageQuery {
  page?: number;
  perPage?: number;
}

interface MyAuctionFilterModel {
  [AuctionType.Buying]: AuctionCatalogFilter & PageQuery;
  [AuctionType.Selling]: AuctionCatalogFilter & PageQuery;
  [AuctionType.Favorite]: AuctionCatalogFilter & PageQuery;
}

const MyAuctionScreen: React.FunctionComponent = () => {
  const [toggleFilter, setToggleFilter] = useState(false);
  const [filter, setFilter] = useState<MyAuctionFilterModel>(initialFilter);
  const [auctionProducts, setAuctionProducts] = useState<Product[]>([]);
  const [favoriteProducts, setFavoriteProducts] = useState<Product[]>([]);
  const [sellingProducts, setSellingProducts] = useState<Product[]>([]);
  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [modal, setModal] = React.useState<{
    isOpen?: boolean;
    productId?: string;
    error?: Error;
  }>({});
  const appEl = useRef(null);
  const [buyLength, setBuyLength] = useState(0);
  const [sellLength, setSellLength] = useState(0);

  const [auctionType, setAuctionType] = useState(AuctionType.Buying);

  const {
    data: productsResponse,
    loading,
    loaded: productLoaded,
    reload: reloadMyAuctions,
  } = useDataLoader<PaginatedApiResponse<Product>>(() => {
    const tmpFilter = filter[AuctionType.Buying];
    return api.catalog.getMyAuctions({
      pagination: {
        page: tmpFilter.page,
        perPage: tmpFilter.perPage,
      },
      sort: {},
      queryParams: {
        ...tmpFilter,
      },
    });
  });

  const {
    data: sellingResponse,
    loading: sellingLoading,
    loaded: sellingLoaded,
    reload: reloadMySellingAuctions,
  } = useDataLoader<PaginatedApiResponse<Product>>(() => {
    const tmpFilter = filter[AuctionType.Selling];
    return api.catalog.getMyAuctions({
      pagination: {
        page: tmpFilter.page,
        perPage: tmpFilter.perPage,
      },
      sort: {},
      queryParams: {
        ...tmpFilter,
      },
    });
  });

  const {
    data: favoriteResponse,
    loading: favoriteLoading,
    loaded: favoriteLoaded,
    reload: reloadMyFavoriteAuctions,
  } = useDataLoader<PaginatedApiResponse<Product>>(() => {
    const tmpFilter = filter[AuctionType.Favorite];
    return api.catalog.getFavoriteAds({
      pagination: {
        page: tmpFilter.page,
        perPage: tmpFilter.perPage,
      },
      sort: {},
      queryParams: {
        ...tmpFilter,
        productType: ProductType.Auction,
      },
    });
  });

  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isChoosePlan, setIsChoosePlan] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');

  const { performAction: payment } = usePerformAction(
    (data: SubscriptionPlan): any => {
      setSubscriptionPlanSelected(data);
      setIsRedirectPayment(true);
    }
  );
  const [choosePlanProduct, setChoosePlanProduct] = useState<
    Product | undefined
  >();

  const { performAction: subscribe } = usePerformAction(
    (paymentId: any, stripePaymentToken?: any): any => {
      const subscribeData: Subscribe = {
        subscriptionPlan: subscriptionPlanSelected?._id || '',
        paymentId,
        paymentType: PaymentType.Subscription,
        categoryId: choosePlanProduct?.category?._id,
        stripePaymentToken,
        productId: choosePlanProduct?._id,
        isFirstTimeSubcription: true,
      };
      api.subscription.subscribe(subscribeData).then((data) => {
        if (data.status === SubscriptionStatus.PaymetSuccess) {
          setIsChoosePlan(false);
          setIsRedirectPayment(false);
          if (choosePlanProduct) activateProduct(choosePlanProduct?._id);
        }
      });
    }
  );

  const { performAction: requestCustomPlan } = usePerformAction((data): any => {
    setSubscriptionPlanSelected(data);
    // createProduct(productDetails);
    const subscribeData: RequestCustomPlan = {
      subscriptionPlan: data?._id || '',
    };
    api.subscription.requestCustomPlan(subscribeData).then((responseData) => {
      if (responseData.status === SubscriptionStatus.CustomRequestPending) {
        setThankYouHeading('Thank You');
        setThankYouMessage('We will contact you soon to discuss a custom plan');
        setIsThankYou(true);
      }
    });
  });

  const { performAction: checkSubscription } = usePerformAction(
    (productToActivate): any => {
      api.subscription
        .getRemainingPostsCountInCategory({
          categoryId: productToActivate?.category?._id,
          productType: productToActivate?.productType,
        })
        .then((response) => {
          // setIsLoaded(true);
          if (response.noOfPostsRemaining > 0) {
            if (productToActivate) {
              const subscriptionResponse = api.subscription.subscribeAndActivateProduct(
                {
                  subscriptionId: response.subscriptionId,
                  productId: productToActivate?._id,
                }
              );
              activateProduct(productToActivate?._id);
            }
          } else {
            setIsChoosePlan(true);
          }
        });

      // if (
      //   !authState.profile?.subscriptionDetails ||
      //   !authState.profile?.subscriptionDetails?.noOfPostsRemaining
      // ) {
      //   setIsChoosePlan(true);
      // } else {
      //   createProduct(product);
      // }
    }
  );

  const choosePlanWhenNoSubscription = (product: Product) => {
    setChoosePlanProduct(product);
    checkSubscription(product);
  };

  const [isFilterActive, setIsFilterActive] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsFirstLoad(false), 500);
  }, []);

  useEffect(() => {
    setAuctionProducts(productsResponse?.data);
  }, [productsResponse?.data]);

  useEffect(() => {
    setFavoriteProducts(favoriteResponse?.data);
  }, [favoriteResponse?.data]);

  useEffect(() => {
    setSellingProducts(sellingResponse?.data);
  }, [sellingResponse?.data]);

  useEffect(() => {
    if (!isFirstLoad) reloadMyAuctions();
  }, [filter[AuctionType.Buying]]);

  useEffect(() => {
    if (!isFirstLoad) reloadMySellingAuctions();
  }, [filter[AuctionType.Selling]]);

  useEffect(() => {
    if (!isFirstLoad) reloadMyFavoriteAuctions();
  }, [filter[AuctionType.Favorite]]);

  useEffect(() => {
    setBuyLength(productsResponse?.count);
  }, [productsResponse?.count]);

  useEffect(() => {
    setSellLength(sellingResponse?.count);
  }, [sellingResponse?.count]);

  const updateFilter = (key: string, val: unknown) =>
    setFilter({ ...filter, [auctionType]: { ...filter[auctionType], [key]: val } });

  const updateFilterByType = (tmpFilter: any) =>
    setFilter({
      ...filter,
      [auctionType]: {
        ...filter[auctionType],
        ...tmpFilter,
      },
    });

  const resetFilter = () => setFilter({ ...initialFilter });

  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };

  const updateProductFromResponse = async (
    id: string,
    newProduct: Product
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const tmp = sellingProducts.map((p: Product) => {
        if (p._id === id) {
          return newProduct;
        }
        return p;
      });
      setSellingProducts(tmp);
      setTimeout(() => resolve(), 200);
    });
  };

  const deActivateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(
      productId,
      'inActive'
    );
    await updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully de-activated');
  };

  const activateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(productId, 'active');
    await updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully activated');
  };

  const renderProducts = () => {
    if (!productsResponse?.data) return;
    const { count, page, perPage } = productsResponse;
    return (
      <>
        {auctionProducts.map((product: Product) => {
          return (
            <MyAuctionProduct
              product={product}
              type={AuctionType.Buying}
              key={`my-action-product-${product._id}`}
            />
          );
        })}

        {count === 0 ? <Empty /> : ''}
        {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop()
            },
          })}
      </>
    );
  };

  const renderFavProducts = () => {
    if (!favoriteResponse?.data) return;
    const { count, page, perPage } = favoriteResponse;
    return (
      <>
        {favoriteProducts?.map((product: Product) => {
          return (
            <MyAuctionProduct
              product={product}
              type={AuctionType.Buying}
              key={`my-auction-fav-product-${product._id}`}
            />
          );
        })}

        {count === 0 ? <Empty /> : ''}
        {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop()
            },
          })}
      </>
    );
  };

  const renderSellingProducts = () => {
    const { count, page, perPage } = sellingResponse;
    return (
      <>
        {count !== 0 &&
          sellingProducts.map((product: Product) => {
            return (
              <MyAuctionProduct
                product={product}
                type={AuctionType.Selling}
                onBiddingHistoryClick={(event: Product) => {
                  setModal({
                    isOpen: true,
                    productId: product._id,
                  });
                }}
                activateProduct={activateProduct}
                deActivateProduct={deActivateProduct}
                choosePlanWhenNoSubscription={choosePlanWhenNoSubscription}
                key={`my-action-product-${product._id}`}
                sellFasterAction={() => {
                  dispatch(selectProductToSellFasterAction(product));
                  history.push(getTopFeaturedPlansLink(product._id));
                }}
                boostAction={() => {
                  dispatch(selectProductToBoostBackAction(product));
                  history.push(getBoostBackLink(product._id));
                }}
              />
            );
          })}

        {count === 0 ? <Empty /> : ''}
        {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop()
            },
          })}
      </>
    );
  };

  const customStyles = {
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      margin: '20px auto',
      width: '100%',
      maxWidth: '500px',
      border: '0',
      padding: '0',
      background: 'transparent',
    },
    overlay: {
      overflow: 'auto',
    },
  };

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Actions
          </li>
        </ol>
      </nav>
    );
  };

  const filterRef = useRef<any>(null);

  return (
    <>
      {!isChoosePlan && (
        <div className="my-ads py-0 py-lg-4" ref={appEl}>
          <div className="container">
            {renderBreadCrumbs()}
            <div className="row">
              <MyAccount pageType={PageType.MyAuction} />

              <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
                <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
                  <Link
                    to="account"
                    className="icon-prev font-lg mr-3 text-white"
                  ></Link>
                  My Auctions
                </h6>
                <i
                  className="icon-filter text-white font-xl"
                  onClick={() => {
                    filterRef?.current?.toggleExtraFilter();
                  }}
                ></i>
              </div>
              <div className="col-lg-9 rmv-padd-mob">
                <div className="right-panel">
                  {auctionType === AuctionType.Buying ? (
                    <ProductFilter
                      title="My Auction"
                      ref={filterRef}
                      defaultValues={initialFilter[AuctionType.Buying]}
                      values={filter[AuctionType.Buying]}
                      searchPlaceHolder="from buying"
                      onFilterChange={(e: ProductFilterModel) => {
                        updateFilterByType(e);
                      }}
                      onFilterReset={resetFilter}
                      key="my-buying-filter"
                    />
                  ) : auctionType === AuctionType.Selling ? (
                    <ProductFilter
                      title="My Auction"
                      ref={filterRef}
                      defaultValues={initialFilter[AuctionType.Selling]}
                      values={filter[AuctionType.Selling]}
                      searchPlaceHolder="from selling"
                      onFilterChange={(e: ProductFilterModel) =>
                        updateFilterByType(e)
                      }
                      onFilterReset={resetFilter}
                      key="my-selling-filter"
                    />
                  ) : auctionType === AuctionType.Favorite ? (
                    <ProductFilter
                      title="My Auction"
                      ref={filterRef}
                      defaultValues={initialFilter[AuctionType.Favorite]}
                      values={filter[AuctionType.Favorite]}
                      searchPlaceHolder="from favorites"
                      onFilterChange={(e: ProductFilterModel) =>
                        updateFilterByType(e)
                      }
                      onFilterReset={resetFilter}
                      key="my-fav-filter"
                    />
                  ) : (
                    ''
                  )}

                  <nav className="tabs-header myaccount">
                    <div
                      className="nav nav-tabs row m-0"
                      id="nav-tab"
                      role="tablist"
                    >
                      <a
                        className={classNames(
                          'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                          { active: auctionType === AuctionType.Buying }
                        )}
                        id="nav-home-tab"
                        data-toggle="tab"
                        onClick={() => setAuctionType(AuctionType.Buying)}
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Buying{' '}
                        {productLoaded && buyLength > 0 && (
                          <span className="count">({buyLength})</span>
                        )}
                      </a>
                      <a
                        className={classNames(
                          'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                          { active: auctionType === AuctionType.Selling }
                        )}
                        id="nav-profile-tab"
                        data-toggle="tab"
                        onClick={() => setAuctionType(AuctionType.Selling)}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Selling{' '}
                        {sellingLoaded && sellLength > 0 && (
                          <span className="count">({sellLength})</span>
                        )}
                      </a>
                      <a
                        className={classNames(
                          'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                          { active: auctionType === AuctionType.Favorite }
                        )}
                        id="nav-profile-tab"
                        data-toggle="tab"
                        onClick={() => setAuctionType(AuctionType.Favorite)}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Favorite{' '}
                        {favoriteLoaded && favoriteResponse?.count > 0 && (
                          <span className="count">
                            ({favoriteResponse?.count})
                          </span>
                        )}
                      </a>
                    </div>
                  </nav>

                  <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
                    <div
                      className={`tab-pane fade show ${auctionType === AuctionType.Buying ? 'active' : ''
                        } `}
                      id="nav-buying"
                      role="tabpanel"
                    >
                      <div className="ads-item">
                        {productLoaded
                          ? renderProducts()
                          : renderContainerLoader()}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade show ${auctionType === AuctionType.Selling ? 'active' : ''
                        } `}
                      id="nav-selling"
                      role="tabpanel"
                    >
                      <div className="ads-item">
                        {sellingLoaded
                          ? renderSellingProducts()
                          : renderContainerLoader()}
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade show ${auctionType === AuctionType.Favorite ? 'active' : ''
                        } `}
                      id="nav-selling"
                      role="tabpanel"
                    >
                      <div className="ads-item">
                        {favoriteLoaded
                          ? renderFavProducts()
                          : renderContainerLoader()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isChoosePlan && !isRedirectPayment && !isThankYou && (
        <ChoosePlan
          onPlanSelect={(data: SubscriptionPlan) =>
            data.isCustom ? requestCustomPlan(data) : payment(data)
          }
          productType={choosePlanProduct?.productType}
          categoryId={choosePlanProduct?.category?._id}
          subCategoryId={choosePlanProduct?.subcategory?._id}
          onBackClick={() => {
            setIsChoosePlan(false);
            // setShowSummary(true);
          }}
        />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          paymentType={PaymentType.Subscription}
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          email={auth.profile?.email!}
          amount={subscriptionPlanSelected?.amount}
          subscriptionPlanId={subscriptionPlanSelected?._id}
          isReccuring={subscriptionPlanSelected?.isRecurring || false}
          successhandler={subscribe}
          planName={subscriptionPlanSelected?.name}
          period={subscriptionPlanSelected?.period}
          listingDays={subscriptionPlanSelected?.listingDays}
        ></Payment>
      )}
      {isThankYou && (
        <ThankYou
          productId={choosePlanProduct?._id}
          heading={thankYouHeading}
          message={thankYouMessage}
          productType={choosePlanProduct?.productType}
          isCustomPlan={subscriptionPlanSelected?.isCustom}
        />
      )}
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen || false}
        contentLabel={'Bidding History'}
        onRequestClose={() => setModal({ isOpen: false })}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        {modal.productId ? (
          <BiddingHistory
            productId={modal.productId}
            onRequestClose={() => setModal({})}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
};
export default MyAuctionScreen;
