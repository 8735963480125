import React, { useEffect, useState } from 'react';
import { Product, ProductType } from '../../api/models';
import moment from 'moment';
import ShareProduct from './ShareProduct';
import FavouriteProduct from './FavouriteProduct';
import ProductChat from './ProductChat';
import { getProductLink } from '../../utils/link-utils';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import DealCountDown from '../deal-count-down';
import { CountDownType } from '../deal-count-down/CountDown';
import { useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import api from '../../api';
import { RouteKeys } from '../../containers/routes/route-keys';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import { MyProductType } from '../../containers/profile/components/profile-new';

const _target = '_top';
const newDate = new Date();
const ProductTile: React.FunctionComponent<{
  product: Product;
  className?: string;
  target?: string;
  type?: string;
}> = (props) => {
  const [isBiddingComplete, setIsBiddingComplete] = useState(false);
  const [isAuctionClosed, setIsAuctionClosed] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    props.type === MyProductType.Bought ? true : false
  );
  const [hasBiddingStarted, setHasBiddingStarted] = useState(false);

  const auth = useAuth();
  const authState = useSelector((state: State) => state.authUser);
  const history = useHistory();
  const userId = authState.profile?._id || '';
  const onFavourite = () => {
    // TODO
  };

  const [target, setTarget] = useState(_target);

  useEffect(() => {
    if (props?.target) {
      setTarget(props.target);
    }
  }, [props.target]);

  useEffect(() => {
    if (
      authState?.profile?._id.toString() ===
      props.product?.createdBy.id.toString()
    ) {
      setIsButtonDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (props?.product?.auctionDetails) {
      const { endDate } = props.product.auctionDetails;
      const bidCount = props.product?.bidCount || 0;
      if (
        new Date().getTime() > new Date(endDate).getTime() &&
        bidCount === 0
      ) {
        setIsAuctionClosed(true);
      }
      if (
        props.product?.auctionDetails?.startDate &&
        new Date().getTime() >
          new Date(props.product?.auctionDetails?.startDate).getTime()
      ) {
        setHasBiddingStarted(true);
      }
    }
  }, [props.product]);

  const onMakeOffer = async () => {
    if (!userId) history.push(RouteKeys.Login);
    if (userId && props.product.createdBy.id !== userId) {
      // await api.chat.createChat(props.product?._id);
      const { data } = await api.chat.createChat(props.product?._id);
      if (data?.chat?.id) {
        history.push(
          RouteKeys.ChatsDetail.replace(':chatId', data.chat.id) +
            '?view=make-offer'
        );
      } else {
        toastr.warning('Warning', 'Something went wrong');
      }
    }
    // history.push(RouteKeys.Chats + '?view=make-offer');
  };

  return (
    <div className={props.className}>
      <div className="bg-white shadow rounded productBox position-relative w-100 d-flex flex-column">
        {props.product.isFeaturedValidTill &&
          moment(props.product.isFeaturedValidTill).isSameOrAfter(moment()) && (
            <div className="featured bg-primary position-absolute font-xxs font-weight-semibold">
              <i className="icon-star mr-1"></i>Featured
            </div>
          )}
        {props.product.swapWith && (
          <div className="swap text-white font-xs rounded-circle position-absolute d-flex align-items-center justify-content-center cursor-pointer">
            <i className="icon-swap"></i>
          </div>
        )}
        <figure className="mb-0 d-flex align-items-center justify-content-center overflow-hidden rounded-top">
          <Link
            target={target}
            to={getProductLink(props.product.slug)}
            className="h-100 w-100"
          >
            <img
              src={
                props.product.defaultImage?.path ||
                props.product.images?.[0]?.path
              }
              className="img-cover w-100 h-100"
              alt="product"
              height="250"
              width="250"
            />
          </Link>
        </figure>
        <figcaption className="d-flex flex-column flex-fill">
          <a href={getProductLink(props.product.slug)}>
            <div className="productBox__info">
              <div className="d-flex align-items-baseline">
                <h6 className="font-weight-bold mb-1">
                  $
                  {props.product.productType === ProductType.Auction
                    ? `${props.product.auctionDetails?.bidNowPrice}`
                    : `${props.product.price}`}
                </h6>
                {props.product?.createdBy?.emailVerified &&
                  props.product?.createdBy?.phoneVerified && (
                    <div className="verified-product font-xxxs ml-auto d-md-inline-flex d-none align-items-center">
                      <i className="icon-verified font-lg"></i>
                      <span className="bg-success text-white">Verified</span>
                    </div>
                  )}
              </div>
              <p className="font-normal text-gray-700 mb-2 productBox__title">
                {props.product.title.length > 70
                  ? props.product.title.substr(0, 70) + '...'
                  : props.product.title}
              </p>
              {/* {props.product.verified && ( */}
              <div className="verified-product font-xxxs ml-auto mb-2 d-md-none d-flex align-items-center">
                <i className="icon-verified font-lg"></i>
                <span className="bg-success text-white">Verified</span>
              </div>
              {/* )} */}
              <div className="d-flex no-gutters align-items-center mb-1">
                <div className="col text-truncate font-xs text-gray-500">
                  <i className="icon-location mr-1 font-xxs"></i>
                  {props.product.location?.address}
                </div>
                <div className="col-auto">
                  <div className="bg-gray-200 text-gray-500 font-xxxs rounded-pill px-2">
                    {moment(props.product.createdAt).fromNow(true)}
                  </div>
                </div>
              </div>
              {props.product.swapWith && (
                <div className="text-info font-xs">
                  Swap: {props.product.swapWith}
                </div>
              )}
            </div>
          </a>
          {/* <div className="row flex-column gutter-5 px-3">
            <code>{props.product.productType}</code>
            <br />
            <code>
              Start{' '}
              {moment(props.product?.auctionDetails?.startDate).format(
                'DD MM YYYY'
              )}
            </code>
            <br />
            <code>
              End{' '}
              {moment(props.product?.auctionDetails?.endDate).format(
                'DD MM YYYY'
              )}
            </code>
            <br />
          </div> */}

          {!props?.product?.isSold ? (
            <>
              {props.product.productType === ProductType.Auction ? (
                <div className="productBox_auction mt-auto">
                  {props.product?.auctionDetails?.startDate ? (
                    isAuctionClosed || isBiddingComplete ? (
                      // <p>Auction Closed!</p>
                      ''
                    ) : (
                      <p className="font-sm">
                        <DealCountDown
                          type={CountDownType.text}
                          startDate={
                            new Date(props.product?.auctionDetails?.startDate)
                          }
                          endDate={
                            new Date(props.product?.auctionDetails?.endDate)
                          }
                          onCompleted={() => setIsBiddingComplete(true)}
                          isComplete={props.product.isSold}
                        />
                      </p>
                    )
                  ) : (
                    ''
                  )}
                  {!isAuctionClosed ? (
                    <div className="productBox_auction--action">
                      <Link
                        target={target}
                        to={`/product/${props.product.slug}`}
                        className={classNames(
                          'btn btn-sm btn-outline-dark col-7 py-2',
                          {
                            disabled:
                              !hasBiddingStarted ||
                              isBiddingComplete ||
                              isAuctionClosed,
                          }
                        )}
                      >
                        Bid Now
                      </Link>{' '}
                      <span className="text-primary font-base">
                        {props.product.bidCount || 0} bids
                      </span>
                    </div>
                  ) : (
                    <div className="row gutter-5 d-flex mt-auto">
                      <div className="col-6 d-none d-lg-block">
                        <Link
                          target={target}
                          to={getProductLink(props.product.slug)}
                          className="btn btn-sm btn-outline-dark py-2 btn-block"
                        >
                          View Details
                        </Link>
                      </div>
                      <div className="col-lg-6 col-12">
                        <Link
                          target={target}
                          to={RouteKeys.BuyProduct.replace(
                            ':slug',
                            props.product.slug
                          )}
                          className={`btn btn-sm btn-outline-primary py-2 btn-block ${
                            isButtonDisabled ? 'disabled' : ''
                          }`}
                        >
                          Buy Now
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              {props.product.productType === ProductType.Lead ? (
                <div className="productBox_lead mt-auto">
                  <div className="row gutter-5">
                    <div className="col-6 d-none d-lg-block">
                      <Link
                        target={target}
                        to={getProductLink(props.product.slug)}
                        className="btn btn-sm btn-outline-dark py-2 btn-block"
                      >
                        View Details
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <Link
                        target={target}
                        to={`/enquire-now/${props.product.slug}`}
                        className={`btn btn-sm btn-outline-primary py-2 btn-block ${
                          isButtonDisabled ? 'disabled' : ''
                        }`}
                      >
                        Inquire Now
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {props.product.productType === ProductType.Sale ? (
                <div className="productBox_sale mt-auto">
                  <div className="row gutter-5">
                    <div className="col-6 d-none d-lg-block">
                      <Link
                        target={target}
                        to={getProductLink(props.product.slug)}
                        className="btn btn-sm btn-outline-dark py-2 btn-block"
                      >
                        View Details
                      </Link>
                    </div>
                    <div className="col-lg-6 col-12">
                      <a
                        onClick={onMakeOffer}
                        className={`btn btn-sm btn-outline-primary  py-2 btn-block ${
                          isButtonDisabled ? 'disabled' : ''
                        }`}
                      >
                        Make Offer
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="d-flex font-lg text-center border-top productBox__actions">
                <div className="col-4 border-right">
                  <ProductChat userId={userId} product={props.product} />
                </div>
                <div className="col-4 border-right">
                  <FavouriteProduct
                    product={props.product}
                    onClick={onFavourite}
                  />
                </div>
                <ShareProduct product={props.product} />
              </div>
            </>
          ) : (
            ''
          )}
        </figcaption>
      </div>
    </div>
  );
};

export default ProductTile;
