import {
  ApiResponse,
  AuctionCatalogFilter,
  BiddingHistoryRequestModel,
  BiddingResponse,
  BidNowData,
  CatalogFilter,
  CatalogRequestModel,
  Category,
  CrudListRequestModel,
  EnquiryModel,
  EnquiryResponse,
  Filters,
  FilterType,
  LatLng,
  PaginatedApiResponse,
  Product,
  ProductDetails,
  ProductEnquiryModel,
  ProductEnquiryRequestModel,
  ProductReview,
  ProductType,
  ReportPostForm,
  SortBy,
} from '../models';
import ApiService from './service';
import { Dict } from '../../models';
import { wait } from '../utils';
import * as http from './http';
import { store } from '../../store';
import { stringify } from 'query-string';
import api from '..';

const products: Product[] = [
  {
    _id: 'dswyertkdlf464v56',
    slug: 'apple-iphone-11-pro-512gb',
    title: 'Apple iPhone 11 Pro (512GB) - Midnight Green',
    description: '',
    createdAt: new Date(),
    currency: '$',
    image: require('../../assets/images/products/product-img-1.png'),
    createdBy: {
      name: 'James Fdaulkner',
      avatar: {
        path: require('../../assets/images/propc.png'),
        encoding: '',
        fieldname: '',
        filename: '',
        mimetype: '',
        originalname: '',
        size: 20,
      },
      id: 'dswyertkdlf464v56',
    },
    address: 'Dunmore Town',
    price: 599,
    favorited: true,
    featured: true,
    swapWith: 'oneplus 8',
    verified: true,
    location: {
      address: 'Bahamas',
      location: {
        type: 'Point',
        coordinates: [24.638185, -78.055734],
      },
      latLong: {
        lat: 24.638184,
        lng: -78.05573,
      },
    },
    productType: ProductType.Auction,
  },
  {
    _id: 'dswyertkdlf464v786',
    slug: 'royal-enfield-classic-350',
    title: 'Royal Enfield - Classic 350',
    description: '',
    createdAt: new Date(),
    currency: '$',
    image: require('../../assets/images/products/product-img-2.png'),
    createdBy: {
      name: 'James Fdaulkner',
      avatar: {
        path: require('../../assets/images/propc.png'),
        encoding: '',
        fieldname: '',
        filename: '',
        mimetype: '',
        originalname: '',
        size: 20,
      },
      id: 'dswyertkdlf464v56',
    },
    address: 'Dunmore Town',
    price: 599,
    location: {
      address: 'Bahamas',
      location: {
        type: 'Point',
        coordinates: [24.638185, -78.055734],
      },
      latLong: {
        lat: 24.638184,
        lng: -78.05573,
      },
    },
    productType: ProductType.Auction,
  },
];
export default class CatalogService extends ApiService {
  public async getProductReccomendations(
    _page: number,
    city?: string
  ): Promise<any> {
    const url = `${this.apiDomain}/search/dashboard`;
    const response = await http.post<any>(
      url,
      {
        page: _page,
        perPage: 15,
        city,
      },
      this.store
    );
    return response?.data;
  }

  // public async getRelatedAds(productId: string): Promise<Product[]> {
  //   const items: Product[] = [];
  //   for (let i = 0; i <= 20; i++) {
  //     items.push(...products);
  //   }
  //   return items;
  // }

  public async createPost(product: Product): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/product`;
    const response = await http.post<ApiResponse<Product>>(
      url,
      product,
      this.store
    );
    return response.data;
  }
  public async updatePost(
    product: Product,
    _id: string
  ): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/product/${_id}`;
    const response = await http.put<ApiResponse<Product>>(
      url,
      product,
      this.store
    );
    return response.data;
  }
  public async getMyAds(
    request: CatalogRequestModel<CatalogFilter>
  ): Promise<PaginatedApiResponse<Product>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort;
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/my-ads?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }
  public async getMyEnquiredLeads(
    request: CatalogRequestModel<CatalogFilter>
  ): Promise<PaginatedApiResponse<Product>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort;
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/favourite-leads?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }
  public async getMyAuctions(
    request: CatalogRequestModel<AuctionCatalogFilter>
  ): Promise<PaginatedApiResponse<Product>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort;
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/my-auctions?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async markAsRead(): Promise<boolean> {
    const state = store.getState();
    const url = `${this.apiDomain}/product/notification/mark-as-read`;
    const response = await http.post<ApiResponse<any>>(url, {}, this.store);
    return true;
  }

  public async getMyProducts(type: string): Promise<Product[]> {
    const state = store.getState();
    const url = `${this.apiDomain}/product/my-products/${type}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data.data;
  }

  public async updateProductStatus(id: string, status: string) {
    const state = store.getState();
    const url = `${this.apiDomain}/product/${id}/modify-status`;
    const response = await http.put<ApiResponse<Product>, { status: string }>(
      url,
      { status },
      {
        apiDomain: this.store.apiDomain,
        token: state.authUser.token,
      }
    );
    return response.data;
  }

  public async markAsSold(id: string) {
    const state = store.getState();
    const url = `${this.apiDomain}/product/${id}/mark-as-sold`;
    const response = await http.get<ApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async getFavoriteAds(
    request: CatalogRequestModel<CatalogFilter>
  ): Promise<PaginatedApiResponse<Product>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort;
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/favourite-ads?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }
  public async getRelatedAds(
    categoryId: string,
    parentProductId: string
  ): Promise<Product[]> {
    const state = store.getState();
    const url = `${this.apiDomain}/product/related-ads`;
    const response = await http.post<any>(
      url,
      { categoryId, parentProductId },
      {
        apiDomain: this.store.apiDomain,
        token: state.authUser.token,
      }
    );
    return response.data.data?.response;
  }

  public async getProductDetails(slug: string): Promise<ProductDetails> {
    const state = store.getState();
    let response;
    if (state.authUser.token) {
      const url = `${this.apiDomain}/product/${slug}`;
      response = await http.get<ApiResponse<ProductDetails>>(url, {
        apiDomain: this.store.apiDomain,
        token: state.authUser.token,
      });
    } else {
      const url = `${this.apiDomain}/product/details/${slug}`;
      response = await http.get<ApiResponse<ProductDetails>>(url);
    }
    return response.data.data;
    // await wait(2000)
    // const pr: ProductDetails = {
    //   ...products[0],
    //   image: require('../../assets/images/products/detail-img.png'),
    //   images: [
    //     require('../../assets/images/products/detail-img.png'),
    //     require('../../assets/images/products/detail-img.png'),
    //     require('../../assets/images/products/detail-img.png'),
    //     require('../../assets/images/products/detail-img.png'),
    //     require('../../assets/images/products/detail-img.png'),
    //     require('../../assets/images/products/detail-img.png'),
    //   ],
    //   likeCount: 140,
    //   customAttributes: {
    //     year: 2018,
    //     kilometers: 20000,
    //     wheels: '2 wheels',
    //     warranty: true,
    //     brand: 'Royal Enfield',
    //     enginePower: '400cc',
    //     model: 'Himalayan',
    //   },
    //   highlightedFields: [
    //     {
    //       title: 'YEAR',
    //       field: 'year',
    //       icon: 'icon-attach',
    //     },
    //     {
    //       title: 'KILOMETERS',
    //       field: 'kilometers',
    //       icon: 'icon-attach',
    //     },
    //     {
    //       title: 'WHEELS',
    //       field: 'wheels',
    //       icon: 'icon-attach',
    //     },
    //     {
    //       title: 'WARRANTY',
    //       field: 'warranty',
    //       icon: 'icon-attach',
    //     },
    //   ],
    //   description:
    //     '<p class="mb-4">This is a used Royal Enfield Himalayan 410cc 2016 Motorcycle/Bike model with Petrol variant. It is a Off Road Motorcycle/Bike and it is registered with the state maharashtra. The specifications of the Motorcycle/Bike include engine displacement 411 cc , fuel efficiency level 32 kmpl , fuel tank capacity of 15 litres, maximum power of 24.50 bhp @ 6,500 rpm , maximum torque of 32 Nm @ 4,500 rpm and transmission is Manual. If this second hand vehicle goes hand in hand with your requirement, comfort and affordability, then it is the best option for you. </p> <p class="mb-4"> However, this particular pre owned Motorcycle/Bike has been driven for 9,600 KMs only, the condition of the Motorcycle/Bike and the Full Circle Trust Score is 8.4. The ownership of the Motorcycle/Bike belongs to the First Owner , who is willing to sell royal enfield himalayan in mumbai and in other adjacent cities at a price of $2500.00. </p>',
    //   keyFields: [
    //     {
    //       title: 'Make',
    //       field: 'brand',
    //     },
    //     {
    //       title: 'Model',
    //       field: 'model',
    //     },
    //     {
    //       title: 'Trim',
    //       field: 'enginePower',
    //     },
    //     {
    //       title: 'Year',
    //       field: 'year',
    //     },
    //   ],
    //   banks: [
    //     {
    //       name: 'Sunbank',
    //       image: require('../../assets/images/products/sunbank.png'),
    //       interestRateDescription: '8.65% Floating',
    //     },
    //     {
    //       name: 'Sunbank',
    //       image: require('../../assets/images/products/sunbank.png'),
    //       interestRateDescription: '8.65% Floating',
    //     },
    //   ],
    //   rating: {
    //     overallRating: 4.7,
    //     totalRatings: 152,
    //     totalReviews: 167,
    //     ratingSplit: {
    //       '5': 70,
    //       '4': 50,
    //       '3': 20,
    //       '2': 10,
    //       '1': 1,
    //     },
    //   },
    // };
    // return pr;
  }
  public async search(
    filters: Dict,
    filterType: Dict,
    sortBy: SortBy,
    searchTerm?: string,
    address?: any,
    categoryId?: string,
    subcategoryId?: string,
    _page?: number,
    city?: string
  ): Promise<{
    filters: Dict<Filters>;
    products: Product[];
    category: Category;
    count: number;
  }> {
    // const items: Product[] = [];
    // for (let i = 0; i <= 10; i++) {
    //   items.push(...products);
    // }
    const url = `${this.apiDomain}/search/product-filter`;
    const response = await http.post<ApiResponse<any>>(url, {
      filters,
      filterType,
      searchTerm,
      location: address?.latLng,
      categoryId,
      subcategoryId,
      page: _page || 1,
      perPage: 15,
      sortBy,
      city,
    });

    const items: Product[] = response.data.data?.response;

    return {
      products: items,
      filters: response.data.data?.filters,
      category: response.data.data?.category,
      count: response.data.data?.count || 0,
      // filters: {
      //   category: {
      //     type: FilterType.MultiSelect,
      //     meta: {
      //       key: 'category',
      //       name: 'Category',
      //       textField: 'name',
      //       valueField: 'id',
      //       options: [
      //         {
      //           name: 'Mobile Phones',
      //           id: 'mobilephone',
      //         },
      //         {
      //           name: 'Electronics & Appliances',
      //           id: 'electroincs',
      //         },
      //         {
      //           name: 'TVs, Video - Audio',
      //           id: 'tv-video-audio',
      //         },
      //       ],
      //       minNumberOfItemsToShow: 5,
      //     },
      //   },
      //   amount: {
      //     type: FilterType.MinMaxSlider,
      //     meta: {
      //       name: 'Amount',
      //       key: 'amount',
      //       min: 500,
      //       max: 10000,
      //       valueSuffix: '$',
      //     },
      //   },
      //   brands: {
      //     type: FilterType.MultiSelect,
      //     meta: {
      //       key: 'brands',
      //       name: 'Brands',
      //       textField: 'name',
      //       valueField: 'id',
      //       options: [
      //         {
      //           name: 'Apple',
      //           id: 'apple',
      //         },
      //         {
      //           name: 'Mkeke',
      //           id: 'mkeke',
      //         },
      //         {
      //           name: 'Samsung',
      //           id: 'samsung',
      //         },
      //         {
      //           name: 'Nokia',
      //           id: 'nokia',
      //         },
      //         {
      //           name: 'Cannon',
      //           id: 'cannon',
      //         },
      //         {
      //           name: 'Nikon',
      //           id: 'nikon',
      //         },
      //       ],
      //       minNumberOfItemsToShow: 5,
      //     },
      //   },
      // },
    };
  }

  public async suggest(
    searchTerm?: string,
    address?: any
  ): Promise<{ products: any }> {
    // const items: Product[] = [];
    // for (let i = 0; i <= 10; i++) {
    //   items.push(...products);
    // }

    const url = `${this.apiDomain}/search/product`;
    const response = await http.post<ApiResponse<Product[]>>(url, {
      searchTerm,
      location: address?.latLng,
      city: address?.address?.city || address?.address?.state,
      page: 1,
      perPage: 15,
    });
    const items: Product[] = response.data.data;

    return {
      products: items,
    };
  }

  public async getProductReview(productId: string): Promise<ProductReview[]> {
    return [
      {
        title: 'Perfectly made for no roads and all roads.',
        comment:
          "I have been riding this bike for a year now. This is the fastest bike that can run on super bad roads. Superb handling, enough power, smooth and no vibrations. For people who did not like this bike, it's actually not made for them.",
        rating: 4.7,
      },
      {
        title: 'Perfectly made for no roads and all roads.',
        comment:
          "I have been riding this bike for a year now. This is the fastest bike that can run on super bad roads. Superb handling, enough power, smooth and no vibrations. For people who did not like this bike, it's actually not made for them.",
        rating: 4.7,
      },
      {
        title: 'Perfectly made for no roads and all roads.',
        comment:
          "I have been riding this bike for a year now. This is the fastest bike that can run on super bad roads. Superb handling, enough power, smooth and no vibrations. For people who did not like this bike, it's actually not made for them.",
        rating: 4.7,
      },
      {
        title: 'Perfectly made for no roads and all roads.',
        comment:
          "I have been riding this bike for a year now. This is the fastest bike that can run on super bad roads. Superb handling, enough power, smooth and no vibrations. For people who did not like this bike, it's actually not made for them.",
        rating: 4.7,
      },
      {
        title: 'Perfectly made for no roads and all roads.',
        comment:
          "I have been riding this bike for a year now. This is the fastest bike that can run on super bad roads. Superb handling, enough power, smooth and no vibrations. For people who did not like this bike, it's actually not made for them.",
        rating: 4.7,
      },
    ];
  }

  public async setFavourite(productId: string): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/product/favourite/${productId}`;
    const response = await http.post<ApiResponse<Product>>(
      url,
      { productId },
      this.store
    );
    return response.data;
  }
  public async reportAd(data: ReportPostForm): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/product/report`;
    const response = await http.post<ApiResponse<Product>>(
      url,
      data,
      this.store
    );
    return response.data;
  }

  public async bidNow(data: BidNowData): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/product/add-bid`;
    const response = await http.post<ApiResponse<any>>(url, data, this.store);
    return response.data;
  }

  public async getBiddingHistory(
    request: BiddingHistoryRequestModel
  ): Promise<PaginatedApiResponse<any>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort || {};
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/bidding-history?${stringify(query)}`;
    const response = await http.get<BiddingResponse>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async getProductEnquires(
    request: ProductEnquiryRequestModel
  ): Promise<PaginatedApiResponse<EnquiryModel>> {
    const state = store.getState();
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort || {};
    const query = {
      _sort: field,
      _order: order,
      page,
      perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/product/product-enquiries?${stringify(
      query
    )}`;
    const response = await http.get<EnquiryResponse>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async enquireProduct(data: ProductEnquiryModel): Promise<any> {
    const url = `${this.apiDomain}/product/product-enquiry`;
    const response = await http.post<any>(url, data, this.store);
    return response.data;
  }
  public async downloadenquiries(
    productId?: string,
    productName?: string
  ): Promise<void> {
    const state = store.getState();
    api.setToken(state.authUser.token);
    const url = `${this.apiDomain}/product/enqury-list/download`;
    const resposne = await http.downloadPostData(
      url,
      { productId },
      this.store
    );
    http.saveDownloadedFile(
      resposne.data,
      'application/application/vnd.openxmlformats',
      `${productName}-${new Date()}.xlsx`
    );
  }
}
