import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import ForgotPasswordLink from '../../../components/links/forgot-password-link';
import classnames from 'classnames';
import ErrorMessage from '../../../components/text/ErrorMessage';

interface FormProps {
  email: string;
  password: string;
}

const LoginWithEmailForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
  defaultEmail?: string;
  error?: string;
}> = (props) => {
  const { control, handleSubmit, errors } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const renderEmail = () => {
    return (
      <div className="form-group">
        <label htmlFor="login-email" className="sr-only">
          Email
        </label>
        <Controller
          control={control}
          rules={{ required: true, validate: { isEmail: validator.isEmail } }}
          name="email"
          defaultValue={props.defaultEmail}
          render={({ onChange, value, name }) => (
            <input
              type="text"
              className={classnames({
                'form-control': true,
                error: !!errors.email,
              })}
              placeholder="Email"
              name={name}
              value={value}
              onChange={onChange}
              id="login-email"
            />
          )}
        />
        {errors.email && (
          <ErrorMessage>
            {errors.email.type === 'required'
              ? 'Email is required'
              : 'Invalid Email'}
          </ErrorMessage>
        )}
      </div>
    );
  };
  const renderPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="login-password" className="sr-only">
          Password
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="password"
          defaultValue={props.defaultEmail}
          render={({ onChange, value, name }) => (
            <input
              type="password"
              className={classnames({
                'form-control': true,
                error: !!errors.password,
              })}
              id="login-password"
              placeholder="Password"
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.password && (
          <ErrorMessage>
            {errors.password.type === 'required'
              ? 'Password is required'
              : 'Invalid Password'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderForgotPasswordLink = () => {
    return (
      <div className="mb-3 mt-2 pb-4 pt-3">
        <ForgotPasswordLink className="text-body text-underline">
          Forgot your password?
        </ForgotPasswordLink>
      </div>
    );
  };

  const renderSignupBannerResponsive = () => {
    return (
      <div className="form-group d-lg-none">
        Enter your personal details and
        <br />
        Start making money from selling!
        <a href="/signup" className="text-underline font-weight-bold mt-5">
          Sign Up
        </a>
      </div>
    );
  };

  const renderSigninButton = () => {
    return (
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold"
          disabled={props.isSubmitting}
        >
          Sign In
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {renderEmail()}
      {renderPassword()}
      <ErrorMessage>{props.error}</ErrorMessage>
      {renderForgotPasswordLink()}
      {renderSigninButton()}
      {renderSignupBannerResponsive()}
    </form>
  );
};

export default LoginWithEmailForm;
