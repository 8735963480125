import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FileUploadView } from '../../../components/upload';
import useAuth from '../../../hooks/useAuth';
import api from '../../../api';
import Menu from '../../common/menu';
import suncashimage from '../../../assets/images/suncash.svg';
import { PageType } from '..';
import MyAccount from '../../common/my-account';
import { useDispatch, useSelector } from 'react-redux';
import { useDataLoader } from 'react-remote-data-hooks';
import { PaginatedApiResponse, Product } from '../../../api/models';
import { State } from '../../../store/interfaces';
import { ContainerLoader } from '../../../components/loader';
import ProductTile from '../../../components/product/ProductTile';
import {
  loadProductsRequestAction,
  loadProductsSuccessAction,
  loadProductsErrorAction,
} from '../../../store/reducers/profile';

import defaultAvatar from '../../../components/avatar/default-avatar.png';
import Empty from '../../../components/empty';
import classNames from 'classnames';
import { AdsPaginationProps } from '../../../components/ads-pagination';
import AdsPagination from '../../my-ads/components/pagination';
import { scrollTop } from '../../../utils/window-utils';

export enum MyProductType {
  All = 'all',
  Buying = 'buying',
  Selling = 'selling',
  Sold = 'sold',
  Bought = 'bought',
  Favorites = 'favorite',
}

const initialFilter = {
  [MyProductType.Buying]: {
    page: 1,
    perPage: 10,
  },
  [MyProductType.Selling]: {
    page: 1,
    perPage: 10,
  },
  [MyProductType.Sold]: {
    page: 1,
    perPage: 10,
  },
  [MyProductType.Bought]: {
    page: 1,
    perPage: 10,
  },
  [MyProductType.Favorites]: {
    page: 1,
    perPage: 10,
  },
};

const Profile: React.FunctionComponent = () => {
  const auth = useAuth();
  const authState = useSelector((state: State) => state.authUser);
  const profile = useSelector((state: State) => state.profile);
  const dispatch = useDispatch();

  const [filter, setFilter] = useState<any>(initialFilter);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [products, setProducts] = useState<PaginatedApiResponse<Product>>();
  const [productType, setProductType] = useState<string>(MyProductType.Buying);
  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };
  const updateFilter = (key: string, val: unknown) =>
    setFilter({
      ...filter,
      [productType]: { ...filter[productType], [key]: val },
    });
  const loadProducts = async (refresh?: boolean, selectedTab?: string) => {
    if (!refresh && (profile.isLoading || profile.isLoaded)) {
      return;
    }
    const type = selectedTab || productType;

    dispatch(loadProductsRequestAction());
    try {
      const response = await api.profile.getMyProducts({
        type,
        pagination: {
          page: filter[productType].page || 1,
          perPage: 10,
        },
      });
      setProducts(response);
      // const { count, page, perPage } = response;
      dispatch(loadProductsSuccessAction(response.data));
    } catch (error) {
      dispatch(loadProductsErrorAction());
    }
  };

  useEffect(() => {
    onTabClick(productType);
  }, [filter[productType]]);

  useEffect(() => {
    setIsFirstLoad(false);
  }, []);
  // const updateFilter = (key: string, val: unknown) =>
  //   setFilter({ ...filter, [key]: val });
  useEffect(() => {
    if (!profile.isLoaded && !profile.isLoading) {
      loadProducts();
    }
  }, [profile.products, profile.isLoaded, profile.isLoading]);

  const allClassName =
    productType === MyProductType.All ? 'nav-link active' : 'nav-link';

  const buyingClassName =
    productType === MyProductType.Buying ? 'nav-link active' : 'nav-link';

  const sellingClassName =
    productType === MyProductType.Selling ? 'nav-link active' : 'nav-link';

  const soldClassName =
    productType === MyProductType.Sold ? 'nav-link active' : 'nav-link';

  const boughtClassName =
    productType === MyProductType.Bought ? 'nav-link active' : 'nav-link';

  const favoritesClassName =
    productType === MyProductType.Favorites ? 'nav-link active' : 'nav-link';

  const onTabClick = async (type: string) => {
    setProductType(type);
    dispatch(loadProductsRequestAction());
    await loadProducts(true, type);
  };
  const userId = authState.profile?._id || '';

  const renderEmpty = () => {
    return <Empty />;
  };

  const { count, page, perPage } = products || {
    count: 0,
    page: 1,
    perPage: 10,
  };

  const renderSearchResults = () => {
    if (profile.products.length === 0 && profile.isLoaded) {
      return renderEmpty();
    }
    if (profile.products.length && profile.isLoaded) {
      const prods: Product[] = profile.products;

      return (
        <div className="row gutter-5">
          {prods.map((pr) => {
            return (
              <ProductTile
                product={pr}
                type={productType}
                className="col-sm-4 col-6 d-flex"
              ></ProductTile>
            );
          })}
        </div>
      );
    }
    return <></>;
  };

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Profile
          </li>
        </ol>
      </nav>
    );
  };

  return (
    <div className="py-0 py-lg-4">
      <div className="container rmv-padd-sm">
        {renderBreadCrumbs()}
        <div className="row pb-4 gutter-0">
          <MyAccount pageType={PageType.Profile} />
          <div className="col-lg-9">
            <div className="right-panel">
              <div className="profile-inner">
                <h4 className="text-gray-900 d-none d-lg-block font-weight-bold">
                  Profile
                </h4>

                <div className="profile-fill pt-lg-3">
                  <a
                    className="font-xxl icon-settings m-3 position-absolute right-0 text-white top-0"
                    href="/edit-profile"
                  ></a>
                  <div className="profile-header d-lg-flex">
                    <div className="profile-pic mr-lg-4">
                      <img
                        src={auth.profile?.avatar?.path || defaultAvatar}
                        className="img-fluid img-cover rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="profile-detail w-100">
                      <div className="row gutter-0">
                        <div className="col-lg-6">
                          <div className="name-rating">
                            <h4 className="font-weight-bold m-0 d-inline-flex align-items-center">
                              {auth.profile?.name}{' '}
                              <span className="status active ml-2">
                                {auth.profile?.accountType}
                              </span>
                            </h4>

                            {/* <div className="ratings">
                              <span className="icon-star-filled-2 star mr-1"></span>
                              <span className="icon-star-filled-2 star mr-1"></span>
                              <span className="icon-star-filled-2 star mr-1"></span>
                              <span className="icon-star-filled-2 star mr-1"></span>
                              <span className="icon-star-outline-2 star-outer"></span>
                              <span className="pl-2 text-gray-500">(25)</span>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-flex justify-content-end">
                          <div className="edit-prof text-lg-right text-center pt-lg-0 pt-2">
                            <a
                              className="text-primary font-sm"
                              href="/edit-profile"
                            >
                              <i className="icon-edit pr-2"></i>Edit Profile
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="align-items-lg-center gutter-0 row pt-2 pt-lg-0">
                        <div className="col-lg-6">
                          <div className="name-rating">
                            <div className="social pt-1">
                              <ul className="align-items-center d-flex list-unstyled m-0 p-0 justify-content-center justify-content-lg-start ">
                                <li className="d-flex pr-3 align-items-center">
                                  <span className="icon-verified2 font-lg pr-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </span>{' '}
                                  <span className="verify-text text-gray-500">
                                    Verified with
                                  </span>
                                </li>
                                <li className="d-flex pr-1 font-xxl">
                                  <span
                                    className={classNames({
                                      'icon-fb': true,
                                      active: auth.profile?.fbVerified,
                                    })}
                                  ></span>
                                </li>
                                <li className="d-flex pr-1 font-xxl">
                                  <span
                                    className={classNames({
                                      'icon-gplus': true,
                                      active: auth.profile?.googleVerified,
                                    })}
                                  >
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                    <span className="path6"></span>
                                    <span className="path7"></span>
                                  </span>
                                </li>
                                <li className="d-flex pr-1 font-xxl">
                                  <span
                                    className={classNames({
                                      'icon-phone2': true,
                                      active: auth.profile?.phoneVerified,
                                    })}
                                  >
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                  </span>
                                </li>
                                <li className="d-flex pr-1 font-xxl">
                                  <span
                                    className={classNames({
                                      'icon-mail2': true,
                                      active: auth.profile?.emailVerified,
                                    })}
                                  >
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                  </span>
                                </li>
                                {/* <li className="font-xxl">
                                  <a href="#">
                                    <img
                                      src={suncashimage}
                                      className="img-fluid"
                                    />
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                          <div className="pt-2 mt-1 mb-3 mb-lg-0">
                            <span className="font-xl font-weight-bold text-gray-900 mr-1">
                              {auth.profile?.productCount}
                            </span>
                            <label className="mb-0 text-gray-500 font-base">
                              Posts
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-12 mb-3 mb-lg-0">
                          <div className="follow-btns d-flex justify-content-lg-end justify-content-center">
                            <Link
                              className="btn-outline-primary btn px-lg-3 py-1 mr-2 px-4"
                              to="/following"
                            >
                              <span className="font-xxl pr-2">
                                {auth.profile?.followedCount || 0}
                              </span>
                              Followers
                            </Link>
                            <Link
                              className="btn-outline-primary btn px-lg-3 py-1 ml-2 px-4"
                              to="/following"
                            >
                              <span className="font-xxl pr-2">
                                {auth.profile?.followingCount || 0}
                              </span>
                              Following
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {profile.isLoading && (
                    <ContainerLoader height={250}></ContainerLoader>
                  )}

                  {profile.isLoaded && (
                    <div className="profile-tab pt-md-5">
                      {' '}
                      <div className="col-12 px-0">
                        <div className="tab-profile pt-lg-2">
                          <div className="tab-hed mb-lg-3 pb-4 mb-2 pb-lg-0">
                            <ul
                              className="nav nav-tabs"
                              id="myTab"
                              role="tablist"
                            >
                              {/* <li className="nav-item d-none">
                              <a
                                className={allClassName}
                                id="home-tab"
                                data-toggle="tab"
                                role="tab"
                                href="javascript:void(0)"
                                onClick={() => onTabClick(MyProductType.All)}
                                aria-controls="home"
                                aria-selected="true"
                              >
                                All Products
                              </a>
                            </li> */}
                              <li className="nav-item ">
                                <a
                                  className={buyingClassName}
                                  id="Buying-tab"
                                  data-toggle="tab"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    onTabClick(MyProductType.Buying)
                                  }
                                  role="tab"
                                  aria-controls="profile"
                                  aria-selected="true"
                                >
                                  Buying
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={sellingClassName}
                                  id="Selling-tab"
                                  data-toggle="tab"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    onTabClick(MyProductType.Selling)
                                  }
                                  role="tab"
                                  aria-controls="messages"
                                  aria-selected="false"
                                >
                                  Selling
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={soldClassName}
                                  id="Sold-tab"
                                  data-toggle="tab"
                                  href="javascript:void(0)"
                                  onClick={() => onTabClick(MyProductType.Sold)}
                                  role="tab"
                                  aria-controls="messages"
                                  aria-selected="false"
                                >
                                  Sold
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={boughtClassName}
                                  id="Bought-tab"
                                  data-toggle="tab"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    onTabClick(MyProductType.Bought)
                                  }
                                  role="tab"
                                  aria-controls="messages"
                                  aria-selected="false"
                                >
                                  Bought
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className={favoritesClassName}
                                  id="Bought-tab"
                                  data-toggle="tab"
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    onTabClick(MyProductType.Favorites)
                                  }
                                  role="tab"
                                  aria-controls="messages"
                                  aria-selected="false"
                                >
                                  Favorites
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content">
                            {/* <div
                            className="tab-pane active"
                            id="Allchats"
                            role="tabpanel"
                            aria-labelledby="Allchats-tab"
                          >
                            <div className="row gutter-5">
                              {renderSearchResults()}
                            </div>
                          </div> */}
                            <div
                              className="tab-pane active"
                              id="Buying"
                              role="tabpanel"
                              aria-labelledby="Buying-tab"
                            >
                              {renderSearchResults()}
                              {renderPagination({
                                count,
                                page,
                                perPage,
                                onPageChange: (nextPage: number) => {
                                  updateFilter('page', nextPage);
                                  scrollTop();
                                },
                              })}
                            </div>
                            <div
                              className="tab-pane"
                              id="Selling"
                              role="tabpanel"
                              aria-labelledby="Selling-tab"
                            >
                              {renderSearchResults()}
                            </div>
                            <div
                              className="tab-pane"
                              id="Sold"
                              role="tabpanel"
                              aria-labelledby="Sold-tab"
                            >
                              {renderSearchResults()}
                            </div>
                            <div
                              className="tab-pane"
                              id="Bought"
                              role="tabpanel"
                              aria-labelledby="Bought-tab"
                            >
                              {renderSearchResults()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
