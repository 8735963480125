import React, { createRef, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { PageType } from '../../profile';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import {
  AccountType,
  PaginatedApiResponse,
  Product,
  RequestCustomPlan,
  Subscribe,
  SubscriptionPlan,
  SubscriptionStatus,
} from '../../../api/models';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import SelectInput from '../../../components/select-input';
import { RouteKeys } from '../../routes/route-keys';
import {
  getBoostBackLink,
  getTopFeaturedPlansLink,
} from '../../../utils/link-utils';
import {
  selectProductToSellFasterAction,
  selectProductToBoostBackAction,
} from '../../../store/reducers/catalog';
import MyProduct from './my-product';
import { CatalogFilter, ProductType } from '../../../api/models/catalog';
import AdsPagination, {
  AdsPaginationProps,
} from '../../../components/ads-pagination/pagination';
import Empty from '../../../components/empty';
import { toastr } from 'react-redux-toastr';
import { scrollTop } from '../../../utils/window-utils';
import ProductFilter from '../../../components/product-filter/ProductFilter';
import { ProductFilterModel } from '../../../components/product-filter/ProductFilterModel';
import ChoosePlan from '../../choose-plan/components/ChoosePlan';
import { usePerformAction } from 'react-remote-data-hooks';
import Payment, { PaymentType } from '../../payment/components/payment';
import ThankYou from '../../create/components/ThankYou';
export enum AdsType {
  'Ads',
  'Favourite',
}

interface PageQuery {
  page?: number;
  perPage?: number;
}

interface MyAdsFilter {
  [AdsType.Ads]: CatalogFilter & PageQuery;
  [AdsType.Favourite]: CatalogFilter & PageQuery;
}

const initialFilter = {
  [AdsType.Ads]: {
    status: '',
    query: '',
    startDate: '',
    endDate: '',
    category: '',
    subCategory: '',
    page: 1,
    perPage: 10,
  },
  [AdsType.Favourite]: {
    status: '',
    query: '',
    startDate: '',
    endDate: '',
    category: '',
    subCategory: '',
    page: 1,
    perPage: 10,
  },
};

const MyAdsScreen: React.FunctionComponent = () => {
  const [adsType, setAdsType] = useState<AdsType>(AdsType.Ads);
  const [filter, setFilter] = useState<MyAdsFilter>(initialFilter);
  const [adProducts, setAdProducts] = useState<Product[]>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [toggleFilter, setToggleFilter] = useState(false);
  const {
    data: productsResponse,
    loading,
    loaded,
    reload: reloadMyAds,
  } = useDataLoader<PaginatedApiResponse<Product>>(() =>
    api.catalog.getMyAds({
      pagination: {
        page: initialFilter[AdsType.Ads].page,
        perPage: initialFilter[AdsType.Ads].perPage,
      },
      sort: {},
      queryParams: {
        ...filter[AdsType.Ads],
        productType: ProductType.Sale,
      },
    })
  );

  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isChoosePlan, setIsChoosePlan] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');

  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();

  const { performAction: payment } = usePerformAction(
    (data: SubscriptionPlan): any => {
      setSubscriptionPlanSelected(data);
      setIsRedirectPayment(true);
    }
  );
  const [choosePlanProduct, setChoosePlanProduct] = useState<
    Product | undefined
  >();

  const { performAction: subscribe } = usePerformAction(
    (paymentId: any, stripePaymentToken?: any): any => {
      const subscribeData: Subscribe = {
        subscriptionPlan: subscriptionPlanSelected?._id || '',
        paymentId,
        paymentType: PaymentType.Subscription,
        categoryId: choosePlanProduct?.category?._id,
        stripePaymentToken,
        productId: choosePlanProduct?._id,
        isFirstTimeSubcription: true,
      };
      api.subscription.subscribe(subscribeData).then((data) => {
        if (data.status === SubscriptionStatus.PaymetSuccess) {
          setIsChoosePlan(false);
          setIsRedirectPayment(false);
          if (choosePlanProduct) activateProduct(choosePlanProduct?._id);
        }
      });
    }
  );

  const { performAction: checkSubscription } = usePerformAction(
    (productToActivate): any => {
      api.subscription
        .getRemainingPostsCountInCategory({
          categoryId: productToActivate?.category?._id,
          productType: productToActivate?.productType,
        })
        .then((response) => {
          // setIsLoaded(true);
          if (response.noOfPostsRemaining > 0) {
            if (productToActivate) {
              const subscriptionResponse = api.subscription.subscribeAndActivateProduct(
                {
                  subscriptionId: response.subscriptionId,
                  productId: productToActivate?._id,
                }
              );
              activateProduct(productToActivate?._id);
            }
          } else {
            setIsChoosePlan(true);
          }
        });

      // if (
      //   !authState.profile?.subscriptionDetails ||
      //   !authState.profile?.subscriptionDetails?.noOfPostsRemaining
      // ) {
      //   setIsChoosePlan(true);
      // } else {
      //   createProduct(product);
      // }
    }
  );

  const { performAction: requestCustomPlan } = usePerformAction((data): any => {
    setSubscriptionPlanSelected(data);
    // createProduct(productDetails);
    const subscribeData: RequestCustomPlan = {
      subscriptionPlan: data?._id || '',
    };
    api.subscription.requestCustomPlan(subscribeData).then((responseData) => {
      if (responseData.status === SubscriptionStatus.CustomRequestPending) {
        setThankYouHeading('Thank You');
        setThankYouMessage('We will contact you soon to discuss a custom plan');
        setIsThankYou(true);
      }
    });
  });

  const {
    data: favoritesResponse,
    loaded: favoriteLoaded,
    reload: reloadFavoriteAds,
  } = useDataLoader<PaginatedApiResponse<Product>>(() =>
    api.catalog.getFavoriteAds({
      pagination: {
        page: initialFilter[AdsType.Favourite].page,
        perPage: initialFilter[AdsType.Favourite].perPage,
      },
      sort: {},
      queryParams: {
        ...filter[AdsType.Favourite],
        productType: ProductType.Sale,
      },
    })
  );

  const [adLength, setAdLength] = useState(0);
  const [favLength, setFavLength] = useState(0);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const updateFilter = (key: string, val: unknown) =>
    setFilter({ ...filter, [adsType]: { ...filter[adsType], [key]: val } });

  const updateFilterByType = (tmpFilter: any) =>
    setFilter({
      ...filter,
      [adsType]: {
        ...filter[adsType],
        ...tmpFilter,
      },
    });

  const resetFilter = () =>
    setFilter({ ...filter, [adsType]: { ...initialFilter[adsType] } });

  useEffect(() => {
    setTimeout(() => setIsFirstLoad(false), 500);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) reloadMyAds();
  }, [filter[AdsType.Ads]]);

  useEffect(() => {
    if (!isFirstLoad) reloadFavoriteAds();
  }, [filter[AdsType.Favourite]]);

  useEffect(() => {
    setAdLength(productsResponse?.count);
  }, [productsResponse?.count]);

  useEffect(() => {
    setAdProducts(productsResponse?.data);
  }, [productsResponse?.data]);

  useEffect(() => {
    setFavLength(favoritesResponse?.count);
  }, [favoritesResponse?.count]);

  const updateProductFromResponse = (id: string, product: Product) => {
    setAdProducts([
      ...adProducts.map((p: Product) => {
        if (p._id === id) return product;
        return p;
      }),
    ]);
  };

  const deActivateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(
      productId,
      'inActive'
    );
    updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully de-activated');
  };

  const activateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(productId, 'active');
    updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully activated');
  };

  const markAsSold = async (productId: string) => {
    const { data } = await api.catalog.markAsSold(productId);
    updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully marked as sold.');
  };

  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };

  const choosePlanWhenNoSubscription = (product: Product) => {
    setChoosePlanProduct(product);
    checkSubscription(product);
  };

  const renderProducts = () => {
    const newDate = new Date();
    return (
      <div className="ad-products">
        {adProducts?.map((product: Product) => {
          return (
            <MyProduct
              type={AdsType.Ads}
              product={product}
              key={`my-product-${product._id}`}
              boostAction={() => {
                dispatch(selectProductToBoostBackAction(product));
                history.push(getBoostBackLink(product._id));
              }}
              newDate={newDate}
              sellFasterAction={() => {
                dispatch(selectProductToSellFasterAction(product));
                history.push(getTopFeaturedPlansLink(product._id));
              }}
              deActivateProduct={deActivateProduct}
              activateProduct={activateProduct}
              markAsSold={markAsSold}
              choosePlanWhenNoSubscription={choosePlanWhenNoSubscription}
            />
          );
        })}
        {productsResponse?.count === 0 ? <Empty /> : ''}
        {productsResponse?.count !== 0 &&
          renderPagination({
            count: productsResponse.count,
            page: productsResponse.page,
            perPage: productsResponse.perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop();
            },
          })}
      </div>
    );
  };

  const renderFavoriteProducts = () => {
    const newDate = new Date();
    const { data: favorites, count, page, perPage } = favoritesResponse;

    return (
      <>
        {favorites.map((product: Product) => {
          return (
            <MyProduct
              type={AdsType.Favourite}
              newDate={newDate}
              product={product}
              key={`my-fav-${product._id}`}
            />
          );
        })}

        {count === 0 ? <Empty /> : ''}
        {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
            },
          })}
      </>
    );
  };

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Ads
          </li>
        </ol>
      </nav>
    );
  };

  const filterRef = useRef<any>(null);

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  return (
    <>
      {!isChoosePlan && (
        <div className="my-ads py-0 py-lg-4">
          <div className="container">
            {renderBreadCrumbs()}
            <div className="row">
              <MyAccount pageType={PageType.MyAds} />
              {/* {loaded && favoriteLoaded && ( */}
              <>
                <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
                  <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
                    <Link
                      to="account"
                      className="icon-prev font-lg mr-3 text-white"
                    ></Link>
                    My Ads
                  </h6>
                  <i
                    className="icon-filter text-white font-xl"
                    onClick={() => {
                      // console.log(filterRef)
                      filterRef?.current?.toggleExtraFilter();
                      setToggleFilter(!toggleFilter);
                      setIsFilterActive(!isFilterActive);
                      // document.body.classList.toggle('open-filter');
                    }}
                  ></i>
                </div>
                <div className="col-lg-9 rmv-padd-mob">
                  <div className="right-panel">
                    {adsType === AdsType.Ads ? (
                      <ProductFilter
                        title="My Ads"
                        ref={filterRef}
                        defaultValues={initialFilter[AdsType.Ads]}
                        values={filter[AdsType.Ads]}
                        searchPlaceHolder="My Ads"
                        onFilterChange={(e: ProductFilterModel) =>
                          updateFilterByType(e)
                        }
                        onFilterReset={resetFilter}
                      />
                    ) : (
                      <ProductFilter
                        title="My Ads"
                        ref={filterRef}
                        defaultValues={initialFilter[AdsType.Favourite]}
                        values={filter[AdsType.Favourite]}
                        searchPlaceHolder="Favorites"
                        onFilterChange={(e: ProductFilterModel) =>
                          updateFilterByType(e)
                        }
                        onFilterReset={resetFilter}
                      />
                    )}
                    <div className="border-bottom">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-auto order-md-last">
                          <a
                            href={RouteKeys.Create}
                            className="btn btn-primary px-4 py-1 rounded-sm"
                          >
                            Post an Ad
                          </a>
                        </div>
                        <nav className="tabs-header myaccount col-md col-12 mt-md-0 mt-3">
                          <div
                            className="nav nav-tabs border-bottom-0"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              className={
                                adsType === AdsType.Ads
                                  ? 'nav-item nav-link active cursor-pointer'
                                  : 'nav-item nav-link cursor-pointer'
                              }
                              id="nav-home-tab"
                              data-toggle="tab"
                              onClick={() => setAdsType(AdsType.Ads)}
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              Ads{' '}
                              {loaded && adLength > 0 && (
                                <span className="count">({adLength})</span>
                              )}
                            </a>
                            <a
                              className={
                                adsType === AdsType.Favourite
                                  ? 'nav-item nav-link active cursor-pointer'
                                  : 'nav-item nav-link cursor-pointer'
                              }
                              id="nav-profile-tab"
                              data-toggle="tab"
                              onClick={() => setAdsType(AdsType.Favourite)}
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              Favorites{' '}
                              {favoriteLoaded && favLength > 0 && (
                                <span className="count">({favLength})</span>
                              )}
                            </a>
                          </div>
                        </nav>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="d-none mb-3 mob-package-block mt-3 py-3">
                          <div className="col-6">
                            <p className="font-base text-primary mb-0 font-weight-semibold">
                              Heavy Discount on Packages
                            </p>
                          </div>
                          <div className="col-6">
                            <Link
                              className="btn btn-primary font-base w-100"
                              to="account"
                            >
                              View Packages
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-content pt-lg-4 pt-2"
                      id="nav-tabContent"
                    >
                      {favoriteLoaded && loaded ? (
                        <>
                          <div
                            className={`tab-pane fade show ${
                              adsType === AdsType.Ads ? 'active' : ''
                            } `}
                            id="nav-ads"
                            role="tabpanel"
                          >
                            <div className="ads-item">{renderProducts()}</div>
                          </div>
                          <div
                            className={`tab-pane fade show ${
                              adsType === AdsType.Favourite ? 'active' : ''
                            } `}
                            id="nav-favs"
                            role="tabpanel"
                          >
                            <div className="ads-item">
                              {renderFavoriteProducts()}
                            </div>
                          </div>
                        </>
                      ) : (
                        renderContainerLoader()
                      )}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
      {isChoosePlan && !isRedirectPayment && !isThankYou && (
        <ChoosePlan
          onPlanSelect={(data: SubscriptionPlan) =>
            data.isCustom ? requestCustomPlan(data) : payment(data)
          }
          productType={choosePlanProduct?.productType}
          categoryId={choosePlanProduct?.category?._id}
          subCategoryId={choosePlanProduct?.subcategory?._id}
          onBackClick={() => {
            setIsChoosePlan(false);
            // setShowSummary(true);
          }}
        />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          paymentType={PaymentType.Subscription}
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          email={auth.profile?.email!}
          amount={subscriptionPlanSelected?.amount}
          subscriptionPlanId={subscriptionPlanSelected?._id}
          isReccuring={subscriptionPlanSelected?.isRecurring || false}
          successhandler={subscribe}
          planName={subscriptionPlanSelected?.name}
          period={subscriptionPlanSelected?.period}
          listingDays={subscriptionPlanSelected?.listingDays}
        ></Payment>
      )}
      {isThankYou && (
        <ThankYou
          productId={choosePlanProduct?._id}
          heading={thankYouHeading}
          message={thankYouMessage}
          productType={choosePlanProduct?.productType}
          isCustomPlan={subscriptionPlanSelected?.isCustom}
        />
      )}
    </>
  );
};
export default MyAdsScreen;
