import React from 'react';
import AppLayout from '../layout/app';
import MyLeadsScreen from './components/my-leads';

const MyAds: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyLeadsScreen />
    </AppLayout>
  );
};

export default MyAds;
