import { Attributes } from 'react';
import { Attribute } from './attribute';
import { Filter } from './filters';
import { MediaFile } from './media';

export interface Banner {
  _id: string;
  title: string;
  subTitle: string;
  image?: MediaFile;
  isActive?: boolean;
  bannerType: string;
  description?: string;
}

export enum BannerType {
  Top = 'top',
  Bottom = 'bottom',
}
