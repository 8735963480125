import { ApiResponse } from '../models';
import ApiService from './service';
import * as http from './http';
import { AllEntitiesResponse } from '../models/entity';

export default class EntityService extends ApiService {
  public async loadAllEntities(): Promise<ApiResponse<AllEntitiesResponse>> {
    const url = `${this.apiDomain}/entity/frontend`;

    const response = await http.get<ApiResponse<AllEntitiesResponse>>(url);
    return response.data;
  }
}
