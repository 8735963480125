import React from 'react';
import AppLayout from '../layout/app';
import MyEnquiryScreen from './components/my-enquiry';

const myEnquiry: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyEnquiryScreen />
    </AppLayout>
  );
};

export default myEnquiry;
