import React from 'react';
import "../../styles/cms.scss";

const ProhibitedItemsGuidelines: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-12 font-md text-gray-700">
                        <h2 className="text-center font-weight-bold mb-4 pb-3">Prohibited items guidelines</h2>
                        <p className="mb-5 font-md text-gray-700">
                            Although most things can be sold on DealDen, certain items aren't allowed. Some present legal risks
                            or may pose health and safety issues. We've also chosen not to allow items that we feel are
                            inappropriate for our community or could be considered offensive.<br />
                            Before posting your items, please read the guidelines below to ensure that they are OK to sell on
                            DealDen. Also please review our Posting rules and Community guidelines. DealDen may make exceptions
                            to these rules for certain verified sellers in its sole discretion.
                            <br />
                            <br />
                            Each Country has specific allowances.
                        </p>

                        <h4 className="font-weight-bold mb-3">The following items are not allowed for sale</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Alcohol, drugs & tobacco</li>
                            <li>Adult & mature content</li>
                            <li>Animals</li>
                            <li>Wildlife products</li>
                            <li>Counterfeit and replica items</li>
                            <li>Dangerous items</li>
                            <li>Recalled items</li>
                            <li>Misleading claims</li>
                            <li>Food items</li>
                            <li>Gift cards</li>
                            <li>Illegal items or encouraging illegal activity</li>
                            <li>Intangible items</li>
                            <li>Medical & healthcare items</li>
                            <li>Offensive materials</li>
                            <li>Cultural items</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Alcohol, drugs & tobacco</h4>
                        <p className="mb-3 font-md text-gray-700">Alcohol, drugs, tobacco, and related products are often subject to
                            regulatory restrictions and are illegal for sale in some cases. Because we can't enforce these laws
                            and regulations, we don't allow these items for sale, including:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Alcohol</li>
                            <li>Prescription drugs</li>
                            <li>Drugs, narcotics, or controlled substances (even those that are legal in some states)</li>
                            <li>Items marketed for the purpose of intoxication</li>
                            <li>Drug paraphernalia such as bongs, vaporizers, and pipes</li>
                            <li>Tobacco and related products, including e-cigarettes, e-juice, vaporizers, mods, hookahs, and
                                hookah accessories</li>
                            <li>Cannabis and cannabis-derived products, such as CBD</li>
                            <li>Growing or cultivation equipment for cannabis and cannabis-derived products</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Adult & mature content</h4>
                        <p className="mb-3 font-md text-gray-700">DealDen strives to be an open marketplace but understands that not
                            all people want to see explicit sexual content. Items intended for adult use or that contain
                            explicit sexual content are not allowed on DealDen, such as:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Explicit sexual content or nudity</li>
                            <li>Pornography</li>
                            <li>Adult toys</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Animals</h4>
                        <p className="mb-3 font-md text-gray-700">Our policy around animals and wildlife products represents
                            DealDen’s commitment to improving animal welfare in our own communities and in helping to protect
                            threatened and endangered animals around the world.
                        </p>
                        <p className="mb-3 font-md text-gray-700">You can certainly post many types of animals. However, depending
                            on your country some live animals are never allowed for sale on the marketplace including:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Cats </li>
                            <li>Dogs </li>
                            <li>Fish </li>
                            <li>Marine mammals</li>
                            <li>Reptiles such as lizards or snakes </li>
                            <li>Horses or ponies </li>
                            <li>Hamsters, gerbils, rabbits or other pets</li>
                            <li>Farm animals such as chickens, goats, pigs, or cows</li>
                            <li>Insects</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Wildlife & wildlife products</h4>
                        <p className="mb-3 font-md text-gray-700">You can certainly post many types of animal products. However,
                            wildlife products that were sourced illegally or are being traded in contravention of any law are
                            prohibited. Examples of prohibited live wildlife and their products include, but are not limited to:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Bears (e.g. Asiatic Black Bear, Giant Panda, Sloth Bear, Sun Bear)</li>
                            <li>Birds (e.g. African Grey Parrot, Falcons, Eagles, Helmeted Hornbill and some other Parrots)</li>
                            <li>Cetaceans (e.g. Dolphins, Porpoises, Whales)</li>
                            <li>Corals (Elkhorn, Staghorn)</li>
                            <li>Elephants</li>
                            <li>Marine Turtles</li>
                            <li>Otters (e.g. European River Otter, Giant Otter, California Sea Otter)</li>
                            <li>Pangolins (All)</li>
                            <li>Primates (e.g. Bonobo, Chimpanzee, Gorilla, Monkeys, Orangutan)</li>
                            <li>Reptiles & Amphibians (e.g. Ploughshare Tortoise, Iranian Spotted Newt, Indian Rock Python)</li>
                            <li>Rhinoceros</li>
                            <li>Sharks, Sawfishes, Manta & Devil Rays (e.g. Great White Shark, Basking Shark, Whale Shark,
                                Smalltooth Sawfish)</li>
                            <li>Tibetan Antelope (also known as Chiru and shawls known as Shahtoosh)</li>
                            <li>Wild Cats (e.g. Cheetah, Jaguar, Leopard, Lion, Ocelot, Tiger)</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Counterfeit or replica items</h4>
                        <p className="mb-3 font-md text-gray-700">The sale of bootlegs, counterfeits, fakes, and unauthorized or
                            pirated copies of items is illegal and not allowed on DealDen, including:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Counterfeits, fakes, and replicas of brand-name items, including items “inspired” by a brand
                                without permission of the owner</li>
                            <li>Bootlegged or unauthorized recordings</li>
                            <li>Pirated copies of any copyrighted materials</li>
                            <li>Use of a trademark or other implied affiliation with a brand </li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Dangerous items</h4>
                        <p className="mb-3 font-md text-gray-700">Items that pose health and safety concerns or that are regulated
                            or illegal are not allowed on DealDen. These include but are not limited to:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Firearms, no exceptions</li>
                            <li>Knives primarily used as weapons or for self-defense. Note: Cutlery, kitchen, and utility knives
                                are generally permitted</li>
                            <li>Mace, pepper spray, bear spray, tasers, and other weapons used to subdue humans or animals</li>
                            <li>Batons, billyclubs, or similar weapons used for striking a person or animal</li>
                            <li>Paintball guns, BB guns, guns that fire blanks, Airsoft Guns, and all related ammunition and
                                accessories</li>
                            <li>Sporting or hunting weapons such as bows and arrows, crossbows, archery items, including
                                historical or cultural items such tttas arrowheads</li>
                            <li>Swords, if sharpened or functional. Note: Decorative blunt swords are generally permitted</li>
                            <li>Weapon parts and accessories, including any item that attaches to a firearm, ammunition,
                                bullets, clips, arrows, and bolts</li>
                            <li>Body armor, including Kevlar and other tactical and protective vests, except protective clothing
                                for sporting activities</li>
                            <li>Regulated chemicals, poisons, or substances</li>
                            <li>New or used airbags sold separately from a vehicle</li>
                            <li>Hazardous materials such as: </li>
                            <li>Fireworks</li>
                            <li>Explosives</li>
                            <li>Items deemed to pose significant health or safety risks to others</li>
                            <li>Items used to incite violence</li>
                            <li>Gun manufacturing mills or production units</li>
                            <li>Recalled items (see below)</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Recalled items</h4>
                        <p className="mb-3 font-md text-gray-700">Items that are subject to a recall, typically because they are
                            deemed unsafe or defective by federal regulators, are not allowed on DealDen. These items present a
                            unique risk because the harm they present is often not visibly apparent.  Items recalled in the past
                            include
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Samsung Galaxy Note 7 cell phones</li>
                            <li>Fisher-Price Rock N’ Play infant sleepers</li>
                            <li>Fisher Price 4-in-1 Rock n' Glide soothers</li>
                            <li>Fisher Price 2-in-1 Soothe n' Play glider</li>
                            <li>Drop-side cribs</li>
                            <li>Three-wheel all-terrain vehicles (ATVs)</li>
                            <li>Boppy newborn loungers</li>
                            <li>Other items listed <a href="#">on the U.S. CPSC list of recalls</a></li>
                        </ul>
                        <p className="mb-4 font-md text-gray-700 pb-4">You should avoid selling or buying any recalled item or
                            product.
                            If you end up selling or buying a recalled item, you should contact the manufacturer as they may
                            offer a refund, replacement, or voucher in exchange for surrendering the recalled item. For more
                            information on recalled consumer products generally, visit the <a href="#">U.S. Consumer Product
                                Safety Commission’s website.</a>
                        </p>

                        <h4 className="font-weight-bold mb-2">Misleading claims</h4>
                        <p className="mb-3 font-md text-gray-700">Some consumer products are regulated by federal agencies or
                            organizations in order to protect the public against unreasonable risks of injury or death. While
                            some of these products are allowed on DealDen, we cannot verify the guarantees or certifications of
                            safety provided by these agencies. Therefore, certain posts that claim that an item has been
                            endorsed or certified by a federal agency or organization for safety, and that agency states to
                            DealDen they don’t provide such an endorsement or certification, are not allowed. Examples include:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Items claiming Consumer Product Safety Commission (CPSC) confirmation or certification</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Food items</h4>
                        <p className="mb-3 font-md text-gray-700">The sale of homemade prepared food is subject to regulatory
                            guidelines and often requires a permit. Because we can't enforce these restrictions, prepared food
                            is not allowed. Examples:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Most perishable goods, including meat, seafood, dairy, and cut fruits or vegetables</li>
                            <li>Homemade shelf products, such as jams, jellies, and pickles</li>
                            <li>Homemade meals or baked goods</li>
                            <li>Eggs sold outside of the seller's farm </li>
                            <p className="my-2">Exceptions to this policy include:</p>
                            <li>Whole, uncut fruits and vegetables</li>
                            <li>Eggs sold directly on the seller's farm</li>
                            <li>Unopened and packaged commercially sold food goods (canned soup, packages of cookies, etc.) as
                                long as the packaging has vvnot been tampered with and the items are sold before their
                                expiration date</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Gift cards</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Gift cards and reloadable cards are not allowed due to the
                            risk for buyers. The balance can't always be verified, and sometimes the card balance can be used or
                            cancelled by the original purchaser.
                        </p>

                        <h4 className="font-weight-bold mb-2">Illegal items or encouraging illegal activity</h4>
                        <p className="mb-3 font-md text-gray-700">Any item that is illegal or that encourages illegal activity is
                            not allowed, including but not limited to:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Offers to trade for illegal goods</li>
                            <li>Offers to trade for items defined as prohibited on DealDen</li>
                            <li>Items with sensitive information such as IDs, passports, and social security cards</li>
                            <li>Fake or forged documents (such as licenses, IDs, diplomas, and government-issued documents)</li>
                            <li>Items received through Government Assistance</li>
                            <li>Police badges or security uniforms, as these can be used to impersonate an officer or gain
                                access to secure areas</li>
                            <li>Locked phones or devices, for example blacklisted, activation, iCloud or similar locks</li>
                            <li>Current vehicle license plates or plates that were valid in the last 5 years</li>
                            <li>License plate tags</li>
                            <li>Cars without titles</li>
                            <li>Stolen or found good</li>
                            <li>Catalytic converters</li>
                            <li>Rideshare signs</li>
                            <li>TV streaming devices</li>
                            <li>Canisters or cylinders of pressurized gas (such as CO2 or nitrous oxide)</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Intangible Items</h4>
                        <p className="mb-3 font-md text-gray-700">DealDen is intended for the sale of individual, tangible items.
                            Items must exist and be available for immediate sale. Examples of things that are not allowed
                            include:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Services for intangible items such as dog walking, babysitting, and IT support</li>
                            <li>Advertisements </li>
                            <ul className="pl-3 mb-0 font-md text-gray-700">
                                <li>Links to or information about flea markets, yard sales, and garage sales</li>
                                <li>URLs, links, or redirecting to other websites</li>
                                <li>Rentals and timeshares</li>
                                <li>Multi-level marketing schemes and related promotional materials</li>
                                <li>Job postings</li>
                                <li>Lost pet ads</li>
                            </ul>
                            <li>Wanted ads</li>
                            <li>Solicitations</li>
                            <li>Digital account transfers</li>
                            <li>Invites, referral codes, or discount codes for apps or websites</li>
                            <li>Items planned for future purchase from another retailer, wholesaler, distributor, or
                                manufacturer</li>
                            <li>Items of unknown contents such as “mystery boxes” or “grab bags”</li>
                            <li>Items where the photo does not match the title or description</li>
                            <li>eBooks</li>
                            <li>Digital goods</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Medical & healthcare items</h4>
                        <p className="mb-3 font-md text-gray-700">Due to health and safety, privacy, and regulatory concerns,
                            certain medical and healthcare items are not allowed for sale on DealDen, including but not limited
                            to:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Devices, drugs, and medications that require a prescription from a licensed medical practitioner
                                (such as a dentist, doctor, ccoptometrist, or veterinarian)</li>
                            <li>Ventilators and other respiratory equipment</li>
                            <li>Cannabis and cannabis-derived products, such as CBD</li>
                            <li>Pills, vitamins, and supplements</li>
                            <li>Contact lenses</li>
                            <li>Used cosmetics</li>
                            <li>Used makeup sponges and applicators</li>
                            <li>Needles and items containing needles (such as syringes or tattoo equipment)</li>
                            <li>Listings that claim the item can diagnose, cure, mitigate, treat, or prevent a disease or
                                viruses, including COVID-19 cc(coronavirus)</li>
                            <li>Any post related to administering vaccinations, including access to, proof of, or a physical
                                item. </li>
                            <li>Posting any prohibited vaccine items mentioned above may result in immediate removal of your
                                account.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Offensive materials</h4>
                        <p className="mb-3 font-md text-gray-700">DealDen is an inclusive community that welcomes people from all
                            backgrounds. To build a respectful mobile marketplace, posts that contain foul language or that
                            promote hatred, violence, or discrimination are not allowed. This includes posts that support hate
                            or discrimination toward others based on age, race, ethnicity, national origin, religion, gender,
                            gender identity, disability, or sexual orientation, or items that promote or glorify organizations
                            with such views. Examples:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Posts containing racial slurs</li>
                            <li>Posts promoting intolerance or discrimination</li>
                            <li>Items that promote, support, or commemorate groups with views of hatred or intolerance (for
                                example Nazi, Neo-Nazi, KKK vvgroups, Proud Boys, QAnon, Antifa, or the Boogaloo movement)</li>
                            <li>Items that depict or glorify violence against people or animals</li>
                            <li>Items that reflect people of color through offensive or dehumanizing caricatures, including
                                historic, antique, or memorabilia vvitems</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Cultural items</h4>
                        <p className="mb-3 font-md text-gray-700">To build a respectful and diverse mobile marketplace, we may
                            prohibit certain cultural or historical artifacts, objects, or memorabilia. Examples of things that
                            are not allowed include:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700">
                            <li>Items that reflect people of color through offensive or dehumanizing caricatures, including
                                historic, antique, or memorabilia vvitems</li>
                            <li>Any item that violates the Indian Arts and Crafts Act by falsely suggesting it is: (1) produced
                                by Native Americans or American vvIndians; and/or (2) the product of a particular tribe or
                                tribal arts and crafts organization within the United States.</li>
                            <li>Native American, Alaska Native, American Indian cultural or ceremonial items, such as grave
                                markers, human remains, vvceremonial masks and pray sticks.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Non-compliance</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Posts that violate any of our guidelines, policies, or terms
                            may be removed at our discretion. Additional actions may be taken for severe or repeat offenses.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProhibitedItemsGuidelines;
