import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import classnames from 'classnames';
import SelectInput from '../../../../components/select-input';
import { DateInput } from '../../../../components/form-group';
import moment from 'moment';

interface FormProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  dob: Date | string;
}
const genders: { label: string; value: string }[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

const PersonalDetailsForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isUpdating?: boolean;
  default: { name?: string; email?: string };
}> = (props) => {
  const { control, handleSubmit, errors, watch } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const endDate = moment().subtract(18, 'year').toDate();

  const renderName = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-name" className="sr-only">
          Name
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="name"
          defaultValue={props.default?.name}
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.name,
                })}
                id="register-name"
                placeholder="Name"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.name && <div className="error">Name is required</div>}
      </div>
    );
  };
  const renderEmail = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-email" className="sr-only">
          Email Address
        </label>
        <Controller
          control={control}
          defaultValue={props.default?.email}
          rules={{ required: true, validate: { isEmail: validator.isEmail } }}
          name="email"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="email"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.email,
                })}
                id="register-email"
                placeholder="Email"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.email && (
          <>
            {errors.email.type === 'required' && (
              <div className="error">Email is required</div>
            )}
            {errors.email.type === 'isEmail' && (
              <div className="error">Invalid Email</div>
            )}
          </>
        )}
      </div>
    );
  };
  const renderPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-password" className="sr-only">
          Password
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="password"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.password,
                })}
                id="register-password"
                placeholder="Password"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.password && <div className="error">Password is required</div>}
      </div>
    );
  };
  const renderConfirmPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-confirm-password" className="sr-only">
          Confirm Password
        </label>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: { isMatching: (value) => value === watch('password') },
          }}
          name="confirmPassword"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.password,
                })}
                id="register-confirm-password"
                placeholder="Confirm Password"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.confirmPassword && (
          <>
            {errors.confirmPassword.type === 'required' && (
              <div className="error">Confirm Password is required</div>
            )}
            {errors.confirmPassword.type === 'isMatching' && (
              <div className="error">Passwords should match</div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderNextButton = () => {
    return (
      <div className="form-group pt-4">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          value=""
        >
          Next
        </button>
      </div>
    );
  };
  const renderGender = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-gender" className="sr-only">
          Gender
        </label>
        <Controller
          control={control}
          name="gender"
          rules={{
            required: true,
          }}
          render={({ onChange, name, value }) => (
            <SelectInput
              optionLabel="label"
              optionValue="value"
              placeholder="Gender"
              data={genders}
              onChange={(val: number) => {
                onChange(val);
              }}
              isSearchable={false}
            />
          )}
        />
        {errors.gender && <div className="error">Gender is required</div>}
      </div>
    );
  };
  const renderDOB = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-dob" className="sr-only">
          DOB
        </label>
        <Controller
          control={control}
          name="dob"
          render={({ onChange, value, name }) => (
            <DateInput
              placeholder="Date of Birth"
              onChange={(val) => {
                onChange(val);
              }}
              error={errors.dob?.message}
              default={value}
              maxDate={endDate}
            ></DateInput>
          )}
        ></Controller>
        {errors.dob && <div className="error">DOB is required</div>}
      </div>
    );
  };

  return (
    <div className="login-details col-lg-10 px-0 mt-4">
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {renderName()}
        {renderEmail()}
        {renderGender()}
        {renderDOB()}
        {renderPassword()}
        {renderConfirmPassword()}
        {renderNextButton()}
      </form>
    </div>
  );
};

export default PersonalDetailsForm;
