/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';
import { Product } from '../../api/models';
import classnames from 'classnames';
import api from '../../api';
import useAuth from '../../hooks/useAuth';

const FavouriteProduct: React.FunctionComponent<{
  product: Product;
  onClick: () => void;
}> = (props) => {
  const [favorited, setFavorited] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setFavorited(props.product?.favorited || false);
  }, [props.product]);

  const setFavourite = (productId: string) => {
    setFavorited(!favorited);
    api.catalog.setFavourite(productId);
  };
  return (
    <a
      className={classnames({
        'd-flex justify-content-center h-100 align-items-center cursor-pointer': true,
        'text-gray-600': !favorited,
        'text-primary': favorited,
        disabled: props.product.createdBy.id === auth.profile?._id,
      })}
      onClick={(e) => setFavourite(props.product._id)}
    >
      {favorited && <i className="icon-heart-filled"></i>}
      {!favorited && <i className="icon-heart-outline"></i>}
    </a>
  );
};

export default FavouriteProduct;
