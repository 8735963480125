import React from 'react';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import Routes from './routes/Routes';

import '../styles/common.scss';
import useEntities from '../hooks/useEntities';

const App: React.SFC = () => {
  useEntities();
  return (
    <div>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-center"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={false}
      />
      <Routes />
    </div>
  );
};

// without using withRouter, route changes only reflect in url but not on page.
export default withRouter(App);
