import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import api from '../../../api';
import { ReportPostForm } from '../../../api/models';
import { RadioSelectInputReportAd } from '../../../components/form-group';
import TextArea from '../../../components/form-group/TextArea';

const ReportAd: React.FunctionComponent<{
  reported?: boolean;
  adId: string;
  onRequestClose: () => void;
}> = (props) => {
  const { control, setValue, errors, handleSubmit } = useForm<ReportPostForm>({
    shouldFocusError: true,
    defaultValues: {
      reason: '',
      description: '',
      productId: props.adId,
    },
  });
  const reportAd = (postData: ReportPostForm & {}) => {
    const data = {
      ...postData,
    };
    data.productId = props.adId;
    api.catalog.reportAd(data).then((response) => {
      props.onRequestClose();
    });
  };

  const renderDescriptionInput = () => {
    return (
      <Controller
        control={control}
        name="description"
        rules={{ required: 'Description is required' }}
        render={({ onChange, name, value }) => {
          return (
            <div className="mb-4 pb-md-2 pt-2">
              <textarea
                className="form-control bg-white"
                rows={4}
                onChange={onChange}
                name={name}
                value={value}
                placeholder="Comments"
              ></textarea>
              <span className="d-block font-xxs py-1 text-danger">
                {errors?.description?.message}
              </span>
            </div>
          );
        }}
      ></Controller>
    );
  };

  const renderReasonInput = () => {
    return (
      <Controller
        control={control}
        name="reason"
        rules={{ required: 'Reason is required' }}
        render={({ onChange, name, value }) => {
          return (
            <>
              <RadioSelectInputReportAd
                options={[
                  {
                    label: 'Offensive Content',
                    value: 'offensive',
                  },
                  {
                    label: 'Fraud',
                    value: 'fraud',
                  },
                  {
                    label: 'Duplicate Ad',
                    value: 'duplicate',
                  },
                  {
                    label: 'Product already sold',
                    value: 'sold',
                  },
                  {
                    label: 'Other',
                    value: 'other',
                  },
                ]}
                optionLabel="label"
                optionValue="value"
                name={name}
                type="text"
                default={value}
                onChange={onChange}
              ></RadioSelectInputReportAd>
              <span className="d-block font-xxs py-1 text-danger">
                {errors?.reason?.message}
              </span>
            </>
          );
        }}
      ></Controller>
    );
  };
  const renderSubmitButton = () => {
    return (
      <input
        type="submit"
        className="btn btn-primary btn-block font-weight-bold py-2"
        value="Report this Ad"
      />
    );
  };

  return (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <form
          onSubmit={handleSubmit(reportAd, (err) => {
            toastr.error('Error', 'Please correct errors in form');
          })}
        >
          <div className="modal-body py-5 px-lg-5 px-4">
            <div
              className="close position-absolute right-0 top-0 mr-3 mt-3 cursor-pointer"
              onClick={(e) => props.onRequestClose()}
              aria-label="Close"
            >
              <span className="icon-close font-md"></span>
            </div>
            <h4 className="mb-4 pb-md-2">Item Report</h4>
            {renderReasonInput()}
            {renderDescriptionInput()}
            {renderSubmitButton()}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportAd;
