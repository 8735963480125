import React from 'react';
import './Upload.scss';

import uploadIcon from './file-upload.svg';
import Button from '../button';

interface CustomProps {
  id: string;
  title: string;
  description?: string;
  filename: string;
  fileSize: string;
  progress?: number;
  onClose?: () => void;
  disabled?: boolean;
  className?:string;
  errorMsg?:string;
}

const FileUploading: React.SFC<CustomProps> = (props) => {
  return (
    <>
      <div className={`uploadfile uploaded p-0 ${props.className || ''}`}>
        <div
          className="uploadfile__progress-bar"
          style={{ width: `${(props.progress || 0) * 100}%` }}
        ></div>
        <div className="uploadfile__outer uploadfile--spacing ">
          <label className="uploadfile__label">
            <span className="uploadfile__filename">
              <div className="uploadfile__icon px-2">
                <img src={uploadIcon} alt="" />
              </div>
              <span className="uploadfile__filename-outer">
                {/* <span className="uploadfile__filename-text">
                  {props.filename}
                </span> */}
                <span className="uploadfile__filename-subtext">
                  {`${((props.progress || 0) * 100).toFixed(0)}%`}
                </span>
              </span>
            </span>
          </label>
          {!props.disabled && (
            <Button type="button" btnlink onClick={props.onClose}>
              <span className="uploadfile__close">
                <i className="icon-close uploadfile__close-icon"></i>
              </span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUploading;
