import * as React from 'react';

import spinnerImage from './Spinner.svg';

interface ISpinnerProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    'src'
  > {}

const Spinner: React.FunctionComponent<ISpinnerProps> = (props) => {
  return <img src={spinnerImage} {...props} />;
};

export default Spinner;
