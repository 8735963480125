import { Product } from '../../api/models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// action types
const CATALOG_RECCOMENDATIONS_LOADED_ACTION = 'CATALOG/RECCOMENDATIONS_LOADED';
const CATALOG_RECCOMENDATIONS_ADD_PRODUCT_ACTION =
  'CATALOG/RECCOMENDATIONS_ADD_PRODUCT';
const CATALOG_SELECT_PRODUCT_SELL_FASTER = 'CATALOG/SELECT_PRODUCT_SELL_FASTER';
const CATALOG_SELECT_PRODUCT_SELL_FASTER_SUCCESS =
  'CATALOG/SELECT_PRODUCT_SELL_FASTER_SUCCESS';
const CATALOG_SELECT_PRODUCT_BOOST_BACK = 'CATALOG/SELECT_PRODUCT_BOOST_BACK';
const CATALOG_SELECT_PRODUCT_BOOST_BACK_SUCCESS =
  'CATALOG/SELECT_PRODUCT_BOOST_BACK_SUCCESS';
const RECCOMENDATIONS_RESET_DATA_ACTION = 'SEARCH/RESET_DATA_ACTION';

export interface CatalogState {
  reccomendations: Product[];
  productSelectedToSellFaster: Product | undefined;
  productSelectedToBoostBack: Product | undefined;
}

type ReccomendationsLoadedActionType = IActionWithPayload<
  typeof CATALOG_RECCOMENDATIONS_LOADED_ACTION,
  Product[]
>;

type ReccomendationsAddedType = IActionWithPayload<
  typeof CATALOG_RECCOMENDATIONS_ADD_PRODUCT_ACTION,
  Product
>;

type SelectProductSellFaster = IActionWithPayload<
  typeof CATALOG_SELECT_PRODUCT_SELL_FASTER,
  Product
>;

type SelectProductSellFasterSuccess = IActionWithPayload<
  typeof CATALOG_SELECT_PRODUCT_SELL_FASTER_SUCCESS
>;

type SelectProductBoostBack = IActionWithPayload<
  typeof CATALOG_SELECT_PRODUCT_BOOST_BACK,
  Product
>;

type SelectProductBoostBackSuccess = IActionWithPayload<
  typeof CATALOG_SELECT_PRODUCT_BOOST_BACK_SUCCESS
>;

type ReccomendationsResetDataActionType = IAction<
  typeof RECCOMENDATIONS_RESET_DATA_ACTION
>;

type Actions =
  | ReccomendationsLoadedActionType
  | ReccomendationsAddedType
  | SelectProductSellFaster
  | SelectProductSellFasterSuccess
  | SelectProductBoostBack
  | SelectProductBoostBackSuccess
  | ReccomendationsResetDataActionType;

export const reccomendationsLoadedAction = (
  reccomendations: Product[]
): ReccomendationsLoadedActionType => {
  return createActionWithPayload(
    CATALOG_RECCOMENDATIONS_LOADED_ACTION,
    reccomendations
  );
};

export const reccomendationsAddedAction = (
  product: Product
): ReccomendationsAddedType => {
  return createActionWithPayload(
    CATALOG_RECCOMENDATIONS_ADD_PRODUCT_ACTION,
    product
  );
};

export const selectProductToSellFasterAction = (
  product: Product
): SelectProductSellFaster => {
  return createActionWithPayload(CATALOG_SELECT_PRODUCT_SELL_FASTER, product);
};

export const selectProductToSellFasterSuccessAction = (): SelectProductSellFasterSuccess => {
  return createActionWithPayload(
    CATALOG_SELECT_PRODUCT_SELL_FASTER_SUCCESS,
    undefined
  );
};

export const selectProductToBoostBackAction = (
  product: Product
): SelectProductBoostBack => {
  return createActionWithPayload(CATALOG_SELECT_PRODUCT_BOOST_BACK, product);
};

export const selectProductToBoostBackSuccessAction = (): SelectProductBoostBackSuccess => {
  return createActionWithPayload(
    CATALOG_SELECT_PRODUCT_BOOST_BACK_SUCCESS,
    undefined
  );
};
export const reccomendationsResetDataAction = (): ReccomendationsResetDataActionType => {
  return createAction(RECCOMENDATIONS_RESET_DATA_ACTION);
};
// initial state
const initialState: CatalogState = {
  reccomendations: [],
  productSelectedToSellFaster: undefined,
  productSelectedToBoostBack: undefined,
};

// reducer
export default (
  state: CatalogState = initialState,
  action: Actions
): CatalogState => {
  switch (action.type) {
    case CATALOG_RECCOMENDATIONS_LOADED_ACTION:
      console.log(
        'CATALOG_RECCOMENDATIONS_LOADED_ACTION',
        action.payload,
        state.reccomendations
      );
      return {
        ...state,
        reccomendations: [...state.reccomendations, ...action.payload],
      };

    case CATALOG_RECCOMENDATIONS_ADD_PRODUCT_ACTION:
      // console.log(
      //   'CATALOG_RECCOMENDATIONS_ADD_PRODUCT_ACTION',
      //   action.payload,
      //   state.reccomendations
      // );
      return {
        ...state,
        reccomendations: [action.payload, ...state.reccomendations],
      };
    case CATALOG_SELECT_PRODUCT_SELL_FASTER:
      return {
        ...state,
        productSelectedToSellFaster: action.payload,
      };
    case CATALOG_SELECT_PRODUCT_SELL_FASTER_SUCCESS:
      return {
        ...state,
        productSelectedToSellFaster: action.payload,
      };
    case CATALOG_SELECT_PRODUCT_BOOST_BACK:
      return {
        ...state,
        productSelectedToBoostBack: action.payload,
      };
    case CATALOG_SELECT_PRODUCT_BOOST_BACK_SUCCESS:
      return {
        ...state,
        productSelectedToBoostBack: action.payload,
      };
    case RECCOMENDATIONS_RESET_DATA_ACTION:
      return {
        ...state,
        reccomendations: [],
      };

    default:
      return state;
  }
};
