import React, { useEffect, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { useHistory, useParams, Link } from 'react-router-dom';
import api from '../../api';
import { ContainerLoader } from '../../components/loader';
import useAuth from '../../hooks/useAuth';
import AppLayout from '../layout/app';
import PlainLayout from '../layout/plain';
import NotFound from '../notfound';

const CMSPAGES: React.FunctionComponent = () => {
  const auth = useAuth();
  const [loaded, setIsLoaded] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const params = useParams<{ key: string }>();

  const getCmsPageDetails = async (key: string) => {
    setIsLoading(true);
    try {
      const response = await api.cms.getPageByKey(key);
      setData(response.data);
    } catch (error) {
      setError(true);
      setData(undefined);
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCmsPageDetails(params.key);
  }, [loaded]);
  return (
    <AppLayout hideCategories>
      <>
        {loaded && !loading && data && (
          <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        )}
        {loaded && !loading && error && <NotFound></NotFound>}
        {loading && !loaded && <ContainerLoader height={500}></ContainerLoader>}
      </>
    </AppLayout>
  );
};

export default CMSPAGES;
