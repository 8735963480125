import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import VerifiedImg from '../../../assets/images/verified.png';
import {
  AccountType,
  BillingAddress,
  ProductDetails,
  ProductType,
} from '../../../api/models';
import api from '../../../api';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import DealCountDown, {
  CountDownType,
} from '../../../components/deal-count-down/CountDown';
import { AvatarView } from '../../../components/avatar';
import Empty from '../../../components/empty';
import { ContainerLoader } from '../../../components/loader';
import AddNewAddressForm from '../../profile/components/add-new-address';
import { useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import Modal from 'react-modal';
import Payment from '../../payment/components/payment';
import { PaymentType } from '../../../api/models/payment';
import useAuth from '../../../hooks/useAuth';
import ThankYou from '../../create/components/ThankYou';
import { RouteKeys } from '../../routes/route-keys';
import '../../../styles/detail.scss';
const customStyles = {
  content: {
    margin: '20px auto',
    inset: '0px',
    width: '100%',
    maxWidth: '780px',
    borderRadius: '10px',
  },
};

const BuyProduct = () => {
  const auth = useAuth();
  const history = useHistory();
  const params = useParams<{ slug: string }>();
  const [product, setProduct] = useState<ProductDetails>();
  const [isRedirectPayment, setIsRedirectPayment] = useState(false);
  const [isBiddingComplete, setIsBiddingComplete] = useState(true);
  const [canProceed, setCanProceed] = useState(false);
  const userProfile = useSelector((state: State) => state.authUser.profile);
  const [billingAddress, setBillingAddress] = useState<
    BillingAddress | undefined
  >();
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { data: productResponse, loading, loaded } = useDataLoader<
    ProductDetails
  >(() => api.catalog.getProductDetails(params.slug));
  const [paymentDetails, setPaymentDetails] = useState<
    | {
        amount: number;
        productId: string;
        productTitle: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    setProduct(productResponse);
    if (productResponse?.auctionDetails?.buyNowPrice) {
      setPaymentDetails({
        amount: productResponse.auctionDetails?.buyNowPrice,
        productId: productResponse._id,
        productTitle: productResponse.title,
      });
    }
    if (productResponse?.auctionDetails?.endDate) {
      setIsBiddingComplete(
        (new Date().getTime() >
          new Date(productResponse.auctionDetails?.endDate).getTime() &&
          productResponse?.isSold) ||
          false
      );
    }
  }, [productResponse]);

  useEffect(() => {
    setCanProceed(
      !isBiddingComplete &&
        billingAddress !== undefined &&
        paymentDetails !== undefined
    );
  }, [isBiddingComplete.toString(), billingAddress, paymentDetails]);

  useEffect(() => {
    setBillingAddress(userProfile?.billingAddress);
  }, [userProfile]);

  const { performAction: purchase } = usePerformAction((paymentId): any => {
    const purchaseData = {
      productId: paymentDetails?.productId,
      paymentId,
      paymentType: PaymentType.Purchase,
    };
    api.order.purchase(purchaseData).then((data) => {
      if (data.status === 'success') {
        setIsThankYou(true);
      }
    });
  });

  if (loading && !loaded) {
    return <ContainerLoader height={200} />;
  }
  if (!product) {
    return <Empty />;
  }
  if (product.isSold) {
    return <p>The product has been sold</p>;
  }
  if (product.productType !== ProductType.Auction) {
    return <p>You cannot buy this product</p>;
  }

  const renderNewAddressButton = () => {
    return (
      <button
        type="submit"
        className="btn btn-primary btn-sm py-1 mt-2"
        onClick={() => setIsModalOpen(true)}
      >
        <span className="font-weight-normal font-xs icon-plus pr-2"></span>Add
        New Address
      </button>
    );
  };

  const renderBillingAddress = () => {
    return (
      <address className="font-normal">
        <span className="font-weight-bold">
          {' '}
          {billingAddress?.firstName} {billingAddress?.lastName}
        </span>
        <p className="mb-1">
          {billingAddress?.address}
          <br />
          {billingAddress?.city}, {billingAddress?.state},{' '}
          {billingAddress?.country}, {billingAddress?.zip}
        </p>
        <a
          className="border border-primary cursor-pointer font-xs px-2 rounded py-1 "
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <i className="font-xxxs icon-edit pr-1"></i>Edit
        </a>
      </address>
    );
  };

  if (isRedirectPayment && !isThankYou) {
    return (
      <div className="my-ads py-0 py-lg-5">
        {paymentDetails && paymentDetails?.amount ? (
          <Payment
            accountType={AccountType.Individual}
            paymentType={PaymentType.Purchase}
            email={auth.profile?.email!}
            amount={+paymentDetails?.amount}
            isSinglePayment={true}
            productId={paymentDetails?.productId}
            successhandler={purchase}
            planName={paymentDetails?.productTitle}
          ></Payment>
        ) : (
          ''
        )}
      </div>
    );
  }

  if (isThankYou) {
    return (
      <div className="my-ads py-0 py-lg-5">
        <ThankYou
          productId={paymentDetails?.productId}
          heading={'Thank You'}
          message={'You have successfully purchased the product'}
          isCustomPlan={false}
          isPurchase={true}
        />
      </div>
    );
  }

  return (
    <div>
      {/* <code>Sajan</code> */}
      <div className="my-ads py-0 py-lg-5 buy-prod">
        <div className="container rmv-padd-sm">
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link
                to={RouteKeys.ProductDetails.replace(':slug', params.slug)}
                className="text-white"
              >
                <i className="icon-prev font-lg mr-3"></i>Buy Product
              </Link>
            </h6>
          </div>
          <div className="">
            <div className="justify-content-between align-items-center line-height-sm d-lg-flex d-none mb-3">
              <h1 className="mb-0">Buy Product</h1>
              <div className="back-btn">
                <Link
                  to={RouteKeys.ProductDetails.replace(':slug', params.slug)}
                  className="text-gray-500 cursor-pointer"
                >
                  <span className="icon-prev pr-1 font-xxxs"></span>Back
                </Link>
              </div>
            </div>
            <div className="bg-white p-3 px-lg-4 py-lg-3 rounded shadow auction-buynow">
              <div className="p-lg-1">
                <div className="border-bottom align-items-center">
                  <div className="row pb-3 auction-details justify-content-between">
                    <div className="col-md pb-4 pb-md-0">
                      {product?.auctionDetails ? (
                        <DealCountDown
                          startDate={
                            new Date(product?.auctionDetails?.startDate)
                          }
                          endDate={new Date(product?.auctionDetails?.endDate)}
                          onCompleted={() => setIsBiddingComplete(true)}
                          type={CountDownType.block}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="col-md pb-4 pb-md-0 seller-field d-flex align-items-center justify-content-md-center">
                      <figure className="rounded-circle m-0">
                        <AvatarView
                          src={product.createdBy.avatar?.path}
                          name="user"
                          size="medium"
                        />
                      </figure>
                      <figcaption className="pl-3">
                        <h6 className="text-truncate m-0 font-lg">
                          {product.createdBy.name}
                        </h6>
                        {product.createdBy?.emailVerified &&
                        product.createdBy?.phoneVerified ? (
                          <img
                            src={VerifiedImg}
                            width="64"
                            height="20"
                            alt="verified"
                          />
                        ) : (
                          ''
                        )}
                      </figcaption>
                    </div>
                    <div className="align-items-center col-md d-flex d-md-block font-base justify-content-between text-lg-right">
                      <span className="text-gray-500 d-md-block">
                        Buy now Price
                      </span>
                      <div className="price text-dark font-lg font-weight-bold">
                        $ {product.auctionDetails?.buyNowPrice}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 py-lg-4 border-bottom mid-field">
                  <div className="row">
                    <div className="col-lg-6 col-md-7">
                      <h6>Shipping Address</h6>
                      {!billingAddress?.firstName && renderNewAddressButton()}
                      {billingAddress?.firstName && renderBillingAddress()}
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <h6>Payment Method</h6>
                      <span>Online/Card</span>
                    </div>
                  </div>
                </div>
                <div className="py-3 py-lg-4">
                  <div className="row align-items-center  ">
                    <div className="align-items-center col-12 col-md-8 d-flex">
                      <figure className="mb-0 position-relative noresize listing w-100">
                        <div className="image overflow-hidden bg-light noresize">
                          <img
                            className="img-fluid"
                            src={
                              product?.defaultImage?.path ||
                              product?.image ||
                              product?.images[0]?.path
                            }
                          />
                        </div>
                        <h6 className="title font-base d-block text-gray-700 mb-0">
                          {product.title}
                        </h6>
                        <span className="text-muted font-normal">Seller:</span>{' '}
                        <span className="font-base">
                          {product.createdBy.name}
                        </span>
                        <div className="d-flex d-md-none justify-content-between align-items-center ">
                          <span className="mb-2">Quantity: 1</span>
                          <span className="font-weight-bold mb-2 font-base text-dark">
                            $ {product.auctionDetails?.buyNowPrice}
                          </span>
                        </div>
                      </figure>
                    </div>
                    <div className="col-12 col-md-2 d-md-block d-none">
                      <span className="text-muted">Quantity: </span> 1
                    </div>
                    <div className="col-12 col-md-2 col-sm-4 text-right font-base d-md-block d-none">
                      $ {product.auctionDetails?.buyNowPrice}
                    </div>
                  </div>
                </div>
                <div className="justify-content-between align-items-center pb-3 pb-lg-0 row">
                  <div className="col-12 col-md ">
                    <h5>Seller Location</h5>
                    <p>
                      {product.location.address}
                      <br />
                      {product.location.street
                        ? `${product.location.street}, `
                        : ''}
                      {product.location.city}
                      <br />
                      {product.location.state
                        ? `${product.location.state}, `
                        : ''}
                      {product.location.country}
                    </p>
                  </div>
                  <div className="col-12 col-md-auto btn-position">
                    <button
                      disabled={!canProceed}
                      className={`btn btn-primary px-4 btn-sm rounded ${
                        !canProceed ? 'disabled' : ''
                      }`}
                      onClick={() => setIsRedirectPayment(true)}
                    >
                      Place an Order @ ${product.auctionDetails?.buyNowPrice}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={isModalOpen || false}
          contentLabel={''}
          shouldCloseOnOverlayClick={true}
          style={customStyles}
        >
          <AddNewAddressForm
            onSave={(data: BillingAddress) => {
              setBillingAddress(data);
              setIsModalOpen(false);
            }}
            onClose={() => setIsModalOpen(false)}
            firstName={billingAddress?.firstName}
            lastName={billingAddress?.lastName}
            address={billingAddress?.address}
            city={billingAddress?.city}
            state={billingAddress?.state}
            zip={billingAddress?.zip}
            country={billingAddress?.country}
          />
        </Modal>
      </div>
    </div>
  );
};

export default BuyProduct;
