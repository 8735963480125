import { Dict } from '../../models';

export interface MutiSelectFilterMetaData {
  key: string;
  name: string;
  options: Dict[];
  textField: string;
  valueField: string;
  minNumberOfItemsToShow: number;
}

export interface MinMaxSliderFilterMetaData {
  key: string;
  name: string;
  min: number;
  max: number;
  valuePrefix?: string;
  valueSuffix?: string;
}

export interface SingleSliderFilterMetaData {
  key: string;
  name: string;
  min: number;
  max: number;
  valuePrefix?: string;
  valueSuffix?: string;
}

export enum FilterType {
  MultiSelect = 'MultiSelect',
  MinMaxSlider = 'MinMaxSlider',
  SingleSlider = 'SingleSlider',
}

export interface Filter<T, U extends FilterType, M> {
  type: U;
  meta: M;
  value?: T;
}

export type Filters =
  | Filter<string[], FilterType.MultiSelect, MutiSelectFilterMetaData>
  | Filter<number[], FilterType.MinMaxSlider, MinMaxSliderFilterMetaData>
  | Filter<number, FilterType.SingleSlider, SingleSliderFilterMetaData>;

export enum SortBy {
  Relevence = 'Price',
  LowToHigh = 'Low to High',
  HighToLow = 'High to Low',
}
