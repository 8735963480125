export enum RouteKeys {
  Home = '/',
  Login = '/login',
  PhoneLogin = '/phone-login',
  SignupOtp = '/signup-otp',
  Signup = '/signup',
  OnBoard = '/on-board',
  ForgotPassword = '/forgot-password',
  NotFound = '/page-not-found',
  Help = '/help',
  Create = '/create',
  Chats = '/chats',
  ChatsDetail = '/chats/:chatId',
  Feed = '/feed',
  Search = '/search',
  ProductDetails = '/product/:slug',
  ForgotPasswordOtp = '/forgot-password-otp',
  LoginWithPhoneOtp = '/login-phone-otp',
  PhoneSignup = '/phone-signup',
  ChoosePlan = '/choose-plan',
  Profile = '/profile',
  EditProfile = '/edit-profile',
  EditBankDetails = '/edit-bank-details',
  PayoutRequest = '/payout-request',
  UpdatePhone = '/update-phone',
  MyAds = '/my-ads',
  MyAuction = '/my-auction/',
  MyOrders = '/my-orders',
  Invoices = '/invoices',
  BoostBack = '/boost-back/:productId',
  TopFeaturedPlans = '/top-featured-plans/:productId',
  CustomSubscription = '/custom-subscription/:subscriptionId',
  Notifications = '/notifications',
  Settings = '/settings',
  Subscription = '/subscription',
  ChooseInterestedCategories = '/choose-interested-categories',
  ResponsiveMenu = '/account',
  ChangePassword = '/change-password',
  DeliverProduct = '/deliver/:token',
  EnquireNow = '/enquire-now/:slug',
  MyEnquiries = '/my-leads/:slug/enquiry',
  MyLeads = '/my-leads',
  Following = '/following',
  OrderDetail = '/order-detail/:id',
  BuyProduct = '/product/:slug/buy-product',
  VerifyEmail = '/verify-email',
  EditProduct = '/product/edit/:slug',
  PageDetails = '/page/:key',
  InvoiceDetail = '/invoice-detail/:id',
  PayoutDetail = '/payout-detail/:id',
  ScanQR = '/scan-qr',
  ContactUs = '/contact-us',
  HowItWorks = '/how-it-works',
  PostAnItem = '/post-an-item',
  TrustAndSafety = '/trust-and-safety',
  Business = '/business',
  Career = '/career',
  CommunityGuidelines = '/community-guidelines',
  PostingRules = '/posting-rules',
  ProhibitedItemsGuidelines = '/prohibited-items-guidelines',
  MeetupSpots = '/meetup-spots',
  LocalBusiness = '/local-business',
}
