import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import LeftPane from './components/left-pane';
import LoginLeftPane from './components/login-left-pane';
import PhoneNumberForm from './components/phone-number-form';
import HelpLink from '../../components/links/help-link';
import useAuth from '../../hooks/useAuth';

const LoginWithPhoneScreen: React.FunctionComponent = () => {
  const { loginWithPhone, updating,loginError } = useAuth();
 
  return (
    <AuthLayout>
      <LoginLeftPane  />
      <div className="bg-white col-lg-7 col-xl enterphone-right-box content-box signup">
          <a href="/" title="DealDen">
            <LogoSVG className="d-lg-none" />
          </a>
        <div className="login-form mx-auto py-5">
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <PhoneNumberForm
            onSubmit={(data) => loginWithPhone(data)}
            isSubmitting={updating}
            error={loginError}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginWithPhoneScreen;
