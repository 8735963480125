import React from 'react';
import OnBoardIcon from '../../../../components/icons/on-board-icon';
import LogoSVG from '../../../../components/logo/logo-svg';

const Banner: React.FunctionComponent = (props) => {
  return (
    <div className="col-lg-6 col-xl-5 col-md-12 col-xl bg-box  d-lg-flex align-items-center text-white px-5 left-radius">
      <a href="/" title="DealDen" className="">
        <LogoSVG
          type="white"
          className="d-none d-lg-block position-absolute logo-white"
        />
      </a>
      <div className="text-center mx-auto">
        <OnBoardIcon className="d-block mb-4" />
        <h1 className="mb-3 font-weight-bold text-white">Congratulations!</h1>
        <h6 className="mb-4 font-weight-normal text-white">
          Your account has been verified successfully
        </h6>
      </div>
    </div>
  );
};

export default Banner;
