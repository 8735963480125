import React from 'react';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

const PhoneInput: React.FunctionComponent<PhoneInputProps> = (props) => {
  return <ReactPhoneInput {...props} />;
};

export default PhoneInput;
