import React, { FunctionComponent, useState } from 'react';
import { getProductLink } from '../../../utils/link-utils';
import { format } from 'date-fns';
import Button from '../../../components/button';
import { Product } from '../../../api/models';
import { OrderStatus, OrderStatusSyllables } from '../../../api/models/catalog';
import { Dropdown } from 'react-bootstrap';
import { MyLeadsType } from '../../../api/models/leads';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import ProductStatus from '../../../components/product-status';

interface MyProductProps {
  product: Product;
  newDate: Date;
  type: MyLeadsType;
  boostAction?: () => void;
  sellFasterAction?: () => void;
  activateProduct?: (productId: string) => void;
  deActivateProduct?: (productId: string) => void;
  choosePlanWhenNoSubscription?: (product: Product) => void;
}

const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any) => (
    <div
      className="dropdown filter position-absolute"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

const MyProduct: FunctionComponent<MyProductProps> = (
  props: MyProductProps
) => {
  const {
    product,
    boostAction,
    sellFasterAction,
    newDate,
    type: productType,
  } = props;

  const history = useHistory();

  const handleEnquiryClick = (id: string) => {
    history.push(RouteKeys.MyEnquiries.replace(':slug', id));
  };

  return (
    <>
      <div
        className={`bg-white shadow px-lg-3 pt-lg-3 pb-2 pb-lg-0 position-relative mb-3 rounded ${
          product.orderStatus === OrderStatus.Sold || !product.isActive
            ? 'inactive'
            : ''
        }`}
      >
        {productType === MyLeadsType.Leads ? (
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <span className="icon-more text-muted font-lg"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.type === MyLeadsType.Leads ? (
                <Dropdown.Item
                  onClick={() => handleEnquiryClick(props.product.slug)}
                >
                  Inquires
                </Dropdown.Item>
              ) : (
                ''
              )}
              {product.isActive ? (
                <Dropdown.Item
                  onClick={() => props?.deActivateProduct?.(props.product._id)}
                >
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => {
                    if (props?.product?.subscriptionPlan) {
                      props?.activateProduct?.(props.product._id);
                    } else {
                      props?.choosePlanWhenNoSubscription?.(product);
                    }
                  }}
                >
                  Activate
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() =>
                  history.push(
                    RouteKeys.EditProduct.replace(':slug', props.product.slug)
                  )
                }
              >
                Edit
              </Dropdown.Item>{' '}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          ''
        )}
        <div className="px-lg-0 px-3">
          <div className="row">
            <div className="col-12 d-lg-none d-block bg-gray-100 border-bottom border-gray-300">
              <div className="top-date">
                <span className="date d-block font-sm py-2">
                  <span className="text-muted">Posted on:</span>
                  {format(new Date(product.createdAt), 'dd MMM yyyy')}
                </span>
              </div>
            </div>
            <div className="align-items-center border-bottom-lg-0 col-12 col-md-6  py-2 py-lg-0">
              <figure className="mb-0 position-relative noresize ">
                <a
                  href={getProductLink(product.slug)}
                  className="image overflow-hidden bg-light noresize"
                >
                  <img className="img-fluid" src={product.defaultImage?.path} />
                </a>
                <figcaption>
                  <a href={getProductLink(product.slug)}>
                    <span className="date d-none font-sm d-lg-inline-block text-gray-700">
                      <span className="text-muted">Posted on:</span>
                      {format(new Date(product.createdAt), 'dd MMM yyyy')}
                    </span>

                    <span className="title font-base d-block text-gray-700 text-truncate">
                      {product.title}
                    </span>
                    <span className="price font-lg text-dark font-weight-bold  d-block d-md-none">
                      ${product.price.toFixed(2)}
                    </span>
                    <span className="font-sm text-muted mt-1">
                      In: {product.subcategory?.title} |
                      {product.category?.title}
                    </span>
                  </a>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-2 col-3 pl-0 border-bottom-lg-0 py-2 py-lg-0  align-items-center d-md-flex d-none">
              <span className="price font-lg text-dark font-weight-bold  d-block">
                ${product.price.toFixed(2)}
              </span>
            </div>
            <div className="align-items-center col-12 col-md-3 col-lg-2 d-flex flex-md-wrap justify-content-between px-lg-0 py-1  py-lg-0">
              <ProductStatus product={product} />
              {productType === MyLeadsType.Leads ? (
                <>
                  {product.orderStatus === OrderStatus.Pending &&
                    product.validTill &&
                    new Date(product.validTill) > newDate && (
                      <>
                        <span className="d-block text-muted font-sm">
                          This Ad is currently live
                        </span>

                        {!product.isFeatured && (
                          <>
                            <button
                              className="btn btn-outline-primary btn-sm mt-lg-1 py-1 text-capitalize border-width-1"
                              type="button"
                              onClick={sellFasterAction}
                            >
                              Sell Faster
                            </button>
                          </>
                        )}
                      </>
                    )}
                  {product.orderStatus === OrderStatus.Pending &&
                    product.validTill &&
                    new Date(product.validTill) < newDate && (
                      <>
                        <span className="d-block text-muted font-sm">
                          This Ad is expired
                        </span>
                        {/* <Button
                          className="btn btn-sm mt-lg-1 font-xxxs text-capitalize btn-primary border-width-1"
                          type="button"
                          onClick={sellFasterAction}
                        >
                          Sell Faster
                        </Button> */}
                      </>
                    )}
                  {/* ) */}
                  {product.orderStatus === OrderStatus.Pending &&
                    product.validTill &&
                    new Date(product.validTill) < newDate && (
                      <>
                        {/* <span className="d-block text-muted font-sm">
                          
                          This Ad is expired
                        </span> */}
                        <Button
                          className="btn btn-sm mt-lg-1 font-xxxs text-capitalize btn-primary"
                          type="button"
                          onClick={boostAction}
                        >
                          Boost Back
                        </Button>
                      </>
                    )}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="views-block border-top mt-lg-3 px-3 px-lg-0 py-lg-2 pt-2 border-gray-400">
          <div className="row gutter-5">
            <div className="col">
              <span className="font-sm text-muted d-flex align-items-center">
                <i className="font-base icon-location"></i>
                {product.location.state}
                {', '}
                {product.location.country}
              </span>
            </div>
            <div className="col-auto d-flex">
              <span className="font-sm text-muted mr-2 mr-lg-3 d-flex align-items-center">
                <i className="icon-visible font-lg"></i>
                {product.viewCount || 0}&nbsp;
                <span className="d-none d-lg-inline-block">views</span>
              </span>
              <span className="font-sm text-muted mr-2 mr-lg-3 d-flex align-items-center">
                <i className="icon-heart-filled font-base"></i>
                {product.likeCount || 0}&nbsp;
                <span className="d-none d-lg-inline-block">likes</span>
              </span>

              <span
                className="font-sm text-primary d-flex align-items-center cursor-pointer"
                onClick={() => {
                  if (product?.leadCount)
                    handleEnquiryClick(props.product.slug);
                }}
              >
                <i className="icon-bid font-base"></i>
                {product?.leadCount || 0}&nbsp;inquiries
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProduct;
