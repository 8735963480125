import React from 'react';
import Filter from '../../components/filters/Filter';
import useSearch from '../../hooks/useSearch';

const Filters: React.FunctionComponent<{
  className?: string;
  onFilterChange: () => void;
}> = (props) => {
  const search = useSearch();
  return (
    <div className={`filter-section ${props.className}`}>
      <h3 className="mb-4 pb-1 d-lg-block d-none">{search.category?.title}</h3>
      <h6 className="mb-3 d-lg-block d-none">Filter</h6>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">Filters</h6>
        <i
          className="icon-close font-base"
          onClick={() => {
            document.body.classList.remove('open-filter');
          }}
        ></i>
      </div>
      {Object.keys(search.filterOptions).map((filterKey) => {
        const filter = search.filterOptions[filterKey];

        return (
          <Filter
            filter={filter}
            value={search.getFilterValue(filterKey)}
            onChange={(val) => {
              search.setFilterValue(filterKey, val, filter.type);
              props.onFilterChange();
            }}
          />
        );
      })}
      <a
        className="bottom-0 btn btn-block btn-primary position-fixed rounded-0 d-lg-none"
        onClick={() => {
          document.body.classList.remove('open-filter');
        }}
      >
        Apply
      </a>
    </div>
  );
};
export default Filters;
