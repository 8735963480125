import api from '../../api';
import { User } from '../../api/models';
import { RouteKeys } from '../../containers/routes/route-keys';
import { Dict } from '../../models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
  PAction,
} from '../utils';
import { COMMON_RESET_DATA_ACTION, ResetActionType } from './common';

// action types
// login
const AUTH_SET_EMAIL_ACTION = 'AUTH/SET_EMAIL';
const AUTH_SET_PHONE_ACTION = 'AUTH/SET_PHONE';
const AUTH_LOGGED_IN_ACTION = 'AUTH/LOGGED_IN';
const AUTH_FIREBASE_LOGGED_IN_ACTION = 'AUTH/FIREBASE_LOGGED_IN';
const AUTH_SET_FACEBOOK_ACTION = 'AUTH/SET_FACEBOOK';
// profile
const AUTH_PROFILE_UPDATED_ACTION = 'AUTH/PROFILE_UPDATED_ACTION';
const SET_TIME_OFFSET_ACTION = 'AUTH/SET_TIME_OFFSET_ACTION';
const AUTH_PROFILE_UPDATE_PHONE_ACTION = 'AUTH/PROFILE_UPDATE_PHONE_ACTION';
// logout
const AUTH_LOGOUT_ACTION = 'AUTH/LOGOUT';
// redirect
const AUTH_REDIRECT_SAVE_ACTION = 'AUTH/REDIRECT_SAVE';
const AUTH_REDIRECT_APPLY_ACTION = 'AUTH/REDIRECT_APPLY';
// forgot-password
const AUTH_FORGOTPASSWORD_OTP_SENT_ACTION = 'AUTH/OTP_SENT';
// password box
const SHOW_PASSWORDBOX_ACTION = 'AUTH/PASSWORDBOX';

export interface AuthState {
  token?: string;
  firebaseToken?: string;
  redirectTo?: string;
  email?: string;
  phoneNumber?: string;
  facebookToken?: string;
  profile?: User;
  apiPermissions?: Dict;
  userId?: string;
  phone?: string;
  updatePhoneToken?: string;
  updatedPhone?: string;
  offSet?: number;
}

type SetEmailActionType = IActionWithPayload<
  typeof AUTH_SET_EMAIL_ACTION,
  string
>;

type SetFacebookActionType = IActionWithPayload<
  typeof AUTH_SET_FACEBOOK_ACTION,
  string
>;

type SetPhoneActionType = IActionWithPayload<
  typeof AUTH_SET_PHONE_ACTION,
  string
>;
type LoggedInActionType = IActionWithPayload<
  typeof AUTH_LOGGED_IN_ACTION,
  { token: string; user: User; apiPermissions: Dict }
>;

type FirebaseLoggedInActionType = IActionWithPayload<
  typeof AUTH_FIREBASE_LOGGED_IN_ACTION,
  { token: string }
>;

type RedirectSaveActionType = IActionWithPayload<
  typeof AUTH_REDIRECT_SAVE_ACTION,
  string
>;

type ProfileUpdatedActionType = IActionWithPayload<
  typeof AUTH_PROFILE_UPDATED_ACTION,
  User
>;

type SetTimeOffsetActionType = IActionWithPayload<
  typeof SET_TIME_OFFSET_ACTION,
  number
>;

type OtpSentActionType = IActionWithPayload<
  typeof AUTH_FORGOTPASSWORD_OTP_SENT_ACTION,
  { phone: string; userId: string }
>;

type UpdatePhoneActionType = IActionWithPayload<
  typeof AUTH_PROFILE_UPDATE_PHONE_ACTION,
  { userId: string; updatePhoneToken: string; updatedPhone: string }
>;

type LogoutActionType = IAction<typeof AUTH_LOGOUT_ACTION>;
type RedirectApplyActionType = IAction<typeof AUTH_REDIRECT_APPLY_ACTION>;

type ShowPasswordBoxType = IActionWithPayload<typeof SHOW_PASSWORDBOX_ACTION,{ phone: string }>;
type Actions =
  | SetEmailActionType
  | SetFacebookActionType
  | SetPhoneActionType
  | LoggedInActionType
  | RedirectSaveActionType
  | LogoutActionType
  | RedirectApplyActionType
  | ResetActionType
  | ProfileUpdatedActionType
  | OtpSentActionType
  | UpdatePhoneActionType
  | FirebaseLoggedInActionType
  | SetTimeOffsetActionType
  | ShowPasswordBoxType;

// initial state
const initialState: AuthState = {
  redirectTo: '/',
};

export const logoutAction = (): LogoutActionType => {
  return createAction(AUTH_LOGOUT_ACTION);
};

export const setEmailAction = (email: string): SetEmailActionType => {
  return createActionWithPayload(AUTH_SET_EMAIL_ACTION, email);
};

export const setFacebookAction = (token: string): SetFacebookActionType => {
  return createActionWithPayload(AUTH_SET_FACEBOOK_ACTION, token);
};

export const redirectApplyAction = (): RedirectApplyActionType => {
  return createAction(AUTH_REDIRECT_APPLY_ACTION);
};

export const redirectSaveAction = (to: string): RedirectSaveActionType => {
  return createActionWithPayload(AUTH_REDIRECT_SAVE_ACTION, to);
};

export const setPhoneAction = (phone: string): SetPhoneActionType => {
  return createActionWithPayload(AUTH_SET_PHONE_ACTION, phone);
};

export const showPasswordBoxAction = (data: {
  phone: string;
}): ShowPasswordBoxType => {
  return createActionWithPayload(SHOW_PASSWORDBOX_ACTION, data);
};

export const profileUpdatedAction = (
  profile: User
): ProfileUpdatedActionType => {
  return createActionWithPayload(AUTH_PROFILE_UPDATED_ACTION, profile);
};

export const setTimeOffsetAction = (
  offset: number
): SetTimeOffsetActionType => {
  return createActionWithPayload(SET_TIME_OFFSET_ACTION, offset);
};

export const loggedInAction = (data: {
  token: string;
  user: User;
  apiPermissions: Dict;
}): LoggedInActionType => {
  return createActionWithPayload(AUTH_LOGGED_IN_ACTION, data);
};

export const firebaseLoggedInAction = (data: {
  token: string;
}): FirebaseLoggedInActionType => {
  return createActionWithPayload(AUTH_FIREBASE_LOGGED_IN_ACTION, data);
};

export const otpSentAction = (data: {
  phone: string;
  userId: string;
}): OtpSentActionType => {
  return createActionWithPayload(AUTH_FORGOTPASSWORD_OTP_SENT_ACTION, data);
};

export const UpdatePhoneAction = (data: {
  userId: string;
  updatePhoneToken: string;
  updatedPhone: string;
}): UpdatePhoneActionType => {
  return createActionWithPayload(AUTH_PROFILE_UPDATE_PHONE_ACTION, data);
};

// reducer
export default (
  state: AuthState = initialState,
  action: Actions
): AuthState => {
  switch (action.type) {
    // login
    case AUTH_SET_EMAIL_ACTION:
      return {
        ...state,
        email: action.payload,
      };
    case AUTH_SET_FACEBOOK_ACTION:
      return {
        ...state,
        facebookToken: action.payload,
      };

    case AUTH_SET_PHONE_ACTION:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case AUTH_LOGGED_IN_ACTION:
      return {
        ...state,
        token: action.payload.token,
        profile: action.payload.user,
        apiPermissions: action.payload.apiPermissions,
      };

    case AUTH_FIREBASE_LOGGED_IN_ACTION:
      return {
        ...state,
        firebaseToken: action.payload.token,
      };

    // logout
    case AUTH_LOGOUT_ACTION:
      api.setToken(undefined);
      return {
        ...state,
        token: undefined,
        profile: undefined,
      };
    // profile
    case AUTH_PROFILE_UPDATED_ACTION:
      return {
        ...state,
        profile: action.payload,
      };

    case COMMON_RESET_DATA_ACTION:
      return {
        ...initialState,
        phoneNumber: state.phoneNumber,
        email: state.email,
      };

    case SET_TIME_OFFSET_ACTION:
      return {
        ...state,
        offSet: state.offSet,
      };

    // redirect
    case AUTH_REDIRECT_SAVE_ACTION:
      return { ...state, redirectTo: action.payload };
    case AUTH_REDIRECT_APPLY_ACTION:
      return { ...state, redirectTo: undefined };
    case AUTH_FORGOTPASSWORD_OTP_SENT_ACTION:
      return {
        ...state,
        userId: action.payload.userId,
        phone: action.payload.phone,
      };
    case AUTH_PROFILE_UPDATE_PHONE_ACTION:
      return {
        ...state,
        userId: action.payload.userId,
        updatePhoneToken: action.payload.updatePhoneToken,
        updatedPhone: action.payload.updatedPhone,
      };
      case SHOW_PASSWORDBOX_ACTION:
        return {
          ...state,
          phone: action.payload.phone,
        };
    default:
      return state;
  }
};
