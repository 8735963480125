import { ApiResponse, TokenData } from '../models';
import * as http from './http';
import ApiService from './service';

export default class AuthService extends ApiService {
  public async login(credentials: { username: string; password: string }) {
    const url = `${this.apiDomain}/auth/login`;
    const data = {
      username: credentials.username,
      password: credentials.password,
    };
    const resposne = await http.post<ApiResponse<TokenData>>(url, data);
    return resposne.data;
  }

  public async phoneLogin(phone: string) {
    const url = `${this.apiDomain}/auth/phone-login`;
    const data = {
      phone,
    };
    const response = await http.post<
      ApiResponse<{ userId: string; isSignup: boolean }>
    >(url, data);
    return response.data;
  }
  public async forgotPassword(phone: string) {
    const url = `${this.apiDomain}/auth/forgot-password`;
    const data = {
      phone,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data
    );
    return response.data;
  }
  public async resetPassword(otp: string, password: string, userId: string) {
    const url = `${this.apiDomain}/auth/reset-password`;
    const data = {
      otp,
      password,
      userId,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data
    );
    return response.data;
  }
  public async resendOtp(phone: string) {
    const url = `${this.apiDomain}/auth/forgot-password-resend-otp`;
    const data = {
      phone,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data
    );
    return response.data;
  }
  public async verifyLoginOtp(userId: string, otp: string) {
    const url = `${this.apiDomain}/auth/verify-login-otp`;
    const data = {
      userId,
      otp,
    };
    const response = await http.post<ApiResponse<TokenData>>(url, data);
    return response.data;
  }
  public async loginWithPhoneResendOtp(phone: string) {
    const url = `${this.apiDomain}/auth/login-resend-otp`;
    const data = {
      phone,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data
    );
    return response.data;
  }
  public async updatePhoneOtp(phone: string) {
    const url = `${this.apiDomain}/user/profile/request-phone-number-change`;
    const data = {
      phone,
    };
    const response = await http.post<
      ApiResponse<{ userId: string; isSignup: boolean; token: string }>
    >(url, data, this.store);
    return response.data;
  }
  public async updateProfilePhone(otp: string, token: string) {
    const url = `${this.apiDomain}/user/profile/update-phone-number`;
    const data = {
      otp,
      token,
    };
    const response = await http.post<ApiResponse<TokenData>>(
      url,
      data,
      this.store
    );
    return response.data;
  }

  public async getFirebaseToken() {
    const url = `${this.apiDomain}/user/firebase-login`;
    const response = await http.post<ApiResponse<TokenData>>(
      url,
      {},
      this.store
    );
    return response.data;
  }

  public async updatePhoneResendOtp(phone: string) {
    const url = `${this.apiDomain}/user/profile/request-phone-resend-otp`;
    const data = {
      phone,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data,
      this.store
    );
    return response.data;
  }
  public async changePassword(password: string, oldPassword: string) {
    const url = `${this.apiDomain}/auth/change-password`;
    const data = {
      password,
      oldPassword,
    };
    const response = await http.post<ApiResponse>(url, data, this.store);
    return response.data;
  }
}
