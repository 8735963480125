import React from 'react';
import Plans from './Plans';
import { SubscriptionPlan } from '../../../api/models';

interface BoostBackToTopProps {
  onPlanSelect: (data: SubscriptionPlan) => void;
  categoryId?: string;
  subCategoryId?: string;
}

const BoostBackToTop: React.FunctionComponent<BoostBackToTopProps> = (
  props
) => {
  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i className="icon-prev font-lg mr-3"></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Boost Back Plans
        </h6>
      </div>

      <div className="post-product">
        <div className="container">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-lg-auto col-12 post-col">
              <h3 className="text-center text-gray-900 m-0 d-lg-block d-none mb-4 w-100">
                Boost Back Plans
              </h3>
              <div className="post-product-inner rounded bg-white">
                <Plans
                  onPlanSelect={(data: SubscriptionPlan) =>
                    props.onPlanSelect(data)
                  }
                  categoryId={props.categoryId}
                  subCategoryId={props.subCategoryId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoostBackToTop;
