import React from 'react';
import { MediaFile } from '../../../api/models';
import SlickSlider from '../../../components/slick-slider';
import camImg from '../../../assets/images/camera.png';

const ProductImages: React.FunctionComponent<{
  images: MediaFile[];
  featured?: boolean;
  className?: string;
  title: string;
  createElements?: () => void;
}> = (props) => {
  if (!props.images || !props.images.length) {
    return <></>;
  }

  const renderImages = () => {
    return (
      props.images.length > 0 && (
        <SlickSlider
          className="list-unstyled mb-lg-4 px-0 detail-image-list"
          key={'product-images' + props.images.length}
          settings={{
            dots: false,
            infinite: false,
            arrows: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            initialSlide: 0,
            lazyLoad: 'ondemand',
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  dots: true,
                  arrows: false,
                },
              },
            ],
          }}
        >
          {props.images.map((im, index) => (
            // <div className="text-center">
            <img
              src={im.path}
              alt={props.title}
              id={`${index}`}
              key={index}
              className="img-fluid"
            />
            // </div>
          ))}
        </SlickSlider>
      )
    );
  };
  return (
    <>
      <div className={`product-image-block ${props.className || ''}`}>
        {props.images.length === 1 ? (
          <div className="text-center">
            <img
              src={props.images[0].path}
              alt={props.title}
              className="img-fluid"
            />
          </div>
        ) : (
          // renderImages()
          <SlickSlider
            className="list-unstyled mb-lg-4 px-0 detail-image-list"
            key={'product-images' + props.images.length}
            settings={{
              dots: false,
              infinite: false,
              arrows: true,
              speed: 300,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
              initialSlide: 0,
              lazyLoad: 'ondemand',
              responsive: [
                {
                  breakpoint: 767,
                  settings: {
                    dots: true,
                    arrows: false,
                  },
                },
              ],
            }}
          >
            {props.images.map((im, index) => (
              // <div className="text-center">
              <img
                src={im.path}
                alt={props.title}
                id={`${index}`}
                key={index}
                className="img-fluid"
              />
              // </div>
            ))}
          </SlickSlider>
        )}
        {props.featured && (
          <div className="featured bg-primary position-absolute font-xxs font-weight-semibold d-lg-inline-block d-none">
            <i className="icon-star mr-1"></i>Featured
          </div>
        )}
        <div className="slider-count">
          <img
            src={camImg}
            alt="photo count"
            width="17"
            height="17"
            className="mr-2"
          />{' '}
          ( {props.images.length} )
        </div>
      </div>
    </>
  );
};

export default ProductImages;
