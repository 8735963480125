import React, { useState } from 'react';
import {
  Category,
  CreatePostFormProps,
  Subcategory,
  Subscribe,
  SubscriptionStatus,
  SubscriptionPlan,
  RequestCustomPlan,
  RemainingPostsCountInCategoryResponseData,
  ProductType,
  Auction,
  Product,
} from '../../api/models';
import AppLayout from '../layout/app';
import CategorySeletion from './components/CategorySelection';
import '../../styles/post-product.scss';
import SubcategorySeletion from './components/SubcategorySelection';
import CreatePostForm from './components/CreatePostForm';
import CreatePostSummary from './components/CreatePostSummary';
import { usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import ChoosePlan from '../choose-plan/components/ChoosePlan';
import Payment, {
  AccountType,
  PaymentType,
} from '../../containers/payment/components/payment';
import { State } from '../../store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import {
  reccomendationsAddedAction,
  selectProductToSellFasterAction,
} from '../../store/reducers/catalog';
import { StatusCodes } from 'http-status-codes';
import useAuth from '../../hooks/useAuth';
import ThankYou from './components/ThankYou';
import { ContainerLoader } from '../../components/loader';
import ProductTypeForm from './components/ProductType';
import useEntities from '../../hooks/useEntities';

const CreatePostScreen: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();
  const entities = useEntities();

  const [category, setCategory] = useState<Category>();
  const [subcategory, setSubcategory] = useState<Subcategory>();
  const [product, setProduct] = useState<CreatePostFormProps>();
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [isChoosePlan, setIsChoosePlan] = useState<boolean>(false);
  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [createdProductId, setCreatedProductId] = useState('');
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [productType, setProductType] = useState<ProductType>();
  const [productDetails, setProductDetails] = useState<any>();
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [isProductDetails, setIsProductDetails] = useState<boolean>(false);

  const { performAction: createProduct } = usePerformAction(
    (d: Product): any => {
      setIsLoaded(false);
      api.catalog.createPost(d).then((t) => {
        if (t.status === StatusCodes.CREATED) {
          dispatch(selectProductToSellFasterAction(t.data));
          dispatch(reccomendationsAddedAction(t.data));
          setIsLoaded(true);
          setCreatedProductId(t.data._id);
          setIsThankYou(true);
        }
      });
    }
  );
  const [auctionProduct, setAuctionProduct] = useState<Auction>();
  const { performAction: checkSubscription } = usePerformAction((d): any => {
    setIsLoaded(false);
    setProductDetails(d);
    api.subscription
      .getRemainingPostsCountInCategory({
        categoryId: category?._id,
        productType,
      })
      .then((response) => {
        setIsLoaded(true);
        if (response.noOfPostsRemaining > 0) {
          createProduct(d);
        } else {
          setIsChoosePlan(true);
        }
      });

    // if (
    //   !authState.profile?.subscriptionDetails ||
    //   !authState.profile?.subscriptionDetails?.noOfPostsRemaining
    // ) {
    //   setIsChoosePlan(true);
    // } else {
    //   createProduct(product);
    // }
  });

  const { performAction: payment } = usePerformAction(
    (data: SubscriptionPlan): any => {
      setSubscriptionPlanSelected(data);
      setIsRedirectPayment(true);
    }
  );

  const { performAction: subscribe } = usePerformAction(
    (paymentId: any, stripePaymentToken?: any): any => {
      const subscribeData: Subscribe = {
        subscriptionPlan: subscriptionPlanSelected?._id || '',
        paymentId,
        paymentType: PaymentType.Subscription,
        categoryId: category?._id,
        stripePaymentToken,
      };
      api.subscription.subscribe(subscribeData).then((data) => {
        if (data.status === SubscriptionStatus.PaymetSuccess) {
          setIsChoosePlan(false);
          setIsRedirectPayment(false);
          createProduct(productDetails);
        }
      });
    }
  );

  const { performAction: requestCustomPlan } = usePerformAction((data): any => {
    setSubscriptionPlanSelected(data);
    createProduct(productDetails);
    const subscribeData: RequestCustomPlan = {
      subscriptionPlan: data?._id || '',
    };
    api.subscription.requestCustomPlan(subscribeData).then((responseData) => {
      if (responseData.status === SubscriptionStatus.CustomRequestPending) {
        setThankYouHeading('Thank You');
        setThankYouMessage('We will contact you soon to discuss a custom plan');
        setIsThankYou(true);
      }
    });
  });
  if (!isLoaded) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }
  const updateAuctionDetails = (data: Auction) => {
    const productDetailsWithAuction = {
      ...product,
      auctionDetails: { ...data, bidNowPrice: data.startPrice },
      productType: ProductType.Auction,
    };
    setProductType(ProductType.Auction);
    setProductDetails(productDetailsWithAuction);
    setShowSummary(true);
  };
  const updateProductDetails = (productTypeVal: ProductType) => {
    const productDetailsWithProductType = {
      ...product,
      productType: productTypeVal,
    };
    setProductDetails(productDetailsWithProductType);
    if (productTypeVal !== ProductType.Auction) {
      setShowSummary(true);
    }
  };

  return (
    <AppLayout hideCategories hideFooter>
      {!category && (
        <CategorySeletion
          onSelect={(e, a) => [setCategory(e), setSubcategory(a)]}
          onClearCategory={() => {
            setCategory(undefined);
            setSubcategory(undefined);
          }}
        ></CategorySeletion>
      )}
      {category &&
        subcategory &&
        !isSummary &&
        !isThankYou &&
        !isProductDetails && (
          <CreatePostForm
            onClearCategory={() => {
              setCategory(undefined);
              setSubcategory(undefined);
            }}
            onSubmit={(d) => {
              setProduct(d);
              setIsProductDetails(true);
            }}
            productDetails={product}
            category={category}
            subcategory={subcategory}
          ></CreatePostForm>
        )}
      {category &&
        subcategory &&
        !isSummary &&
        !showSummary &&
        product &&
        isProductDetails &&
        (!productType ||
          (productType && productType === ProductType.Auction)) && (
          <ProductTypeForm
            onSelect={(val: ProductType) => {
              setProductType(val);
              updateProductDetails(val);
            }}
            onAuctionSubmit={(newProductDetails) => {
              setAuctionProduct(newProductDetails);
              updateAuctionDetails(newProductDetails);
            }}
            auctionProductDetails={auctionProduct}
            onBackClick={() => {
              setProductType(undefined);
              setIsProductDetails(false);
            }}
          ></ProductTypeForm>
        )}
      {category &&
        subcategory &&
        showSummary &&
        productDetails &&
        !isChoosePlan &&
        !isThankYou && (
          <CreatePostSummary
            onClearCategory={() => {
              setCategory(undefined);
              setSubcategory(undefined);
            }}
            onSubmit={(d) => {
              setProductDetails(d);
              checkSubscription(d);
            }}
            category={category}
            subcategory={subcategory}
            product={productDetails}
            onBackClick={() => {
              setShowSummary(false);
              setProductType(undefined);
            }}
            onEditBackClick={() => {
              setProductType(undefined);
              setIsProductDetails(false);
              setShowSummary(false);
            }}
          ></CreatePostSummary>
        )}
      {isChoosePlan && !isRedirectPayment && !isThankYou && (
        <ChoosePlan
          onPlanSelect={(data: SubscriptionPlan) =>
            data.isCustom ? requestCustomPlan(data) : payment(data)
          }
          productType={productDetails?.productType}
          categoryId={category?._id}
          subCategoryId={subcategory?._id}
          onBackClick={() => {
            setIsChoosePlan(false);
            setShowSummary(true);
          }}
        />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          paymentType={PaymentType.Subscription}
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          email={auth.profile?.email!}
          amount={subscriptionPlanSelected?.amount}
          subscriptionPlanId={subscriptionPlanSelected?._id}
          isReccuring={subscriptionPlanSelected?.isRecurring || false}
          successhandler={subscribe}
          planName={subscriptionPlanSelected?.name}
          period={subscriptionPlanSelected?.period}
          listingDays={subscriptionPlanSelected?.listingDays}
        ></Payment>
      )}
      {isThankYou && (
        <ThankYou
          productId={createdProductId}
          heading={thankYouHeading}
          message={thankYouMessage}
          productType={productType}
          isCustomPlan={subscriptionPlanSelected?.isCustom}
        />
      )}
    </AppLayout>
  );
};

export default CreatePostScreen;
