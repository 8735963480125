import React, { useState } from 'react';
import moment from 'moment';
import { PageType } from '../../profile';
import Empty from '../../../components/empty';
import { Link } from 'react-router-dom';
import MyAccount from '../../common/my-account';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firestore from '../../../components/firebase/firebase';
import useAuth from '../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import { AvatarView } from '../../../components/avatar';

const NotificationScreen: React.FunctionComponent = () => {
  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();
  const userId = auth.profile?._id?.toString();
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const notificationRef = firestore.collection('Notifications');
  const query = notificationRef.where('userId', '==', userId).limit(25);
  const [notifications]: any = useCollectionData(query, { idField: 'id' });

  const notificationsForUser: any[] =
    notifications
      ?.filter((notification: any) => !deletedIds.includes(notification.id))
      .sort((a: any, b: any) => b.createdAt - a.createdAt) || [];

  const deleteNotification = (id: string) => {
    const response = firestore.collection('Notifications').doc(id).delete();
    setDeletedIds([...deletedIds, id]);
  };

  const deleteAllNotification = () => {
    const allIds = notifications.map((notification: any) => {
      firestore.collection('Notifications').doc(notification.id).delete();
      return notification.id;
    });
    setDeletedIds(allIds);
  };
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Notifications
          </li>
        </ol>
      </nav>
    );
  };

  return (
    <div className="invoice-inner py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.Notifications} />
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link to="/account" className="text-white">
                <i className="icon-prev font-lg mr-3"></i> Notification
              </Link>
            </h6>
          </div>
          <div className="col-lg-9">
            <div className="right-panel">
              <div className="notification-inner">
                <div className="d-lg-flex justify-content-between mb-3 ">
                  <h2 className="text-dark font-weight-bold title-head d-none d-lg-block mb-0">
                    Notification
                  </h2>
                  {notifications && !!notifications.length && (
                    <button className="btn btn-outline-primary btn-sm px-3 font-sm" onClick={() => deleteAllNotification()}>
                      {' '}
                      Clear All{' '}
                    </button>
                  )}
                </div>
                <div className="notification-fill pt-md-3">
                  {!!notificationsForUser &&
                    notificationsForUser.length <= 0 && <Empty></Empty>}
                  {notificationsForUser &&
                    notificationsForUser.length > 0 &&
                    notificationsForUser.map((notification: any) => {
                      return (
                        <div className="notification-box rounded d-flex align-items-center justify-content-between bg-white">
                          <div className="pic-content d-flex  ">
                            <div className="avatar position-relative">
                              <img
                                src={notification?.productImage}
                                className="avatar-main "
                              />
                              <AvatarView
                                className="avatar-sub"
                                src={notification?.image}
                                size="medium"
                              />
                            </div>

                            <div className="notification-text">
                              <p className="m-0 font-base">
                                {/* <span className="text-gray-900">
                                  Jinsha Mariya{' '}
                                </span>{' '}
                                liked your post: “Daniel Wellington classNameic
                                for Women” */}
                                {notification.message}
                              </p>
                              <span className="time font-sm text-gray-500">
                                {moment(notification.createdAt).format('HH:mm')}{' '}
                              </span>
                            </div>
                          </div>
                          <div className="more-option">
                            <button
                              type="button"
                              className="close px-2 py-1"
                              onClick={() =>
                                deleteNotification(notification?.id)
                              }
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationScreen;
