export default class Config {
  static readonly MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1Ijoic2hhcmFmdWRoZWVuIiwiYSI6ImNrNHNsMGpjYTA1dDAzZG93a2tnNzV3cnEifQ.hZ0R4NowNN6YzbhOCZFGQg';
  static readonly GOOGLE_MAP_KEY = 'AIzaSyDjx34KHrubvGP7pdIVf5fld_gBxxARyEo';
  static GOOGLE_ANALYTICS_ID = 'UA-155985479-1';
  static ANDROID_APP_LINK = '';
  static IOS_APP_LINK = '';
  static FACEBOOK_LINK = 'https://www.facebook.com/DealDen-105050628686754';
  static TWITTER_LINK = '';
  static INSTAGRAM_LINK = 'https://www.instagram.com/dealden/?hl=en';
  static YOUTUBE_LINK = '';
  static readonly GOOGLE_CLIENT_ID =
    process.env.GOOGLE_CLIENT_ID ||
    '290828512096-7j5vibsmqd78ehoiv18j7t1fp4kpk630.apps.googleusercontent.com';
  static readonly FACEBOOK_APP_ID = '1503517766648630';
  static PAYMENT_URL: 'https://www.cenpos.net/simplewebpay/cards/';
  static ISCVV: true;
  static ISEMAIL: true;
}
