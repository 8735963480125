import React from 'react';

import AppStoreImage from '../../assets/images/appstore.png';
import Config from '../../config';

const IOSAppLink: React.FunctionComponent<{ className?: string }> = (props) => {
  return (
    <a href={Config.IOS_APP_LINK} className={props.className}>
      <img src={AppStoreImage} className="img-fluid" alt="appstore" />
    </a>
  );
};

export default IOSAppLink;
