import React from 'react';
import Plans from './Plans';
import {
  ProductType,
  Subscription,
  SubscriptionPlan,
} from '../../../api/models';

interface ChoosePlanProps {
  onPlanSelect: (data: SubscriptionPlan) => void;
  onBackClick?: () => void;
  categoryId?: string;
  subCategoryId?: string;
  productType?: ProductType;
}

const ChoosePlan: React.FunctionComponent<ChoosePlanProps> = (props) => {
  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i className="icon-prev font-lg mr-3"></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Choose Plan
        </h6>
      </div>
      <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center">
        <a
          href="#"
          className="font-sm mr-3 mt-2 position-absolute right-0 top-0"
          onClick={() => props?.onBackClick?.()}
        >
          <i className="font-xxxs mr-1">
            <small className="icon-prev"></small>
          </i>
          Back
        </a>
        <h3 className="m-0 text-center text-gray-900"> Choose Plan</h3>
      </div>
      <div className="post-product">
        <div className="container">
          <div className="row d-flex justify-content-center my-lg-4 mb-4">
            <div className="col-lg-auto col-12 post-col">
              <div className="bg-white mx-lg-0 post-product-inner pt-2 rounded row">
                <Plans
                  onPlanSelect={(data: SubscriptionPlan) =>
                    props.onPlanSelect(data)
                  }
                  categoryId={props.categoryId}
                  subCategoryId={props.subCategoryId}
                  productType={props.productType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
