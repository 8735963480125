export interface Dict<T = any> {
  [key: string]: T;
}

export * from './crud-request';
export * from './user';
export * from './category';
export * from './catalog';
export * from './filters';
export * from './response';
export * from './login';
export * from './media';
export * from './subscription';
export * from './product';
export * from './banner';
export * from './bidders';
export * from './enquiry';
