import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import '../../assets/sass/scanning.scss';
import QrReader from 'react-qr-reader';
import { useScanner } from '../../hooks/useScanner';
import { RouteKeys } from '../routes/route-keys';
import { ContainerLoader } from '../../components/loader';
const ScanQR: React.FunctionComponent = () => {
  const [er, setEr] = useState('');
  const [data, setData] = useState('');

  const { verifyQr, loader } = useScanner();
  const history = useHistory();
  const reader = useRef(null);
  return (
    <div className="scanner bg-dark">
      <div className="container">
        <div className="justify-content-start d-flex py-lg-4 py-3">
          <a
            onClick={() => {
              history.replace(RouteKeys.Home);
            }}
            className="close-btn p-3"
          >
            <img
              src={require('../../assets/images/close.png')}
              width="15"
              height="15"
            />
          </a>
        </div>
        <div
          style={{ alignItems: 'center' }}
          className="scanning-div with-border col-lg-6 mx-auto"
        >
          <QrReader
            key={'qr-reader-' + data}
            ref={reader}
            delay={300}
            onError={(err) => {
              setEr(err);
            }}
            showViewFinder={true}
            // legacyMode={true}
            onScan={(result) => {
              if (result) {
                verifyQr(result);
                setData(result);
              }
            }}
            style={{ border: 'none', width: '50vh', margin: 'auto' }}
          />
        </div>
        {/* <div className="text-center text-white font-lg  font-weight-medium mt-5 pt-lg-5 ">
          <a
            style={{
              textDecoration: 'underline',
              zIndex: 9,
              position: 'relative',
            }}
            className="text-primary"
            onClick={() => {
              history.push(RouteKeys.Home);
            }}
          >
            Verify Manually
          </a>
        </div> */}
      </div>

      {loader && <ContainerLoader></ContainerLoader>}
    </div>
  );
};

export default ScanQR;
