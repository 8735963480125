import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { FollowedUser, FollowingUser } from '../../../api/models';
import { AvatarView } from '../../../components/avatar';
import Empty from '../../../components/empty';
import { ContainerLoader } from '../../../components/loader';
const Following: React.FunctionComponent<{ onUnFollow?: () => void }> = (
  props
) => {
  const { data: myFollowers, reload, loading, loaded } = useDataLoader<
    FollowingUser[]
  >(() => api.user.getMyFollowing());
  const renderEmpty = () => {
    if (myFollowers.length === 0) return <Empty />;
  };
  const setUnFollow = (userIdVal?: string) => {
    if (!userIdVal) return;
    api.user.setUnFollow(userIdVal).then((response) => {
      reload();
      if (props.onUnFollow) props.onUnFollow();
    });
  };
  return (
    <>
      {loaded && !loading && (
        <>
          {renderEmpty()}
          {myFollowers.map((item: FollowingUser) => {
            return (
              <div className="tab-content pt-lg-3 pt-2" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="follower-list">
                    <div className="row justify-content-between align-items-center border-bottom border-fade py-3">
                      <div className="col-lg col-7 d-flex align-items-center">
                        <figure className="mb-0">
                          <AvatarView
                            src={item.followingUser.avatar?.path}
                          ></AvatarView>
                        </figure>
                        <figcaption className="pl-3">
                          <h6 className="font-normal mb-0">
                            {item.followingUser?.name}
                          </h6>
                          <span className="font-xs text-gray-500">
                            {item.followerCount} Followers
                          </span>
                        </figcaption>
                      </div>
                      <div className="col-lg col-5 d-flex justify-content-end">
                        <button
                          onClick={(e) => setUnFollow(item.followingUser?._id)}
                          className="btn btn-outline-primary bg-white btn-sm border-width-1 d-flex align-items-center justify-content-center"
                        >
                          UnFollow
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {!loaded && loading && <ContainerLoader height={500}></ContainerLoader>}
    </>
  );
};
export default Following;
