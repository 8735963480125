import React from 'react';
import { Control, Controller } from 'react-hook-form';
import validator from 'validator';
import { Attribute } from '../../api/models/attribute';
import { Dict } from '../../models';
import TextArea from '../../components/form-group/TextArea';
import { PasswordInput, TextInput } from '../../components/form-group';
import SwitchInput from '../../components/form-group/switch-input';
import SelectInput from '../../components/select-input';
import SelectMultipleInput from '../../components/form-group/SelectMultipleInput';

const AttributeInputController: React.FunctionComponent<{
  attribute: Attribute;
  control: Control;
  errors?: any;
  prefix?: string;
  defaultValue?: string | number | boolean;
  defaultMultiValue?: string | [];
}> = (props) => {
  if (!props.attribute.key) {
    return <></>;
  }
  const generateRules = (attribute: Attribute) => {
    const rls: Dict = {
      validate: {},
    };
    if (
      attribute.type !== 'Boolean' &&
      attribute.required &&
      !attribute.allowNull
    ) {
      rls.required = `${attribute.label} is required`;
    }
    if (attribute.type === 'String') {
      if (attribute.alphanumeric) {
        rls.validate.alphnumeric = (val: string) =>
          !val ||
          validator.isAlphanumeric(val || '') ||
          `${attribute.label} should be alphanumeric`;
      }
      if (attribute.format === 'Regex' && attribute.regex) {
        rls.validate.pattern = (val: string) =>
          !val ||
          validator.matches(val, new RegExp(attribute.regex!, 'si')) ||
          `${attribute.label} is invalid`;
      }
      if (attribute.format === 'Email') {
        rls.validate.isEmail = (val: string) =>
          !val || validator.isEmail(val) || `Invalid email`;
      }
    }
    if (attribute.type === 'Number') {
      rls.validate.isNotNan = (val: number) =>
        !isNaN(val || 0) || 'Not a valid number';
      if (attribute.min !== undefined) {
        rls.validate.min = (val: number) =>
          val !== undefined ||
          val <= attribute.min! ||
          `${attribute.label} should not be greater than ${attribute.min}`;
      }
      if (attribute.max !== undefined) {
        rls.validate.max = (val: number) =>
          val !== undefined ||
          val <= attribute.max! ||
          `${attribute.label} should not be less than ${attribute.max}`;
      }
      if (attribute.integer) {
        rls.validate.isInteger = (val: number) =>
          val !== undefined ||
          Number(val) === Math.ceil(Number(val)) ||
          `Only integers are allowed`;
      }
    }

    if (attribute.type === 'String' || attribute.type === 'Array') {
      if (attribute.minLength !== undefined) {
        rls.validate.minLength = (val: string | []) =>
          !val ||
          val.length >= attribute.minLength! ||
          `Minimum ${attribute.minLength!} ${
            attribute.type === 'String' ? 'character(s)' : 'item(s)'
          } needed`;
      }
      if (attribute.maxLength !== undefined) {
        rls.validate.maxLength = (val: string | []) =>
          !val ||
          val.length <= attribute.maxLength! ||
          `Maximum ${attribute.maxLength!} ${
            attribute.type === 'String' ? 'character(s)' : 'item(s)'
          } are allowed`;
      }
    }
    return rls;
  };
  const rules = generateRules(props.attribute);
  const attributeName = `${props.prefix || ''}${props.attribute.key}`;
  let defaultValueByType: any;
  if (
    props?.attribute?.type === 'String' ||
    props?.attribute?.type === 'Number'
  ) {
    if (props.attribute.isMultiSelect) {
      defaultValueByType = props.defaultMultiValue;
    } else {
      defaultValueByType = props.defaultValue;
    }
  } else if (props?.attribute?.type === 'Boolean') {
    defaultValueByType = false;
  } else {
    defaultValueByType = props.defaultValue;
  }
  
  return (
    <div className="form-group mb-4">
      <Controller
        name={attributeName}
        control={props.control}
        rules={rules}
        defaultValue={defaultValueByType}
        render={({ onChange, name, value }) => {
          const defaultProps = {
            onChange,
            name,
            error: props.errors?.[props.attribute.key]?.message,
            required: props.attribute.required,
          };

          switch (props.attribute.type) {
            case 'String':
              if (props.attribute.options?.length) {
                return (
                  <>
                    <label className="mb-1">
                      {props.attribute.label}{' '}
                      {props.attribute.required && (
                        <span className="text-danger">*</span>
                      )}
                    </label>
                    {props?.attribute?.isMultiSelect && (
                      <SelectMultipleInput
                        data={props.attribute.options}
                        optionLabel={props.attribute.textField || 'name'}
                        optionValue={props.attribute.valueField || 'value'}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        name={name}
                        default={value}
                      />
                    )}
                    {!props?.attribute?.isMultiSelect && (
                      <SelectInput
                        {...defaultProps}
                        data={props.attribute.options}
                        optionLabel={props.attribute.textField || 'name'}
                        optionValue={props.attribute.valueField || 'value'}
                        default={value}
                      ></SelectInput>
                    )}
                  </>
                );
              }
              if (props.attribute.format === 'RichText') {
                return (
                  <>
                    <label className="mb-1">
                      {props.attribute.label}{' '}
                      {props.attribute.required && (
                        <span className="text-danger">*</span>
                      )}
                    </label>{' '}
                    <TextArea {...defaultProps}></TextArea>
                  </>
                );
              }
              if (props.attribute.format === 'Password') {
                return (
                  <>
                    <label className="mb-1">
                      {props.attribute.label}{' '}
                      {props.attribute.required && (
                        <span className="text-danger">*</span>
                      )}
                    </label>{' '}
                    <PasswordInput {...defaultProps}></PasswordInput>
                  </>
                );
              }
              return (
                <>
                  <label className="mb-1">
                    {props.attribute.label}{' '}
                    {props.attribute.required && (
                      <span className="text-danger">*</span>
                    )}
                  </label>{' '}
                  <TextInput {...defaultProps} default={value}></TextInput>
                </>
              );
            case 'Number':
              if (props.attribute.options?.length) {
                return (
                  <>
                    <label className="mb-1">
                      {props.attribute.label}{' '}
                      {props.attribute.required && (
                        <span className="text-danger">*</span>
                      )}
                    </label>{' '}
                    {props?.attribute?.isMultiSelect && (
                      <SelectMultipleInput
                        data={props.attribute.options}
                        optionLabel={props.attribute.textField || 'name'}
                        optionValue={props.attribute.valueField || 'value'}
                        onChange={(val) => {
                          onChange(val);
                        }}
                        name={name}
                        default={value}
                      />
                    )}
                    {!props?.attribute?.isMultiSelect && (
                      <SelectInput
                        {...defaultProps}
                        data={props.attribute.options}
                        optionLabel={props.attribute.textField || 'name'}
                        optionValue={props.attribute.valueField || 'value'}
                        default={value}
                      ></SelectInput>
                    )}
                  </>
                );
              }
              return (
                <>
                  <label className="mb-1">
                    {props.attribute.label}{' '}
                    {props.attribute.required && (
                      <span className="text-danger">*</span>
                    )}
                  </label>{' '}
                  <TextInput
                    {...defaultProps}
                    default={value}
                    type="number"
                  ></TextInput>
                </>
              );
            case 'Boolean':
              return (
                <>
                  <label className="mb-1">
                    {props.attribute.label}{' '}
                    {props.attribute.required && (
                      <span className="text-danger">*</span>
                    )}
                  </label>{' '}
                  <SwitchInput {...defaultProps} default={false}></SwitchInput>
                </>
              );
            default:
              return <></>;
          }
        }}
      />
      {props.errors &&
        props.errors.customAttributes &&
        props.errors?.customAttributes?.[props.attribute.key]?.message && (
          <span className="error">
            {props.errors?.customAttributes?.[props.attribute.key]?.message}
          </span>
        )}
    </div>
  );
};

export default AttributeInputController;
