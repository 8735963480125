import { stringify } from 'query-string';
import {
  ApiResponse,
  CrudDetailsRequestModel,
  CrudListRequestModel,
  CrudRequestModel,
  Paginated,
  PaginatedApiResponse,
} from '../models';
import * as http from './http';
import ApiService from './service';

export default class CrudService extends ApiService {
  public async getList<T>(
    request: CrudListRequestModel
  ): Promise<PaginatedApiResponse<T>> {
    const { page = 1, perPage = 10 } = request.pagination || {};
    const { field, order } = request.sort;
    const query = {
      _filters: JSON.stringify(request.filter),
      _sort: field,
      _order: order,
      page: request.noPagination ? undefined : page,
      perPage: request.noPagination ? undefined : perPage,
      ...request.queryParams,
    };
    const url = `${this.apiDomain}/${request.resource}?${stringify(query)}`;

    const response = await http.get<PaginatedApiResponse<T>>(url, this.store);
    return response.data;
  }

  public async create(request: CrudRequestModel, data: any) {
    const url = `${this.apiDomain}/${request.resource}`;
    return http.post(url, data, this.store);
  }

  public async get(request: CrudDetailsRequestModel) {
    const url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    return http.get(url, this.store);
  }

  public async update(request: CrudDetailsRequestModel, data: any) {
    const url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    return http.put(url, data, this.store);
  }

  public async remove(request: CrudDetailsRequestModel) {
    const url = `${this.apiDomain}/${request.resource}/${request.resourceId}`;
    return http.remove(url, this.store);
  }
}
