import React from 'react';
import { ProductType } from '../../../api/models';
import Button from '../../../components/button';
import { getTopFeaturedPlansLink } from '../../../utils/link-utils';
import { RouteKeys } from '../../routes/route-keys';

interface ThankYouProps {
  productId?: string;
  heading?: string;
  message?: string;
  isCustomPlan?: boolean;
  isPurchase?: boolean;
  productType?: ProductType;
  isBid?: boolean;
  onClickOkayButton?: () => void;
}

const renderHeading = (heading?: string) => {
  return (
    <div className="font-xxl font-weight-bold mb-3 mt-4">
      {heading || 'Congratulations!'}
    </div>
  );
};
const renderMessage = (message?: string) => {
  return (
    <div className="font-normal">
      {message || 'Your ad will go live shortly..'}.
    </div>
  );
};

const renderSellFasterButton = (productId: string) => {
  return (
    <div className="mt-3">
      {' '}
      <Button
        type="button"
        className="btn py-2 btn-primary font-weight-bold btn-block"
        link={getTopFeaturedPlansLink(productId)}
      >
        Sell Faster Now
      </Button>{' '}
    </div>
  );
};

const renderPreviewAdButton = (productType?: ProductType) => {
  return (
    <div className="mt-3">
      {' '}
      <Button
        type="button"
        className="btn py-2 btn-outline-primary font-weight-bold btn-block"
        link={
          productType &&
          (productType === ProductType.Sale
            ? RouteKeys.MyAds
            : productType === ProductType.Auction
              ? RouteKeys.MyAuction
              : RouteKeys.MyLeads)
        }
      >
        Preview Ad
      </Button>{' '}
    </div>
  );
};

const renderGoHomeButton = () => {
  return (
    <div className="mt-3">
      {' '}
      <Button
        type="button"
        className="btn py-2 btn-outline-primary font-weight-bold btn-block"
        link={RouteKeys.Home}
      >
        Go To Home Page
      </Button>{' '}
    </div>
  );
};

const renderOkayButton = (onClickOkayButton: () => void) => {
  return (
    <div className="mt-3">
      {' '}
      <Button
        type="button"
        className="btn py-2 btn-outline-primary font-weight-bold btn-block"
        onClick={onClickOkayButton}
      >
        Okay
      </Button>{' '}
    </div>
  );
};

const ThankYou: React.FunctionComponent<ThankYouProps> = (props) => {
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <div className="col-md-5 col-sm-6 col-12 bg-white shadow text-center p-5 rounded">
          <i className="icon-tick1 display-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          {renderHeading(props.heading)}
          {renderMessage(props.message)}
          {props.productId &&
            !props.isPurchase &&
            renderSellFasterButton(props.productId)}
          {!props.isCustomPlan &&
            !props.isPurchase &&
            renderPreviewAdButton(props.productType)}
          {props.isCustomPlan && renderGoHomeButton()}
          {props.isBid && props.onClickOkayButton && renderOkayButton(props.onClickOkayButton)}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
