import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../../api';
import Button from '../../../components/button';
import { RouteKeys } from '../../routes/route-keys';
import * as qs from 'query-string';
import { ContainerLoader } from '../../../components/loader';
import warningImage from '../../../assets/images/warning.png';

const renderHeading = (heading?: string) => {
  return (
    <div className="font-xxl font-weight-bold mb-3 mt-4">
      {heading || 'Congratulations!'}
    </div>
  );
};
const renderMessage = (message?: string) => {
  return (
    <div className="font-normal">
      {message || 'Your email has been verified successfully'}.
    </div>
  );
};

const renderGoHomeButton = () => {
  return (
    <div className="mt-3">
      {' '}
      <Button
        type="button"
        className="btn py-2 btn-outline-primary font-weight-bold btn-block"
        link={RouteKeys.Home}
      >
        Go To Home Page
      </Button>{' '}
    </div>
  );
};

const VerifyEmail: React.FunctionComponent = (props) => {
  const [message, setMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaded, setIsLoaded] = useState<boolean>(false);
  const queryParams = useLocation();
  const params = qs.parse(queryParams.search);
  const verifyEmailToken =
    params.signature && Array.isArray(params.signature)
      ? params.signature.join(' ')
      : params.signature || '';
  useEffect(() => {
    setIsLoading(true);
    api.user
      .verifyEmail(verifyEmailToken)
      .then((response) => {
        setMessage('Your email has been verified successfully');
        setIsLoading(false);
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsError(true);
        setMessage(error.response.data.message);
        setIsLoading(false);
        setIsLoaded(true);
      });
  }, []);
  return (
    <div className="container">
      {!isLoading && loaded && (
        <div className="row justify-content-center py-5">
          <div className="col-md-5 col-sm-6 col-12  lg-shadow text-center p-5 rounded">
            {!isError && (
              <i className="icon-tick1 display-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            )}
            {isError && <img src={warningImage} />}
            {renderHeading(isError ? 'Error' : 'Congratulations!')}
            {renderMessage(message)}
            {renderGoHomeButton()}
          </div>
        </div>
      )}
      {(isLoading || !loaded) && (
        <ContainerLoader height={500}></ContainerLoader>
      )}
    </div>
  );
};

export default VerifyEmail;
