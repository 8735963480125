import { ApiResponse, TokenData, User } from '../models';
import * as http from './http';
import ApiService from './service';

export default class SignupService extends ApiService {
  public async signup(signupData: {
    email: string;
    phone: string;
    name: string;
  }) {
    const url = `${this.apiDomain}/auth/signup`;

    const response = await http.post<ApiResponse<User>>(url, signupData);
    return response.data;
  }

  public async resendOtp(phone: string) {
    const url = `${this.apiDomain}/auth/signup-resend-otp`;
    const data = {
      phone,
    };
    const response = await http.post<ApiResponse<{ userId: string }>>(
      url,
      data
    );
    return response.data;
  }

  public async verifySignupOtp(userId: string, phone: string, otp: string) {
    const url = `${this.apiDomain}/auth/verify-signup-otp`;
    const data = {
      userId,
      phone,
      otp,
    };
    const response = await http.post<ApiResponse<TokenData>>(url, data);
    return response.data;
  }

  public async verifyLoginOtp(userId: string, phone: string, otp: string) {
    const url = `${this.apiDomain}/auth/verify-signup-otp`;
    const data = {
      userId,
      phone,
      otp,
    };
    const response = await http.post<ApiResponse<TokenData>>(url, data);
    return response.data;
  }

  public async facebookLogin(token: string) {
    try {
      const url = `${this.apiDomain}/auth/facebook-login`;
      const response = await http.post(url, { accessToken: token });
      return response;
    } catch (err) {
      return {
        data: {
          success: false,
        },
      };
    }
  }

  public async googleLogin(token: string) {
    try {
      const url = `${this.apiDomain}/auth/google-login`;
      const response = await http.post(url, { accessToken: token });
      return response;
    } catch (err) {
      return {
        data: {
          success: false,
        },
      };
    }
  }
}
