import React from 'react';
import AppLayout from '../layout/app';
import InvoicesScreen from './components/invoices';

const MyOrders: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <InvoicesScreen />
    </AppLayout>
  );
};

export default MyOrders;
