import React, { useEffect, useState } from 'react';
import { PageType } from '../../profile';
import { Link } from 'react-router-dom';
import Empty from '../../../components/empty';
import MyAccount from '../../common/my-account';
import useAuth from '../../../hooks/useAuth';
import { format } from 'date-fns';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import { Payment, PaymentStatus } from '../../../api/models/payment';
import { ContainerLoader } from '../../../components/loader';
import SubscriptionPlan from './subscription-plan';

const MySubscription: React.FunctionComponent = () => {
  const auth = useAuth();
  const [payments, setPayments] = useState<any>({
    data: [],
    headerData: null,
  });
  const { data: paymentResponse, loading, loaded } = useDataLoader<{
    data: {
      data: any[];
      headerData: any;
    };
  }>(() => api.payment.getMyPayments());

  useEffect(() => {
    if (paymentResponse?.data) setPayments(paymentResponse.data);
  }, [paymentResponse]);

  const renderProfileSubscriptions = () =>
    auth.profile?.subscriptions.map(
      (plan: any) =>
        ((plan.isRecurring &&
          plan.expireOn &&
          plan.noOfPostsRemaining! > 0 &&
          new Date(plan.expireOn) > new Date()) ||
          (!plan.isRecurring && plan.noOfPostsRemaining! > 0)) && (
          <SubscriptionPlan plan={plan} />
        )
    );

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  const renderPayments = () =>
    payments.data
      .filter((e: any) => {
        return e.paymentType === 'subscription';
      })
      .map((payment: Payment) => {
        return (
          <div className="invoice-row bg-white rounded">
            <div className="gutter-5 justify-content-between row">
              <div className="col-md-3 col-sm-4 col-6 pb-3 pb-md-0">
                <p className="m-0 d-md-none labelin">Invoice ID</p>
                <div className="inv-id d-flex ">
                  <span>
                    <i className="icon-invoice"></i>
                  </span>
                  <p className="m-0">{payment.invoiceNumber}</p>
                </div>
              </div>
              <div className="col-md-2 col-4 pb-3 pb-md-0">
                <p className="m-0 d-md-none labelin">Date</p>
                <p className="m-0">
                  {' '}
                  {format(new Date(payment.createdAt), 'dd MMM yyyy')}
                </p>
              </div>
              <div className="col-md-2 col-4 pb-3 pb-md-0">
                <p className="m-0 d-md-none labelin">Payment Type</p>
                <p className="m-0">{payment.paymentMode || 'Online'}</p>
              </div>
              <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                <h6 className="m-0">$ {payment.amountPaid}</h6>
              </div>
              <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                <p className="m-0 d-md-none labelin">Status</p>
                <span
                  className={
                    payment.status === PaymentStatus.Success
                      ? 'text-white font-xxs rounded-pill status-completed'
                      : 'text-white font-xxs rounded-pill status-progress'
                  }
                >
                  {payment.status}
                </span>
              </div>
            </div>
          </div>
        );
      });

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Subscription
          </li>
        </ol>
      </nav>
    );
  };
  return (
    <div className="my-ads py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.Subscription} />
          <h6 className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
            <Link to="/account" className="text-white">
              <i className="icon-prev font-lg mr-3"></i> My Subscription
            </Link>
          </h6>
          <div className="col-lg-9">
            <div className="right-panel mt-4 mt-lg-0">
              <h2 className="text-dark font-weight-bold mb-4 pb-2 title-head d-none d-lg-block pt-3">
                {loaded && 'My Subscriptions'}
              </h2>
              <div className="row gutter-10">
                {loaded
                  ? renderProfileSubscriptions()
                  : renderContainerLoader()}
              </div>
              {/* <div className="invoice-pannel">
                <div className="invoice-pannel-listing pt-4 pt-md-0 ">
                  {loaded
                    ? (
                      <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                        <div className="gutter-5 justify-content-between row">
                          <div className="col-md-3">
                            <p className="m-0">Invoice ID</p>
                          </div>
                          <div className="col-md-2">
                            <p className="m-0">Date</p>
                          </div>
                          <div className="col-md-2">
                            <p className="m-0">Payment Type</p>
                          </div>
                          <div className="col-md-2">
                            <p className="m-0">Amount</p>
                          </div>
                          <div className="col-md-2">
                            <p className="m-0">Status</p>
                          </div>
                        </div>
                      </div>
                    ) && renderPayments()
                    : renderContainerLoader()}
                  {payments?.data?.length === 0 ? <Empty /> : ''}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MySubscription;
