import api from '..';
import {
  AccountType,
  ApiResponse,
  BankDetails,
  BillingAddress,
  BusinessDetails,
  ReportUserForm,
  User,
} from '../models';
import * as http from './http';
import ApiService from './service';
import { store } from '../../store';
import moment from 'moment';

export default class UserService extends ApiService {
  public async updateBasicDetails(data: {
    name: string;
    email: string;
    password: string;
    accountType: AccountType;
  }) {
    const url = `${this.apiDomain}/user/update-basic-details`;

    const response = await http.post<ApiResponse<User>>(url, data, this.store);
    return response.data;
  }
  public async updateBankDetails(data: BankDetails) {
    const url = `${this.apiDomain}/user/update-bank-details`;

    const response = await http.post<ApiResponse<User>>(url, data, this.store);
    return response.data;
  }
  public async updatePaypalDetails(data: string) {
    const url = `${this.apiDomain}/user/update-paypal-details`;

    const response = await http.post<ApiResponse<User>>(
      url,
      { paypalReciever: data },
      this.store
    );
    return response.data;
  }
  public async updateProfileDetails(data: any) {
    const url = `${this.apiDomain}/user/update-profile-details`;
    const response = await http.post<ApiResponse<User>>(url, data, this.store);
    return response.data;
  }
  public async updateInterestedCategories(data: {
    interestedCategories: string[];
  }) {
    const url = `${this.apiDomain}/user/update-interested-categories`;

    const response = await http.post<ApiResponse<User>>(url, data, this.store);
    return response.data;
  }
  public async updateBusinessDetails(data: BusinessDetails) {
    const url = `${this.apiDomain}/user/update-business-details`;

    const response = await http.post<ApiResponse<User>>(url, data, this.store);
    return response.data;
  }

  public async getProfile() {
    const url = `${this.apiDomain}/user/profile`;
    const response = await http.get<ApiResponse<User>>(url, this.store);
    return response.data;
  }

  public async getTimeOffset(): Promise<any> {
    try {
      const response = await http.get(
        `${this.apiDomain}/user/time-offset/${moment(new Date()).toISOString()}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  public async reportUser(data: ReportUserForm): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/user/report`;
    const response = await http.post<ApiResponse<any>>(url, data, this.store);
    return response.data;
  }
  public async setFollow(userId: string): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/user/follow`;
    const response = await http.post<ApiResponse<any>>(
      url,
      { userId },
      this.store
    );
    return response.data;
  }

  public async updateSettings(
    allowNotification?: boolean,
    isLogoutAll?: boolean,
    isDeactivate?: boolean
  ): Promise<any> {
    const url = `${this.apiDomain}/user/update-settings`;
    const response = await http.post<ApiResponse<any>>(
      url,
      { allowNotification, isLogoutAll, isDeactivate },
      this.store
    );
    return response.data.data?.response;
  }
  public async updateBillingAddress(data: BillingAddress): Promise<any> {
    const url = `${this.apiDomain}/user/update-billing-address`;
    const response = await http.post<ApiResponse<any>>(url, data, this.store);
    return response.data.data?.response;
  }
  public async getMyFollowers(): Promise<any> {
    const url = `${this.apiDomain}/user/my-followers`;
    const state = store.getState();
    api.setToken(state.authUser.token);
    const response = await http.get<ApiResponse>(url, this.store);
    return response.data.data;
  }
  public async getMyFollowing(): Promise<any> {
    const url = `${this.apiDomain}/user/my-following`;
    const state = store.getState();
    api.setToken(state.authUser.token);
    const response = await http.get<ApiResponse>(url, this.store);
    return response.data.data;
  }
  public async setUnFollow(userId: string): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/user/un-follow`;
    const response = await http.post<ApiResponse<any>>(
      url,
      { userId },
      this.store
    );
    return response.data;
  }
  public async getCompanyDetails(): Promise<any> {
    const url = `${this.apiDomain}/admin/company-details`;
    const response = await http.get<ApiResponse>(url);
    return response.data.data;
  }
  public async verifyEmail(token: string): Promise<any> {
    const url = `${this.apiDomain}/user/verify-email`;
    const response = await http.post<ApiResponse<any>>(
      url,
      { token },
      this.store
    );
    return response.data.data?.response;
  }
}
