import React from 'react';
import FileUpload from './Upload';
import FileUploading from './Uploading';
import FileUploaded from './Uploaded';

interface CustomProps {
  id: string;
  name: string;
  title?: string;
  fileName?: string;
  filePath?: string;
  fileSize?: string;
  isChosen?: boolean;
  progress?: number;
  isUploading?: boolean;
  isUploadingCompleted?: boolean;
  onFileChosen?: (file: File) => void;
  onFileClosed?: () => void;
  disabled?: boolean;
  supportedFormats: string[];
  className?: string;
  errorMsg?:string;
}

const FileUploadView: React.SFC<CustomProps> = (props: CustomProps) => {
  if (!props.isChosen) {
    return (
      <FileUpload
        id={`upload_${props.id}`}
        name={props.name}
        title={props.title || ''}
        errorMsg={props.errorMsg || ''}
        className={props.className || ''}
         onFileChosen={(file) =>
          file ? props.onFileChosen?.(file) : props.onFileClosed?.()
        }
        disabled={props.disabled}
        description={`Select any ${props.supportedFormats
          .map((f) => `.${f}`)
          .join(', ')} files`}
        accept={props.supportedFormats}
      ></FileUpload>
    );
  } else if (props.isUploading) {
    return (
      <FileUploading
        id={`uploading_${props.id}`}
        title={props.title || ''}
        description={''}
        className={props.className || ''}
        filename={props.fileName || ''}
        fileSize={props.fileSize || ''}
        onClose={() => props.onFileClosed?.()}
        progress={props.progress}
        disabled={props.disabled}
      ></FileUploading>
    );
  } else if (props.isUploadingCompleted) {
    return (
      <FileUploaded
        id={`uploaded_${props.id}`}
        title={props.title || ''}
        description={''}
        filename={props.fileName || ''}
        fileSize={props.fileSize || ''}
        onClose={() => props.onFileClosed?.()}
        disabled={props.disabled}
        filePath={props.filePath}
        className={props.className || ''}
      ></FileUploaded>
    );
  }

  return <div></div>;
};

export default FileUploadView;
