import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteKeys } from './route-keys';
import useAuth from '../../hooks/useAuth';
import AppLayout from '../layout/app';

interface CustomProps {
  component: any;
  exact?: boolean;
  path?: string;
}

const AuthenticateCmsRoute: React.FunctionComponent<CustomProps> = (props) => {
  const { component, ...rest } = props;
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(childProps) => {
        return <AppLayout><props.component {...childProps} /></AppLayout>;
      }}
    />
  );
};

export default AuthenticateCmsRoute;
