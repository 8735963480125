import React from 'react';
import Plans from './Plans';
import { Subscription, SubscriptionPlan } from '../../../api/models';

interface ChoosePlanProps {
  onPlanSelect: (data: SubscriptionPlan) => void;
  onBackClick?: () => void;
  categoryId?: string;
  subCategoryId?: string;
}

const ChoosePlan: React.FunctionComponent<ChoosePlanProps> = (props) => {
  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i
          onClick={() => props?.onBackClick?.()}
          className="icon-prev font-lg mr-3"
        ></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Top Featured Plans
        </h6>
      </div>

      <div className="post-product">
        <div className="container">
          <div className="row d-flex justify-content-center my-lg-4 mb-4">
            <div className="col-lg-auto col-12 post-col">
              <h3 className="text-center text-gray-900 m-0 d-lg-block d-none mb-4 w-100">
                Top Featured Plans
              </h3>
              <div className="post-product-inner rounded bg-white mx-lg-0">
                <Plans
                  onPlanSelect={(data: SubscriptionPlan) =>
                    props.onPlanSelect(data)
                  }
                  categoryId={props.categoryId}
                  subCategoryId={props.subCategoryId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
