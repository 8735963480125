import React from 'react';
import HelpLink from '../../../../components/links/help-link';
import useSignup from '../../../../hooks/useSignup';
import AuthLayout from '../../../layout/auth';
import Banner from './Banner';
import PersonalDetailsForm from './PersonalDetailsForm';

const PersonalDetailsWidget: React.FunctionComponent = () => {
  const signup = useSignup();
  const onBasicDetailsSubmit = (data: any) => {
    signup.updateBasicDetails(data);
  };
  return (
    <AuthLayout
      loginContainerClass="account-details pb-5 pb-md-0 mb-lg-0"
    >
      <Banner></Banner>
      <div className="col-lg-6 col-xl-7 col-xl enterphone-right-box content-box bg-white right-radius">
        <div className="p-sm-4 p-2">
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <div className="px-xl-5 mx-auto pb-lg-5 py-3 px-2 px-lg-0 py-lg-0">
            <div className="top-head-common">
              <h3 className="text-dark mb-lg-3 mb-2 h3 d-none d-lg-block">
                Enter Detail
              </h3>
              <h3 className="text-dark mb-lg-3 mb-2 h3 d-block d-lg-none mt-3">
                Include Some Details
              </h3>
              <p className="d-block d-lg-none text-muted">
                Stranger feel so impersonal, you know?
              </p>
            </div>
            <PersonalDetailsForm
              onSubmit={onBasicDetailsSubmit}
              isUpdating={signup.updating}
              default={{ name: signup.name, email: signup.email }}
            ></PersonalDetailsForm>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default PersonalDetailsWidget;
