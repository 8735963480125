import React from 'react';
import "../../styles/cms.scss";

const PostAnItem: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 h-100 position-relative">
                    <div className="row h-100">
                        <div className="col-md-6 col-12">
                            <div className="h-100 d-flex justify-content-center align-items-start flex-column">
                                <h1 className="text-white mb-3">Sell Almost Anything </h1>
                                <p className="h3 mb-5 text-white">Make Money From Selling</p>
                                <a href="#"
                                    className="btn btn-secondary mb-5 px-4 rounded-sm text-primary font-md font-weight-bold">
                                    <span className="px-4">Sell Now</span>
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-md-6 col-12 justify-content-center banner-right-block py-4 d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/postanitem-banner-img.png')} className="img-fluid position-relative my-md-4" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <h2 className="mt-5 text-center text-dark mb-3 font-weight-bold px-2">Selling has never been so easy</h2>
                <p className="mb-5 text-center font-normal font-weight-semibold line-height-md text-dark">It takes just minutes, and
                    listing
                    prices ranges from $10 and up. Take advantage
                    of<br /> some
                    FREE posting
                    within some categories. </p>
                <div className="container pb-5 d-flex">
                    <div className="row d-md-block">
                        <div className="col-md-6 col-12 float-left pb-3">
                            <div className="bg-white post-item-box rounded-lg p-4 mb-4">
                                <div className="p-3">
                                    <div
                                        className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                        01
                                    </div>
                                    <ul className="post-item-list pl-4">
                                        <li className="mb-2">Just take a few pics</li>
                                        <li className="mb-2">Make the first one your best. It’ll be front and center in your
                                            listing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 float-right pb-3">
                            <div className="bg-white post-item-box rounded-lg p-4 mb-4">
                                <div className="p-3">
                                    <div
                                        className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                        02
                                    </div>
                                    <ul className="post-item-list pl-4">
                                        <li className="mb-2">Click the sell button</li>
                                        <li className="mb-2">Select the category & subcategory</li>
                                        <li className="mb-2">Enter item details such as title, description, brands, attributes, and
                                            price</li>
                                        <li className="mb-2">Upload photos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 float-left pb-3">
                            <div className="bg-white post-item-box rounded-lg p-4 mb-4">
                                <div className="p-3">
                                    <div
                                        className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                        03
                                    </div>
                                    <ul className="post-item-list pl-4">
                                        <li className="mb-2">Select your post type</li>
                                        <li className="mb-2">Auction, Sale or Lead Generation</li>
                                        <li className="mb-2">Enter the details required</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 float-right pb-3">
                            <div className="bg-white post-item-box rounded-lg p-4 mb-4">
                                <div className="p-3">
                                    <div
                                        className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                        04
                                    </div>
                                    <ul className="post-item-list pl-4">
                                        <li className="mb-2">Select your posting plan either within the City or Nationwide</li>
                                        <li className="mb-2">Some posting are FREE</li>
                                        <li className="mb-2">Proceed to checkout with the payment details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostAnItem;
