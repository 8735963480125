import React, { useState } from 'react';
import MyAccount from '../../common/my-account';
import { PageType } from '../../profile';
import { Link } from 'react-router-dom';
import FollowerImg from '../../../assets/images/follower.jpg';
import FollowersAndFollowingTab from './Tabs';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import Followers from './Followers';
import Following from './Following';

export enum FollowingAndFollowers {
  Followers = 'followers',
  Followings = 'followings',
}
export interface FollowerProps {
  followers: number;
  following: number;
  tab?: FollowingAndFollowers;
  onTabChange?: (tab: FollowingAndFollowers) => void;
  onFollow?: () => void;
  onUnFollow?: () => void;
}

const FollowersAndFollowing: React.FunctionComponent<FollowerProps> = (
  props
) => {
  const [tab, setTab] = useState<FollowingAndFollowers>(
    FollowingAndFollowers.Followers
  );
  const [followersCount, setFollowersCount] = useState<number>(props.followers);
  const [followingCount, setFollowingCount] = useState<number>(props.following);
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/profile">My Account</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/profile">Profile</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Following
          </li>
        </ol>
      </nav>
    );
  };
  return (
    <div className="py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.Profile} />
          <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link to="/profile">
                <span className="icon-prev font-lg mr-3 text-white"></span>
              </Link>
              My Following & Followers
            </h6>
          </div>
          <div className="col-lg-9 rmv-padd-mob">
            <div className="right-panel">
              <div className="justify-content-between mb-3 align-items-center line-height-sm d-lg-flex d-none">
                <h5 className="follow-head">Following & Followers</h5>
                <div className="back-btn">
                  <Link to="/profile" className="text-gray-500">
                    <span className="icon-prev pr-1 font-xxxs"></span>Back
                  </Link>
                </div>
              </div>
              <FollowersAndFollowingTab
                followers={followersCount}
                following={followingCount}
                onTabChange={setTab}
              ></FollowersAndFollowingTab>
              {tab === FollowingAndFollowers.Followers && (
                <Followers
                  onFollow={() => setFollowingCount(followingCount + 1)}
                  onUnFollow={() => setFollowingCount(followingCount - 1)}
                ></Followers>
              )}
              {tab === FollowingAndFollowers.Followings && (
                <Following
                  onUnFollow={() => setFollowingCount(followingCount - 1)}
                ></Following>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FollowersAndFollowing;
