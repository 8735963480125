import { omit } from 'lodash';
import React, { forwardRef } from 'react';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';

const renderThumb = ({ style, ...props }: any) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'rgba(255, 0, 0, 0.8)',
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

export enum ScrollPosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface CustomProps extends ScrollbarProps {
  startPosition?: ScrollPosition;
}

const CustomScrollbars = (props: CustomProps, ref: any) => {
  return (
    <Scrollbars
      ref={ref}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...omit(props, ['startPosition'])}
    />
  );
};

export default forwardRef(CustomScrollbars);
