import React from 'react';
import PlainLayout from './plain';
import '../../styles/login.scss';

const AuthLayout: React.FunctionComponent<{
  loginContainerClass?: string;
  containerClass?: string;
}> = (props) => {
  return (
    <PlainLayout>
      <div className="login-outer py-lg-5">
        <div className={`container ${props.containerClass || ''}`}>
          <div className={`login-container ${props.loginContainerClass || ''}`}>
            <div className="row h-100">{props.children}</div>
          </div>
        </div>
      </div>
    </PlainLayout>
  );
};

export default AuthLayout;
