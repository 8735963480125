import { PayoutStatus } from '../../containers/invoices/components/payment-filter';
import { OrderStatus } from './catalog';
import { PaymentStatus } from './payment';

export enum MyLeadsType {
  'Leads' = 'Leads',
  'Favorite' = 'Favorite',
  'Enquired' = 'Inquired',
}

export interface PageQuery {
  page?: number;
  perPage?: number;
}

export interface StatusModel {
  id: string;
  name: string;
}
export const OrderStatuses: StatusModel[] = [
  { id: '', name: 'Status' },
  ...Object.entries(OrderStatus).map(([key, value]) => ({
    id: value,
    name: key === 'Sold' ? 'Completed' : key,
  })),
];

export const PayoutStatuses: StatusModel[] = [
  { id: '', name: 'Status' },
  ...Object.entries(PayoutStatus).map(([key, value]) => ({
    id: value,
    name: key === 'Sold' ? 'Completed' : key,
  })),
];

export const PaymentStatuses: StatusModel[] = [
  { id: '', name: 'Status' },
  ...Object.entries(PaymentStatus).map(([key, value]) => ({
    id: value,
    name: key === 'Sold' ? 'Completed' : key,
  })),
];
