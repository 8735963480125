import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import OtpIcon from '../../components/icons/otp-icon';
import HelpLink from '../../components/links/help-link';
import LeftPane from '../login-with-phone/components/left-pane';
import OtpForm from './components/otp-form';
import useSignup from '../../hooks/useSignup';
import useAuth from '../../hooks/useAuth';

const LoginOtpScreen: React.FunctionComponent = () => {
  const {
    updating,
    verifyOtp,
    loginWithPhoneResendOtp,
    loginError,
  } = useAuth();

  return (
    <AuthLayout>
      <LeftPane />
      <div className="col-lg-7  enterphone-right-box content-box otp-block bg-white p-md-3 signup">
        <a href="/" title="DealDen">
          <LogoSVG className="d-lg-none" />
        </a>
        <p className="text-muted text-right mt-2 d-none d-lg-block">
          Having troubles? <HelpLink>Get Help</HelpLink>
        </p>
        <div className="login-form p-sm-4 p-2 mx-auto py-5">
          <div className="top-head-block mt-lg-5">
            <OtpIcon className="mb-4 mb-lg-5 d-none d-lg-block" />
            <h4 className="text-dark mb-3">OTP Verification</h4>
            <p className="text-muted font-weight-lighter f-16">
              OTP has been sent to your mobile number. Please verify
            </p>
            <OtpIcon className="mb-5 mb-lg-5 d-block d-lg-none text-center" />
          </div>
          <OtpForm
            onSubmit={(data) => verifyOtp(data.otp)}
            onResendButtonClicked={loginWithPhoneResendOtp}
            isUpdating={updating}
            error={loginError}
          ></OtpForm>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginOtpScreen;
