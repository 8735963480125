/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { Link } from 'react-router-dom';
import api from '../../../api';
import AndroidAppLink from '../../../components/links/android-app-link';
import IOSAppLink from '../../../components/links/ios-app-link';
import FacebookLink from '../../../components/links/social-media/facebook';
import InstagramLink from '../../../components/links/social-media/instagram';
import TwitterLink from '../../../components/links/social-media/twitter';
import YoutubeLink from '../../../components/links/social-media/youtube';
import LogoSVG from '../../../components/logo/logo-svg';
import { RouteKeys } from '../../routes/route-keys';
import CategoryLinks from './category-links';

const Footer: React.FunctionComponent = (props) => {
  const {
    data: companyDetails,
    loaded: companyDetailsLoaded,
    loading: companyDetailsLoading,
  } = useDataLoader<any>(() => api.user.getCompanyDetails());
  const renderMobileBottomBarLinks = () => {
    return (
      <div className="row text-uppercase text-center gutter-10">
        <div className="col col-1-5">
          <a
            href={RouteKeys.Home}
            className="d-flex flex-column text-gray-600 active"
          >
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-home "></i>
            </div>
            <span className="font-xxs">Home</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a
            onClick={() => document.body.classList.toggle('open-allcategory')}
            className="d-flex flex-column text-gray-600"
          >
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-category"></i>
            </div>
            <span className="font-xxs">Category</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a
            href={RouteKeys.Create}
            className="d-flex flex-column text-gray-600"
          >
            <div className="footer-mob__icon footer-mob__icon--center position-relative mb-1">
              <i className="icon-sell-strock"></i>
            </div>
            <span className="font-xxs">Sell</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a
            href={RouteKeys.Chats}
            className="d-flex flex-column text-gray-600"
          >
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-chat"></i>
            </div>
            <span className="font-xxs">Chat</span>
          </a>
        </div>
        <div className="col col-1-5">
          <Link to="/account" className="d-flex flex-column text-gray-600">
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-profile"></i>
            </div>
            <span className="font-xxs">Account</span>
          </Link>
        </div>
      </div>
    );
  };

  const renderCompanyDetails = () => {
    return (
      <div className="col-4">
        <LogoSVG className="img-fluid mb-3" />
        <p className="font-md text-gray-700">
          Deal Den is the fastest growing social classified marketplace to buy,
          sell, auction and get leads locally in the Caribbean. Start making
          money from selling online today! Join Now!
        </p>
        {companyDetailsLoaded && !companyDetailsLoading && (
          <>
            <div className="d-flex align-items-baseline mb-2">
              <i className="icon-location text-gray-700 mr-2"></i>
              <p className="mb-0 font-md text-gray-700">
                {companyDetails.address} {companyDetails.city},{' '}
                {companyDetails.state}, {companyDetails.country}
              </p>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="icon-mail text-gray-700 mr-2"></i>
              <p className="mb-0 font-md text-gray-700">
                {companyDetails.email}
              </p>
            </div>
            <div className="d-flex align-items-center mb-2">
              <i className="icon-phone text-gray-700 mr-2"></i>
              <p className="mb-0 font-md text-gray-700">
                {companyDetails.phone}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderQuickLinks = () => {
    return (
      <div className="col-2 pl-5">
        <h6 className="font-weight-semibold font-base mb-3">Quick Links</h6>
        <ul className="list-unstyled p-0 m-0 font-md">
          {/* <li className="mb-2">
            <a
              href={RouteKeys.PageDetails.replace(':key', 'about-us')}
              target="_blank"
              className="text-gray-700"
            >
              About Us
            </a>
          </li> */}
          <li className="mb-2">
            <a
              href={RouteKeys.ContactUs}
              className="text-gray-700"
            >
              Contact Us
            </a>
          </li>
          {/* <li className="mb-2">
            <a
              href={RouteKeys.PageDetails.replace(':key', 'careers')}
              target="_blank"
              className="text-gray-700"
            >
              Careers
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.PageDetails.replace(':key', 'faq')}
              target="_blank"
              className="text-gray-700"
            >
              Faq
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.PageDetails.replace(':key', 'privacy-policy')}
              target="_blank"
              className="text-gray-700"
            >
              Policy
            </a>
          </li> */}
          <li className="mb-2">
            <a
              href={RouteKeys.HowItWorks}
              className="text-gray-700"
            >
              How it Works
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.PostAnItem}
              className="text-gray-700"
            >
              Post an Item
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.TrustAndSafety}
              className="text-gray-700"
            >
              Trust and Safety
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.Business}
              className="text-gray-700"
            >
              Business
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.Career}
              className="text-gray-700"
            >
              Career
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.CommunityGuidelines}
              className="text-gray-700"
            >
              Community Guidelines
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.PostingRules}
              className="text-gray-700"
            >
              Posting Rules
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.ProhibitedItemsGuidelines}
              className="text-gray-700"
            >
              Prohibited Items Guidelines
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.MeetupSpots}
              className="text-gray-700"
            >
              Meetup Spots
            </a>
          </li>
          <li className="mb-2">
            <a
              href={RouteKeys.LocalBusiness}
              className="text-gray-700"
            >
              Local Business
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const renderNewsLetterInput = () => {
    return (
      <>
        <h6 className="font-weight-semibold font-base mb-3">News Letter</h6>
        <div className="d-flex bg-white rounded p-1 newsletter-blk mb-4">
          <input
            type="text"
            placeholder="Your Email"
            className="border-0 col px-3"
          />
          <input
            type="submit"
            className="btn btn-primary py-0"
            value="Subscribe"
          />
        </div>
      </>
    );
  };

  const renderMobileAppLinks = () => {
    return (
      <div className="d-flex mb-4 pb-2">
        <AndroidAppLink className="mr-2" />
        <IOSAppLink />
      </div>
    );
  };

  const renderSocialMediaLinks = () => {
    return (
      <ul className="list-unstyled p-0 m-0 d-flex h4">
        <li className="mr-2">
          <FacebookLink className="mr-1" />
        </li>
        <li className="mr-2">
          <TwitterLink className="mr-1" />
        </li>
        <li className="mr-2">
          <InstagramLink className="mr-1" />
        </li>
        <li className="mr-2">
          <YoutubeLink className="mr-1" />
        </li>
      </ul>
    );
  };

  const renderCopyRight = () => {
    return (
      <div className="footer-bottom bg-white d-lg-block d-none">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center py-3 font-sm text-gray-700">
              © {new Date().getFullYear()} Copyright Dealden. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <footer>
        <div className="footer-mob d-lg-none d-block bg-white">
          <div className="container-fluid">{renderMobileBottomBarLinks()}</div>
        </div>
        <div className="footer-top py-5 bg-gray-400 d-lg-block d-none">
          <div className="container">
            <div className="row">
              {renderCompanyDetails()}
              {renderQuickLinks()}
              <CategoryLinks />
              <div className="col-4">
                {/* {renderNewsLetterInput()} */}
                <h6 className="font-weight-semibold font-base mb-3">
                  Download Mobile App
                </h6>
                {renderMobileAppLinks()}
                {renderSocialMediaLinks()}
              </div>
            </div>
          </div>
        </div>
        {renderCopyRight()}
      </footer>
    </>
  );
};

export default Footer;
