import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import authUser from './reducers/authUser';
import signup from './reducers/signup';
import catalog from './reducers/catalog';
import search from './reducers/search';
import entities from './reducers/entity';
import profile from './reducers/profile';
import { State } from './interfaces';

const rootReducer = combineReducers<State>({
  authUser,
  signup,
  entities,
  catalog,
  search,
  profile,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
});

export default rootReducer;
