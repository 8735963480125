import React from 'react';

const SellIcon: React.FunctionComponent<{ className?: string }> = (props) => {
  return (
    <i className={`icon-sell ${props.className || ''}`}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path4"></span>
      <span className="path4"></span>
    </i>
  );
};

export default SellIcon;
