import React, { useState } from 'react';
import { AccountType } from '../../../../api/models';
import AccountTypeItem from './account-type';

const ChooseAccountType: React.FunctionComponent<{
  onAccountTypeSelection: (accountType: AccountType) => void;
}> = (props) => {
  const [accountType, setAccountType] = useState<AccountType | undefined>();

  return (
    <div className="px-xl-5 mx-auto py-lg-5 py-3 px-lg-0 py-lg-2">
      <div className="top-head-common">
        <h3 className="text-dark mb-lg-3 mb-2 h3">
          Choose the type of account
        </h3>
        <p className="text-muted font-weight-lighter mb-4 pb-3 f-16">
          Creating your account in DealDen is just a few steps away, Choose your
          account type to continue.
        </p>
      </div>

      <div className="chose-type-block">
        <ul className="list-unstyled row mx-0">
          <AccountTypeItem
            icon="icon-individual"
            selected={accountType === AccountType.Individual}
            onClick={() => setAccountType(AccountType.Individual)}
          >
            Individual <span className="d-none d-lg-inline-block">Account</span>
          </AccountTypeItem>
          <AccountTypeItem
            icon="icon-business"
            selected={accountType === AccountType.Business}
            onClick={() => setAccountType(AccountType.Business)}
          >
            Business <span className="d-none d-lg-inline-block">Account</span>
          </AccountTypeItem>
        </ul>
      </div>
      <div className="col-lg-4 px-0 mt-4">
        <button
          type="button"
          className="btn btn-primary btn-block font-weight-bold fixed-bt"
          disabled={!accountType}
          onClick={() => props.onAccountTypeSelection(accountType!)}
        >
          Next
        </button>
      </div>
    </div>
  );
};
export default ChooseAccountType;
