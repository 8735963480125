import React, { useRef } from 'react';
import { PageType } from '../../profile';
import { Link, useHistory, useParams } from 'react-router-dom';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import { RouteKeys } from '../../routes/route-keys';
import { format } from 'date-fns';
import { OrderStatusSyllables } from '../../../api/models';
import useAuth from '../../../hooks/useAuth';
import { Payment, Payout } from '../../../api/models/payment';
import AppLayout from '../../layout/app';
import { PaymentType } from '../../payment/components/payment';
import { PayoutStatus } from './payment-filter';

const PayoutDetails: React.FunctionComponent<{}> = () => {
  const componentRef = useRef();

  const history = useHistory();
  const auth = useAuth();

  const params = useParams<{ id: string }>();
  if (!params.id) {
    history.push(RouteKeys.MyOrders);
  }
  const { data, reload, loading, loaded } = useDataLoader<Payout>(() =>
    api.order.getPayoutDetails(params.id)
  );
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/profile">My Account</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/invoices">Payments</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Payout Details
          </li>
        </ol>
      </nav>
    );
  };
  return (
    <AppLayout hideCategories>
      <div className="my-ads py-0 py-lg-4">
        <div className="container">
          {renderBreadCrumbs()}
          <div className="row">
            <MyAccount pageType={PageType.Invoices} />
            <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
              <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
                <Link to="/my-orders" className="text-white">
                  <i className="icon-prev font-lg mr-3"></i>Payout Details
                </Link>
              </h6>
            </div>
            {loaded && !loading && (
              <div className="col-lg-9">
                <div className="right-panel">
                  <div className="justify-content-between align-items-center line-height-sm d-lg-flex d-none">
                    <h5 className="follow-head">Payout Details</h5>
                    <div className="back-btn">
                      <a className="text-gray-500" href="/invoices">
                        <span className="icon-prev pr-1 font-xxxs"></span>Back
                      </a>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="rounded bg-white p-3 p-lg-4 shadow">
                      {/* <div className="rounded bg-white p-3 p-lg-4 shadow"> */}
                      {/* <div className="row"> */}
                      <div className="p-lg-1">
                        {/* <div className="col-6 col-md-auto mb-3 ">
                          <span className="text-muted font-small">Price</span>
                          <h5 className="h6 mb-0">
                            {data?.currency || '$'}
                            {data?.totalPayoutAmount}
                          </h5>
                        </div>
                        <div className="col-6 col-md-auto mb-3 ">
                          <span className="text-muted font-small">
                            InvoiceNumber{' '}
                          </span>
                          <h5 className="font-medium mb-0">
                            {data?.payoutNumber}
                          </h5>
                        </div>
                        <div className="col-6 col-md-auto mb-3 ">
                          <span className="text-muted font-small">
                            Requested Date
                          </span>
                          <h5 className="font-medium mb-0">
                            {format(new Date(data?.createdAt), 'dd/MMM/yyyy')}
                          </h5>
                        </div> */}
                        <div className="d-flex justify-content-between border-bottom align-items-center pb-3">
                          <h6 className="p-0 m-0">
                            Invoice No: {data?.payoutNumber}
                          </h6>
                          <div className="right-icons">
                            {/* <a className="icon-print mx-2 text-gray-600"></a> */}
                            {/* <a className="icon-download mx-2 text-gray-600"></a> */}
                            {/* <a className="icon-share mx-2 text-gray-600"></a> */}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="pt-4 pb-3  border-bottom">
                        <h6 className="font-base mb-3 ">User Details</h6>
                        <div className="row font-small">
                          <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                            Name
                          </div>
                          <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                            {data?.requestedUser?.name}
                          </div>
                        </div>
                        <div className="row font-small">
                          <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                            Email
                          </div>
                          <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                            {data?.requestedUser?.email}
                          </div>
                        </div>
                        <div className="row font-small">
                          <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                            Phone
                          </div>
                          <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                            {data?.requestedUser?.phone}
                          </div>
                        </div>
                      </div>

                      {data?.status === PayoutStatus.Processed && (
                        <div className="pt-4 pb-3  border-bottom">
                          <h6 className="font-base mb-3 ">
                            Processed User Details
                          </h6>
                          <div className="row font-small">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                              Processed Date
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                              {format(
                                new Date(data?.payoutProcessedOn!),
                                'dd/MMM/yyyy'
                              )}
                            </div>
                          </div>
                          <div className="row font-small">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                              Name
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                              {data?.processedBy?.name}
                            </div>
                          </div>
                          <div className="row font-small">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                              Email
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                              {data?.processedBy?.email}
                            </div>
                          </div>
                          <div className="row font-small">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                              Phone
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                              {data?.processedBy?.phone}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="pt-4 pb-3  border-bottom">
                        <h6 className="font-base mb-3 ">Payment Details</h6>
                        <div className="row font-small">
                          <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                            Payment Mode
                          </div>
                          <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                            {data?.payoutPaymentDetails?.paymentMode}
                          </div>
                        </div>
                        {data?.payoutPaymentDetails?.paymentMode ===
                          'paypal' && (
                          <div className="row font-small">
                            <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                              Paypal ID
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                              {data?.payoutPaymentDetails?.paypalRecipient}
                            </div>
                          </div>
                        )}
                        {data?.payoutPaymentDetails?.paymentMode === 'bank' && (
                          <>
                            <div className="row font-small">
                              <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                                Bank Name
                              </div>
                              <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                                {
                                  data?.payoutPaymentDetails?.bankDetails
                                    ?.bankName
                                }
                              </div>
                            </div>
                            <div className="row font-small">
                              <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                                Bank Code
                              </div>
                              <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                                {
                                  data?.payoutPaymentDetails?.bankDetails
                                    ?.bankCode
                                }
                              </div>
                            </div>
                            <div className="row font-small">
                              <div className="col-12 col-md-4 col-lg-3 col-xl-2 mb-3 text-muted">
                                Branch
                              </div>
                              <div className="col-12 col-md-8 col-lg-9 col-xl-10 mb-3">
                                {
                                  data?.payoutPaymentDetails?.bankDetails
                                    ?.branch
                                }
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="pt-4 pb-3">
                        <div className="col-12 col-md-6 ">
                          <div className="d-flex justify-content-between align-items-center pb-3">
                            <div className="col-12 mb-2 text-muted">
                              Subtotal Amount
                            </div>
                            <div className="col-12 text-right mb-2 font-base">
                              $ {data.totalAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-bottom">
                        <div className="col-12 col-md-6 ">
                          <div className="d-flex justify-content-between align-items-center pb-3">
                            <div className="col-12 mb-2 text-muted">
                              Commission
                            </div>
                            <div className="col-12 text-right mb-2 font-base">
                              $ {data.totalCommission}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4 pb-3">
                        <div className="col-12 col-md-6 ">
                          <div className="d-flex justify-content-between align-items-center pb-3">
                            <div className="col-12 mb-2 text-muted">
                              Total Payout Amount
                            </div>
                            <div className="col-12 text-right mb-2 font-base">
                              $ {data.totalPayoutAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}{' '}
          </div>
        </div>
      </div>
      {/* <PayoutProducListView
        products={data.data.productDetails}
      ></PayoutProducListView> */}
    </AppLayout>
  );
};

export default PayoutDetails;
