import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import classnames from 'classnames';
import PhoneInput from '../../../components/phone-input';
import ErrorMessage from '../../../components/text/ErrorMessage';
import useAuth from '../../../hooks/useAuth';
import FileUploadController from '../../../components/upload/FileUploadController';
import Checkbox from '../../../components/form-group/checkbox';

interface FormProps {
  bankName: string;
  accountNumber: string;
  confirmAccountNumber: string,
  bankCode: string;
  branch?: string;
  isDefault?: boolean;
}

const BankUpdateForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
}> = (props) => {
  const auth = useAuth();
  const { control, handleSubmit, errors, setValue, watch } = useForm<FormProps>(
    {
      reValidateMode: 'onChange',
      mode: 'onSubmit',
    }
  );

  const renderBankName = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4">
          <label className="font-sm mb-1">Bank Name</label>
          <Controller
            control={control}
            rules={{ required: true}}
            name="bankName"
            // defaultValue={props.bankName}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control bg-white': true,
                  error: !!errors.bankName,
                })}
                // defaultValue={props.bankName}
                // ref={register}
                placeholder="Bank Name"
                name={name}
                value={value}
                onChange={onChange}
                id="bankName"
              />
            )}
          />
          {errors.bankName && (
            <ErrorMessage>
              Bank name is required
            </ErrorMessage>
          )}
        </div>
      </div>
    );
  };
  const renderBankCode = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4">
          <label htmlFor="profile-name" className="font-sm mb-1">
          Bank Code
          </label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="bankCode"
            // defaultValue={props.bankCode}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control bg-white': true,
                  error: !!errors.bankCode,
                })}
                id="name"
                placeholder="Name"
                name={name}
                value={value}
                // ref={register}
                onChange={onChange}
              />
            )}
          />
          {errors.bankCode && (
            <ErrorMessage>
              {'Bank Code is required'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };

  const renderAccountNumber = () => {
    return (
      <>
        <div className="col-md-12">
          <div className="form-group mb-4">
            <label htmlFor="accountNumber" className="font-sm mb-1">
            Account Number
          </label>
            <Controller
              control={control}
              name="accountNumber"
              rules={{ required: true }}
              render={({ onChange, value, name }) => (
                <input
                  type="text"
                  className={classnames({
                    'form-control bg-white': true,
                    error: !!errors.accountNumber,
                  })}
                  id="name"
                  placeholder="Account Number"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.accountNumber && (
            <ErrorMessage>
              {'Account Number is required'}
            </ErrorMessage>
          )}{' '}
          </div>
        </div>
      </>
    );
  };

  const renderConfirmAccountNumber = () => {
    return (
      <>
        <div className="col-md-12">
          <div className="form-group mb-4">
            <label htmlFor="confirmAccountNumber" className="font-sm mb-1">
            Confirm Account Number
          </label>
            <Controller
              control={control}
              name="confirmAccountNumber"
              rules={{ 
                required: true,
                validate: { isMatching: (value) => value === watch('accountNumber') },
              }}
              render={({ onChange, value, name }) => (
                <input
                  type="text"
                  className={classnames({
                    'form-control bg-white': true,
                    error: !!errors.confirmAccountNumber,
                  })}
                  id="confirmAccountNumber"
                  placeholder="Confirm Account Number"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.confirmAccountNumber && (
            <>
            {errors.confirmAccountNumber.type === 'required' && (
              <div className="error">Confirm Account Number is required</div>
            )}
            {errors.confirmAccountNumber.type === 'isMatching' && (
              <div className="error">Account Numbers should match</div>
            )}
          </>
        )}
          </div>
        </div>
      </>
    );
  };

  const renderBranch = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4">
          <label htmlFor="branch" className="font-sm mb-1">
            Branch
          </label>
          <Controller
            control={control}
            name="branch"
            rules={{ required: true }}
            render={({ onChange, name, value }) => {
              return (
                <input
                  type="text"
                  className={classnames({
                    'form-control bg-white': true,
                    error: !!errors.branch,
                  })}
                  id="name"
                  placeholder="Branch"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          ></Controller>
          {errors.bankCode && (
            <ErrorMessage>
              {'Branch is required'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };

  const renderIsDefault = () => {
    return (
     
      <div className="col-md-6 align-self-center col-md-6">
      <div className="form-group font-weight-semibold mb-4 mb-lg-0 checkbox ">
         
          <Controller
            control={control}
            name="isDefault"
            defaultValue={false}
            render={({ onChange, value, name }) => {
              return (
                <>
                <Checkbox
                  id={'isDefault'}
                  onChange={onChange}
                  label={"Is Default"}
                />
               </>
              );
            }}
          ></Controller>
        </div>
      </div>
    );
  };
  const renderAddButton = () => {
    return (
      <div className="col-md-6">
        <button
          type="submit"
          className="btn btn-primary font-weight-bold btn-block px-5 py-2 profile-edit-save"
          disabled={props.isSubmitting}
        >
          Add Bank
        </button>
      </div>
    );
  };

  return (
    <>
      <h6 className="font-base text-primary pb-3">Add New Bank Account</h6>
      <form onSubmit={handleSubmit(props.onSubmit)}>
                  
                      <div className="row">
                        {renderBankName()}
                        {renderBankCode()}
                        {renderAccountNumber()}
                        {renderConfirmAccountNumber()}
                        {renderBranch()}
                        {renderIsDefault()}
                        {renderAddButton()}
                       </div>
                     
                </form>
             
     
      
        
                   </>
    
  );
};

export default BankUpdateForm;

