import React from 'react';
import { FieldsMeta, ProductDetails } from '../../../api/models';

const ProductKeyFeatures: React.FunctionComponent<{
  product: ProductDetails;
  keyFields?: FieldsMeta[];
}> = (props) => {
  if (
    !props.product.subcategory?.properties ||
    !props.product.subcategory?.properties.length
  ) {
    return <></>;
  }
  return (
    <div className="key-features px-3 px-lg-0">
      <h5 className="mb-3">Key Information</h5>
      <div className="row font-normal">
        {props.product.subcategory?.properties?.map((kf) => {
          const value =
            props.product[kf.key as keyof ProductDetails] ||
            props.product?.customAttributes?.[kf.key];

          return (
            <>
              <div className="col-3 text-gray-500 mb-3 overflow-wrap">
                {kf.label}
              </div>
              <div className="col-9 text-gray-700 mb-3 font-weight-semibold overflow-wrap">
                {value || '-'}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProductKeyFeatures;
