import React from 'react';
import api from '../../../api';
import Modal from 'react-modal';
import ReportUser from './ReportUser';

const ReportAdResponsive: React.FunctionComponent<{
  reported?: boolean;
  userId: string;
}> = (props) => {
  const [modal, setModal] = React.useState<{ isOpen?: boolean; error?: Error }>(
    {}
  );

  const customStyles = {
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      margin: '20px auto',
      width: '100%',
      maxWidth: '500px',
      border: '0',
      padding: '0',
      background: 'transparent',
    },
    overlay: {
      overflow: 'auto',
    },
  };
  return (
    <>
      <div className="text-center">
        <div
          onClick={() => setModal({ isOpen: true })}
          className="d-inline-flex align-items-baseline text-primary font-normal cursor-pointer"
        >
          <i className="icon-warning mr-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
          Report this User
        </div>
      </div>
      <Modal
        isOpen={modal.isOpen || false}
        contentLabel={'Report this User'}
        onRequestClose={() => setModal({ isOpen: false })}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <ReportUser userId={props.userId} onRequestClose={() => setModal({})} />
      </Modal>
    </>
  );
};

export default ReportAdResponsive;
