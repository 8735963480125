import * as React from 'react';
import { usePerformAction } from 'react-remote-data-hooks';
import { Spinner } from '../loader';
import { toastr } from 'react-redux-toastr';

interface ButtonProps {
  id: string;
  downloadFn: (id: string) => void;
}

const PdfDownloadButton: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    performAction: downloadPDF,
    isPerforming: downloading,
  } = usePerformAction(
    async (id: string) => {
      await props.downloadFn(id);
    },
    () => {
      toastr.success('Success', 'Downloaded successfully');
    },
    () => {
      toastr.error('Error', 'Failed to download');
    }
  );

  return (
    <>
      {downloading ? (
        <span className="cursor-pointer p-0 m-0">
          <Spinner height={40} width={40} />
        </span>
      ) : (

        <div className="row align-self-center py-1">
          <div className="col">
            <div
              onClick={() => downloadPDF(props.id)}
              className="font-base font-weight-semibold text-primary cursor-pointer"
            >
              <a className="icon-download mx-2 text-gray-600"></a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PdfDownloadButton;
