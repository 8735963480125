import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import classnames from 'classnames';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorMessage from '../../../../components/text/ErrorMessage';
import { BusinessDetails } from '../../../../api/models';

const BusinessDetailsForm: React.FunctionComponent<{
  onSubmit: (data: BusinessDetails) => void;
  isUpdating?: boolean;
  default: {
    businessName?: string;
    businessEmail?: string;
    businessPhone?: string;
  };
}> = (props) => {
  const { control, handleSubmit, errors } = useForm<BusinessDetails>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const renderBusinessName = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-business-name" className="sr-only">
          Business Name
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="name"
          defaultValue={props.default?.businessName}
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.name,
                })}
                id="register-business-name"
                placeholder="Business Name"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.name && <div className="error">Business Name is required</div>}
      </div>
    );
  };
  const renderRegistrationNumber = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-registration-number" className="sr-only">
          Registration Number
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="registrationNumber"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.registrationNumber,
                })}
                id="register-registration-number"
                placeholder="Registration Number"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.registrationNumber && (
          <div className="error">Registration Number is required</div>
        )}
      </div>
    );
  };

  const renderBusinessEmail = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-business-email" className="sr-only">
          Business Email
        </label>
        <Controller
          control={control}
          defaultValue={props.default?.businessEmail}
          rules={{ required: true, validate: { isEmail: validator.isEmail } }}
          name="email"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="email"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.email,
                })}
                id="register-business-email"
                placeholder="Business Email"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.email && (
          <>
            {errors.email.type === 'required' && (
              <div className="error">Business Email is required</div>
            )}
            {errors.email.type === 'isEmail' && (
              <div className="error">Invalid Email</div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderAddress = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-address" className="sr-only">
          Address
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="address"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.address,
                })}
                id="register-address"
                placeholder="Address"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.address && <div className="error">Address is required</div>}
      </div>
    );
  };

  const renderCity = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-city" className="sr-only">
          city
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="city"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.city,
                })}
                id="register-city"
                placeholder="City"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.city && <div className="error">City is required</div>}
      </div>
    );
  };

  const renderState = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-state" className="sr-only">
          State
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="state"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="text"
                className={classnames({
                  'form-control bg-light': true,
                  error: !!errors.city,
                })}
                id="register-state"
                placeholder="State"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.state && <div className="error">State is required</div>}
      </div>
    );
  };
  const renderBusinessPhone = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-business-phone" className="sr-only">
          Business Phone
        </label>
        <Controller
          control={control}
          name="phone"
          // defaultValue={props.default?.phone || ''}
          rules={{
            required: true,
          }}
          key={`phone`}
          render={({ onChange, name, value }) => (
            <PhoneInput
              autoFormat={false}
              country={'bs'}
              preferredCountries={['bs', 'in']}
              enableSearch={false}
              placeholder={'Phone Number'}
              inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
              containerClass={'textField'}
              dropdownStyle={{ borderColor: '#e5e6e7' }}
              disableCountryCode={false}
              countryCodeEditable={true}
              inputStyle={{
                width: '100%',
                borderRadius: '0px',
                borderColor: '#e5e6e7',
              }}
              disableSearchIcon={true}
              onChange={(val, data: { dialCode: string }) => {
                onChange(val);
              }}
              value={value}
            />
          )}
        />
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.type === 'required'
              ? 'Phone is required'
              : 'Invalid Phone'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderNextButton = () => {
    return (
      <div className="form-group pt-4">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          disabled={props.isUpdating}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="login-details col-lg-10 px-0 mt-4">
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {renderBusinessName()}
        {renderRegistrationNumber()}
        {renderBusinessEmail()}
        {renderAddress()}
        {renderCity()}
        {renderState()}
        {renderBusinessPhone()}
        {renderNextButton()}
      </form>
    </div>
  );
};

export default BusinessDetailsForm;
