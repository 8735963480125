import React, { useState } from 'react';
import AppLayout from '../layout/app';
import '../../styles/chat.scss';
import ChatScreen from './components/chats';

const Chat: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories hideFooter>
      <ChatScreen />
    </AppLayout>
  );
};

export default Chat;
