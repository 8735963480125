import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AvatarIcon from '../../../components/icons/avatar-icon';
import validator from 'validator';
import PhoneInput from '../../../components/phone-input';
import ErrorMessage from '../../../components/text/ErrorMessage';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import ForgotPasswordLink from '../../../components/links/forgot-password-link';

interface FormProps {
  phone: string;
  password: string;
}

const PhoneNumberForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  error?: string;
  defaultEmail?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const [isPasswordInput, setIsPasswordInput] = useState(false);
  const renderPhoneNumber = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-phone" className="sr-only">
          Phone
        </label>
        <Controller
          control={control}
          name="phone"
          // defaultValue={props.default?.phone || ''}
          rules={{
            required: true,
          }}
          key={`phone`}
          render={({ onChange, name, value }) => (
            <PhoneInput
              autoFormat={false}
              country={'bs'}
              preferredCountries={['bs', 'in']}
              enableSearch={false}
              placeholder={'Phone Number'}
              inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
              containerClass={'textField'}
              dropdownStyle={{ borderColor: '#e5e6e7' }}
              disableCountryCode={false}
              countryCodeEditable={true}
              inputStyle={{
                width: '100%',
                borderRadius: '0px',
                borderColor: '#e5e6e7',
              }}
              disableSearchIcon={true}
              onChange={(val, data: { dialCode: string }) => {
                onChange(val);
              }}
              value={value}
            />
          )}
        />
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.type === 'required'
              ? 'Phone is required'
              : 'Invalid Phone'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderNextButton = () => {
    return (
      <div className="row gutter-5 form-group mt-4 pt-3">
        <div className="col-5 col-md-4">
          <a href="otp-varification.html">
            <button
              type="submit"
              className="btn btn-primary btn-block font-weight-bold "
              disabled={props.isSubmitting}
            >
              Login
            </button>
          </a>
        </div>
        <div className="col-3 col-sm-2 d-flex align-items-center justify-content-center">
          <a
            onClick={() => history.goBack()}
            className="font-weight-bold cursor-pointer"
          >
            Cancel
          </a>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="top-head-block mt-lg-5">
        <AvatarIcon className="d-block mb-4 mb-lg-5" />
        <h3 className="text-dark mb-3 h3">Enter your phone</h3>
        <p className="text-muted font-weight-lighter mb-4 pb-3 f-16">
          Please enter your mobile number to receive a verification code
        </p>
      </div>
    );
  };

  const renderForgotPasswordLink = () => {
    return (
      <div className="mb-3 mt-2 pb-4 pt-3">
        <ForgotPasswordLink className="text-body text-underline">
          Forgot your password?
        </ForgotPasswordLink>
      </div>
    );
  };

  const renderPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="login-password" className="sr-only">
          Password
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="password"
          defaultValue={props.defaultEmail}
          render={({ onChange, value, name }) => (
            <input
              type="password"
              className={classnames({
                'form-control': true,
                error: !!errors.password,
              })}
              id="login-password"
              placeholder="Password"
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {errors.password && (
          <ErrorMessage>
            {errors.password.type === 'required'
              ? 'Password is required'
              : 'Invalid Password'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {renderPhoneNumber()}
        {renderPassword()}
        {props.error && (
          <span className="error text-danger ">{props.error}</span>
        )}
        {renderNextButton()}
        {renderForgotPasswordLink()}

      </form>
    </>
  );
};

export default PhoneNumberForm;
