import React, { useState } from 'react';
import { FollowerProps, FollowingAndFollowers } from './my-following';
import classnames from 'classnames';

const FollowersAndFollowingTab: React.FunctionComponent<FollowerProps> = (
  props
) => {
  const [tab, setTab] = useState<FollowingAndFollowers>(
    FollowingAndFollowers.Followers
  );
  const onTabChange = (tabValue: FollowingAndFollowers) => {
    setTab(tabValue);
    if (props.onTabChange) {
      props.onTabChange(tabValue);
    }
  };
  return (
    <nav className="tabs-header myaccount">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          className={classnames({
            'nav-item nav-link  cursor-pointer': true,
            active: tab === FollowingAndFollowers.Followers,
          })}
          id="nav-home-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
          onClick={() => onTabChange(FollowingAndFollowers.Followers)}
        >
          {props.followers} Followers
        </a>
        <a
          className={classnames({
            'nav-item nav-link  cursor-pointer': true,
            active: tab === FollowingAndFollowers.Followings,
          })}
          id="nav-profile-tab"
          data-toggle="tab"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
          onClick={() => onTabChange(FollowingAndFollowers.Followings)}
        >
          {props.following} Followings
        </a>
      </div>
    </nav>
  );
};
export default FollowersAndFollowingTab;
