import React, { CSSProperties } from 'react';
// import PropTypes from 'prop-types';

import '../Input.scss';
import './Checkbox.scss';

interface CustomProps {
  selected?: boolean;
  disabled?: boolean;
  label: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: string;
  error?: string;
  id: string;
  onChange: (value: boolean) => void;
  customStyles?: CSSProperties;
  labelClassName?: string;
}

class Checkbox extends React.Component<CustomProps> {
  renderLabel(id: string) {
    return (
      <label
        className={`checkbox__label ${this.props.labelClassName || ''}`}
        htmlFor={id}
      >
        {this.props.label}
      </label>
    );
  }

  renderError() {
    return (
      this.props.error && (
        <span className="Input__error error">{this.props.error}</span>
      )
    );
  }

  renderInput(id: string) {
    return (
      <input
        type="checkbox"
        id={id}
        name={id}
        checked={this.props.selected}
        onChange={(e: any) => this.props.onChange(e.target.checked)}
        disabled={this.props.disabled}
      />
    );
  }

  render() {
    const sizeClass = `checkbox__${this.props.size || 'sm'}`;

    const id = `checkbox-${this.props.id}`;
    return (
      <div className={`Input Input--gap-${this.props.gap || 'md'}`}>
        <div
          className={`checkbox ${sizeClass}`}
          style={this.props.customStyles}
        >
          {this.renderInput(id)}
          {this.renderLabel(id)}
        </div>
        {this.renderError()}
      </div>
    );
  }
}

export default Checkbox;
