import React, { useState } from 'react';
import { PageType } from '../../profile';
import { Link } from 'react-router-dom';
import MyAccount from '../../common/my-account';
import SwitchInput from '../../../components/form-group/switch-input';
import authUser from '../../../store/reducers/authUser';
import useAuth from '../../../hooks/useAuth';
import api from '../../../api';
import Logout from './logoutAllDevices';
import Deactivate from './deactivateAccount';
enum SettingsTab {
  'Notifications',
  'Logout',
  'Deactivate',
}

const SettingsScreen: React.FunctionComponent = () => {
  const auth = useAuth();

  const [settingsTab, setSettingsTab] = useState<SettingsTab>(
    SettingsTab.Notifications
  );
  const [allowNotification, setAllowNotification] = useState<boolean>(
    auth.profile?.allowNotification || false
  );
  const updateSettings = (val: boolean) => {
    api.user.updateSettings(val).then((response) => {
      setAllowNotification(val);
    });
  };
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Settings
          </li>
        </ol>
      </nav>
    );
  };

  return (
    <div className="invoice-inner py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.Settings} />
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link to="/account" className="text-white">
                <i className="icon-prev font-lg mr-3"></i>Settings
              </Link>
            </h6>
          </div>
          <div className="col-lg-9">
            <div className="right-panel">
              <div className="settings-inner">
                <h4 className="text-gray-900 d-none d-lg-block">Settings</h4>
                <div className="settings-fill-tabs ">
                  <nav className="tabs-header myaccount d-none d-lg-block">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <a
                        className={
                          settingsTab === SettingsTab.Notifications
                            ? 'nav-item nav-link font-base active cursor-pointer'
                            : 'nav-item nav-link font-base cursor-pointer'
                        }
                        id="nav-month-tab"
                        data-toggle="tab"
                        onClick={() =>
                          setSettingsTab(SettingsTab.Notifications)
                        }
                        role="tab"
                        aria-controls="nav-Week"
                        aria-selected="true"
                      >
                        Notifications
                      </a>
                      <a
                        data-toggle="modal"
                        data-target="#Loguout"
                        onClick={() => setSettingsTab(SettingsTab.Logout)}
                        className={
                          settingsTab === SettingsTab.Logout
                            ? 'nav-item nav-link font-base active cursor-pointer'
                            : 'nav-item nav-link font-base cursor-pointer'
                        }
                      >
                        Logout from all Device
                      </a>
                      <a
                        onClick={() => setSettingsTab(SettingsTab.Deactivate)}
                        className={
                          settingsTab === SettingsTab.Deactivate
                            ? 'nav-item nav-link font-base active cursor-pointer'
                            : 'nav-item nav-link font-base cursor-pointer'
                        }
                        data-toggle="modal"
                        data-target="#DeactivateAccount"
                      >
                        Deactivate Account
                      </a>
                    </div>
                  </nav>

                  <div
                    className="tab-content pt-lg-4 pt-md-2"
                    id="nav-tabContent"
                  >
                    {settingsTab === SettingsTab.Notifications && (
                      <div
                        className="tab-pane fade p-3 p-md-4 bg-white rounded show active"
                        id="nav-month"
                        role="tabpanel"
                        aria-labelledby="nav-month-tab"
                      >
                        <div className=" row flex-column-reverse flex-lg-row d-lg-flex align-items-center notifications">
                          <div className="col-lg-10">
                            <h6 className="m-0 text-gray-900 up-text">
                              Receive updates, offer, surveys & Recommendation
                              based on your activity
                            </h6>
                          </div>
                          <div className="d-flex d-lg-block justify-content-between col-lg-2">
                            <h6 className="d-lg-none">Notifications</h6>
                            <div className="switching-checkbox d-flex justify-content-end">
                              <SwitchInput
                                onChange={(val) => {
                                  updateSettings(val);
                                }}
                                default={allowNotification}
                                name="allowNotification"
                              ></SwitchInput>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {settingsTab === SettingsTab.Logout && (
                      <Logout
                        isOpen={settingsTab === SettingsTab.Logout}
                        onRequestClose={() =>
                          setSettingsTab(SettingsTab.Notifications)
                        }
                      />
                    )}
                    {settingsTab === SettingsTab.Deactivate && (
                      <Deactivate
                        isOpen={settingsTab === SettingsTab.Deactivate}
                        onRequestClose={() =>
                          setSettingsTab(SettingsTab.Notifications)
                        }
                      />
                    )}
                    <a
                      data-toggle="modal"
                      data-target="#Loguout"
                      onClick={() => setSettingsTab(SettingsTab.Logout)}
                      className={
                        settingsTab === SettingsTab.Logout
                          ? 'nav-item nav-link font-base active cursor-pointer text-gray-900 py-3 bg-white d-lg-none border-bottom border-fade'
                          : 'nav-item nav-link font-base cursor-pointer text-gray-900 py-3 bg-white d-lg-none border-bottom border-fade'
                      }
                    >
                      Logout from all Device
                    </a>
                    <a
                      onClick={() => setSettingsTab(SettingsTab.Deactivate)}
                      className={
                        settingsTab === SettingsTab.Deactivate
                          ? 'nav-item nav-link font-base active cursor-pointer text-gray-900 py-3 bg-white d-lg-none border-bottom border-fade'
                          : 'nav-item nav-link font-base cursor-pointer text-gray-900 py-3 bg-white d-lg-none border-bottom border-fade'
                      }
                      data-toggle="modal"
                      data-target="#DeactivateAccount"
                    >
                      Deactivate Account
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingsScreen;
