import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../api';
import { RouteKeys } from '../containers/routes/route-keys';
import { State } from '../store/interfaces';
import {
  loggedInAction,
  firebaseLoggedInAction,
  logoutAction,
  setEmailAction,
  redirectApplyAction,
  redirectSaveAction,
  otpSentAction,
  profileUpdatedAction,
  UpdatePhoneAction,
  showPasswordBoxAction,
} from '../store/reducers/authUser';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';

const useAuth = () => {
  const authState = useSelector((state: State) => state.authUser);
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const history = useHistory();

  const sendOtp = async (phone: string, onSuccess?: () => void) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.phoneLogin(phone);
      if (response.status === StatusCodes.OK) {
        if (response.data.isSignup) {
          dispatch(otpSentAction({ phone, userId: response.data.userId }));
          history.push(RouteKeys.SignupOtp);
        } else {
          dispatch(otpSentAction({ phone, userId: response.data.userId }));
          history.push(RouteKeys.LoginWithPhoneOtp);
        }
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      setLoginError('Unable to send OTP');
    }
    setUpdating(false);
  };

  const forgotPassword = async (phone: string, onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.forgotPassword(phone);
      if (response.status === StatusCodes.OK) {
        dispatch(otpSentAction({ phone, userId: response.data!.userId }));
      }
      history.push(RouteKeys.ForgotPasswordOtp);
      if (onSuccess) onSuccess();
    } catch (error) {
      setError('Unable to send OTP');
    }
    setUpdating(false);
  };
  const resetPassword = async (
    otp: string,
    password: string,
    onSuccess?: () => void
  ) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.resetPassword(
        otp,
        password,
        authState.userId!
      );
      if (response.status === StatusCodes.OK) {
        history.push(RouteKeys.Login);
        if (onSuccess) onSuccess();
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
    setUpdating(false);
  };
  const resendOtp = async (onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      await api.auth.resendOtp(authState.phone!);
      if (onSuccess) onSuccess();
    } catch (error) {
      setError('Unable to resend OTP');
    }
    setUpdating(false);
  };

  const login = async (
    data: {
      email: string;
      password: string;
    },
    onSuccess?: () => void
  ) => {
    setLoginError(undefined);
    setUpdating(true);
    dispatch(setEmailAction(data.email));
    try {
      const response = await api.auth.login({
        username: data.email,
        password: data.password,
      });
      if (response.status === StatusCodes.OK) {
        api.setToken(response.data?.token);
        dispatch(loggedInAction(response.data));
        const firbaseTokenResponse = await api.auth.getFirebaseToken();
        dispatch(firebaseLoggedInAction(firbaseTokenResponse.data));
        api.setFirebaseToken(response.data?.token);
        history.push(authState.redirectTo || RouteKeys.Home);
        redirectApply();
        if (onSuccess) onSuccess();
      } else {
        setLoginError('Invalid Username or Password!');
      }
    } catch (error) {
      setLoginError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const verifyOtp = async (otp: string, onSuccess?: () => void) => {
    setLoginError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.verifyLoginOtp(authState.userId!, otp);
      if (response.status === StatusCodes.OK) {
        dispatch(loggedInAction(response.data));
        api.setToken(response.data?.token);
        if (response.data?.user?.isProfileSet) {
          history.push(RouteKeys.Home);
        } else {
          history.push(RouteKeys.OnBoard);
        }
        if (onSuccess) onSuccess();
      } else {
        setLoginError('OTP not verified');
      }
    } catch (error) {
      setLoginError(error.response.data.message);
    }
    setUpdating(false);
  };
  const loginWithPhoneResendOtp = async (onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      await api.auth.loginWithPhoneResendOtp(authState.phone!);
      if (onSuccess) onSuccess();
    } catch (error) {
      setError('Unable to resend OTP');
    }
    setUpdating(false);
  };
  const logout = () => {
    dispatch(logoutAction());
    history.push(RouteKeys.Login);
  };

  const updateBasicDetails = async (
    data: {
      name: string;
      email: string;
      password: string;
    },
    onSuccess?: () => void
  ) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateBasicDetails({
        ...data,
        accountType: authState.profile?.accountType!,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        history.push(RouteKeys.Profile);
        if (onSuccess) onSuccess();
      } else {
        setError('Unable to update details');
      }
    } catch (error) {
      setError('Unable to update details');
    }
    setUpdating(false);
  };
  const updateProfileDetails = async (data: any, onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateProfileDetails({
        ...data,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        history.push(RouteKeys.Profile);
        if (onSuccess) onSuccess();
      } else {
        setError('Unable to update details');
      }
    } catch (error) {
      setError('Unable to update details');
    }
    setUpdating(false);
  };

  const updateBankDetails = async (
    data: {
      bankName: string;
      accountNumber: string;
      bankCode: string;
      branch?: string;
      default?: boolean;
    },
    onSuccess?: () => void
  ) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateBankDetails({
        ...data,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        history.push(RouteKeys.PayoutRequest);
        if (onSuccess) onSuccess();
      } else {
        setError('Unable to update details');
      }
    } catch (error) {
      setError('Unable to update details');
    }
    setUpdating(false);
  };

  const updatePaypalDetails = async (data: string, onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updatePaypalDetails(data);
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        history.push(RouteKeys.PayoutRequest);
        if (onSuccess) onSuccess();
      } else {
        setError('Unable to update details');
      }
    } catch (error) {
      setError('Unable to update details');
    }
    setUpdating(false);
  };

  const updateProfilePhone = async (otp: string, onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.updateProfilePhone(
        otp,
        authState.updatePhoneToken!
      );
      if (response.status === StatusCodes.OK) {
        history.push(authState.redirectTo || RouteKeys.Profile);
        redirectApply();
        if (onSuccess) onSuccess();
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
    setUpdating(false);
  };
  const updatePhoneOtp = async (phone: string, onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.updatePhoneOtp(phone);
      if (response.status === StatusCodes.OK) {
        dispatch(
          UpdatePhoneAction({
            updatePhoneToken: response.data.token,
            userId: response.data.userId,
            updatedPhone: phone,
          })
        );
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      setError(error.response.data.message);
    }
    setUpdating(false);
  };

  const updatePhoneResendOtp = async (onSuccess?: () => void) => {
    setError(undefined);
    setUpdating(true);
    try {
      await api.auth.updatePhoneResendOtp(authState.updatedPhone!);
      if (onSuccess) onSuccess();
    } catch (error) {
      setError('Unable to resend OTP');
    }
    setUpdating(false);
  };

  const redirectSave = (to: string) => {
    dispatch(redirectSaveAction(to));
  };

  const offset: number = useSelector(
    (state: State) => state.authUser.offSet ?? 0
  );

  const getCurrentTimeWithTimeOffset = () => {
    return moment(new Date()).add(offset).toDate().getTime();
  };

  const showLoginPasswordBox = async (phone: string, onSuccess?: () => void) => {
       console.log('hi');
  };

  const loginWithPhone = async (
    data: {
      phone: string;
      password: string;
    },
    onSuccess?: () => void
  ) => {

    setLoginError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.login({
        username: data.phone,
        password: data.password,
      });
      if (response.status === StatusCodes.OK) {
        api.setToken(response.data?.token);
        dispatch(loggedInAction(response.data));
        const firbaseTokenResponse = await api.auth.getFirebaseToken();
        dispatch(firebaseLoggedInAction(firbaseTokenResponse.data));
        api.setFirebaseToken(response.data?.token);
        history.push(authState.redirectTo || RouteKeys.Home);
        redirectApply();
        if (onSuccess) onSuccess();
      } else {
        setLoginError('Invalid Username or Password!');
      }
    } catch (error) {
      setLoginError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const redirectApply = () => dispatch(redirectApplyAction());
  return {
    isAuthenticated: !!authState.token,
    profile: authState.profile,
    loginError,
    updating,
    login,
    logout,
    redirectSave,
    redirectApply,
    sendOtp,
    forgotPassword,
    resetPassword,
    error,
    verifyOtp,
    resendOtp,
    loginWithPhoneResendOtp,
    updateBasicDetails,
    updateBankDetails,
    updatePaypalDetails,
    updatePhoneOtp,
    updateProfilePhone,
    updatePhoneResendOtp,
    updateProfileDetails,
    getCurrentTimeWithTimeOffset,
    showLoginPasswordBox,
    loginWithPhone,
  };
};

export default useAuth;
