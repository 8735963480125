import AuthLayout from '../layout/auth';
import { useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import LoginBanner from './components/login-banner';
import {
  FacebookLogin,
  GoogleLogin,
  PhoneSignup,
} from '../../components/social-media-login';
import SignupForm from './components/signup-form';
import useSignup from '../../hooks/useSignup';

const SignupScreen: React.FunctionComponent = () => {
  const {
    signup,
    facebookLogin,
    googleLogin,
    updating,
    signupError,
  } = useSignup();
  const signupState = useSelector((state: State) => state.signup);
  console.log(signupState);

  return (
    <AuthLayout containerClass="signup-form">
      <div
        className="col-lg-5 bg-box d-none d-lg-flex align-items-center text-white px-5 signup"
        style={{ maxWidth: '473px' }}
      >
        <LoginBanner />
      </div>
      <div className="col-lg-7 col-xl bg-white content-box signup">
        <div className="p-sm-4">
          <a href="/" title="DealDen">
            <LogoSVG className="d-lg-none"></LogoSVG>
          </a>
          <div className="login-form mx-auto py-5">
            <h1 className="font-weight-bold pb-4 text-center">
              Create Account
            </h1>
            <div className="d-flex justify-content-center social-media-login pb-5">
              <FacebookLogin onClick={facebookLogin} />
              <GoogleLogin onClick={googleLogin} />

              <PhoneSignup />
            </div>
            <p className="text-muted text-center">
              or use your email for registration
            </p>
            <SignupForm
              onSubmit={(signupData) => signup(signupData)}
              isSubmitting={updating}
              email={signupState.email}
              name={signupState.name}
              isSocialMedia={signupState.isSocialMedia}
              error={signupError}
            ></SignupForm>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignupScreen;
