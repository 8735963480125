import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import {
  GoogleLogin,
  PhoneLogin,
  FacebookLogin,
} from '../../components/social-media-login';
import LoginWithEmailForm from './components/login-with-email';
import SignupBanner from './components/signup-banner';
import useAuth from '../../hooks/useAuth';
import useSignup from '../../hooks/useSignup';

const LoginScreen: React.FunctionComponent = () => {
  const { login, updating, loginError } = useAuth();
  const { facebookLogin, googleLogin, signupError } = useSignup();

  return (
    <AuthLayout>
      <div className="col-lg-7 col-xl bg-white content-box login">
        <div className="p-sm-4">
          <a href="/" title="DealDen">
            <LogoSVG />
          </a>
          <div className="login-form mx-auto py-5 text-center">
            <h1 className="font-weight-bold pb-4">Sign in to DealDen</h1>
            <div className="d-flex justify-content-center social-media-login pb-5">
              <FacebookLogin onClick={facebookLogin} />
              <GoogleLogin onClick={googleLogin} />
              <PhoneLogin />
            </div>
            <p className="text-muted text-center">
              or use your email for registration
            </p>
            <LoginWithEmailForm
              onSubmit={(loginData) => login(loginData)}
              isSubmitting={updating}
              error={loginError || signupError}
            ></LoginWithEmailForm>
          </div>
        </div>
      </div>
      <div
        className="col-lg-5 bg-box d-none d-lg-flex align-items-center text-white px-5 login"
        style={{ maxWidth: '473px' }}
      >
        <SignupBanner />
      </div>
    </AuthLayout>
  );
};

export default LoginScreen;
