import AuthLayout from '../layout/auth';
import React, { useState } from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import HelpLink from '../../components/links/help-link';
import useAuth from '../../hooks/useAuth';
import PhoneNumberForm from '../login-with-phone/components/phone-number-form';
import AppLayout from '../layout/app';
import OtpIcon from '../../components/icons/otp-icon';
import OtpForm from './components/otp-form';
import { Link } from 'react-router-dom';


const renderBreadCrumbs = () => {
  return (
    <nav aria-label="breadcrumb" className="d-none d-lg-block">
      <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">My Account</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/profile">Profile</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            <a href="/edit-profile">Edit Profile</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Update Phone
            </li>
          </ol>
    </nav>
  );
};


const LoginWithPhoneScreen: React.FunctionComponent = () => {
  const {
    updatePhoneOtp,
    updateProfilePhone,
    updatePhoneResendOtp,
    updating,
    error,
  } = useAuth();
  const [isPhoneSubmit, setIsPhoneSubmit] = useState<boolean>(false);

  return (
    <AppLayout hideCategories hideFooter>
       <div className="container pt-lg-4 pt-0 pb-md-5 rmv-padd-sm">
          {renderBreadCrumbs()}
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
          <Link className="text-white" to="/edit-profile"><i className="icon-prev font-lg mr-3"></i>Change Password</Link>
        </h6>
      </div>
      <div className="px-lg-5 mx-auto py-5 py-lg-2 update-phone px-3 px-lg-0 bg-white mt-0 mt-sm-3">
      {!isPhoneSubmit && (
        <PhoneNumberForm
          onSubmit={(data: { phone: string }) =>
            updatePhoneOtp(data.phone, () => {
              setIsPhoneSubmit(true);
            })
          }
          isSubmitting={updating}
          error={error}
        />
      )}
      {isPhoneSubmit && (
       
     <>
          <div className="top-head-block otp-block mt-lg-4">
            <OtpIcon className="mb-4 mb-lg-5 d-none d-lg-block" />
            <h4 className="text-dark mb-3">OTP Verification</h4>
            <p className="text-muted font-weight-lighter">
              OTP has been sent to your mobile number. Please verify
            </p>
            <OtpIcon className="mb-5 mb-lg-5 d-block d-lg-none text-center" />
          </div>
          <OtpForm
            onSubmit={(data) => updateProfilePhone(data.otp)}
            onResendButtonClicked={() => {
              updatePhoneResendOtp();
            }}
            isUpdating={updating}
          ></OtpForm>
         
        </>
      )}
       </div>
      </div>
    </AppLayout>
  );
};

export default LoginWithPhoneScreen;
