import React, { useEffect, useRef, useState } from 'react';
import { PageType } from '../../profile';
import { Link } from 'react-router-dom';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import Empty from '../../../components/empty';
import { Payment, PaymentStatus } from '../../../api/models/payment';
import { format } from 'date-fns';
import { RouteKeys } from '../../routes/route-keys';
import {
  OrdersCatalogFilter,
  OrderStatusSyllables,
  Product,
} from '../../../api/models';
import ProductFilter from '../../../components/product-filter/ProductFilter';
import { ProductFilterModel } from '../../../components/product-filter/ProductFilterModel';
import AdsPagination, {
  AdsPaginationProps,
} from '../../../components/ads-pagination/pagination';
import { scrollTop } from '../../../utils/window-utils';
import { pick } from 'lodash';
import { OrderStatus } from '../../../api/models/order';
import Modal from 'react-modal';
import BiddingHistory from '../../my-auction/components/bidding-history';
import { Dropdown } from 'react-bootstrap';
import ReactModal from 'react-modal';

enum MyOrdersTab {
  'MyOrders',
  'Purchased',
}
interface CustomProps {
  onBiddingHistoryClick?: (product: Product) => void;
}

const initialFilter = {
  [MyOrdersTab.MyOrders]: {
    status: '',
    query: '',
    startDate: '',
    endDate: '',
    category: '',
    subCategory: '',
    page: 1,
    perPage: 10,
  },
  [MyOrdersTab.Purchased]: {
    status: '',
    query: '',
    startDate: '',
    endDate: '',
    category: '',
    subCategory: '',
    page: 1,
    perPage: 10,
  },
};

interface PageQuery {
  page?: number;
  perPage?: number;
}

interface MyOrdersFilterModel {
  [MyOrdersTab.MyOrders]: OrdersCatalogFilter & PageQuery;
  [MyOrdersTab.Purchased]: OrdersCatalogFilter & PageQuery;
}

interface PaymentResponseModel {
  data: any;
  headerData: any;
}

const MyOrdersScreen: React.FunctionComponent<CustomProps> = (props) => {
  const [myOrderTab, setMyOrderTab] = useState<MyOrdersTab>(
    MyOrdersTab.MyOrders
  );

  const [payments, setPayments] = useState<PaymentResponseModel>({
    data: [],
    headerData: null,
  });
  const [orders, setOrders] = useState<any>([]);
  const [modal, setModal] = React.useState<{
    isOpen?: boolean;
    productId?: string;
    error?: Error;
  }>({});
  const {
    data: products,
    reload: orderReload,
    loaded: productsLoaded,
  } = useDataLoader<any[]>(() => {
    const tmpFilter = filter[MyOrdersTab.MyOrders];
    return api.profile.getMyProductOrders({
      pagination: {
        perPage: tmpFilter.perPage,
        page: tmpFilter.page,
      },
      sort: {},
      // queryParams: {
      //   ...pick(tmpFilter, [
      //     'status',
      //     'query',
      //     'startDate',
      //     'endDate',
      //     'category',
      //     'subCategory',
      //   ]),
      // },
      queryParams: {
        query: tmpFilter.query,
        status: tmpFilter.status,
        startDate: tmpFilter.startDate,
        endDate: tmpFilter.endDate,
        category: tmpFilter.category,
        subCategory: tmpFilter.subCategory,
      },
    });
  });
  const {
    data: paymentResponse,
    loading,
    loaded: paymentLoaded,
    loading: paymentLoading,
    reload: paymentReload,
  } = useDataLoader<{
    count: number;
    page: number;
    perPage: number;
    totalPage: number;
    data: PaymentResponseModel;
  }>(() => {
    const tmpFilter = filter[MyOrdersTab.Purchased];
    return api.payment.getMyPayments({
      pagination: {
        perPage: tmpFilter.perPage,
        page: tmpFilter.page,
      },
      sort: {},
      queryParams: {
        ...pick(tmpFilter, [
          'status',
          'query',
          'startDate',
          'endDate',
          'category',
          'subCategory',
        ]),
      },
    });
  });

  useEffect(() => {
    if (paymentResponse?.data) {
      setPayments(paymentResponse.data);
    }
  }, [paymentResponse]);

  const {
    data: orderResponse,
    loaded: orderLoaded,
    loading: orderLoading,
  } = useDataLoader<{ data: any[] }>(() => {
    const tmpFilter = filter[MyOrdersTab.MyOrders];
    return api.order.getMyOrders({
      pagination: {
        perPage: tmpFilter.perPage,
        page: tmpFilter.page,
      },
      sort: {},
      queryParams: {
        ...pick(tmpFilter, [
          'status',
          'query',
          'startDate',
          'endDate',
          'category',
          'subCategory',
        ]),
      },
    });
  });

  useEffect(() => {
    if (orderResponse?.data) {
      setOrders(orderResponse.data);
    }
  }, [orderResponse]);

  // useEffect(() => {
  //   if (orderResponse.) {
  //     setOrders(paymentResponse.data);
  //   }
  // }, [orderResponse]);

  const QRCode = require('qrcode.react');

  const [filter, setFilter] = useState<MyOrdersFilterModel>(initialFilter);
  const updateFilterByType = (tmpFilter: any) =>
    setFilter({
      ...filter,
      [myOrderTab]: {
        ...filter[myOrderTab],
        ...tmpFilter,
      },
    });
  const filterRef = useRef<any>(null);
  const resetFilter = () => setFilter({ ...initialFilter });

  useEffect(() => {
    paymentReload();
  }, [filter[MyOrdersTab.Purchased]]);

  useEffect(() => {
    orderReload();
  }, [filter[MyOrdersTab.MyOrders]]);

  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };

  const renderPurchasedPlans = () => {
    if (payments?.data?.length === 0 || paymentResponse?.count === 0)
      return <Empty />;

    const { count, page, perPage } = paymentResponse;

    return (
      <>
        <div className="invoice-pannel-listing pt-lg-4 pt-2">
          <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
            <div className="row gutter-5 justify-content-between">
              <div className="col-md-2">
                <p className="m-0">Invoice ID</p>
              </div>
              <div className="col-md-2">
                <p className="m-0">Date</p>
              </div>

              <div className="col-md-2">
                <p className="m-0">Payment Type</p>
              </div>
              <div className="col-md-2">
                <p className="m-0">Amount</p>
              </div>
              <div className="col-md-2">
                <p className="m-0">Status</p>
              </div>
            </div>
          </div>
          {payments?.data?.map((payment: Payment) => {
            return (
              <div className="invoice-row bg-white rounded">
                <div className="row gutter-5 justify-content-between">
                  <div className="col-md-2 col-sm-4 col-6 pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Invoice ID</p>
                    <div className="inv-id d-flex ">
                      <span className="d-md-inline-block d-none">
                        <i className="icon-invoice"></i>
                      </span>
                      <p className="m-0">{payment.invoiceNumber}</p>
                    </div>
                  </div>
                  <div className="col-md-2 col-4 pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Date</p>
                    <p className="m-0">
                      {format(new Date(payment.createdAt), 'dd MMM yyyy')}
                    </p>
                  </div>
                  {/* <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                  <p className="m-0 d-md-none labelin">Recepient</p>
                  <p className="m-0 font-weight-semibold">Shini Sebastin</p>
                </div> */}
                  <div className="col-md-2 col-4 pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Payment Type</p>
                    <p className="m-0">{payment.paymentMode || 'Online'}</p>
                  </div>
                  <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                    <h6 className="m-0">$ {payment.amountPaid}</h6>
                  </div>
                  <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Status</p>
                    <span
                      className={
                        payment.status === PaymentStatus.Success
                          ? 'status completed'
                          : 'status pending'
                      }
                    >
                      {payment.status}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilterByType({
                ...filter[MyOrdersTab.Purchased],
                page: nextPage,
              });
              scrollTop();
            },
          })}
      </>
    );
  };
  const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <div
        className="dropdown filter position-absolute"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    )
  );
  const onBiddingHistoryClick = (product: Product) => {
    setModal({
      isOpen: true,
      productId: product._id,
    });
  };
  const customStyles = {
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      margin: '20px auto',
      width: '100%',
      maxWidth: '500px',
      border: '0',
      padding: '0',
      background: 'transparent',
    },
    overlay: {
      overflow: 'auto',
    },
  };
  const customStylesQr = {
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      margin: '20px auto',
      width: '100%',
      maxWidth: '280px',
      border: '0',
      padding: '0',
      background: 'transparent',
    },
    overlay: {
      overflow: 'auto',
    },
  };
  const [qrModal, setQrModal] = React.useState<boolean>(false);
  const [qrValue, setQrValue] = React.useState<string>('');
  const renderMyOrders = () => {
    if (products?.length === 0) return <Empty />;
    const {} = orders;

    const QrModal = () => {
      return (
        <ReactModal
          isOpen={qrModal}
          contentLabel="Qr Code"
          style={customStylesQr}
          onRequestClose={() => setQrModal(false)}
          shouldCloseOnOverlayClick={true}
        >
          <div className="p-3 bg-white rounded text-center">
            <QRCode value={qrValue} size={248} />
            <button
              className="btn btn-outline-dark border border-gray-500 mt-3 btn-block py-2"
              onClick={() => setQrModal(false)}
            >
              Close
            </button>
          </div>
        </ReactModal>
      );
    };
    return (
      <>
        {QrModal()}
        <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            {products?.map((product: Product) => {
              return (
                <div className="ads-item  pb-5 mb-2 mb-lg-0 pb-lg-0">
                  <div className="bg-white shadow px-lg-3 pt-lg-3 pb-2 pb-lg-0 position-relative mb-3 rounded">
                    {(product.bidCount || 0) > 0 && (
                      <Dropdown alignRight>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                          <span className="icon-more text-muted font-lg"></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => onBiddingHistoryClick(product)}
                          >
                            Bidding History
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <div className="px-lg-0 px-3">
                      <div className="row">
                        <div className="col-12 d-lg-none d-block bg-gray-100 border-bottom border-gray-300">
                          <div className="top-date">
                            <span className="date d-block font-sm py-2">
                              <span className="text-muted">Posted on:</span>
                              {format(
                                new Date(product.createdAt),
                                'dd MMM yyyy'
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="align-items-center border-bottom-lg-0 col-12 col-md-6 py-2 py-lg-0">
                          <figure className="mb-0 position-relative noresize ">
                            <Link
                              to={'/order-detail/' + product._id}
                              className="image overflow-hidden bg-light noresize"
                            >
                              {product?.images?.length && (
                                <img
                                  className="img-fluid"
                                  src={product?.images[0]?.path}
                                />
                              )}
                            </Link>
                            <figcaption>
                              <Link to={'/order-detail/' + product._id}>
                                <span className="date d-none font-sm d-lg-inline-block text-gray-700">
                                  <span className="text-muted">Posted on:</span>
                                  {format(
                                    new Date(product.createdAt),
                                    'dd MMM yyyy'
                                  )}
                                </span>

                                <span className="font-base text-gray-700 text-truncate d-block">
                                  {product.title}
                                </span>
                                <span className="price font-lg text-dark font-weight-bold  d-block d-lg-none">
                                  $
                                  {product?.sellingPrice?.toFixed(2) ||
                                    product?.price?.toFixed(2)}
                                </span>
                                <span className="font-sm text-muted mt-1">
                                  In: {product.subcategory?.title} |
                                  {product.category?.title}
                                </span>
                              </Link>
                            </figcaption>
                          </figure>
                        </div>
                        <div className="col-md-2 col-3 pl-0 border-bottom-lg-0 py-2 py-lg-0  align-items-center d-lg-flex d-none">
                          <span className="price font-lg text-dark font-weight-bold  d-block">
                            $
                            {product?.sellingPrice?.toFixed(2) ||
                              product?.price?.toFixed(2)}
                          </span>
                        </div>
                        {product?.orderStatus ===
                          OrderStatus.PaymentSuccess && (
                          <div className="align-self-center col-6 col-md-auto">
                            <div
                              className="qr-code py-2 py-lg-0"
                              onClick={() => {
                                setQrModal(true);
                                setQrValue(
                                  'https://dealden.ispghosting.com' +
                                    RouteKeys.DeliverProduct.replace(
                                      ':token',
                                      product?.qrcodeToken?.token || ''
                                    )
                                );
                              }}
                            >
                              <QRCode
                                value={
                                  'https://staging.dealden.com' +
                                  RouteKeys.DeliverProduct.replace(
                                    ':token',
                                    product?.qrcodeToken?.token || ''
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="align-self-center col-6 col-md">
                          {product?.orderStatus ? (
                            <span
                              className={`status ${
                                OrderStatusSyllables?.[product.orderStatus]
                                  ?.class
                              }`}
                            >
                              {
                                OrderStatusSyllables?.[product.orderStatus]
                                  ?.label
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="views-block border-top mt-lg-3 px-3 px-lg-0 py-lg-2 pt-2 border-gray-400">
                      <div className="row gutter-5">
                        <div className="col">
                          <span className="font-sm text-muted">
                            <i className="font-base icon-location"></i>
                            {product.location.state}
                            {', '}
                            {product.location.country}
                          </span>
                        </div>
                        <div className="col-auto d-flex">
                          <span className="font-sm text-muted mr-2 mr-lg-3 d-flex align-items-center">
                            <i className="icon-visible font-lg"></i>
                            {product.viewCount || 0}&nbsp;
                            <span className="d-none d-lg-inline-block">
                              views
                            </span>
                          </span>
                          <span className="font-sm text-muted d-flex align-items-center">
                            <i className="icon-heart-filled font-base"></i>
                            {product.likeCount || 0}&nbsp;
                            <span className="d-none d-lg-inline-block">
                              likes
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* {count !== 0 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilterByType({
                ...filter[MyOrdersTab.Purchased],
                page: nextPage,
              });
              scrollTop();
            },
          })} */}
      </>
    );
  };
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Orders
          </li>
        </ol>
      </nav>
    );
  };

  // if (!loaded || loading || !orderLoaded) {
  //   return (
  //     <>
  //       <div className="my-ads py-0 py-lg-4">
  //         <div className="container">
  //           {renderBreadCrumbs()}
  //           <div className="row">
  //             <MyAccount pageType={PageType.MyOrders} />
  //             <div className="col-lg-9">
  //               <div className="right-panel">
  //                 <h2 className="text-dark font-weight-bold mb-0 pb-2 title-head d-none d-lg-block">
  //                   My Orders
  //                 </h2>
  //                 <nav className="tabs-header myaccount">
  //                   <div
  //                     className="nav nav-tabs"
  //                     id="nav-tab"
  //                     role="tablist"
  //                   ></div>
  //                 </nav>
  //                 <ContainerLoader height={500}></ContainerLoader>;
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  return (
    <>
      <div className="my-ads py-0 py-lg-4">
        <div className="container">
          {renderBreadCrumbs()}
          <div className="row">
            <MyAccount pageType={PageType.MyOrders} />
            <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
              <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
                <Link to="/account" className="text-white">
                  <i className="icon-prev font-lg mr-3"></i> My Orders
                </Link>
              </h6>
              <i
                className="icon-filter text-white font-xl"
                onClick={() => {
                  filterRef?.current?.toggleExtraFilter();
                }}
              ></i>
            </div>
            {/* {loaded && orderLoaded && productsLoaded && ( */}
            <div className="col-lg-9">
              <div className="right-panel">
                {/* <h2 className="text-dark font-weight-bold mb-0 pb-2 title-head d-none d-lg-block">
                    My Orders
                  </h2> */}
                {myOrderTab === MyOrdersTab.MyOrders ? (
                  <ProductFilter
                    title="My Orders"
                    ref={filterRef}
                    defaultValues={initialFilter[MyOrdersTab.MyOrders]}
                    values={filter[MyOrdersTab.MyOrders]}
                    searchPlaceHolder="My Orders"
                    onFilterChange={(e: ProductFilterModel) => {
                      updateFilterByType(e);
                    }}
                    onFilterReset={resetFilter}
                    key="my-orders-filter"
                  />
                ) : (
                  <ProductFilter
                    title="My Orders"
                    ref={filterRef}
                    defaultValues={initialFilter[MyOrdersTab.Purchased]}
                    values={filter[MyOrdersTab.Purchased]}
                    searchPlaceHolder="Purchased Plan"
                    onFilterChange={(e: ProductFilterModel) => {
                      updateFilterByType(e);
                    }}
                    onFilterReset={resetFilter}
                    key="my-leads-filter"
                  />
                )}
                <nav className="tabs-header myaccount">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className={`nav-item nav-link cursor-pointer ${
                        myOrderTab === MyOrdersTab.MyOrders ? 'active' : ''
                      }`}
                      id="nav-profile-tab"
                      data-toggle="tab"
                      onClick={() => setMyOrderTab(MyOrdersTab.MyOrders)}
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      My Orders
                      {products && products.length > 0 ? (
                        <span className="count">({products.length})</span>
                      ) : (
                        ''
                      )}
                    </a>
                    <a
                      className={`nav-item nav-link cursor-pointer ${
                        myOrderTab === MyOrdersTab.Purchased ? 'active' : ''
                      }`}
                      id="nav-home-tab"
                      data-toggle="tab"
                      onClick={() => setMyOrderTab(MyOrdersTab.Purchased)}
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Purchased Plans
                      {paymentResponse && paymentResponse?.count > 0 ? (
                        <span className="count">
                          ({paymentResponse?.count})
                        </span>
                      ) : (
                        ''
                      )}
                    </a>
                  </div>
                </nav>

                {/* Tab Content */}
                <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
                  <div
                    className={`tab-pane fade show ${
                      myOrderTab === MyOrdersTab.MyOrders ? 'active' : ''
                    } `}
                    id="nav-buying"
                    role="tabpanel"
                  >
                    <div className="ads-item">
                      {orderLoaded ? renderMyOrders() : renderContainerLoader()}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade show ${
                      myOrderTab === MyOrdersTab.Purchased ? 'active' : ''
                    } `}
                    id="nav-selling"
                    role="tabpanel"
                  >
                    <div className="ads-item">
                      {paymentLoaded && !paymentLoading
                        ? renderPurchasedPlans()
                        : renderContainerLoader()}
                    </div>
                  </div>
                </div>
                {/* End Tab Content */}

                {/* {myOrderTab === MyOrdersTab.MyOrders
                  ? renderMyOrders()
                  : renderPurchasedPlans()} */}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen || false}
        contentLabel={'Bidding History'}
        onRequestClose={() => setModal({ isOpen: false })}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        {modal.productId ? (
          <BiddingHistory
            productId={modal.productId}
            onRequestClose={() => setModal({})}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  );
};
export default MyOrdersScreen;
