import React from 'react';
import "../../styles/cms.scss";

const ContactUs: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="contact-banner py-5">
                <div className="text-center py-5">
                    <h1 className="text-white my-3">Contact Us
                    </h1>
                </div>
            </div>

            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-12">
                            <h4 className="text-uppercase mb-2">Contact Dealden customer support</h4>
                            <p className="font-normal mb-4 text-gray-900">We’re always here to help. Our customer service
                                representatives are standing by.
                            </p>
                            <h6 className="font-lg mb-2 pb-1">The Bahamas </h6>
                            <p className="font-gray-700 mb-2 d-flex align-items-start">
                                <img src="./images/location.png" className="mr-3" alt="" />
                                #50 Shirley Street P. O. Box N-532 Nassau,<br /> N.P., The Bahamas
                            </p>
                            <p className="font-gray-700 mb-2 d-flex align-items-start">
                                <img src="./images/email.png" className="mr-3" alt="" />
                                info@dealden.ocm
                            </p>
                            <p className="font-gray-700 mb-2 d-flex align-items-start">
                                <img src="./images/phone.png" className="mr-3" alt="" />
                                (242) - 397-8995
                            </p>
                        </div>
                        <div className="col-md-5 col-12 pt-md-0 pt-4">
                            <div className="bg-white rounded-lg p-4 getintouch-block position-relative">
                                <h4 className="font-weight-bold mb-3 pb-1 pt-2">Get in touch with us</h4>
                                <div className="form-group mb-3 pb-1">
                                    <input type="text" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group mb-3 pb-1">
                                    <input type="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group mb-3 pb-1">
                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                </div>
                                <div className="form-group mb-3 pb-1">
                                    <textarea className="form-control" placeholder="How can we help?" rows={4}></textarea>
                                </div>
                                <div className="pt-2 pb-3">
                                    <button className="btn btn-primary btn-block">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
