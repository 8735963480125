import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorMessage from '../../../components/text/ErrorMessage';
import useAuth from '../../../hooks/useAuth';
import FileUploadController from '../../../components/upload/FileUploadController';
import InterestedCategory from './interested-category';
import useEntities from '../../../hooks/useEntities';
import { BillingAddress, Category } from '../../../api/models';
import SelectInput from '../../../components/select-input';
import { DateInput } from '../../../components/form-group';
import moment from 'moment';
import defaultAvatar from '../../components/avatar/default-avatar.png';
import Modal from 'react-modal';
import BiddingHistory from '../../my-auction/components/bidding-history';
import AddNewAddressForm from './add-new-address';

const genders: { label: string; value: string }[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];
interface FormProps {
  name: string;
  dob?: Date | string;
  gender: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  interestedCategories?: string[];
}
const customStyles = {
  content: {
    margin: '20px auto',
    inset: '0px',
    width: '100%',
    maxWidth: '780px',
    borderRadius: '10px',
  },
  overlay: {
    overflow: 'auto',
  },
};
const ProfileUpdateForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
  email?: string;
  name?: string;
  dob?: Date | string;
  gender?: string;
  phone?: string;
  error?: string;
  interestedCategories?: string[];
  billingAddress?: BillingAddress;
}> = (props) => {
  const auth = useAuth();
  const endDate = moment().subtract(18, 'year').toDate();
  const [categories, setCategories] = useState<string[]>(
    props.interestedCategories || []
  );
  const [billingAddress, setBillingAddress] = useState<
    BillingAddress | undefined
  >(props.billingAddress);
  const { control, handleSubmit, errors, setValue, watch } = useForm<FormProps>(
    {
      reValidateMode: 'onChange',
      defaultValues: {
        email: props.email || '',
        name: props.name || '',
        phone: props.phone || '',
        password: '',
        confirmPassword: '',
        dob: props.dob || '',
        gender: props.gender || '',
        interestedCategories: props.interestedCategories || [],
      },
      mode: 'onSubmit',
    }
  );
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  useEffect(() => {
    if (props.name) {
      setValue('name', props.name);
    }
    if (props.email) {
      setValue('email', props.email);
    }
  }, [props.name, props.email]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleNewAddressForm = () => {
    setIsModalOpen(true);
  };

  const renderEmail = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-2">
          <label className="font-sm mb-1">Email Address</label>
          <Controller
            control={control}
            rules={{ required: true, validate: { isEmail: validator.isEmail } }}
            name="email"
            defaultValue={props.email}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control bg-white': true,
                  error: !!errors.email,
                })}
                defaultValue={props.email}
                // ref={register}
                placeholder="Email"
                name={name}
                value={value}
                onChange={onChange}
                id="profile-email"
              />
            )}
          />
          {errors.email && (
            <ErrorMessage>
              {errors.email.type === 'required'
                ? 'Email is required'
                : 'Invalid Email'}
            </ErrorMessage>
          )}
        </div>
      </div>
    );
  };
  const renderName = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-2">
          <label htmlFor="profile-name" className="font-sm mb-1">
            Name
          </label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="name"
            defaultValue={props.name}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control bg-white': true,
                  error: !!errors.name,
                })}
                id="name"
                placeholder="Name"
                name={name}
                value={value}
                // ref={register}
                onChange={onChange}
              />
            )}
          />
          {errors.name && (
            <ErrorMessage>
              {errors.name.type === 'required'
                ? 'Name is required'
                : 'Invalid Name'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };

  const renderPhoneNumber = () => {
    return (
      <>
        <div className="col-md-6">
          <div className="form-group mb-4 pb-2">
            <label htmlFor="profile-phone" className="font-sm mb-1">
              Phone
            </label>
            <Controller
              control={control}
              name="phone"
              // defaultValue={props.default?.phone || ''}
              rules={{
                required: true,
              }}
              key={`phone`}
              render={({ onChange, name, value }) => (
                <PhoneInput
                  autoFormat={false}
                  country={'bs'}
                  preferredCountries={['bs', 'in']}
                  enableSearch={false}
                  placeholder={'Phone Number'}
                  inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
                  containerClass={'textField'}
                  dropdownStyle={{ borderColor: '#e5e6e7' }}
                  disableCountryCode={false}
                  countryCodeEditable={true}
                  inputStyle={{
                    width: '100%',
                    borderRadius: '0px',
                    borderColor: '#e5e6e7',
                  }}
                  disableSearchIcon={true}
                  onChange={(val, data: { dialCode: string }) => {
                    onChange(val);
                  }}
                  value={value}
                />
              )}
            />
          </div>
        </div>
        {renderUpdatePhoneLink()}
      </>
    );
  };

  const renderDOB = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-2 dob">
          <label htmlFor="Date of Birth" className="font-sm mb-1">
            Date of Birth
          </label>
          <Controller
            control={control}
            name="dob"
            defaultValue={props.dob}
            render={({ onChange, value, name }) => (
              <DateInput
                placeholder="dd/mm/yyyy"
                onChange={(val) => {
                  onChange(val);
                }}
                error={errors.dob?.message}
                default={value}
                maxDate={endDate}
              ></DateInput>
            )}
          ></Controller>
        </div>
      </div>
    );
  };
  const renderGender = () => {
    return (
      <div className="col-md-6">
        {/* {props.gender} */}
        <div className="form-group mb-4 pb-2">
          <label htmlFor="Gender" className="font-sm mb-1">
            Gender
          </label>
          <Controller
            control={control}
            name="gender"
            rules={{
              required: true,
            }}
            defaultValue={props.gender}
            render={({ onChange, name, value }) => (
              <SelectInput
                optionLabel="label"
                optionValue="value"
                placeholder="Select Gender*"
                data={genders}
                default={value}
                onChange={(val: number) => {
                  onChange(val);
                }}
                isSearchable={false}
              />
            )}
          />
          {errors.gender && <div className="error">Gender is required</div>}
        </div>
      </div>
    );
  };

  const renderPassword = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-2">
          <label htmlFor="profile-password" className="font-sm mb-1">
            Password
          </label>
          <Controller
            control={control}
            rules={{ required: isChangePassword }}
            name="password"
            render={({ onChange, name, value }) => {
              return (
                <input
                  type="password"
                  className={classnames({
                    'form-control': true,
                    error: !!errors.password,
                  })}
                  id="register-password"
                  placeholder="Password"
                  name={name}
                  value={!isChangePassword ? '*******' : value}
                  onChange={onChange}
                />
              );
            }}
          ></Controller>
          {errors.password && <div className="error">Password is required</div>}
        </div>
      </div>
    );
  };
  const renderConfirmPassword = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-2">
          <label htmlFor="profile-confirmpassword" className="font-sm mb-1">
            Confirm Password
          </label>
          <Controller
            control={control}
            rules={{
              required: isChangePassword,
              validate: { isMatching: (value) => value === watch('password') },
            }}
            name="confirmPassword"
            render={({ onChange, name, value }) => {
              return (
                <input
                  type="password"
                  className={classnames({
                    'form-control bg-white': true,
                    error: !!errors.password,
                  })}
                  id="register-confirm-password"
                  placeholder="Confirm Password"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          ></Controller>
          {errors.confirmPassword && (
            <>
              {errors.confirmPassword.type === 'required' && (
                <div className="error">Confirm Password is required</div>
              )}
              {errors.confirmPassword.type === 'isMatching' && (
                <div className="error">Passwords should match</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderUpdateButton = () => {
    return (
      <div className="col-md-3">
        <button
          type="submit"
          className="btn btn-primary font-weight-bold btn-block px-5 py-2 profile-edit-save"
          disabled={props.isSubmitting}
        >
          Update
        </button>
      </div>
    );
  };

  const renderAddNewAddress = () => {
    return (
      <div className="col-md-12 mb-5">
        <input type="text" />
      </div>
    );
  };

  const renderNewAddressButton = () => {
    return (
      <div className="col-md-12 mb-5">
        <button
          type="button"
          className="btn btn-outline-primary font-weight-normal border-width-1 py-2"
          onClick={handleNewAddressForm}
        >
          <span className="font-weight-normal font-xs icon-plus pr-2"></span>Add
          New Address
        </button>
      </div>
    );
  };
  const renderBillingAddress = () => {
    return (
      <div className="col-md-12 mb-5 ">
        <h5 className="text-dark mb-lg-3 mb-4">Billing Address</h5>
        <div className="bg-white rounded p-4 border border-fade">
          <div className="d-flex justify-content-between">
            <p className="font-md text-gray-700">
              {billingAddress?.firstName} {billingAddress?.lastName}
              <br />
              {billingAddress?.address}
              <br />
              {billingAddress?.city}, {billingAddress?.state},{' '}
              {billingAddress?.country}, {billingAddress?.zip}
            </p>
            <div
              className="text-capitalize text-primary cursor-pointer"
              onClick={handleNewAddressForm}
            >
              <span className="icon-edit pr-2"></span>Edit
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderChangePasswordLink = () => {
    return (
      <Link
        to="/change-password"
        className="pswd-btn align-items-center bg-white border-width-1 btn btn-outline-primary btn-sm d-block d-inline-flex font-weight-semibold py-0"
      >
        Change Password
      </Link>
    );
  };
  const renderUpdatePhoneLink = () => {
    return (
      <Link to="/update-phone" className="text-underline font-sm">
        Update Phone
      </Link>
    );
  };

  const renderAvatar = () => {
    return (
      <FileUploadController
        control={control}
        setValue={setValue}
        name={`avatar`}
        title=""
        value={auth.profile?.avatar}
        className="rounded-circle font-sm"
      />
    );
  };
  const onSubmit = (data: any) => {
    data.interestedCategories = categories;
    props.onSubmit(data);
  };
  const onCategorySelect = (val: Category) => {
    const newCategories = [...categories];
    if (categories.length > 0) {
      const index = categories.findIndex((e) => e === val._id);
      if (index > -1) {
        newCategories.splice(index, 1);
      } else {
        newCategories.push(val._id);
      }
    } else {
      newCategories.push(val._id);
    }
    setCategories([...newCategories]);
  };

  return (
    <div className=" py-0 py-lg-4">
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
          <Link className="text-white" to="/profile">
            <i className="icon-prev font-lg mr-3"></i>Edit Profile
          </Link>
        </h6>
      </div>
      <div className="container profile-edit">
        <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">My Account</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/profile">Profile</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit Profile
            </li>
          </ol>
        </nav>

        <div className="row pb-4 d-flex justify-content-center">
          <div className="col-lg-9">
            <div className="right-panel">
              <div className="edit-profile-inner">
                <h4 className="text-gray-900 d-none d-lg-block font-weight-bold">
                  Edit Profile
                </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="edit-profile-fill pt-md-3 pt-4">
                    <div className="row align-items-center">
                      <div className="col-md-2 col-sm-3">
                        <div className="profile-pic">
                          {renderAvatar()}
                          {!auth.profile?.avatar && <span>(max 2MB)</span>}
                          {/* <img
                            src={auth.profile?.avatar?.path}
                            className="img-fluid img-cover rounded-circle"
                            alt=""
                          /> */}
                          {/* <label className="fileup m-0 position-relative">
                          <img src="images/propic.png">
                          <span className="upld position-absolute cursor-pointer d-flex bg-primary justify-content-center align-items-center"><i className="icon-pic-upload"></i></span>
                          <input type="file" hidden className="upload-pic">
                     </label> */}
                        </div>
                      </div>
                      <div className="col-md-10 col-sm-9">
                        <div className=" d-md-flex  justify-content-between align-items-end my-3 my-md-0">
                          <div className="d-sm-flex flex-column mb-2 mb-md-0">
                            <h6 className="font-xxl text-capitalize text-gray-900">
                              {props.name}
                            </h6>
                            <div className="d-flex">
                              <label className="mr-3 mb-0 text-gray-900">
                                Phone Number:{' '}
                                <span className="text-gray-700">
                                  {auth.profile?.phone}
                                </span>
                              </label>
                              {renderUpdatePhoneLink()}
                            </div>
                          </div>
                          {!isChangePassword && renderChangePasswordLink()}
                        </div>
                      </div>
                    </div>
                    <div className="profile-edit-form pt-md-5 pt-3">
                      <div className="row">
                        {renderName()}
                        {renderEmail()}
                        {renderDOB()}
                        {renderGender()}
                        <ErrorMessage>{props.error}</ErrorMessage>
                        <InterestedCategory
                          onSelect={(e) => onCategorySelect(e)}
                          selectedCategories={categories || []}
                        />
                        {!billingAddress?.firstName && renderNewAddressButton()}
                        {billingAddress?.firstName && renderBillingAddress()}
                        {renderUpdateButton()}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen || false}
        contentLabel={''}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <AddNewAddressForm
          onSave={(data: BillingAddress) => {
            setBillingAddress(data);
            setIsModalOpen(false);
          }}
          onClose={() => setIsModalOpen(false)}
          firstName={billingAddress?.firstName}
          lastName={billingAddress?.lastName}
          address={billingAddress?.address}
          city={billingAddress?.city}
          state={billingAddress?.state}
          zip={billingAddress?.zip}
          country={billingAddress?.country}
        />
      </Modal>
    </div>
  );
};

export default ProfileUpdateForm;
