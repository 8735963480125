import React from 'react';
import AppLayout from '../layout/app';
import BuyProduct from './components/buy-product';
import '../../styles/buy-product.scss';
const OrderDetails: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories hideFooter>
      <BuyProduct />
    </AppLayout>
  );
};

export default OrderDetails;
