import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '../api';
import { AccountType, BusinessDetails } from '../api/models';
import { RouteKeys } from '../containers/routes/route-keys';
import store from '../store';
import { State } from '../store/interfaces';
import {
  loggedInAction,
  profileUpdatedAction,
  setEmailAction,
  otpSentAction as authOtpSentAction,
  firebaseLoggedInAction,
  redirectApplyAction,
} from '../store/reducers/authUser';
import {
  signupCheckSuccessAction,
  signupOtpVerifiedAction,
  signupCompletedAction,
  setAccountTypeAction,
  basicInfoUpdatedAction,
  facebookSignupCheckSuccessAction,
  otpSentAction,
} from '../store/reducers/signup';

const useSignup = () => {
  const signupState = useSelector((state: State) => state.signup);
  const authState = useSelector((state: State) => state.authUser);
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState<boolean>(false);
  const [signupError, setSignupError] = useState<string | undefined>();
  const history = useHistory();

  const signup = async (
    data: {
      email: string;
      name: string;
      phone: string;
    },
    onSuccess?: () => void
  ) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.signup.signup(data);
      dispatch(signupCompletedAction());
      if (response.status === StatusCodes.OK) {
        dispatch(
          authOtpSentAction({ phone: data.phone, userId: response.data._id })
        );
        dispatch(
          signupCheckSuccessAction({ ...data, userId: response.data._id })
        );
        history.push(RouteKeys.SignupOtp);
        if (onSuccess) onSuccess();
      } else {
        setSignupError(response.message || 'Unable to signup');
      }
    } catch (error) {
      setSignupError(
        error.response?.data?.data?.message || 'Email or Phone already exists'
      );
    }
    setUpdating(false);
  };

  const facebookLogin = async (accessToken: string, onSuccess?: () => void) => {
    setSignupError(undefined);
    setUpdating(true);
    dispatch(setEmailAction(accessToken));
    try {
      const response = await api.signup.facebookLogin(accessToken);
      if (response && response?.data?.data?.user) {
        const data = {
          user: response.data.data.user,
          token: response.data.data.token,
          apiPermissions: {},
        };
        api.setToken(response.data.data.token);
        dispatch(loggedInAction(data));
        const firbaseTokenResponse = await api.auth.getFirebaseToken();
        dispatch(firebaseLoggedInAction(firbaseTokenResponse.data));
        api.setFirebaseToken(response.data.data.token);
        history.push(authState.redirectTo || RouteKeys.Home);
        redirectApply();
        if (onSuccess) onSuccess();
      } else if (response?.data?.data?.facebookFields) {
        const data = {
          name: response.data.data.facebookFields.name,
          email: response.data.data.facebookFields.email,
          isSocialMedia: true,
        };
        console.log(data);
        dispatch(facebookSignupCheckSuccessAction(data));
        history.push(RouteKeys.Signup);
        dispatch(signupCompletedAction());
        if (onSuccess) onSuccess();
      } else {
        setSignupError('Invalid Username or Password!');
      }
    } catch (error) {
      setSignupError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const googleLogin = async (accessToken: string, onSuccess?: () => void) => {
    setSignupError(undefined);
    setUpdating(true);
    dispatch(setEmailAction(accessToken));
    try {
      const response = await api.signup.googleLogin(accessToken);
      if (response?.data?.data?.user) {
        const data = {
          user: response.data.data.user,
          token: response.data.data.token,
          apiPermissions: {},
        };
        api.setToken(response.data.data.token);
        dispatch(loggedInAction(data));
        const firbaseTokenResponse = await api.auth.getFirebaseToken();
        dispatch(firebaseLoggedInAction(firbaseTokenResponse.data));
        api.setFirebaseToken(response.data.data.token);
        history.push(authState.redirectTo || RouteKeys.Home);
        redirectApply();
        if (onSuccess) onSuccess();
      } else if (response?.data?.data?.googleFields) {
        const data = {
          name: response.data.data.googleFields.fullName,
          email: response.data.data.googleFields.email,
          isSocialMedia: true,
        };
        dispatch(facebookSignupCheckSuccessAction(data));
        history.push(RouteKeys.Signup);
        dispatch(signupCompletedAction());
        if (onSuccess) onSuccess();
      } else {
        setSignupError('Invalid Username or Password!');
      }
    } catch (error) {
      setSignupError('Invalid Username or Password!');
    }
    setUpdating(false);
  };

  const verifyOtp = async (otp: string, onSuccess?: () => void) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.signup.verifySignupOtp(
        authState.userId!,
        authState.phone!,
        otp
      );
      if (response.status === StatusCodes.OK) {
        dispatch(loggedInAction(response.data));
        api.setToken(response.data?.token);
        dispatch(signupOtpVerifiedAction());
        if (response.data?.user?.isProfileSet) {
          history.push(RouteKeys.Home);
        } else {
          history.push(RouteKeys.OnBoard);
        }
        if (onSuccess) onSuccess();
      } else {
        toastr.error('Error', 'OTP not verified');
        setSignupError('OTP not verified');
      }
    } catch (error) {
      toastr.error('Error', error.response?.data?.data?.message);
      setSignupError('OTP not verified');
    }
    setUpdating(false);
  };

  const resendOtp = async (onSuccess?: () => void) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      await api.signup.resendOtp(signupState.phone!);
      if (onSuccess) onSuccess();
    } catch (error) {
      setSignupError('Unable to resend OTP');
    }
    setUpdating(false);
  };

  const updateBasicDetails = async (
    data: {
      name: string;
      email: string;
      password: string;
    },
    onSuccess?: () => void
  ) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateBasicDetails({
        ...data,
        accountType: signupState.accountType!,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        if (signupState.accountType === AccountType.Individual) {
          dispatch(signupCompletedAction());
          history.push(RouteKeys.ChooseInterestedCategories);
          // history.push(RouteKeys.Home);
        } else {
          dispatch(basicInfoUpdatedAction());
        }
        if (onSuccess) onSuccess();
      } else {
        setSignupError('Unable to update details');
      }
    } catch (error) {
      setSignupError('Unable to update details');
      toastr.error(
        '',
        error.response?.data?.data?.message || 'Unable to update details'
      );
    }
    setUpdating(false);
  };
  const updateInterestedCategories = async (
    data: {
      interestedCategories: string[];
    },
    onSuccess?: () => void
  ) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateInterestedCategories({
        ...data,
      });
      if (response.status === StatusCodes.OK) {
        const state = store.store.getState();
        api.setToken(state.authUser.token);
        history.push(RouteKeys.Home);
        if (onSuccess) onSuccess();
      } else {
        setSignupError('Unable to update details');
      }
    } catch (error) {
      setSignupError('Unable to update details');
    }
    setUpdating(false);
  };
  const updateBusinessDetails = async (
    data: BusinessDetails,
    onSuccess?: () => void
  ) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.user.updateBusinessDetails({
        ...data,
      });
      if (response.status === StatusCodes.OK) {
        dispatch(profileUpdatedAction(response.data));
        dispatch(signupCompletedAction());
        // history.push(RouteKeys.Home);
        history.push(RouteKeys.ChooseInterestedCategories);
        if (onSuccess) onSuccess();
      } else {
        setSignupError('Unable to update details');
      }
    } catch (error) {
      setSignupError('Unable to update details');
    }
    setUpdating(false);
  };

  const setAccountType = (accountType: AccountType) => {
    dispatch(setAccountTypeAction(accountType));
  };
  const sendOtp = async (phone: string, onSuccess?: () => void) => {
    setSignupError(undefined);
    setUpdating(true);
    try {
      const response = await api.auth.phoneLogin(phone);
      if (response.status === StatusCodes.OK) {
        if (response.data.isSignup) {
          dispatch(authOtpSentAction({ phone, userId: response.data.userId }));
          history.push(RouteKeys.SignupOtp);
        } else {
          dispatch(authOtpSentAction({ phone, userId: response.data.userId }));
          history.push(RouteKeys.LoginWithPhoneOtp);
        }
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      setSignupError('Unable to send OTP');
    }
    setUpdating(false);
  };
  const redirectApply = () => dispatch(redirectApplyAction());

  return {
    updating,
    signupError,
    ...signupState,
    signup,
    verifyOtp,
    redirectApply,
    updateBasicDetails,
    resendOtp,
    setAccountType,
    updateBusinessDetails,
    facebookLogin,
    googleLogin,
    sendOtp,
    updateInterestedCategories,
  };
};

export default useSignup;
