import React from 'react';

interface CheckBoxProps {
  id: string;
  checked?: boolean;
  onChange?: (id: string, checked: boolean) => void;
  className?: string;
}

const CheckBox: React.FunctionComponent<CheckBoxProps> = (props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(props.id, e.target.checked);
  };

  return (
    <div className={`custom-checkbox ${props.className || ''}`}>
      <input
        type="checkbox"
        id={props.id}
        checked={props.checked}
        onChange={handleChange}
      />
      <label htmlFor={props.id}>{props.children}</label>
    </div>
  );
};

export default CheckBox;
