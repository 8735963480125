import { ApiResponse, PaginatedApiResponse, Product } from '../models';
import ApiService from './service';
import * as http from './http';
import { AllProductsResponse } from '../models/product';
import { store } from '../../store';
import { PaymentRequestModel } from '../models/payment';
import { stringify } from 'query-string';

export default class ProfileService extends ApiService {
  public async getMyProducts(data: {
    type: string;
    pagination: any;
  }): Promise<PaginatedApiResponse<Product>> {
    const state = store.getState();
    let query = {};
    if (data) {
      const { page = 1, perPage = 10 } = data.pagination || {};
      // const { field, order } = request.sort;
      query = {
        // _sort: field,
        // _order: order,
        page,
        perPage,
        type: data.type,
      };
    }
    const url = `${this.apiDomain}/product/my-products/${data.type}?${stringify(
      query
    )}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    const products = {
      products: response.data.data,
    };
    return response.data;
  }

  public async getMyProductOrders(
    request?: PaymentRequestModel<any>
  ): Promise<Product[]> {
    const state = store.getState();
    let query = {};
    if (request) {
      const { page = 1, perPage = 10 } = request.pagination || {};
      const { field, order } = request.sort;
      query = {
        _sort: field,
        _order: order,
        page,
        perPage,
        ...request.queryParams,
      };
    }
    const url = `${this.apiDomain}/product/my-purchase?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<Product>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    const products = {
      products: response.data.data,
    };
    return response.data.data;
  }
}
