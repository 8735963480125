import React, {
  forwardRef,
  FunctionComponent,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { OrderStatuses } from '../../api/models/leads';
import { State } from '../../store/interfaces';
import { formatDate } from '../../utils/date-utils';
import { DateInput } from '../form-group';
import SelectInput from '../select-input';
import FilterHeading from './FilterHeading';
import { ProductFilterModel } from './ProductFilterModel';

interface CustomProps {
  title: string;
  searchPlaceHolder: string;
  initialValues?: ProductFilterModel;
  values?: any;
  extraFilterShown: boolean;
  showCategoryFilter?: boolean;
  toggleExtraFilter?: () => void;
  onFilterChange?: (data: ProductFilterModel) => void;
  onFilterReset?: () => void;
  data?: any;
}

const initialState = {
  category: '',
  subCategory: '',
  startDate: '',
  query: '',
  status: '',
  endDate: '',
};

const InitialProps = {
  showCategoryFilter: true,
  extraFilterShown: false,
  searchPlaceHolder: '',
  title: '',
};

const ProductFilter: FunctionComponent<CustomProps> = (
  props: CustomProps = InitialProps,
  ref: any
) => {
  props = {
    ...InitialProps,
    ...props,
  };
  const { control, handleSubmit, errors, setValue, reset, getValues } = useForm<
    ProductFilterModel
  >({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: props.initialValues || initialState,
  });
  const storeCategories = useSelector(
    (state: State) => state.entities.categories
  );
  const storeSubCategories = useSelector(
    (state: State) => state.entities.subcategories
  );

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);

  useEffect(() => {
    Object.keys(initialState).map((e: string) => {
      if (props?.values?.hasOwnProperty(e)) {
        setValue(e, props.values[e]);
      }
    });
  }, [props.values]);

  useEffect(() => {
    const subs = storeSubCategories.filter(
      (e) => e.categoryId === selectedCategory
    );
    setSubCategories([{ _id: '', title: 'Sub Category' }, ...subs]);
  }, [selectedCategory]);

  useEffect(() => {
    if (storeCategories.length) {
      setCategories([{ _id: '', title: 'Category' }, ...storeCategories]);
    }
  }, [storeCategories]);

  const { extraFilterShown: toggleFilter } = props;
  const toggleExtraFilter = () => props?.toggleExtraFilter?.();
  const onFilterSubmit = (data: ProductFilterModel) => {
    const sDate = data?.startDate
      ? formatDate(data?.startDate, 'MM/DD/yyyy')
      : '';
    const eDate = data?.endDate ? formatDate(data?.endDate, 'MM/DD/yyyy') : '';
    props?.onFilterChange?.({ ...data, startDate: sDate, endDate: eDate });
    toggleExtraFilter();
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.charCode === 13) {
      setTimeout(handleSubmit(onFilterSubmit), 100);
    }
  };

  const handleResetFilter = () => {
    setSelectedCategory(null);
    setSubCategories([]);
    reset({ ...initialState });
    props?.onFilterReset?.();
  };

  return (
    <form noValidate autoComplete="off">
      <div className="product-filter">
        <div className="d-lg-flex justify-content-between mb-3 ">
          <FilterHeading heading={props.title} />
          <div className="d-lg-flex search-field">
            <div className="mt-3 mt-lg-0">
              <Controller
                control={control}
                name="query"
                render={({ value, onChange, name }) => (
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      name={name}
                      value={value}
                      onChange={onChange}
                      className="form-control"
                      onKeyPress={(e: React.KeyboardEvent) => handleKeyPress(e)}
                      placeholder={`Search ${props.searchPlaceHolder}`}
                    />
                    <div className="input-group-append">
                      {getValues().query ? (
                        <button
                          type="button"
                          className="btn bg-white d-flex align-items-center"
                          onClick={() => reset({ query: '' })}
                        >
                          <i className="icon-close font-xs"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn bg-white d-flex align-items-center"
                          onClick={handleSubmit(onFilterSubmit)}
                        >
                          <i className="icon-search"></i>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              ></Controller>
            </div>
            <div
              className="cursor-pointer d-lg-flex d-none filter-toggle-btn"
              onClick={toggleExtraFilter}
            >
              <div className="input-group input-group-sm d-flex align-items-center justify-content-center bg-white px-3 ml-2">
                {!toggleFilter ? (
                  <span className="icon-sort pr-1"></span>
                ) : (
                  <i className="icon-close-rounded text-muted pr-1"></i>
                )}
                <span className="line-height-sm text-gray-500">Filter</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bg-white filter-toggle mb-3 pb-lg-1 ${toggleFilter ? 'active' : ''
            }`}
        >
          <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              Filter
            </h6>
            <i
              onClick={toggleExtraFilter}
              className="icon-close text-white font-sm"
            ></i>
          </div>
          <div className="px-3 pt-3">
            <div className="row gutter-5">
              {props.showCategoryFilter &&
                categories &&
                categories.length > 0 ? (
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <label className="mb-1 text-gray-500">Category</label>
                  <Controller
                    control={control}
                    name="category"
                    render={({ value, onChange, name }) => (
                      <SelectInput
                        placeholder="Category"
                        data={categories}
                        onChange={(val, selected) => {
                          onChange(val);
                          setSelectedCategory(val);
                          setSubCategories([]);
                        }}
                        optionLabel={'title'}
                        optionValue={'_id'}
                        default={value}
                      />
                    )}
                  ></Controller>
                </div>
              ) : (
                ''
              )}
              {props.showCategoryFilter &&
                selectedCategory &&
                subCategories.length > 0 ? (
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <label className="mb-1 text-gray-500">Sub Category</label>
                  <Controller
                    control={control}
                    name="subCategory"
                    render={({ value, onChange, name }) => (
                      <SelectInput
                        placeholder="Sub Category"
                        data={subCategories}
                        onChange={(val, selected) => {
                          onChange(val);
                        }}
                        optionLabel={'title'}
                        optionValue={'_id'}
                        default={value}
                      />
                    )}
                  ></Controller>
                </div>
              ) : (
                ''
              )}
              <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <label className="mb-1 text-gray-500">Start Date</label>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ value, onChange, name }) => (
                    <DateInput
                      key={`product-search-${name}`}
                      placeholder="dd/mm/yyyy"
                      onChange={(val) => {
                        onChange(val);
                        setStartDate(val);
                      }}
                      error={errors.startDate?.message}
                      default={value}
                      dateFormat="dd/MM/yyyy"
                      maxDate={endDate}
                    ></DateInput>
                  )}
                ></Controller>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <label className="mb-1 text-gray-500">End Date</label>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ value, onChange, name }) => (
                    <DateInput
                      key={`product-search-${name}`}
                      placeholder="dd/mm/yyyy"
                      onChange={(val) => {
                        onChange(val);
                        setEndDate(val);
                      }}
                      error={errors.endDate?.message}
                      default={value}
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                    ></DateInput>
                  )}
                ></Controller>
              </div>
              <div className="col-12 col-sm-6 col-lg-2 mb-3">
                <label className="mb-1 text-gray-500">Status</label>
                <Controller
                  control={control}
                  name="status"
                  render={({ onChange, name, value }) => (
                    <SelectInput
                      placeholder="Status"
                      data={props.showCategoryFilter ? OrderStatuses : props?.data}
                      onChange={(val, selected) => {
                        onChange(val);
                      }}
                      optionLabel={'name'}
                      optionValue={'id'}
                      default={value}
                    />
                  )}
                ></Controller>
              </div>
              <div className="col-12 col-sm-6 col-lg-auto filter-btn mb-lg-3 align-self-end">
                <div className="row m-0 align-items-center">
                  <div className="col-6 col-lg-auto p-0">
                    <input
                      type="button"
                      value="Search"
                      className="btn btn-primary rounded-sm py-0 "
                      onClick={handleSubmit(onFilterSubmit)}
                    />
                  </div>
                  <div className="col-6 col-lg-auto p-0">
                    <a
                      className="btn btn-link py-0 text-gray-700"
                      onClick={handleResetFilter}
                    >
                      Reset
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const WrapperComponent = (props: any, ref: any) => {
  const [toggleFilter, setToggleFilter] = useState(false);
  // useEffect(() => {
  //   console.log('toggleFilter', toggleFilter)
  // }, [toggleFilter])
  useImperativeHandle(ref, () => ({
    toggleExtraFilter() {
      document.body.classList.toggle('open-filter');
      setToggleFilter(!toggleFilter);
    },
  }));
  return (
    <ProductFilter
      {...props}
      extraFilterShown={toggleFilter}
      toggleExtraFilter={() => {
        document.body.classList.toggle('open-filter');
        setToggleFilter(!toggleFilter);
      }}
      ref={ref}
    ></ProductFilter>
  );
};

export default forwardRef(WrapperComponent);
