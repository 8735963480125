import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { PageType } from '../profile';
import Menu from './menu';
import defaultAvatar from '../../components/avatar/default-avatar.png';

const MyAccount: React.FunctionComponent<{ pageType: PageType }> = (props) => {
  const auth = useAuth();
  return (
    <div className="col-lg-3 col-xs-auto col-12 my-account-dash d-none d-lg-block">
      <div className="leftpanel bg-white shadow">
        <div className="fig-content px-4 clearfix pt-4 border-bottom pb-3">
          <figure className="float-left rounded-circle m-0">
            <img
              src={auth.profile?.avatar?.path || defaultAvatar}
              className="img-cover rounded-circle"
              alt=""
            />
          </figure>
          <figcaption className="float-left pl-3">
            <span className="title font-base text-dark font-weight-bold">
              {auth.profile?.name}
            </span>
            <a
              href="/edit-profile"
              className="edit-profile d-block text-muted font-xs"
            >
              Edit Profile
            </a>
          </figcaption>
        </div>
        <Menu pageType={props.pageType} />
      </div>
    </div>
  );
};
export default MyAccount;
