import React, { FunctionComponent, useEffect, useState } from 'react';
import MyAccount from '../../common/my-account';
import Menu from '../../common/menu';
import { PageType } from '../../profile';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { EnquiryModel, OrderStatus, ProductDetails } from '../../../api/models';
import api from '../../../api';
import moment from 'moment';
import ProductStatus from '../../../components/product-status';
import { ContainerLoader } from '../../../components/loader';
import LetterAvatar from './letter-avatar';
import AdsPagination from '../../../components/ads-pagination/pagination';

const MyEnquiry: FunctionComponent = () => {
  const history = useHistory();
  const params: any = useParams();
  const [product, setProduct] = useState<ProductDetails>();
  const [enquiries, setEnquiries] = useState<EnquiryModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageOption, setPageOption] = useState<{
    count: number;
    perPage: number;
    page: number;
  }>({
    count: 0,
    page: 1,
    perPage: 10,
  });
  const onDownloadClick = async (id?: string) => {
    await api.catalog.downloadenquiries(id, product?.title || 'Product');
  };

  const getEnquires = async (id: string) => {
    setLoading(true);
    try {
      const {
        data,
        count,
        page,
        perPage,
        totalPages,
      } = await api.catalog.getProductEnquires({
        pagination: {
          perPage: pageOption.perPage,
          page: pageOption.page,
        },
        queryParams: {
          productId: id,
        },
      });
      if (data) {
        setEnquiries(data);
        setPageOption({
          count,
          page,
          perPage,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.slug) {
      getProductDetails(params.slug);
    }
  }, []);

  useEffect(() => {
    if (product) getEnquires(product?._id);
  }, [pageOption.page]);

  const getProductDetails = async (slug: string) => {
    const data = await api.catalog.getProductDetails(slug);
    setProduct(data);
    getEnquires(data._id);
  };

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={RouteKeys.Profile}>My Account</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={RouteKeys.MyLeads}>My Leads</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Enquiry
          </li>
        </ol>
      </nav>
    );
  };

  const newDate = new Date();

  return (
    <div className="my-ads py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}

        <div className="row">
          <MyAccount pageType={PageType.MyLeads} />
          <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link
                className="icon-prev font-lg mr-3 text-white"
                to="/account"
              ></Link>
              My Inquires
            </h6>
          </div>
          <div className="col-lg-9 rmv-padd-mob">
            <div className="right-panel">
              <div className="d-flex justify-content-end">
                <div className="back-btn">
                  <Link className="text-gray-500" to={RouteKeys.MyLeads}>
                    <span className="icon-prev pr-1 font-xxxs"></span>Back
                  </Link>
                </div>
              </div>
              <div className="ads-item mt-lg-0 mt-3 pb-2">
                {product ? (
                  <div className="bg-white shadow px-lg-3 py-lg-3 pb-2 position-relative mb-4 rounded ">
                    <div className="px-lg-0 px-3">
                      <div className="row align-items-center">
                        <div className="col-12 d-lg-none d-block bg-gray-100 border-bottom border-gray-300">
                          <div className="top-date">
                            <span className="date d-block font-sm py-2">
                              <span className="text-muted pr-1">
                                Posted on:
                              </span>
                              {moment(product?.createdAt).format('DD MMM yyy')}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 align-items-center border-bottom-lg-0 py-2 py-lg-0">
                          <figure className="mb-0 position-relative d-flex align-items-center">
                            <a
                              href={RouteKeys.ProductDetails.replace(
                                ':slug',
                                product.slug
                              )}
                              target="_blank"
                              className="image overflow-hidden bg-light border-0"
                            >
                              <img
                                className="img-fluid"
                                src={product.defaultImage?.path}
                              />
                            </a>

                            <figcaption className="w-100">
                              <a href="#">
                                <span className="date d-none font-sm d-lg-inline-block text-gray-700">
                                  <span className="text-muted pr-1">
                                    Posted on:
                                  </span>
                                  {moment(product?.createdAt).format(
                                    'DD MMM yyy'
                                  )}
                                </span>
                                <span className="title font-base d-block text-gray-700">
                                  {product?.title}
                                </span>
                              </a>
                            </figcaption>
                          </figure>
                        </div>

                        <div className="col-12 col-lg-3 d-flex d-lg-block justify-content-between px-lg-0 pt-2 py-lg-0">
                          <ProductStatus product={product} />
                          {product.orderStatus === OrderStatus.Pending &&
                            product.validTill &&
                            new Date(product.validTill) > newDate && (
                              <>
                                <span className="d-block text-muted font-sm">
                                  This Ad is currently live
                                </span>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="d-flex justify-content-between mb-3">
                <h4 className="font-lg mb-0">
                  {pageOption.count > 0 ? pageOption.count : ''} Inquires
                </h4>
                <div className="align-items-center d-flex">
                  <a
                    onClick={() => onDownloadClick(product?._id)}
                    className="text-gray-600 cursor-pointer line-height-sm"
                  >
                    <span className="icon-download pr-1"></span> Download
                  </a>
                </div>
              </div>
              {enquiries && enquiries.length ? (
                <>
                  <div className="invoice-pannel-listing my-enquiry rounded">
                    <div className="enquiry-heads bg-gray-400 d-none d-md-block mb-0">
                      <div className="gutter-5 row">
                        <div className="col-md-3">
                          <p className="m-0">Name</p>
                        </div>
                        <div className="col-md-3">
                          <p className="m-0">Email</p>
                        </div>
                        <div className="col-md-3 col-lg-2">
                          <p className="m-0">Date</p>
                        </div>
                        <div className="col-md-3">
                          <p className="m-0">Phone</p>
                        </div>
                      </div>
                    </div>
                    {enquiries.map((enquiry) => (
                      <div className="enquiry-row bg-white mb-0">
                        <div className="gutter-5 row d-flex align-items-center text-gray-900">
                          <div className="col-md-3 col-sm-5 col-12 pb-3 pb-md-0">
                            <div className="enq-id d-flex align-items-center">
                              <span className="font-xs">
                                <LetterAvatar name={enquiry?.name} />
                              </span>
                              <div className="enq-data pl-2">
                                <p className="m-0">{enquiry?.name}</p>
                                {/* <p className="m-0 d-md-none">Signup@mailinator.com</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-4 col-6 pb-3 pb-md-0 d-none d-md-block">
                            <p className="m-0">{enquiry?.email}</p>
                          </div>
                          <div className="col-md-3 col-sm-3 col-lg-2 col-6 pb-0">
                            <p className="m-0">
                              {moment(enquiry.createdAt).format('D MMM yyyy')}
                            </p>
                          </div>
                          <div className="col-md-3 col-sm-4 col-6 pb-0">
                            <p className="m-0">{enquiry?.phone}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <AdsPagination
                    {...pageOption}
                    onPageChange={(page) => {
                      setPageOption({
                        ...pageOption,
                        page,
                      });
                    }}
                  />
                </>
              ) : loading ? (
                <ContainerLoader height={500} />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyEnquiry;
