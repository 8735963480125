// tslint:disable-next-line: no-reference
/// <reference path="../../../typings.d.ts" />
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Config from '../../config';

const FbLogin: React.FunctionComponent<{
  onClick: (accessToken: string) => void;
}> = (props) => {
  const onResponseFromFacebook = (response: any) => {
    const accessToken = response?.accessToken || undefined;
    props.onClick(accessToken);
  };
  console.log('appId', '1503517766648630');
  return (
    <FacebookLogin
      appId={'1503517766648630'}
      fields="name,email,picture"
      callback={onResponseFromFacebook}
      autoLoad={false}
      render={(renderProps: any) => (
        <i
          className="icon-facebook mx-2"
          title="Fb login"
          onClick={renderProps.onClick}
        />
      )}
    />
  );
};

export default FbLogin;
