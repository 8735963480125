import React from 'react';
import { usePaginatedDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { ProductReview, RatingSummary } from '../../../api/models';
import RatingSummaryView from '../../../components/rating/RatingSummaryView';

const ProductRatingSection: React.FunctionComponent<{
  productId: string;
  rating: RatingSummary;
}> = (props) => {
  const { data: reviews, handleScroll } = usePaginatedDataLoader<
    ProductReview[]
  >({
    loadPage: (page: number) => api.catalog.getProductReview(props.productId),
    resolveList: (data) => data,
    initialParams: [],
  });
  return (
    <div className="rating-block">
      <h5 className="mb-4 pb-2 w-100">Rating & Reviews</h5>
      <RatingSummaryView summary={props.rating} />
      <div className="comment-block row mx-lg-0">
        {reviews?.map((review) => {
          return (
            <div className="comment border-bottom py-lg-4 py-3 col-12 px-lg-0">
              <div className="d-flex align-items-baseline mb-2 pb-1">
                <div className="user-rating mr-2 font-xs text-white bg-dark px-2 d-inline-flex align-items-center rounded-pill font-weight-bold">
                  <i className="icon-star text-warning mr-1"></i>
                  {review.rating}
                </div>
                <div className="user-comment font-normal font-weight-semibold text-gray-900">
                  {review.title}
                </div>
              </div>
              <div className="user-comment-desc font-normal text-gray-500">
                {review.comment}
              </div>
            </div>
          );
        })}

        <div className="load-more text-center mt-lg-5 mt-4 col-12 px-lg-0">
          <button
            className="btn btn-outline-dark py-1 px-4 border-width-1"
            onClick={handleScroll}
          >
            Load more
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductRatingSection;
