/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { Product } from '../../api/models';

const ShareProduct: React.FunctionComponent<{ product: Product }> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="col-4 position-relative">
      <a
        href="javascript:void(0)"
        className="d-flex justify-content-center h-100 align-items-center text-gray-600"
      >
        <i
          className="icon-share"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        ></i>
      </a>
      <div
        className={
          isOpen
            ? 'productBox__share-popup productBox__share-popup--open position-absolute rounded-lg bg-white shadow-lg text-left p-4'
            : 'productBox__share-popup position-absolute rounded-lg bg-white shadow-lg text-left p-4'
        }
      >
        <i
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="icon-close-sm text-gray-700 font-xxxs position-absolute cursor-pointer productBox__share-close"
        ></i>
        <h6 className="font-base font-weight-semibold mb-3">Share</h6>
        <ul className="productBox__share-list list-unstyled p-0 mb-4 d-flex">
          <li>
            <a
              href={`https://twitter.com/share?url=${encodeURI(
                window.location.href
              )}&text=${props.product.title}`}
              className="productBox__share productBox__share--twitter d-flex justify-content-center align-items-center rounded-circle"
            >
              <i className="icon-share-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                window.location.href
              )}`}
              className="productBox__share productBox__share--fb d-flex justify-content-center align-items-center rounded-circle"
            >
              <i className="icon-share-fb"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://pinterest.com/pin/create/button/?url=${encodeURI(
                window.location.href
              )}&description=${props.product.title}`}
              className="productBox__share productBox__share--telegram d-flex justify-content-center align-items-center rounded-circle"
            >
              <i className="icon-pinterest">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </a>
          </li>
        </ul>
        <p className="text-gray-700 font-normal mb-2">Page link</p>
        <div className="productBox__share-link bg-gray-100 rounded d-flex">
          <input
            type="text"
            className="font-xs text-gray-500 border-0 flex-fill bg-transparent px-2"
            value={`${encodeURI(window.location.href)}product/${
              props.product.slug
            }`}
          />
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `${encodeURI(window.location.href)}product/${
                  props.product.slug
                }`
              );
            }}
            className="bg-transparent border-0 d-flex align-items-center font-sm text-gray-500 px-2"
          >
            <i className="icon-copy"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareProduct;
