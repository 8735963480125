import React from 'react';
import Footer from './footer';
import Header from './header';
import PlainLayout from './plain';
import '../../styles/index.scss';

const AppLayout: React.FunctionComponent<{
  hideCategories?: boolean;
  hideFooter?: boolean;
  showHeader?: boolean;
}> = (props) => {
  return (
    <PlainLayout>
     <Header hideCategories={props.hideCategories} showHeader={props.showHeader} />
      {props.children}
      {!props.hideFooter && <Footer />}
    </PlainLayout>
  );
};

export default AppLayout;
