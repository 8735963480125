import React, { useState } from 'react';
import {
  Category,
  CreatePostFormProps,
  Subcategory,
  Subscribe,
  SubscriptionStatus,
  SubscriptionPlan,
  RequestCustomPlan,
  RemainingPostsCountInCategoryResponseData,
  ProductType,
  Auction,
  Product,
} from '../../api/models';
import AppLayout from '../layout/app';
import '../../styles/post-product.scss';
import CreatePostForm from './components/CreatePostForm';
import CreatePostSummary from './components/CreatePostSummary';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import ChoosePlan from '../choose-plan/components/ChoosePlan';
import Payment, {
  AccountType,
  PaymentType,
} from '../../containers/payment/components/payment';
import { State } from '../../store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import {
  reccomendationsAddedAction,
  selectProductToSellFasterAction,
} from '../../store/reducers/catalog';
import { StatusCodes } from 'http-status-codes';
import useAuth from '../../hooks/useAuth';
import { ContainerLoader } from '../../components/loader';
import useEntities from '../../hooks/useEntities';
import { useHistory, useParams } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';
import AuctionForm from '../create/components/AuctionForm';
import ProductTypeForm from './components/ProductTypeTabs';

const EditProduct: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();
  const entities = useEntities();
  const history = useHistory();

  const [category, setCategory] = useState<Category>();
  const [subcategory, setSubcategory] = useState<Subcategory>();
  const [product, setProduct] = useState<Product>();
  const [isSummary, setIsSummary] = useState<boolean>(false);
  const [isChoosePlan, setIsChoosePlan] = useState<boolean>(false);
  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [createdProductId, setCreatedProductId] = useState('');
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [productType, setProductType] = useState<ProductType>();
  const [productDetails, setProductDetails] = useState<any>();
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [showAuctionDetails, setShowAuctionDetails] = useState<boolean>(false);
  const [isProductDetails, setIsProductDetails] = useState<boolean>(false);
  const [auctionDetails, setAuctionDetails] = useState<any>();
  const params = useParams<{ slug: string }>();

  const { data, loading, loaded } = useDataLoader<Product>(() =>
    api.catalog.getProductDetails(params.slug)
  );
  const { performAction: updateProduct } = usePerformAction(
    (d: Product): any => {
      setIsLoaded(false);
      let updateProductObj = { ...d };
      if (data.productType === ProductType.Auction) {
        updateProductObj = {
          ...updateProductObj,
          auctionDetails: auctionDetails,
        };
      }
      api.catalog.updatePost(updateProductObj, data._id).then((t) => {
        if (t.status === StatusCodes.CREATED) {
          setIsLoaded(true);
          data.productType === ProductType.Sale
            ? history.push(RouteKeys.MyAds)
            : data.productType === ProductType.Auction
            ? history.push(RouteKeys.MyAuction)
            : history.push(RouteKeys.MyLeads);
        }
      });
    }
  );
  const updateAuctionDetails = (data: Auction) => {
    setAuctionDetails({ ...data, bidNowPrice: data.startPrice });
    setProductType(ProductType.Auction);
    setShowSummary(true);
    setShowAuctionDetails(false);
  };
  if (!loaded && loading) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }

  return (
    <AppLayout hideCategories hideFooter>
      {!isProductDetails && !showSummary && !showAuctionDetails && (
        <CreatePostForm
          onClearCategory={() => {
            setCategory(undefined);
            setSubcategory(undefined);
          }}
          onSubmit={(d) => {
            setProduct(d);
            setIsProductDetails(true);
            setShowSummary(
              data.productType === ProductType.Auction ? false : true
            );
            setShowAuctionDetails(
              data.productType === ProductType.Auction ? true : false
            );
          }}
          productDetails={data}
          category={product?.category}
          subcategory={product?.subcategory}
        ></CreatePostForm>
      )}
      {showAuctionDetails && data.productType === ProductType.Auction && (
        <ProductTypeForm
          onSelect={(val: ProductType) => {
            // setProductType(val);
            // updateProductDetails(val);
          }}
          onAuctionSubmit={updateAuctionDetails}
          onBackClick={() => {
            setIsProductDetails(false);
            setShowSummary(false);
            setShowAuctionDetails(false);
          }}
          product={data}
        ></ProductTypeForm>
      )}
      {product && isProductDetails && showSummary && (
        <CreatePostSummary
          onSubmit={(d) => {
            updateProduct(d);
          }}
          category={data?.category}
          subcategory={data?.subcategory}
          product={product}
          onBackClick={() => {
            setIsProductDetails(false);
            setShowSummary(false);
          }}
          productType={data.productType}
          isExchange={data.isExchange}
          exchangeables={data.exchangeables}
        ></CreatePostSummary>
      )}
    </AppLayout>
  );
};

export default EditProduct;
