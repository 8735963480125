import React, { FunctionComponent } from 'react';

export interface AdsPaginationProps {
  count: number;
  page: number;
  perPage: number;
  onPageChange?: (page: number) => void;
}

const AdsPagination: FunctionComponent<AdsPaginationProps> = (
  props: AdsPaginationProps
) => {
  const { count, perPage } = props;
  const totalPages = Math.floor((count + (perPage - 1)) / perPage);
  return (
    <nav className="d-flex justify-content-center pt-4">
      <ul className="pagination">
        <li
          className={`page-item ${
            props.page === 1 ? 'disabled' : 'cursor-pointer'
          }`}
          onClick={() => props?.onPageChange?.(props.page - 1)}
        >
          <a className="page-link">Previous</a>
        </li>
        {[...Array(totalPages)].map((page: number, index: number) => (
          <li
            className={`cursor-pointer page-item ${
              index + 1 === props.page ? 'active' : ''
            }`}
            onClick={() => props?.onPageChange?.(index + 1)}
            key={`pagination-${index+1}`}
          >
            <a className="page-link">{index + 1}</a>
          </li>
        ))}
        <li
          className={`page-item ${
            props.page === totalPages ? 'disabled' : 'cursor-pointer'
          }`}
          onClick={() => props?.onPageChange?.(props.page + 1)}
        >
          <a className="page-link">Next</a>
        </li>
      </ul>
    </nav>
  );
};

export default AdsPagination;
