import {
  ApiResponse,
  Subscribe,
  SubscriptionPlan,
  Subscription,
  AccountType,
  RequestCustomPlan,
  SubscribeCustomPlan,
  PaginatedApiResponse,
  Product,
} from '../models';
import ApiService from './service';
import * as http from './http';
import { store } from '../../store';
import api from '..';
import { PaymentRequestModel } from '../models/payment';
import { stringify } from 'query-string';

export default class OrderService extends ApiService {
  public async purchase(data: any): Promise<any> {
    const url = `${this.apiDomain}/order/purchase`;

    const response = await http.post<ApiResponse<any>>(url, data, this.store);

    return response.data.data;
  }
  public async deliverProduct(token: string): Promise<any> {
    const state = store.getState();
    const url = `${this.apiDomain}/order/${token}`;
    const response = await http.get<ApiResponse<any[]>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
  }
  public async getMyOrders(
    request?: PaymentRequestModel<any>
  ): Promise<PaginatedApiResponse<any>> {
    const state = store.getState();
    let query = {};
    if (request) {
      const { page = 1, perPage = 10 } = request.pagination || {};
      const { field, order } = request.sort;
      query = {
        _sort: field,
        _order: order,
        page,
        perPage,
        ...request.queryParams,
      };
    }
    const url = `${this.apiDomain}/order/my-orders?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<{ data: any }>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }
  public async getOrderDetails(id: string): Promise<any> {
    const url = `${this.apiDomain}/order/order-details/${id}`;
    const state = store.getState();
    api.setToken(state.authUser.token);
    const response = await http.get<ApiResponse<any>>(url, this.store);
    return response.data.data;
  }

  public async downloadOrderDetails(orderId: string) {
    const url = `${this.apiDomain}/order/${orderId}/download/pdf`;
    const reponse = await http.download(url, this.store);
    http.saveDownloadedFile(
      reponse.data,
      'application/pdf',
      `OrderDetails.pdf`
    );
    return;
  }

  public async getPaymentDetails(id: string): Promise<any> {
    const url = `${this.apiDomain}/payment/${id}`;
    const state = store.getState();
    api.setToken(state.authUser.token);
    const response = await http.get<ApiResponse<any>>(url, this.store);
    return response.data.data;
  }

  public async getPayoutDetails(id: string): Promise<any> {
    const url = `${this.apiDomain}/payout/${id}`;
    const state = store.getState();
    api.setToken(state.authUser.token);
    const response = await http.get<ApiResponse<any>>(url, this.store);
    return response.data.data;
  }
}
