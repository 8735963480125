import { format } from 'date-fns';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { Bidder, BiddingResponse } from '../../../api/models';
import { ContainerLoader } from '../../../components/loader';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import noUserImage from '../../../assets/images/Profile-PNG-Icon.png';
import maskCharacter from '../../../utils/mask-characters';

interface CustomProps {
  productId: string;
  onRequestClose: () => void;
}

const BiddingHistory: FunctionComponent<CustomProps> = (props: CustomProps) => {
  const {
    data: biddingResponse,
    loading,
    loaded,
    reload: reloadMyAuctions,
  } = useDataLoader<BiddingResponse>(() => {
    return api.catalog.getBiddingHistory({
      queryParams: {
        productId: props.productId,
      },
    });
  });

  const renderProduct = () => {
    const { product } = biddingResponse;
    return (
      <div className="row pb-2">
        <div className="col-7 col-md-9 font-normal text-gray-700">
          {product.title}
        </div>
        {product.auctionDetails ? (
          <>
            <div className="col-5 col-md-3 text-right font-weight-bold font-lg text-dark">
              $
              {product.auctionDetails?.startPrice +
                product.auctionDetails.bidIncrementAmount *
                  (product?.bidCount || 1)}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderBiddingTable = () => {
    const { data: bidders } = biddingResponse;
    return (
      <table
        cellPadding="0"
        cellSpacing="0"
        style={{ width: '100%' }}
        className="text-gray-500"
      >
        <tbody>
          <tr>
            <th className="border-top-0 bg-gray-400 font-sm font-weight-semibold text-gray-700">
              User
            </th>
            <th className="border-top-0 bg-gray-400 font-sm font-weight-semibold text-gray-700">
              Date & Time
            </th>
            <th className="border-top-0 bg-gray-400 font-sm font-weight-semibold text-gray-700">
              Bid Amount
            </th>
          </tr>
          {bidders.map((bid: Bidder) => (
            <tr key={`bid-row-${bid._id}`}>
              <td>
                <span className="title font-base text-dark">
                  {bid.userId?.name ? maskCharacter(bid.userId?.name, '*') : ''}
                </span>
              </td>
              <td>{format(new Date(bid.createdAt), 'dd MMM yyyy HH:mm:s')}</td>
              <td>${bid.bidAmount.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderContainerLoader = () => (
    <ContainerLoader height={200}></ContainerLoader>
  );

  return (
    <div className="modal-dialog" role="document" id="bid-history">
      <div className="modal-content">
        <div className="modal-body py-4">
          {loaded && !loading ? (
            <>
              <div
                onClick={() => props.onRequestClose()}
                className="close position-absolute right-0 top-0 mr-3 mt-3 cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="icon-close font-md"></span>
              </div>
              <h4 className="mb-3 pb-md-2 text-center font-xxxl">
                Bidding History
              </h4>
              {renderProduct()}

              <div className="border rounded table">{renderBiddingTable()}</div>
            </>
          ) : (
            renderContainerLoader()
          )}
        </div>
      </div>
    </div>
  );
};

export default BiddingHistory;
