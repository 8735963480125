import React, { useEffect, useState } from 'react';

import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { MinMaxSliderFilterMetaData } from '../../api/models';

const MinMaxSliderFilter: React.FunctionComponent<{
  meta: MinMaxSliderFilterMetaData;
  onChange?: (value: number[]) => void;
  className?: string;
  value?: number[];
}> = (props) => {
  const [value, setValue] = useState<number[]>(
    props.value || [props.meta.min, props.meta.max]
  );
  const handleOnChange = (val: number[]) => {
    setValue(val);
  };
  const handleOnChangeComplete = (val: number[]) => {
    setValue(val);
    props.onChange?.(val);
  };

  useEffect(() => {
    setValue(props.value || [props.meta.min, props.meta.max]);
  }, [props.value, props.meta.min, props.meta.max]);
  return (
    <div className="rounded shadow bg-white p-lg-3 mb-3 filter-toggle-box">
      <div className="filter-toggle-head d-flex align-items-baseline px-lg-0 px-3 pt-lg-0 pt-3">
        <h6 className="mb-0 mr-3">{props.meta.name}</h6>
      </div>
      <div className="filter-toggle-body pt-lg-4 pt-2">
        <div className="w-100 pl-lg-0 pr-lg-3 px-3">
          <div className="d-none justify-content-between font-normal text-gray-700 ">
            <span>
              {props.meta.valuePrefix}
              {props.meta.min}
              {props.meta.valueSuffix}
            </span>
            <span>
              {props.meta.valuePrefix}
              {props.meta.max}
              {props.meta.valueSuffix}
            </span>
          </div>
          <div className="px-2">
          <Range
            className={props.className}
            value={value}
            onChange={handleOnChange}
            step={1}
            min={props.meta.min}
            max={props.meta.max}
            onAfterChange={handleOnChangeComplete}
          />
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="input-group margin-bottom">
            <span className="input-group-addon addon-inline input-source-observer my-auto p-1">
                {props.meta.valueSuffix}
              </span>
              <input
                type="number"
                className="form-control form-control-sm bg-white font-normal font-weight-bold"
                data-parsley-trigger="change"
                value={value[0]}
                onChange={(e) => {
                  setValue([
                    e.target.value ? parseInt(e.target.value, 10) : 0,
                    value[1],
                  ]);
                }}
              />
            
            </div>
            <div className="col-2 font-xxs text-gray-500">to</div>
            <div className="col-5 px-0">
              <div className="input-group margin-bottom">
              <span className="input-group-addon addon-inline input-source-observer my-auto p-1">
                  {props.meta.valueSuffix}
                </span>
                <input
                  type="number"
                  className="form-control form-control-sm bg-white font-normal font-weight-bold"
                  data-parsley-trigger="change"
                  value={value[1]}
                />
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinMaxSliderFilter;
