import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import { LocationType } from '../../containers/create/components/CreatePostForm';
import { markerimg } from '../../assets/images/marker.png';
import Config from '../../config';
Geocode.setApiKey(Config.GOOGLE_MAP_KEY);
Geocode.enableDebug();
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address || '',
      draggable: this.props.draggable || true,
      city: this.props.city || '',
      area: this.props.street || '',
      state: this.props.state || '',
      mapPosition: {
        lat: this.props.lat || 24.638185,
        lng: this.props.lng || -78.055734,
      },
      markerPosition: {
        lat: this.props.lat || 24.638185,
        lng: this.props.lng || -78.055734,
      },
      defaultLocation: false,
    };
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  /**
   * Get the current address from the default map position and set those values in the state
   */
  componentDidMount() {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          country = this.getCountry(addressArray);
        this.setState({
          address: address ? address : this.props.address,
          area: area ? area : this.props.street,
          city: city ? city : this.props.city,
          state: state ? state : this.props.state,
          defaultLocation: true,
        });
        // if (this.props.handleAddressChange) {
        //   this.handleAddressChange({
        //     street: area,
        //     city,
        //     state,
        //     country,
        //     place: address,
        //     lat: this.state.mapPosition.lat,
        //     lng: this.state.mapPosition.lng,
        //   });
        // }
      },
      (error) => {
        // console.error(error);
      }
    );
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  getGoogleNavigator = () => {
    return navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        this.setState({ mapPosition: { lat, lng } });
        this.setState({ markerPosition: { lat, lng } });
        this.getGeoCode(lat, lng);
        return true;
      }
    );
  };
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.locationType !== nextProps.locationType &&
      nextProps.locationType === LocationType.CurrentLocation
    ) {
      const ret = this.getGoogleNavigator();
      this.setState({
        defaultLocation: false,
      });
      return ret;
    }
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (
      this.props.center &&
      this.props.center.lat === nextProps.center.lat
    ) {
      return false;
    }
    return false;
  }

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  getCountry = (addressArray) => {
    let country = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};
  /**
   * When the user types an address in the search box
   * @param place
   */
  handleAddressChange = (address) => {
    this.props.handleAddressChange(address);
  };
  onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      country = this.getCountry(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address: address ? address : '',
      area: area ? area : '',
      city: city ? city : '',
      state: state ? state : '',
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
    this.setState({
      defaultLocation: false,
    });
    this.handleAddressChange({
      street: area,
      city,
      state,
      country,
      place: address,
      lat: latValue,
      lng: lngValue,
    });
  };
  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  getGeoCode(newLat, newLng) {
    let addressArray = [];
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        console.log(response);
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          country = this.getCountry(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : '',
          area: area ? area : '',
          city: city ? city : '',
          state: state ? state : '',
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
        this.setState({
          defaultLocation: false,
        });
        this.handleAddressChange({
          street: area,
          city,
          state,
          country,
          place: address,
          lat: newLat,
          lng: newLng,
        });
      },
      (error) => {
        // console.error(error);
      }
    );
  }
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat();
    let newLng = event.latLng.lng();
    this.getGeoCode(newLat, newLng);
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
        >
          {/* For Auto complete Search Box */}
          {this.props.locationType === LocationType.SearchLocation && (
            <Autocomplete
              className="form-control bg-white position-absolute"
              componentRestrictions={{ country: 'BS' }}
              onPlaceSelected={this.onPlaceSelected}
              defaultValue={this.state.address}
              types={['(regions)']}
            />
          )}
          {/*Marker*/}
          <Marker
            className="marker"
            google={this.props.google}
            name={'Dolores park'}
            draggable={this.state.draggable || true}
            onDragEnd={this.onMarkerDragEnd}
            icon={{
              url: '../marker.png',
            }}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* InfoWindow on top of marker */}
          {/* <InfoWindow
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          >
            <span></span>
          </InfoWindow> */}
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_MAP_KEY}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default Map;
