import React from 'react';

import '../Input.scss';
import './SwitchInput.scss';

interface SwitchInputProps {
  default?: boolean;
  onChange?: (val: boolean) => void;
  label?: string;
  name?: string;
  placeholder?: string;
  gap?: 'sm' | 'md' | 'lg';
  newLine?: boolean;
  disabled?: boolean;
  error?: string;
}
interface SwitchInputState {
  value?: boolean;
}

// controlled component
class SwitchInput extends React.Component<SwitchInputProps, SwitchInputState> {
  constructor(props: SwitchInputProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: SwitchInputProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    // console.log('this.props.default', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
    }
  }

  handleChange() {
    this.setState((prevState) => {
      if (this.props.onChange) {
        this.props.onChange(!prevState.value);
      }
      return {
        value: !prevState.value,
      };
    });
  }

  render() {
    // tslint:disable-next-line: no-this-assignment
    const { props } = this;
    const inputIdCandidate = props.name || props.label || props.placeholder;
    const inputId = inputIdCandidate;
    return (
      <div className="switching-checkbox d-flex justify-content-end ml-auto">
        <div
          className={`Input Input--gap-${props.gap} ${
            !props.newLine ? 'switch__grow m-0' : ''
            }`}
        >
          {props.label && (
            <label htmlFor={inputId} className="Input__label">
              {props.label}
            </label>
          )}

          <div className="switch">
            <input
              className="switch__input"
              id={`switch-checkbox-${props.name}`}
              type="checkbox"
              checked={!!this.state.value}
              onChange={this.handleChange}
              disabled={props.disabled}
            />

            <label
              className="switch__btn"
              htmlFor={`switch-checkbox-${props.name}`}
            />
          </div>

          {props.error && <span className="Input__error error">{props.error}</span>}
        </div>
      </div>
    );
  }
}

export default SwitchInput;
