import React from 'react';
import "../../styles/cms.scss";

const CommunityGuidelines: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-12 font-md text-gray-700">
                        <h2 className="text-center font-weight-bold mb-4 pb-3">Community guidelines </h2>

                        <h4 className="font-weight-bold mb-3 pb-1">Deal Den is committed to maintaining an inclusive and respectful
                            community.
                            Here are a few tips to help foster an enjoyable experience for everyone</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Be courteous.</li>
                            <li>If you change your mind on an offer, politely let the seller know so they can work with other
                                buyers.</li>
                            <li>If you're running late to meet someone, keep the other person updated.</li>
                            <li>We know it can be frustrating when things go wrong. Although it's uncommon, if you have a
                                problem with an item after a sale, l try to work it out with the seller in a calm, respectful
                                way. In most cases, they'll be open to finding a solution.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">In addition, when using Deal Den and interacting with buyers,
                            sellers, or employees,
                            please refrain from any of the behaviors below, in your post, or in messages.</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Disrespectful behavior</li>
                            <li>Harassment</li>
                            <li>Spam or unsolicited offers</li>
                            <li>Displaying prohibited profile content</li>
                            <li>Fraudulent or illegal activity</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">Disrespectfulness, such as:</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Profanity or hate speech</li>
                            <li>Threats of violence or actual violence</li>
                            <li>Inciting violence</li>
                            <li>Offensive or vulgar posts or messages</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">Harassment, such as:</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Targeting another person with complaints about items that were not purchased or expressing
                                opinions about items with no intent tttto purchase</li>
                            <li>Pressuring a seller to make a sale or a buyer to make a purchase</li>
                            <li>Other comments not related to the item</li>
                            <li>Comments related to another person’s looks, relationship status, etc.</li>
                            <li>Any form of harassment including but not limited to hateful, obscene, offensive, profane,
                                racist, sexual or sexually suggestive, tttdefamatory, or violent language</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">Spam, unsolicited offers, or abusing our system, such as:
                        </h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Unsolicited messages sent to potential buyers, including those of a commercial nature</li>
                            <li>Impersonation of another buyer, seller, DealDen employee, or other entity in communications</li>
                            <li>Any other misleading, fake, or malicious communication</li>
                            <li>Misrepresenting yourself, or the products you’re selling</li>
                            <li>Harvesting information about our community members or directing them to external websites or
                                apps to collect their information</li>
                            <li>Abusing our reporting system, or otherwise exploiting tools meant to protect the community</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">Use of disallowed content in your public profile or custom
                            profile link, such as:</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Offensive or vulgar profile names, links, or photos, or those that infringe on the intellectual
                                property rights of others.</li>
                            <li>Use of the DealDen name or image</li>
                            <li>Inclusion of personal contact details</li>
                            <li>Posting another person's private information, including contact information</li>
                        </ul>

                        <h4 className="font-weight-bold mb-3 pb-1">Fraudulent or illegal activity, such as:</h4>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-2">
                            <li>Falsifying information</li>
                            <li>Using DealDen for any fraudulent purpose</li>
                            <li>Violating any law or regulation while using DealDen</li>
                        </ul>
                        <p className="font-md text-gray-700 mb-4 pb-4">We may remove content that violates these guidelines. In
                            severe cases or where there are repeated violations, we may suspend or cancel an account or take
                            other action. For more information, please see our Terms of service.</p>

                        <h4 className="font-weight-bold mb-3 pb-1">How to help keep DealDen safe
                        </h4>
                        <p className="font-md text-gray-700 mb-4 pb-4">Maintaining a strong community is important to us. If you run
                            into any suspicious behavior or items, please let us know. You can also block a person so they can't
                            contact you in the app. That person won't know when you've reported or blocked them.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunityGuidelines;
