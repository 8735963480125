import { useState, useEffect } from 'react';

type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const getDeviceConfig = (width: number): BreakPoint => {
  if (width < 576) {
    return 'xs';
  } else if (width >= 576 && width < 768) {
    return 'sm';
  } else if (width >= 768 && width < 992) {
    return 'md';
  } else if (width >= 992 && width < 1200) {
    return 'lg';
  } else {
    return 'xl';
  }
};

const useGridBreakPoint = () => {
  const [breakPoint, setBreakPoint] = useState<BreakPoint>(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = () => {
      setBreakPoint(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakPoint;
};
export default useGridBreakPoint;
