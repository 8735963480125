import React from 'react';
import { useSelector } from 'react-redux';
import { Banner as BannerModel } from '../../api/models';
import backgroundImage from '../../assets/images/homepage-banner.png';
import { ContainerLoader } from '../../components/loader';
import { State } from '../../store/interfaces';

enum BannerType {
  Top = 'top',
  Bottom = 'bottom',
}

const Banner: React.FunctionComponent = () => {
  const banners = useSelector((state: State) => state.entities.banners);
  const topBanner = banners.find(
    (t: BannerModel) => t.bannerType === BannerType.Top
  );

  let image = '';
  let title = ``;
  let subTitle = ``;
  if (topBanner) {
    image = topBanner.image?.path || '';
    title = topBanner.title;
    subTitle = topBanner.subTitle;
  }

  return (
    <div className="banner d-lg-block d-none" style={{ minHeight: '460px' }}>
      <div className="banner__item position-relative" style={{ minHeight: '460px' }}>
        {image ? (
          <img src={image} className="img-fluid" width="1200" height="400" />
        ) : (
          <div className="banner-load h-100 d-flex align-items-center justify-content-center bg-gray-300 w-100 position-absolute left-0 top-0">
            <ContainerLoader />
          </div>
        )}
        <div className="banner-cnt">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="display-1 text-white font-weight-bold">{title}</h1>
                <p className="h5 text-white font-weight-normal mb-0">
                  {subTitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
