import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { LatLng } from '../../../api/models';
import Map from '../../../components/google-autocomplete/GoogleAutoComplete';
import { LocationType } from './CreatePostForm';

interface Location {
  latLng: LatLng;
  address: {
    place?: string;
    street?: string;
    zip?: number;
    country?: string;
    state?: string;
    city?: string;
  };
}

const GoogleAutoCompleteController: React.FunctionComponent<{
  control: Control;
  name: string;
  onChange: (value: any) => void;
  value?: any;
  error?: string;
  locationType: LocationType;
  lat?: number;
  lng?: number;
}> = (props) => {
  const [location, setLocation] = useState<Location>({
    latLng: {
      lat: props.lat || 24.638185,
      lng: props.lng || -78.055734,
    },
    address: props.value || {},
  });

  const [centerLatLng, setCenterLatLng] = useState<LatLng | undefined>({
    lat: props.lat || 24.638185,
    lng: props.lng || -78.055734,
  });
  const handleAddressChange = (address: any) => {
    const locationObj = {
      ...location,
      address: {
        street: address.street,
        city: address.city,
        state: address.state,
        country: address.country,
        place: address.place,
      },
      location: { coordinates: [address.lat, address.lng] },
    };
    setLocation(locationObj);
    props.onChange({
      location: { coordinates: [address.lat, address.lng] },
      address: address.place,
      street: address.street,
      city: address.city,
      state: address.state,
      country: address.country,
    });
  };
  const center: LatLng = {
    lat: 24.638185,
    lng: -78.055734,
  };

  const renderPlace = () => {
    const address = location.address;
    console.log(location);
    if (address?.place) {
      return `${location.address?.place ? location.address?.place + ', ' : ''}`;
    } else {
      return `${location.address?.street ? location.address?.street + ', ' : ''}
      ${location.address?.city ? location.address?.city + ', ' : ''}${
        location.address?.state
      },${location.address?.country}`;
    }
  };

  return (
    <>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.value}
        render={({ name, value }) => {
          return (
            <div className="position-relative map-outer">
              <div className="locationselected bg-white">
                <label className="text-muted font-sm p-0">Your location</label>
                <div className="selected-loc">{renderPlace()}</div>
              </div>
              <Map
                name={name}
                id={name}
                center={centerLatLng}
                lat={props.lat || centerLatLng?.lat}
                lng={props.lng || centerLatLng?.lng}
                height="280px"
                zoom={10}
                handleAddressChange={handleAddressChange}
                city={location.address?.city}
                street={location.address?.street}
                locationType={props.locationType}
                state={location.address?.state}
                value={`${
                  location.address?.street
                    ? location.address?.street + ', '
                    : ''
                }${
                  location.address?.city ? location.address?.city + ', ' : ''
                }${location.address?.state},${location.address?.country}`}
              />
            </div>
          );
        }}
      />
      {props.error && <span className="TextArea__error">{props.error}</span>}
    </>
  );
};

export default GoogleAutoCompleteController;
