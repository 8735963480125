import { SubscriptionPlan } from '.';
import { Dict } from '../../models';
import { Category, Subcategory } from './category';
import { MediaFile } from './media';
import { CreatedBy, User } from './user';

export interface Product {
  _id: string;
  slug: string;
  title: string;
  price: number;
  currency: string;
  image: any;
  images?: any[];
  featured?: boolean;
  isFeaturedValidTill?: Date;
  isFollow?: boolean;
  verified?: boolean;
  address: string;
  createdAt: Date;
  swapWith?: string;
  favorited?: boolean;
  description?: string;
  category?: Category;
  subcategory?: Subcategory;
  likeCount?: number;
  viewCount?: number;
  leadCount?: number;
  isSold?: boolean;
  createdBy: {
    name?: string;
    avatar?: MediaFile;
    id: string;
    emailVerified?: boolean;
    phoneVerified?: boolean;
  };
  location: {
    lat?: number;
    lng?: number;
    address: string;
    location: {
      type: string;
      coordinates: number[];
    };
    latLong?: LatLng;
    city?: string;
    state?: string;
    street?: string;
    suburb?: string;
    country?: string;
  };
  defaultImage?: MediaFile;
  validTill?: Date;
  isFeatured?: boolean;
  isActive?: boolean;
  orderStatus?: string;
  contactedUsers?: string[];
  commission?: number;
  sellingPrice?: number;
  invoiceNumber?: string;
  auctionDetails?: Auction;
  bidCount?: number;
  productType: ProductType;
  qrcodeToken?: any;
  lastBidder?: CreatedBy;
  isExchange?: boolean;
  isNegotiable?: boolean;
  exchangeables?: string[];
  brand?: Brand;
  customAttributes?: Dict;
  brandId?: string;
  city?: string;
  subscriptionPlan?: SubscriptionPlan;
  location_address?: any;
}
export interface Brand {
  _id?: string;
  title: string;
  description?: string;
  isActive?: boolean;
  subCategoryId: string;
  subCategory: Subcategory;
  lastBidder?: CreatedBy;
}
export enum ProductType {
  Auction = 'auction',
  Sale = 'sale',
  Lead = 'lead',
  Branding = 'branding',
}
export interface LatLng {
  lat: number;
  lng: number;
}
export interface ProductDetails extends Product {
  images: any[];
  likeCount?: number;
  highlightedFields?: HighlightedFieldsMeta[];
  keyFields?: FieldsMeta[];
  customAttributes?: Dict;
  banks?: BankInfo[];
  rating?: RatingSummary;
}

export interface RatingSummary {
  overallRating: number;
  totalRatings: number;
  totalReviews: number;
  ratingSplit: {
    '5': number;
    '4': number;
    '3': number;
    '2': number;
    '1': number;
  };
}

export interface FieldsMeta {
  field: string;
  title: string;
}

export interface HighlightedFieldsMeta extends FieldsMeta {
  icon: string;
}

export interface BankInfo {
  name: string;
  image: any;
  interestRateDescription: string;
}

export interface ProductReview {
  rating: number;
  title: string;
  comment: string;
}

export interface CreatePostFormProps {
  title?: string;
  description?: string;
  brand?: string;
  selectedBrand?: {
    _id: string;
    title: string;
  };
  brandId?: string;
  price?: number;
  images: MediaFile[];
  location_address?: any;
  customAttributes?: Dict;
  location?: Location;
  isNegotiable?: boolean;
  isExchange?: boolean;
  exchangeables?: string[];
  defaultImage?: MediaFile;
  productType: ProductType;
  auctionDetails?: Auction;
}
export interface ReportPostForm {
  reason: string;
  description: string;
  productId: string;
}
export interface BidNowData {
  userId: string;
  bidAmount: number;
  productId: string;
  notificationToken: string;
  paymentToken?: string;
  bidCount?: Number;
}
export interface ReportUserForm {
  reason: string;
  description: string;
  userId: string;
}
export interface BrandingFormProps {
  website?: string;
  categories: string[];
  locations?: Location[];
  gender: string[];
  ageStart: number;
  ageEnd: number;
  targetMyFollowers?: boolean;
}
export interface Location {
  street?: string;
  suburb?: string;
  city?: string;
  state?: string;
  country?: string;
  address?: string;
  location?: {
    type: string;
    coordinates: number[];
  };
}
export interface Auction {
  startDate: Date;
  startTime: string;
  endTime: string;
  endDate: Date;
  startPrice: number;
  buyNowPrice: number;
  bidIncrementAmount: number;
  bidNowPrice?: number;
}

export enum OrderStatus {
  Pending = 'pending',
  Success = 'success',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Sold = 'payment_received',
  OfferAccepted = 'offer_accepted',
  Delivered = 'delivered',
  Active = 'active',
  Expired = 'expired',
}

interface StatusExt {
  label: string;
  class: string;
}
export const OrderStatusSyllables: Dict<StatusExt> = {
  [OrderStatus.Pending]: { label: 'Pending', class: 'pending' },
  [OrderStatus.OfferAccepted]: { label: 'Offer Accepted', class: 'pending' },
  [OrderStatus.Success]: { label: 'Success', class: 'active' },
  [OrderStatus.Cancelled]: { label: 'Cancelled', class: 'cancelled' },
  [OrderStatus.Failed]: { label: 'Failed', class: 'inactive' },
  [OrderStatus.Sold]: { label: 'Sold', class: 'completed' },
  [OrderStatus.Delivered]: { label: 'delivered', class: 'completed' },
  [OrderStatus.Active]: { label: 'Active', class: 'pending' },
  [OrderStatus.Expired]: { label: 'Expired', class: 'expired' },
};

export interface CatalogFilter {
  status?: string;
  productType?: ProductType;
  query?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  category?: string;
  subCategory?: string;
}

export enum AuctionType {
  Buying = 'buying',
  Selling = 'selling',
  Favorite = 'favorite',
}

export interface AuctionCatalogFilter {
  status?: string;
  query?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  category?: string;
  subCategory?: string;
  type?: AuctionType;
}

export interface CatalogRequestModel<T> {
  pagination?: { page?: number; perPage?: number };
  sort: { field?: string; order?: string };
  queryParams?: T;
}

export interface BiddingHistoryRequestModel {
  pagination?: { page?: number; perPage?: number };
  sort?: { field?: string; order?: string };
  queryParams?: {
    productId: string;
  };
}

export interface OrdersCatalogFilter {
  status?: string;
  query?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  category?: string;
  subCategory?: string;
}
export interface ProductEnquiryRequestModel {
  pagination?: { page?: number; perPage?: number };
  sort?: { field?: string; order?: string };
  queryParams?: {
    productId: string;
  };
}
