import React from 'react';
import { useHistory } from 'react-router-dom';
import AppLayout from '../layout/app';
import Profile from './components/profile-new';
import EditProfile from './components/profile-edit';
import '../../styles/my-account.scss';
import signup from '../../store/reducers/signup';
import useAuth from '../../hooks/useAuth';
import useSignup from '../../hooks/useSignup';
import { useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import { User } from '../../api/models';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../api';
import { ContainerLoader } from '../../components/loader';

export enum PageType {
  Profile,
  EditProfile,
  MyAds,
  MyOrders,
  MyEnquiries,
  Invoices,
  Notifications,
  Subscription,
  Settings,
  Chats,
  MyAuction,
  MyLeads,
}

const ProfileScreen: React.FunctionComponent<{ pageType: PageType }> = (
  props
) => {
  const auth = useAuth();
  const { updating, error, updateProfileDetails } = useAuth();
  return (
    <AppLayout hideCategories>
      {props.pageType === PageType.Profile && <Profile />}
      {props.pageType === PageType.EditProfile && (
        <EditProfile
          onSubmit={(signupData) => {
            updateProfileDetails(signupData);
          }}
          isSubmitting={updating}
          email={auth.profile?.email}
          name={auth.profile?.name}
          phone={auth.profile?.phone}
          gender={auth.profile?.gender}
          dob={auth.profile?.dob}
          interestedCategories={auth.profile?.interestedCategories || []}
          billingAddress={auth.profile?.billingAddress}
          error={error}
        ></EditProfile>
      )}
      {/* {!loaded && loading && <ContainerLoader height={500}></ContainerLoader>} */}
    </AppLayout>
  );
};

export default ProfileScreen;
