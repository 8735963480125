import React from 'react';
import { LatLng } from '../../api/models';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 25px;
  min-width: 320px;
  max-width:100%;
  left: 50%;
  user-select: none;
  transform: translate(-50%, 0%);
  &:hover {
    z-index: 1;
  }
`;

const InfoWindow: React.FunctionComponent<
  LatLng & {
    onClose?: () => void;
    style?: React.CSSProperties;
  }
> = (props) => {
  return <Wrapper style={props.style}>{props.children}</Wrapper>;
};

export default InfoWindow;
