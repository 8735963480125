import { RouteKeys } from '../containers/routes/route-keys';

export const getProductLink = (productId: string) => {
  return RouteKeys.ProductDetails.replace(':slug', productId);
};

export const getBoostBackLink = (productId: string) => {
  return RouteKeys.BoostBack.replace(':productId', productId);
};

export const getTopFeaturedPlansLink = (productId: string) => {
  return RouteKeys.TopFeaturedPlans.replace(':productId', productId);
};
