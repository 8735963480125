import { ApiResponse } from '../models';
import ApiService from './service';
import { Dict } from '../../models';
import { wait } from '../utils';
import * as http from './http';
import { store } from '../../store';
import firestore from '../../components/firebase/firebase';

export default class ChatService extends ApiService {
  public async sendMessage(data: {
    chatId: string;
    productId: string;
    type: string;
    text: string;
    isOffer: boolean;
    offeredBy: string;
    buyerId: string;
  }): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/product/chat/${data.chatId}/message`;
    const response = await http.post<ApiResponse<any>>(
      url,
      {
        productId: data.productId,
        type: data.type,
        text: data.text,
        isOffer: data.isOffer,
        offeredBy: data.offeredBy,
        buyerId: data.buyerId,
      },
      this.store
    );
    return response.data;
  }

  public async sendOffer(data: {
    chatId: string;
    productId: string;
    type: string;
    text: string;
    isOffer: boolean;
    offerAmount: number;
    offeredBy: string;
    buyerId: string;
  }): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/product/chat/${data.chatId}/message`;
    const response = await http.post<ApiResponse<any>>(
      url,
      {
        productId: data.productId,
        type: data.type,
        text: data.text,
        isOffer: data.isOffer,
        offerAmount: data.offerAmount,
        offeredBy: data.offeredBy,
        buyerId: data.buyerId,
      },
      this.store
    );
    return response.data;
  }

  public async acceptOrRejectOffer(data: {
    chatId: string;
    productId: string;
    offerId: string;
    status: string;
    messageId: string;
  }): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/product/chat/${data.chatId}/accept-or-reject`;
    const response = await http.post<ApiResponse<any>>(
      url,
      {
        productId: data.productId,
        offerId: data.offerId,
        status: data.status,
        messageId: data.messageId,
      },
      this.store
    );
    return response.data;
  }

  public async createChat(productId: string): Promise<ApiResponse<any>> {
    const state = store.getState();
    const url = `${this.apiDomain}/product/chat/${productId}`;
    const response = await http.get<ApiResponse<any>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async createMessage(
    chatId: string,
    messageParams: {
      createdAt: number;
      createdDate?: Date;
      isOffer: boolean;
      offerStatus: string;
      sentBy: string;
      text: string;
      type: string;
    }
  ): Promise<boolean> {
    const chatRef = firestore.collection('Chats').doc(chatId);
    const messageRef = firestore
      .collection('Chats')
      .doc(chatId)
      .collection('Messages')
      .doc();

    const transaction = firestore
      .runTransaction((trans) =>
        trans.get(chatRef).then((chat: any) => {
          const chatMembers = chat.data().member_array;

          const userUnreads = chat.data().unreadStatus;

          for (const key in userUnreads) {
            if (userUnreads[key]) {
              userUnreads[key] = (userUnreads[key] ? userUnreads[key] : 0) + 1;
            }
          }

          const chatUpdateFields = {
            unreadStatus: userUnreads,
            lastMessageType: messageParams.type,
            lastMessageText: messageParams.text,
            lastMessageTime: new Date().getTime(),
            lastMessageUserId: messageParams.sentBy,
          };

          trans.set(messageRef, messageParams);
          trans.update(chatRef, chatUpdateFields);
          return Promise.resolve(chatMembers);
        })
      )
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    return true;
  }
}
