import React, { useState } from 'react';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import { SubscriptionPlan, PostType } from '../../../api/models';
import Empty from '../../../components/empty';
import { ContainerLoader } from '../../../components/loader';
import useAuth from '../../../hooks/useAuth';

interface PlanProps {
  onPlanSelect: (data: SubscriptionPlan) => void;
  categoryId?: string;
  subCategoryId?: string;
}

const Plans: React.FunctionComponent<PlanProps> = (props) => {
  const auth = useAuth();
  const accountType = auth.profile?.accountType;

  const [cityTabSelected, setCityTabSelected] = useState(true);
  const [nationalTabSelected, setNationalTabSelected] = useState(false);

  const { data: plans, loading, loaded } = useDataLoader<SubscriptionPlan[]>(
    () =>
      api.subscription.getSubscriptionPlans({
        accountType,
        isBoostBackToTop: true,
        categoryId: props.categoryId,
        subCategoryId: props.subCategoryId,
      })
  );

  const { performAction: onPlanSelect } = usePerformAction((data): any => {
    props.onPlanSelect(data);
  });

  if (!loaded || loading) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }

  const renderPlanDetails = (plan: SubscriptionPlan) => {
    return (
      <>
        {' '}
        <div className="ads-item bg-white shadow px-5 pt-lg-4 pb-2 pb-lg-3 position-relative mb-3">
          <div>
            <h6 className="font-weight-bold mb-1">{plan.name}</h6>
            <h6 className="font-weight-bold mb-1">{`$ ${plan.amount} ${
              plan.isRecurring ? plan.period : ''
            }`}</h6>
          </div>

          <div className="views-block border-top mt-3 pt-3 py-lg-2 pb-2 d-none d-lg-block">
            <div className="row mt-3">
              <ul className="align-items-center d-flex list-unstyled m-0 p-0">
                <li className="d-flex pr-3 align-items-center">
                  <span className="icon-verified2 font-lg pr-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>{' '}
                  <span className="font-base text-gray-500">
                    Maximum {plan.noOfPosts} posts{' '}
                    {plan.isRecurring
                      ? plan.period
                      : `for ${plan.listingDays} days`}
                  </span>
                </li>
              </ul>
            </div>
            <div className="row mt-3">
              <ul className="align-items-center d-flex list-unstyled m-0 p-0">
                <li className="d-flex pr-3 align-items-center">
                  <span className="icon-verified2 font-lg pr-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>{' '}
                  <span className="font-base text-gray-500">
                    Post limit inside{' '}
                    {plan.postType === PostType.City ? 'City' : 'Nation'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-7 px-0">
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold btn-block"
                  onClick={() => {
                    onPlanSelect(plan);
                  }}
                >
                  {`Choose ${plan.name} Plan`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="right-panel">
        <nav className="tabs-header myaccount">
          <div
            className="bg-white justify-content-center nav nav-tabs"
            id="nav-tab"
            role="tablist"
          >
            <a
              className={`nav-item nav-link cursor-pointer ${
                cityTabSelected ? 'active' : ''
              }`}
              id="nav-city-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-city"
              aria-selected="true"
              onClick={() => {
                setCityTabSelected(true);
                setNationalTabSelected(false);
              }}
            >
              {PostType.City}
            </a>
            <a
              className={`nav-item nav-link ${
                nationalTabSelected ? 'active' : ''
              }`}
              id="nav-national-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-national"
              aria-selected="false"
              onClick={() => {
                setCityTabSelected(false);
                setNationalTabSelected(true);
              }}
            >
              {PostType.National}
            </a>
          </div>
        </nav>
        {plans && plans.length > 0 && (
          <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                cityTabSelected ? 'show active' : ''
              }`}
              id="nav-city"
              role="tabpanel"
              aria-labelledby="nav-city-tab"
            >
              {plans.filter((plan) => {
                return plan.postType === PostType.City;
              }).length === 0 ? (
                <Empty></Empty>
              ) : (
                plans
                  .filter((plan) => {
                    return plan.postType === PostType.City;
                  })
                  .map((plan) => {
                    return <>{renderPlanDetails(plan)}</>;
                  })
              )}
            </div>
            <div
              className={`tab-pane fade ${
                nationalTabSelected ? 'show active' : ''
              }`}
              id="nav-national"
              role="tabpanel"
              aria-labelledby="nav-national-tab"
            >
              {plans.filter((plan) => {
                return plan.postType === PostType.National;
              }).length === 0 ? (
                <Empty></Empty>
              ) : (
                plans
                  .filter((plan) => {
                    return plan.postType === PostType.National;
                  })
                  .map((plan) => {
                    return <>{renderPlanDetails(plan)}</>;
                  })
              )}
            </div>
          </div>
        )}{' '}
        {plans && plans.length === 0 && <Empty />}
      </div>
    </>
  );
};

export default Plans;
