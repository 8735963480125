import React from 'react';
import AppLayout from '../layout/app';
import MySubscription from './components/my-subscription';

const Subscription: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MySubscription />
    </AppLayout>
  );
};

export default Subscription;
