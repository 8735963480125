import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import AppLayout from '../layout/app';
import { RouteKeys } from '../routes/route-keys';
import Filters from './Filters';
import '../../styles/listing.scss';
import categoryBannerImage from '../../assets/images/products/listing-banner.png';
import Header from './Header';
import ProductTile from '../../components/product/ProductTile';
import useSearch from '../../hooks/useSearch';
import { ContainerLoader } from '../../components/loader';
import MapView from './MapView';
import { ViewType } from './props';
import { useHistory } from 'react-router-dom';
import { parseUrl } from 'query-string';
import Empty from '../../components/empty';
import { FilterType } from '../../api/models';
import useWindowSrollHandler from '../../hooks/useWindowSrollHandler';
import loaderSmall from '../../assets/images/loader-small.gif';
import useCurrentLocationHandler from '../../hooks/useCurrentLocationToGetCity';

const Search: React.FunctionComponent = () => {
  const search = useSearch();
  const [viewType, setViewType] = useState<ViewType>('list');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [page, setPage] = React.useState(1);
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
  const [isLocation, setIsLocation] = useState<boolean>(false);
  const [currentCity, setCurrentCity] = useState<string>('');
  const loadPage = useCallback(
    async (_page: number, city?: string) => {
      if (loading || allPagesLoaded || search.isSearching) {
        return;
      }
      if (_page < page || _page === 1) {
        return;
      }
      setLoading(true);
      setLoaded(false);
      try {
        setPage(_page);
        const results = await search.loadResults(_page);
        if (results.length === 0) {
          setAllPagesLoaded(true);
        }
        if (page === 1) {
          setFirstPageLoaded(true);
        }
      } catch (e) { }
      setLoaded(true);
      setLoading(false);
    },
    [loading, allPagesLoaded, setLoading, setLoaded, setPage, page]
  );

  useEffect(() => {
    const obj = parseUrl(window.location.href);
    const query: any = obj.query;
    const { searchTerm, address, location, categoryId } = search;
    const tmp = {
      searchTerm: query?.searchTerm || searchTerm,
      address,
      location: query?.location || location,
      categoryId: query?.categoryId || categoryId,
    };
    search.setSearchParams(
      tmp.searchTerm,
      tmp.location,
      tmp.address,
      tmp.categoryId,
      page
    );
    if (query?.subCategory) {
      search.setFilterValue(
        'category',
        [query.subCategory],
        FilterType.MultiSelect
      );
    }
  }, []);

  useEffect(() => {
    search.loadResults(1);
  }, [
    search.filters,
    search.searchTerm,
    search.location,
    search.address,
    search.sortBy,
    search.categoryId,
    search.city,
    search.subcategoryId,
  ]);
  const handleScroll = (direction: 'pageUp' | 'pageDown') => {
    if (direction === 'pageDown') {
      loadPage(page + 1);
    }
  };
  const onCity = (city: string) => {
    if (!isLocation) {
      setIsLocation(true);
      // loadPage(1, city);
    }
  };
  useCurrentLocationHandler(onCity, [onCity]);
  useWindowSrollHandler(handleScroll, [handleScroll]);
  const image = search.category?.bannerImage?.path || categoryBannerImage;

  const renderSearchResults = () => {
    if (search.isSearching && page === 1) {
      return <ContainerLoader height={250}></ContainerLoader>;
    }
    if (!search.isSearching && search.results && search.results?.length === 0) {
      return (
        <div className="d-flex justify-content-center w-100">
          <Empty />
        </div>
      );
    }

    if (viewType === 'map') {
      return <MapView products={search.results} category={search.category} />;
    }
    return (
      <>
        {search.results.map((pr) => {
          return (
            <ProductTile
              product={pr}
              className="col-sm-4 col-6 d-flex"
            ></ProductTile>
          );
        })}
      </>
    );
  };
  console.log(image)
  return (
    <AppLayout>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i
          onClick={() => {
            history.goBack();
          }}
          className="icon-prev font-lg mr-3"
        ></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          {search.category?.title}
        </h6>
        <i
          className="icon-search font-xl ml-auto"
          onClick={() => {
            document.body.classList.toggle('open-search');
          }}
        ></i>
        <i
          className="icon-filter font-lg ml-4 filtered"
          onClick={() => {
            document.body.classList.toggle('open-filter');
          }}
        ></i>
      </div>
      <div className="banner banner--sm" style={{ minHeight: '290px' }}>
        <div className="banner__item position-relative" style={{ minHeight: '290px' }}>
          {image ? (
            <>
              <img src={image} className="img-fluid d-md-block d-none" width="1200" height="400" />
              <img src={image} className="img-fluid d-md-none" width="768" height="400" />
            </>
          ) : (
            <div className="banner-load h-100 d-flex align-items-center justify-content-center bg-gray-300 w-100 position-absolute left-0 top-0">
              <ContainerLoader />
            </div>
          )}

        </div>
      </div>
      <div className="container pt-2 pb-5 listingpage">
        <div className="row">
          <div className="col-12 d-lg-block d-none pt-1 mb-4 pb-1">
            <Breadcrumb>
              <BreadcrumbItem href={RouteKeys.Home}>Home</BreadcrumbItem>
              <BreadcrumbItem active>{search.category?.title}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <Filters
            className="col-lg-3 col-12"
            onFilterChange={() => setPage(1)}
          ></Filters>
          <div className="col-lg-9 col-12">
            <div className="row gutter-5">
              <div className="col-12">
                <div className="font-xl mb-lg-4 mb-0 d-flex ">
                  {search.count ? `${search.count} ads ` : ''}
                  {search?.location ? (
                    <>
                      in{' '}
                      <span className="font-weight-semibold">
                        &nbsp;{search.location}
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <Header setViewType={setViewType} viewType={viewType} />
            <div className="product-list-outer">
              <div className="row gutter-5">{renderSearchResults()}</div>
            </div>
            {loading && !loaded && page > 1 && (
              <div className="d-flex inline-loader justify-content-center">
                <div className="align-items-center border border-primary d-inline-flex font-base px-3 py-1 rounded-sm text-primary">
                  Loading
                  <img src={loaderSmall} width="26" height="26" alt="loader" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Search;
