import React from 'react';
import HelpLink from '../../../../components/links/help-link';
import useSignup from '../../../../hooks/useSignup';
import AuthLayout from '../../../layout/auth';
import Banner from './Banner';
import BusinessDetailsForm from './BusinessDetailsForm';

const BusinessDetailsWidget: React.FunctionComponent = () => {
  const signup = useSignup();
  return (
    <AuthLayout
      loginContainerClass="account-details pb-5 pb-md-0 mb-lg-0"
    >
      <Banner />
      <div className="col-lg-6 col-xl-7 col-xl enterphone-right-box content-box bg-white right-radius">
        <div className="p-sm-4 p-2">
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <div className="px-xl-5 mx-auto pb-lg-5 py-3 px-2 px-lg-0 pt-lg-0">
            <div className="top-head-common">
              <h3 className="text-dark mb-lg-3 mb-2 h3 d-none d-lg-block">
                Enter Business Detail
              </h3>
              <h3 className="text-dark mb-lg-3 mb-2 h3 d-block d-lg-none mt-3">
                Include Some Details
              </h3>
              <p className="d-block d-lg-none text-muted">
                We collect more details for security reasons.
              </p>
            </div>
            <BusinessDetailsForm
              onSubmit={signup.updateBusinessDetails}
              isUpdating={signup.updating}
              default={{
                businessName: signup.name,
                businessEmail: signup.email,
                businessPhone: signup.phone,
              }}
            ></BusinessDetailsForm>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default BusinessDetailsWidget;
