import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import LeftBanner from './components/left-banner';
import ForgotPasswordForm from './components/forgot-password-form';
import useAuth from '../../hooks/useAuth';

const ForgotPasswordScreen: React.FunctionComponent = (props) => {
  const { forgotPassword, updating, error } = useAuth();

  return (
    <AuthLayout>
      <div
        className="col-lg-5 bg-box d-none d-lg-flex align-items-center text-white px-5 signup"
        style={{ maxWidth: '473px' }}
      >
        <LeftBanner />
      </div>
      <div className="col-lg-7 col-xl bg-white content-box signup">
        <div className="p-sm-4">
          <a href="/" title="DealDen">
            <LogoSVG className="d-lg-none"></LogoSVG>
          </a>
          <div className="login-form mx-auto py-5">
            <h1 className="font-weight-bold pb-4 text-center">Forgot Password</h1>
            <p className="text-muted text-center">
              Enter phonenumber, and we’ll send you an otp to reset your
              password.
            </p>
            <ForgotPasswordForm
              onSubmit={(forgotPasswordData) =>
                forgotPassword(forgotPasswordData.phone)
              }
              isSubmitting={updating}
              error={error}
            ></ForgotPasswordForm>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordScreen;
