import React from 'react';
import { BankInfo } from '../../../api/models';

const BankInfoList: React.FunctionComponent<{
  banks: BankInfo[];
}> = (props) => {
  if (!props.banks || !props.banks.length) {
    return <></>;
  }
  return (
    <div className="row detail-section-separator pt-lg-2 pt-4 pb-2 bank-approval-block">
      <div className="col-12">
        <h5 className="mb-lg-4 mb-3">Pre Approval by Bank </h5>
        <div className="row gutter-5">
          {props.banks.map((bank) => {
            return (
              <div className="col-auto text-center mb-3">
                <img
                  src={bank.image}
                  className="img-fluid rounded mb-2"
                  alt={bank.name}
                />
                <p className="font-normal text-gray-500 mb-0">
                  {bank.interestRateDescription}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BankInfoList;
