import React, { useEffect, useState } from 'react';
import { MutiSelectFilterMetaData } from '../../api/models';
import classnames from 'classnames';
import CheckBox from '../check-box';

const MultiSelectCheckboxFilters: React.FunctionComponent<{
  meta: MutiSelectFilterMetaData;
  selected: string[];
  onChange?: (values: string[]) => void;
  className?: string;
}> = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [viewAll, setViewAll] = useState(false);
  const [values, setValues] = useState(props.selected);

  const clearAll = () => {
    setValues([]);
    props.onChange?.([]);
  };

  useEffect(() => {
    setValues(props.selected);
  }, [props.selected]);

  const handleChange = (id: string, checked: boolean) => {
    const newValues: string[] = [];
    if (checked) {
      newValues.push(...values, id);
    } else {
      newValues.push(...values.filter((val) => val !== id));
    }
    setValues(newValues);
    props.onChange?.(newValues);
  };

  const renderOptions = () => {
    let options = props.meta.options;
    if (
      !viewAll &&
      props.meta.options.length > props.meta.minNumberOfItemsToShow
    ) {
      options = props.meta.options.filter(
        (_, index) => index < props.meta.minNumberOfItemsToShow
      );
    }

    return (
      <div className="filter-toggle-body pt-lg-4 pt-2">
        <ul className="list-unstyled px-lg-0 px-3 pt-lg-0 pt-2 mb-0">
          {options.map((op) => {
            return (
              <li className="mb-2">
                <CheckBox
                  id={op[props.meta.valueField]}
                  checked={values.includes(op[props.meta.valueField])}
                  onChange={handleChange}
                >
                  {op[props.meta.textField]}
                </CheckBox>
              </li>
            );
          })}
        </ul>
        {props.meta.options.length > props.meta.minNumberOfItemsToShow && (
          <div
            className="font-xs text-primary mt-3 cursor-pointer d-lg-block d-none"
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View Less' : 'View More'}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={`${props.className || ''} filter-toggle-box`}>
      <div className="filter-toggle-head d-flex align-items-baseline flex-wrap px-lg-0 px-3 pt-lg-0 pt-3">
        <h6 className="mb-0 mr-3">{props.meta.name}</h6>
        {values.length > 0 && (
          <div
            className="font-xxxs text-gray-500 filter-clear-all cursor-pointer"
            onClick={() => clearAll()}
          >
            <i className="icon-close-sm mr-1"></i>Clear All
          </div>
        )}
        <div className="filter-toggle-btn ml-auto ">
          <i
            className={classnames({
              'text-gray-500 cursor-pointer': true,
              'icon-minus': expanded,
              'icon-plus': !expanded,
            })}
            onClick={() => setExpanded(!expanded)}
          ></i>
        </div>
        {props.meta.options.length > props.meta.minNumberOfItemsToShow && (
          <div
            className="font-normal text-gray-700 d-lg-none d-flex justify-content-between w-100 border-bottom py-2"
            onClick={() => setViewAll(!viewAll)}
          >
            <div className="col px-0 text-truncate">View All</div>
            <i className="icon-next font-xxxs"></i>
          </div>
        )}
      </div>
      {expanded && renderOptions()}
    </div>
  );
};

export default MultiSelectCheckboxFilters;
