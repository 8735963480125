import React from 'react';
import LogoSVG from '../../../components/logo/logo-svg';

const LoginLeftPane = () => {
  return (
    <div className="col-lg-5 bg-box d-none d-lg-flex align-items-center text-white px-5 signup">
      <a href="/" title="DealDen">
        <LogoSVG type="white" className="d-none d-lg-block position-absolute logo-white" />
      </a>
      <div className="text-left mx-auto">
        <h1 className="mb-3 font-weight-bold text-white">
          Login to your acccount.
        </h1>
        <h6 className="mb-4 font-weight-normal text-white">
          Log in to your account using phone number and password
        </h6>
      </div>
    </div>
  );
};

export default LoginLeftPane;
