import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { FollowedUser } from '../../../api/models';
import { AvatarView } from '../../../components/avatar';
import Empty from '../../../components/empty';
import { ContainerLoader } from '../../../components/loader';
const Followers: React.FunctionComponent<{
  onUnFollow?: () => void;
  onFollow?: () => void;
}> = (props) => {
  const { data: myFollowers, reload, loading, loaded } = useDataLoader<
    FollowedUser[]
  >(() => api.user.getMyFollowers());
  const renderEmpty = () => {
    if (myFollowers.length === 0) return <Empty />;
  };
  const setFollow = (userIdVal?: string) => {
    if (!userIdVal) return;
    api.user.setFollow(userIdVal).then((response) => {
      if (props.onFollow) props.onFollow();
      reload();
    });
  };
  const setUnFollow = (userIdVal?: string) => {
    if (!userIdVal) return;
    api.user.setUnFollow(userIdVal).then((response) => {
      if (props.onUnFollow) props.onUnFollow();
      reload();
    });
  };
  return (
    <>
      {loaded && !loading && (
        <>
          {renderEmpty()}
          {myFollowers.map((item: FollowedUser) => {
            return (
              <div className="tab-content pt-lg-3 pt-2" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="follower-list">
                    <div className="row justify-content-between align-items-center border-bottom border-fade py-3">
                      <div className="col-lg col-7 d-flex align-items-center">
                        <figure className="mb-0">
                          <AvatarView
                            src={item.follower.createdBy?.avatar?.path}
                          ></AvatarView>
                        </figure>
                        <figcaption className="pl-3">
                          <h6 className="font-normal mb-0">
                            {item.follower.createdBy?.name}
                          </h6>
                          <span className="font-xs text-gray-500">
                            {item.followerCount} Followers
                          </span>
                        </figcaption>
                      </div>
                      <div className="col-lg col-5 d-flex justify-content-end">
                        <button
                          onClick={(e) =>
                            item.isFollowing
                              ? setUnFollow(item.follower.createdBy?.id)
                              : setFollow(item.follower.createdBy?.id)
                          }
                          className="btn btn-outline-primary bg-white btn-sm border-width-1 d-flex align-items-center justify-content-center"
                        >
                          {item.isFollowing ? 'UnFollow' : 'Follow'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      {!loaded && loading && <ContainerLoader height={500}></ContainerLoader>}
    </>
  );
};
export default Followers;
