import React from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { PageType } from '../profile';
import { RouteKeys } from '../routes/route-keys';
import Menu from './menu';
import defaultAvatar from '../../components/avatar/default-avatar.png';

const MyAccount: React.FunctionComponent<{ pageType: PageType }> = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const renderMobileBottomBarLinks = () => {
    return (
      <div className="row text-uppercase text-center gutter-10">
        <div className="col col-1-5">
          <a
            href={RouteKeys.Home}
            className="d-flex flex-column text-gray-600 active"
          >
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-home "></i>
            </div>
            <span className="font-xxs">Home</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a href={RouteKeys.Feed} className="d-flex flex-column text-gray-600">
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-usergroup"></i>
            </div>
            <span className="font-xxs">Social</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a
            href={RouteKeys.Create}
            className="d-flex flex-column text-gray-600"
          >
            <div className="footer-mob__icon footer-mob__icon--center position-relative mb-1">
              <i className="icon-sell-strock"></i>
            </div>
            <span className="font-xxs">Sell</span>
          </a>
        </div>
        <div className="col col-1-5">
          <a
            href={RouteKeys.Chats}
            className="d-flex flex-column text-gray-600"
          >
            <div className="footer-mob__icon position-relative mb-1">
              <i className="icon-chat"></i>
            </div>
            <span className="font-xxs">Chat</span>
          </a>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="my-account-dash-res">
        <div className="leftpanel bg-white">
          <a
            onClick={() => history.goBack()}
            className="font-weight-bold icon-close text-white"
          ></a>
          <div className="fig-content px-4 clearfix pt-4 border-bottom pb-3 bg-primary">
            <figure className="float-left rounded-circle m-0">
              <img
                src={auth.profile?.avatar?.path || defaultAvatar}
                className="img-fluid img-cover rounded-circle"
                alt=""
              />
            </figure>
            <figcaption className="float-left pl-3">
              <span className="font-base text-capitalize text-white title">
                {auth.profile?.name}
              </span>
              <div className="ratings text-white py-2">
                <span className="icon-star-filled-2 star mr-1"></span>
                <span className="icon-star-filled-2 star mr-1"></span>
                <span className="icon-star-filled-2 star mr-1"></span>
                <span className="icon-star-filled-2 star mr-1"></span>
                <span className="icon-star-outline-2 star-outer"></span>
                <span className="pl-2">(25)</span>
              </div>
              <div className="d-flex">
                <a
                  href="/edit-profile"
                  className="d-block text-white font-xs mr-4"
                >
                  <span className="icon-edit pr-2"></span>
                  Edit Profile
                </a>
                <a onClick={auth.logout} className="d-block text-white font-xs">
                  <span className="icon-logout pr-2"></span>
                  Logout
                </a>
              </div>
            </figcaption>
          </div>
          <Menu pageType={props.pageType} />
        </div>
      </div>
      {/* {renderMobileBottomBarLinks()} */}
    </>
  );
};
export default MyAccount;
