import React from 'react';
import { RouteKeys } from '../../containers/routes/route-keys';

const PhoneLoginButton: React.FunctionComponent = (props) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      href={RouteKeys.PhoneSignup}
      className="icon-phone1 mx-2"
      title="Phonenumber login"
    ></a>
  );
};

export default PhoneLoginButton;
