import {
  CatalogService,
  CmsService,
  CrudService,
  PaymentService,
  SignupService,
  UserService,
  OrderService,
  ChatService,
  ProfileService,
} from './services';
import AuthService from './services/auth';
import ApiServiceDataStore from './services/data';
import EntityService from './services/entity';
import UploadService from './services/upload';
import SubscriptionService from './services/subscription';

export default class ApiClient {
  public crud: CrudService;
  public chat: ChatService;
  public profile: ProfileService;
  public auth: AuthService;
  public user: UserService;
  public signup: SignupService;
  public cms: CmsService;
  public catalog: CatalogService;
  public entity: EntityService;
  public upload: UploadService;
  public payment: PaymentService;
  public order: OrderService;
  public subscription: SubscriptionService;
  private store: ApiServiceDataStore = {
    apiDomain: '',
    token: undefined,
    firebaseToken: undefined,
  };

  constructor(apiDomain: string) {
    this.store.apiDomain = apiDomain;
    this.order = new OrderService(this.store);
    this.profile = new ProfileService(this.store);
    this.chat = new ChatService(this.store);
    this.auth = new AuthService(this.store);
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.signup = new SignupService(this.store);
    this.cms = new CmsService(this.store);
    this.catalog = new CatalogService(this.store);
    this.entity = new EntityService(this.store);
    this.upload = new UploadService(this.store);
    this.payment = new PaymentService(this.store);
    this.subscription = new SubscriptionService(this.store);
  }

  public setToken(token?: string) {
    this.store.token = token;
  }
  public setFirebaseToken(token?: string) {
    this.store.firebaseToken = token;
  }
}
