import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import OtpIcon from '../../components/icons/otp-icon';
import HelpLink from '../../components/links/help-link';
import LeftPane from './components/left-pane';
import OtpForm from './components/otp-form';
import useAuth from '../../hooks/useAuth';

const ForgotPasswordOtpScreen: React.FunctionComponent = () => {
  const { updating, resetPassword, error, resendOtp } = useAuth();
  return (
    <AuthLayout>
      <div
        className="col-lg-5 bg-box d-none d-lg-flex align-items-center text-dark px-5 signup"
        style={{ maxWidth: '473px' }}
      >
        <LeftPane />
      </div>{' '}
      <div className="col-lg-7 col-xl enterphone-right-box content-box otp-block bg-white signup">
        <div className="p-sm-4 p-2 login-form mx-auto">
          <a href="/" title="DealDen">
            <LogoSVG className="d-lg-none" />
          </a>
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <div className="mx-auto py-5 py-lg-2">
            <div className="top-head-block mt-lg-3">
              <OtpIcon className="mb-4 mb-lg-3 d-none d-lg-block" />
              <h4 className="text-dark mb-3">OTP Verification</h4>
              <p className="text-muted font-weight-lighter mb-4 f-16">
                OTP has been sent to your mobile number. Please verify
              </p>
              <OtpIcon className="mb-5 mb-lg-5 d-block d-lg-none text-center" />
            </div>
            <OtpForm
              onSubmit={(data) => resetPassword(data.otp, data.password)}
              onResendButtonClicked={resendOtp}
              isUpdating={updating}
              error={error}
            ></OtpForm>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordOtpScreen;
