import React from 'react';
import logoWhite from '../../assets/images/logo-white.svg';
import logoRed from '../../assets/images/logo-red.svg';

interface Props {
  height?: number | string;
  width?: number | string;
  className?: string;
  type?: 'white' | 'red';
}

const LogoSVG: React.FunctionComponent<Props> = (props) => {
  return (
    <img
      src={props.type === 'white' ? logoWhite : logoRed}
      width={props.width || '140'}
      height={props.height || '60'}
      alt="DealDen"
      className={props.className}
    />
  );
};

export default LogoSVG;
