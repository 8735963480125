import React from 'react';
import classnames from 'classnames';

const AccountTypeItem: React.FunctionComponent<{
  icon: string;
  selected?: boolean;
  onClick?: () => void;
}> = (props) => {
  return (
    <li
      className={classnames({
        'bg-white mb-3 clearfix col-lg-12 col-6 ml-0': true,
        active: props.selected,
      })}
      onClick={props.onClick}
    >
      <span className="ico float-lg-left d-block d-lg-inline-flex mx-auto mx-md-0 d-flex align-items-center justify-content-center">
        <i className={props.icon}></i>
      </span>
      <span className="txt float-lg-left mt-3 mt-md-4 pl-md-4 float-md-left text-dark mt-lg-4 pl-lg-4">
        {props.children}
      </span>
    </li>
  );
};

export default AccountTypeItem;
