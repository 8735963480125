import React from 'react';
import { LatLng } from '../../api/models';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
  &.active {
    z-index: 1;
  }
`;

const Marker: React.FunctionComponent<
  LatLng & {
    text?: string;
    onClick?: () => void;
    isActive?: boolean;
  }
> = (props) => {
  return <Wrapper className={`${props?.isActive? 'active': ''}`} onClick={props.onClick}>{props.children}</Wrapper>;
};

export default Marker;
