import React, { useEffect, useRef, useState } from 'react';
import config from '../../config';
import { LatLng } from '../../api/models';
import GoogleMapReact from 'google-map-react';

const GoogleMapView: React.FunctionComponent<{
  containerStyle?: React.CSSProperties;
  center?: LatLng;
  zoom?: number;
  id?: string;
}> = (props) => {
  const mapRef = useRef<GoogleMapReact>(null);
  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const [center, setCenter] = useState<LatLng>({
    lat: 24.638185,
    lng: -78.055734,
  });

  useEffect(() => {
    if (props?.center) {
      setCenter(props.center);
    }
  }, [props?.center]);

  useEffect(() => {
    // console.log('center', center);
    mapRef.current?.forceUpdate();
  }, [center]);

  return (
    <div style={props.containerStyle || containerStyle}>
      {center?.lat ? (
        <GoogleMapReact
          ref={mapRef}
          bootstrapURLKeys={{ key: config.GOOGLE_MAP_KEY }}
          defaultCenter={center}
          center={props.center || center}
          defaultZoom={props.zoom || 10}
        >
          {props.children}
        </GoogleMapReact>
      ) : (
        'No center provided'
      )}
    </div>
  );
};

export default GoogleMapView;
