/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import { Product } from '../../api/models';
import { RouteKeys } from '../../containers/routes/route-keys';
import { State } from '../../store/interfaces';

const ProductChat: React.FunctionComponent<{
  product: Product;
  userId: string;
}> = ({product, userId}) => {
  const history = useHistory();
  const onChat = async () => {
    if (userId && product.createdBy.id !== userId) {
      // await api.chat.createChat(product?._id);
      const { data } = await api.chat.createChat(product?._id);
      if (data?.chat?.id) {
        history.push(
          RouteKeys.ChatsDetail.replace(':chatId', data.chat.id)
        );
      } else {
        toastr.warning('Warning', 'Something went wrong');
      }
    }
    // history.push(RouteKeys.Chats);
  };
  return (
    <a
      onClick={onChat}
      className="cursor-pointer d-flex justify-content-center h-100 align-items-center chat-link text-gray-600"
    >
      <i className="icon-chat"></i>
    </a>
  );
};

export default ProductChat;
