import React, { useState } from 'react';
import { usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import {
  SubscribeCustomPlan,
  Subscription,
  SubscriptionStatus,
} from '../../api/models';
import useAuth from '../../hooks/useAuth';
import AppLayout from '../layout/app';
import Payment, { AccountType, PaymentType } from '../payment/components/payment';
import Plan from './components/Plan';
import ThankYou from '../create/components/ThankYou';

const CustomPlanScreen: React.FunctionComponent = () => {
  const auth = useAuth();
  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [customSubscription, setCustomSubscription] = useState<Subscription>();
  const [isThankYou, setIsThankYou] = useState<boolean>(false);

  const { performAction: payment } = usePerformAction(
    (data: Subscription): any => {
      setCustomSubscription(data);
      setIsRedirectPayment(true);
    }
  );

  const { performAction: subscribeCustomPlan } = usePerformAction(
    (paymentId): any => {
      const subscribeData: SubscribeCustomPlan = {
        subscriptionId: customSubscription?._id || '',
        paymentId,
      };
      api.subscription.subscribeCustomPlan(subscribeData).then((data) => {
        if (data.status === SubscriptionStatus.PaymetSuccess) {
          setIsThankYou(true);
        }
      });
    }
  );
  return (
    <AppLayout hideCategories hideFooter>
      {!isRedirectPayment && (
        <Plan onPlanSelect={(data: Subscription) => payment(data)} />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          paymentType={PaymentType.Subscription}
          accountType={
            customSubscription?.accountType || AccountType.Individual
          }
          email={auth.profile?.email!}
          amount={customSubscription?.amount}
          subscriptionPlanId={customSubscription?._id}
          successhandler={subscribeCustomPlan}
          planName={customSubscription?.planName}
          period={customSubscription?.period}
          listingDays={customSubscription?.listingDays}
          isCustomPlan={true}
        ></Payment>
      )}
      {isThankYou && (
        <ThankYou
          heading="Thank You"
          message="Your Custom plan subscription activated successfully"
          isCustomPlan={true}
        />
      )}
    </AppLayout>
  );
};

export default CustomPlanScreen;
