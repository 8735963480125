import React, { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import moment from 'moment';
import api from '../../../api';
import userImage from '../../../assets/images/Profile-PNG-Icon.png';
import { useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import ChatMessagesScreen from './chatMessages';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firestore from '../../../components/firebase/firebase';
import { usePerformAction } from 'react-remote-data-hooks';
import Payment from '../../payment/components/payment';
import { AccountType } from '../../../api/models';
import { PaymentType } from '../../../api/models/payment';
import ThankYou from '../../create/components/ThankYou';
import CustomScrollbars from '../../../components/scrollbar/custom-scrollbar';
import Empty from '../../../components/empty';
import { AvatarView } from '../../../components/avatar';
import { useHistory, useParams } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
export enum ChatType {
  All = 'all',
  Buying = 'buying',
  Selling = 'selling',
}
interface PaymentProps {
  product: {
    _id: string;
    title: string;
  };
  offerId: string;
  amount: number;
}

interface ChatUrlParams {
  chatId?: string;
}

const ChatScreen: React.FunctionComponent = () => {
  const authState = useSelector((state: State) => state.authUser);
  const params = useParams<ChatUrlParams | undefined>();
  const auth = useAuth();
  const [chatType, setChatType] = useState<string>(ChatType.All);
  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<
    PaymentProps | undefined
  >(undefined);
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [selectedChatId, setChat] = useState('');
  const [selectedProduct, setProduct] = useState('');
  const history = useHistory();
  const selectChat = (chatId: string, ProductId: string) => {
    setChat(chatId);
    setProduct(ProductId);
    history.replace({
      pathname: RouteKeys.ChatsDetail.replace(':chatId', chatId),
    });
    document.querySelector(`#chat-${chatId}`)?.scrollIntoView();
    document.body.classList.add('open-chatwindow');
  };
  const onMakePayment = (paymentData: PaymentProps) => {
    setPaymentDetails(paymentData);
    setIsRedirectPayment(true);
  };

  const { performAction: purchase } = usePerformAction((paymentId): any => {
    const purchaseData = {
      productId: paymentDetails?.product?._id,
      paymentId,
      paymentType: PaymentType.Purchase,
    };
    api.order.purchase(purchaseData).then((data) => {
      if (data.status === 'success') {
        // api.payment.payment(paymentId).then((paymentdata) => {
        setThankYouHeading('Thank You');
        setThankYouMessage('You have successfully purchased the product');
        setIsThankYou(true);
        // });
      }
    });
  });

  const onTabClick = (type: string) => {
    setChatType(type);
    // setTimeout(() => {
    //   // setChat('');
    //   // setProduct('');
    // }, 1000);
  };

  const allClassName =
    chatType === ChatType.All ? 'nav-link active' : 'nav-link';

  const buyingClassName =
    chatType === ChatType.Buying ? 'nav-link active' : 'nav-link';

  const sellingClassName =
    chatType === ChatType.Selling ? 'nav-link active' : 'nav-link';

  const userId = auth.profile?._id?.toString();

  const chatRef = firestore.collection('Chats');

  let query = chatRef
    .where('member_array', 'array-contains', userId)
    .orderBy('createdAt', 'desc');
  // .limit(25);

  if (chatType === ChatType.All) {
    query = chatRef.where('member_array', 'array-contains', userId); // .limit(25);
  } else if (chatType === ChatType.Buying) {
    query = chatRef
      .where('member_array', 'array-contains', userId)
      .where('buyerId', '==', userId);
  } else if (chatType === ChatType.Selling) {
    query = chatRef
      .where('member_array', 'array-contains', userId)
      .where('ownerId', '==', userId);
  }

  const [chats, chatsLoading, chatsError] = useCollectionData(query, {
    idField: 'id',
  });

  const chatsForUser: any[] =
    chats?.sort((a: any, b: any) => b.lastMessageTime - a.lastMessageTime) ||
    [];

  // if (!selectedChatId && chatsForUser && chatsForUser.length) {
  // setTimeout(() => {
  //   setChat(chatsForUser[0].id);
  //   setProduct(chatsForUser[0].productId);
  // }, 200);
  // }

  useEffect(() => {
    if (chatsForUser && params?.chatId) {
      const found: any = chatsForUser.find(
        (chat: any) => chat.id === params.chatId
      );
      if (found) {
        selectChat(found.id, found.productId);
      } else {
        setDefaultChat();
      }
    } else {
      setDefaultChat();
    }
  }, [chatsForUser.length]);

  useEffect(() => {
    setDefaultChat();
  }, [chatType]);

  const setDefaultChat = () => {
    if (chatsForUser && chatsForUser.length > 0) {
      setChat(chatsForUser[0].id);
      setProduct(chatsForUser[0].productId);
    }
  };

  const renderEmpty = () => {
    return <Empty />;
  };

  const renderConversations = () => {
    return (
      <div className="tab pt-lg-2">
        <div className="tab-hed ">
          <ul className="nav nav-tabs px-lg-3" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={allClassName}
                id="home-tab"
                data-toggle="tab"
                // href="#Allchats"
                href="javascript:void(0)"
                onClick={() => onTabClick(ChatType.All)}
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                All Chats
              </a>
            </li>
            <li className="nav-item">
              <a
                className={buyingClassName}
                id="Buying-tab"
                data-toggle="tab"
                // href="#Buying"
                href="javascript:void(0)"
                onClick={() => onTabClick(ChatType.Buying)}
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Buying
              </a>
            </li>
            <li className="nav-item">
              <a
                className={sellingClassName}
                id="Selling-tab"
                data-toggle="tab"
                // href="#Selling"
                href="javascript:void(0)"
                onClick={() => onTabClick(ChatType.Selling)}
                role="tab"
                aria-controls="messages"
                aria-selected="false"
              >
                Selling
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div
            className="tab-pane active"
            id="Allchats"
            role="tabpanel"
            aria-labelledby="Allchats-tab"
          >
            <div className="chat-listnames">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
              >
                {chatsForUser &&
                  chatsForUser.map((chat: any) => {
                    const otherUser = chat.member_array?.find(
                      (usr: any) => usr !== auth.profile?._id?.toString()
                    );

                    const isBuyer = otherUser === chat.buyerId ? true : false;

                    const userDetails = isBuyer
                      ? chat.member_metadata.offeredBy
                      : chat.member_metadata.createdBy;

                    const productImageView = chat?.productImage || '';
                    const userImageView = userDetails?.avatar || userImage;
                    return (
                      <div
                        className={`chat-detail-block px-lg-3 py-3 ${
                          selectedChatId && selectedChatId === chat.id
                            ? 'active'
                            : ''
                        }`}
                        onClick={(e) => selectChat(chat.id, chat.productId)}
                        key={`chat-${chat.id}`}
                      >
                        <div className="inner-det-block d-flex position-relative">
                          <div className="chat-date font-xs text-gray-600 m-0 ">
                            {moment(chat.lastMessageTime).utc().fromNow()}
                          </div>
                          <div className="avatar position-relative">
                            <img
                              src={productImageView}
                              className="img-fluid avatar-main "
                            />
                            <AvatarView
                              className="avatar-sub"
                              src={userImageView}
                              size="small"
                            />
                          </div>
                          <div className="detailed-chat">
                            <p className="font-weight-bold m-0 text-gray-900">
                              {userDetails.name}
                            </p>
                            <p className="text-gray-700 m-0">
                              {chat.productTitle}
                            </p>
                            <p className="font-xs text-gray-600 m-0 ">
                              {chat.lastMessageText}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {!chatsForUser || (chatsForUser.length === 0 && !chatsLoading)
                  ? renderEmpty()
                  : ''}
              </CustomScrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderChat = () => {
    const chatSelected = selectedChatId ? selectedChatId : null;
    const selectedChat = chatsForUser?.find(
      (chat: any) => chat.id === selectedChatId
    );

    if (selectedChat && chatSelected) {
      return (
        <ChatMessagesScreen
          chat={selectedChat}
          onPayment={(paymentData: PaymentProps) => onMakePayment(paymentData)}
        />
      );
    } else {
      return <div> </div>;
    }
  };

  return (
    <>
      {!isRedirectPayment && (
        <div className="chat-section pt-lg-4">
          <div className="container px-0 px-lg-3">
            <div className="d-flex">
              <div className="col-lg-auto col-12 chatlist-align px-0 px-lg-3">
                <div className="bg-white chat-list py-lg-4 rounded h-100">
                  <h4 className="font-weight-bold text-gray-900 px-3 font-xll d-none d-lg-block">
                    My Chats
                  </h4>
                  <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
                    <i
                      className="icon-prev font-lg mr-3"
                      onClick={() => {
                        history.goBack();
                        document.body.classList.remove('open-chatwindow');
                      }}
                    ></i>
                    <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
                      My Chats
                    </h6>
                  </div>
                  {renderConversations()}
                </div>
              </div>
              {renderChat()}
            </div>
          </div>
        </div>
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          accountType={AccountType.Individual}
          paymentType={PaymentType.Purchase}
          email={auth.profile?.email!}
          amount={paymentDetails?.amount}
          isSinglePayment={true}
          productId={paymentDetails?.product._id}
          successhandler={purchase}
          planName={paymentDetails?.product.title}
        ></Payment>
      )}

      {isThankYou && (
        <ThankYou
          productId={paymentDetails?.product._id}
          heading={thankYouHeading}
          message={thankYouMessage}
          isCustomPlan={false}
          isPurchase={true}
        />
      )}
    </>
  );
};
export default ChatScreen;
