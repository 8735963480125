import { ApiResponse, Category } from '../models';
import ApiService from './service';
import * as http from './http';

export default class CmsService extends ApiService {
  public async getCategories(): Promise<ApiResponse<Category[]>> {
    const url = `${this.apiDomain}/category`;

    const response = await http.get<ApiResponse<Category[]>>(url);
    return response.data;
  }
  public async getPageByKey(key: string): Promise<ApiResponse<any>> {
    try {
      const url = `${this.apiDomain}/page/page-key/${key}`;
      const response = await http.get<ApiResponse<any>>(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
