import React from 'react';
import LogoSVG from '../../../components/logo/logo-svg';
import { RouteKeys } from '../../routes/route-keys';

const LoginBanner: React.FunctionComponent = (props) => {
  return (
    <>
      <a
        href="/"
        title="DealDen"
        className="d-none d-lg-block position-absolute logo-white"
      >
        {/* comment for build fix */}
        <LogoSVG
          type="white"
          className="d-none d-lg-block position-absolute logo-white"
        ></LogoSVG>
      </a>
      <div className="text-center mx-auto">
        <h1 className="mb-3 font-weight-bold text-white">Welcome Back!</h1>
        <h6 className="mb-4 font-weight-normal text-white">
          To keep connected with us please
          <br />
          login with your personal info
        </h6>
        <a
          href={RouteKeys.Login}
          className="btn-outline-secondary  btn-block btn font-weight-bold mt-5"
        >
          Sign In
        </a>
      </div>
    </>
  );
};

export default LoginBanner;
