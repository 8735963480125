import React, { useEffect, useRef, useState } from 'react';
import { PageType } from '../../profile';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import Empty from '../../../components/empty';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import { Payment, PaymentStatus } from '../../../api/models/payment';
import { format } from 'date-fns';
import { Order } from '../../../api/models/order';
import useAuth from '../../../hooks/useAuth';
import {
  BankDetails,
  OrderStatus,
  PaginatedApiResponse,
} from '../../../api/models';
import { RouteKeys } from '../../routes/route-keys';
import { Link, useHistory } from 'react-router-dom';
import Payout from './payout';
import PaymentFilter, { PaymentFilterModel } from './payment-filter';
import { Dict } from '../../../models';
import AdsPagination, {
  AdsPaginationProps,
} from '../../../components/ads-pagination/pagination';
import { scrollTop } from '../../../utils/window-utils';
import ProductFilter from '../../../components/product-filter/ProductFilter';
import { PaymentStatuses, PayoutStatuses } from '../../../api/models/leads';
import classnames from 'classnames';

enum PaymentTab {
  'Payments',
  'Payouts',
}

enum PayoutStatus {
  Requested = 'requested',
  Processed = 'processed',
  Rejected = 'rejected',
  Pending = 'pending',
}

const initialFilter = {
  [PaymentTab.Payouts]: {
    query: '',
    status: '',
    startDate: '',
    endDate: '',
    page: 1,
  },
  [PaymentTab.Payments]: {
    query: '',
    status: '',
    startDate: '',
    endDate: '',
    page: 1,
  },
};

const InvoicessScreen: React.FunctionComponent = () => {
  const auth = useAuth();
  const [showModal, setShowModal] = React.useState(false);
  const history = useHistory();
  const [payments, setPayments] = useState<any>({
    data: [],
    headerData: null,
  });
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { data: products, loaded: productsLoaded } = useDataLoader<any[]>(() =>
    api.payment.getPayoutPaymentList()
  );
  if (!dataLoaded && products) {
    setDataLoaded(true);
    if (
      products.length > 0 &&
      products.filter((c) => {
        return c.orderStatus === OrderStatus.Delivered;
      }).length > 0
    ) {
      setIsButtonDisabled(false);
    }
  }
  const [filter, setFilter] = useState<Dict<PaymentFilterModel>>(initialFilter);
  const updateFilterByType = (tmpFilter: any) =>
    setFilter({
      ...filter,
      [myOrderTab]: {
        ...filter[myOrderTab],
        ...tmpFilter,
      },
    });
  const updateFilter = (key: string, val: unknown) =>
    setFilter({
      ...filter,
      [myOrderTab]: { ...filter[myOrderTab], [key]: val },
    });

  const resetFilter = () =>
    setFilter({ ...filter, [myOrderTab]: { ...initialFilter[myOrderTab] } });

  type PaymentResponseType = PaginatedApiResponse<{
    data: any[];
    headerData: any;
  }>;

  const [myOrderTab, setMyOrderTab] = useState<PaymentTab>(PaymentTab.Payments);
  const {
    data: paymentResponse,
    loading,
    loaded: paymentLoaded,
    reload: paymentReload,
  } = useDataLoader<PaymentResponseType>(() => {
    const tmp = filter[PaymentTab.Payments];
    return api.payment.getMyPayments({
      sort: {},
      pagination: {
        page: tmp.page,
      },
      queryParams: {
        query: tmp.query,
        status: tmp.status,
        startDate: tmp.startDate,
        endDate: tmp.endDate,
      },
    });
  });

  useEffect(() => {
    paymentReload();
  }, [filter[PaymentTab.Payments]]);

  useEffect(() => {
    orderReload();
  }, [filter[PaymentTab.Payouts]]);

  useEffect(() => {
    if (paymentResponse?.data) setPayments(paymentResponse.data);
  }, [paymentResponse]);
  const filterRef = useRef<any>(null);
  const {
    data: payouts,
    loaded: orderLoaded,
    reload: orderReload,
  } = useDataLoader(() => {
    const tmp = filter[PaymentTab.Payouts];
    return api.payment.getPayoutList({
      sort: {},
      pagination: {
        page: tmp.page,
      },
      queryParams: {
        query: tmp.query,
        status: tmp.status,
        startDate: tmp.startDate,
        endDate: tmp.endDate,
      },
    });
  });

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  // if (!loaded || loading || !orderLoaded) {
  //   return <ContainerLoader height={500}></ContainerLoader>;
  // }

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Invoices
          </li>
        </ol>
      </nav>
    );
  };

  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };

  const renderPayments = () => {
    if (payments?.data?.length === 0) return <Empty />;
    return (
      <>
        <div className="invoice-pannel-listing pt-4 pt-md-3">
          {paymentLoaded ? (
            <>
              <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                <div className="gutter-5 justify-content-between row">
                  <div className="col-md-2">
                    <p className="m-0">Invoice ID</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Date</p>
                  </div>

                  <div className="col-md-2">
                    <p className="m-0">Payment Type</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Amount</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Status</p>
                  </div>
                </div>
              </div>
              {payments?.data?.map((payment: Payment) => {
                return (
                  <div className="invoice-row bg-white rounded">
                    <div className="gutter-5 justify-content-between row">
                      <div className="col-md-2 col-6 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <Link
                          to={'/invoice-detail/' + payment._id}
                          className="image overflow-hidden bg-light noresize"
                        >
                          <div className="inv-id d-flex ">
                            <span>
                              <i className="icon-invoice"></i>
                            </span>
                            <p className="m-0">{payment.invoiceNumber}</p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-2 col-6 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Date</p>
                        <p className="m-0">
                          {' '}
                          {format(new Date(payment.createdAt), 'dd MMM yyyy')}
                        </p>
                      </div>
                      {/* <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Recepient</p>
                    <p className="m-0 font-weight-semibold">Shini Sebastin</p>
                  </div> */}

                      <div className="col-md-2 col-6 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Payment Type</p>
                        <p className="m-0">{payment.paymentMode || 'Online'}</p>
                      </div>
                      <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                        <h6 className="m-0">$ {payment.amountPaid}</h6>
                      </div>
                      <div className="col-md-2 col-6 order-5 order-md-0 status pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Status</p>
                        <span
                          className={
                            payment.status === PaymentStatus.Pending
                              ? ' status pending'
                              : ' status completed'
                          }
                        >
                          {payment.status}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            renderContainerLoader()
          )}
        </div>
        {paymentResponse?.count &&
          paymentResponse?.count !== 0 &&
          renderPagination({
            count: paymentResponse.count,
            page: paymentResponse.page,
            perPage: paymentResponse.perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop();
            },
          })}
      </>
    );
  };

  const renderPayouts = () => {
    if (payouts?.count === 0) return <Empty />;
    return (
      <>
        <div className="invoice-pannel-listing pt-4 pt-3">
          {orderLoaded ? (
            <>
              <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                <div className="gutter-5 justify-content-between row">
                  <div className="col-md-2">
                    <p className="m-0">Invoice ID</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Date</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Product</p>
                  </div>

                  <div className="col-md-2">
                    <p className="m-0">Amount</p>
                  </div>
                  <div className="col-md-2">
                    <p className="m-0">Status</p>
                  </div>
                </div>
              </div>
              {payouts?.data?.data?.map((pay: any) => {
                const productName =
                  pay.productDetails.length > 1
                    ? `${pay.productDetails[0].title} + ${
                        pay.productDetails.length - 1
                      } `
                    : `${pay.productDetails[0].title}`;
                return (
                  <div className="invoice-row bg-white rounded">
                    <div className="gutter-5 justify-content-between row">
                      {/* <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <Link
                          to={'/invoice-detail/' + pay._id}
                          className="image overflow-hidden bg-light noresize"
                        >
                          <div className="inv-id d-flex ">
                            <span>
                              <i className="icon-invoice"></i>
                            </span>
                            <p className="m-0">{pay.invoiceNumber}</p>
                          </div>
                        </Link>
                      </div> */}
                      <div className="col-md-2 col-6 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Invoice ID</p>
                        <Link
                          to={'/payout-detail/' + pay._id}
                          className="image overflow-hidden bg-light noresize"
                        >
                          <div className="inv-id d-flex ">
                            <span>
                              <i className="icon-invoice"></i>
                            </span>
                            <p className="m-0">{pay.payoutNumber}</p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Date</p>
                        <p className="m-0">
                          {' '}
                          {format(new Date(pay.createdAt), 'dd MMM yyyy')}
                        </p>
                      </div>
                      {/* <div className="col-md-2 col-4 order-4 order-md-0 pb-3 pb-md-0">
                    <p className="m-0 d-md-none labelin">Recepient</p>
                    <p className="m-0 font-weight-semibold">Shini Sebastin</p>
                  </div> */}
                      <div className="col-md-2 col-4 pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Product</p>
                        <p className="m-0">{productName}</p>
                      </div>

                      <div className="col-md-2 col-12 order-last order-md-0 pt-1 pt-md-0 pb-md-0 price">
                        <h6 className="m-0">$ {pay.totalPayoutAmount}</h6>
                      </div>
                      <div className="col-md-2 col-4 order-5 order-md-0 status pb-3 pb-md-0">
                        <p className="m-0 d-md-none labelin">Status</p>
                        <span
                          className={
                            pay.status === PayoutStatus.Requested
                              ? ' status pending'
                              : ' status completed'
                          }
                        >
                          {pay.status}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            renderContainerLoader()
          )}
          {payouts &&
            payouts?.count &&
            payouts?.count !== 0 &&
            renderPagination({
              count: payouts.count,
              page: payouts.page,
              perPage: payouts.perPage,
              onPageChange: (nextPage: number) => {
                updateFilter('page', nextPage);
                scrollTop();
              },
            })}
        </div>
      </>
    );
  };

  return (
    // <div className="invoice-inner py-md-5 pb-4 pt-3">
    <div className="my-ads py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.Invoices} />
          <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white d-flex align-items-center">
              <Link
                to="account"
                className="icon-prev font-lg mr-3 text-white"
              ></Link>
              Invoices
            </h6>

            <i
              className="icon-filter text-white font-xl"
              onClick={() => {
                // console.log(filterRef)
                filterRef?.current?.toggleExtraFilter();
                // document.body.classList.toggle('open-filter');
              }}
            ></i>
          </div>

          {/* {loaded && orderLoaded ? ( */}
          <div className="col-lg-9">
            <div className="right-panel">
              <div className="invoice-pannel">
                {myOrderTab === PaymentTab.Payments && (
                  <ProductFilter
                    title="Invoices"
                    ref={filterRef}
                    defaultValues={initialFilter[PaymentTab.Payments]}
                    values={filter[PaymentTab.Payments]}
                    searchPlaceHolder="Payments"
                    onFilterChange={(e: any) => updateFilterByType(e)}
                    onFilterReset={resetFilter}
                    showCategoryFilter={false}
                    data={PaymentStatuses}
                  />
                )}
                {myOrderTab === PaymentTab.Payouts && (
                  <ProductFilter
                    title="Invoices"
                    ref={filterRef}
                    defaultValues={initialFilter[PaymentTab.Payouts]}
                    values={filter[PaymentTab.Payouts]}
                    searchPlaceHolder="Payouts"
                    onFilterChange={(e: any) => updateFilterByType(e)}
                    onFilterReset={resetFilter}
                    showCategoryFilter={false}
                    data={PayoutStatuses}
                  />
                )}
                {myOrderTab === PaymentTab.Payments && (
                  <div className="row gutter-5 width-row">
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Total earnings</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          <span>
                            ${' '}
                            {paymentLoaded &&
                              payments?.headerData?.totalEarnings?.toFixed(2)}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Awaiting payment</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          ${' '}
                          {payouts?.data.headerData?.awaitingPayment?.toFixed(
                            2
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Total paid</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          $ {payments?.headerData?.totalPaid?.toFixed(2)}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}

                {myOrderTab === PaymentTab.Payouts && (
                  <div className="row gutter-5 width-row">
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Total earnings</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          ${' '}
                          {payouts?.data?.headerData?.totalEarnings?.toFixed(2)}
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Awaiting payment</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          ${' '}
                          {payouts?.data?.headerData?.awaitingPayment?.toFixed(
                            2
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-4 panel-box1">
                      <div className="invoice-pannel-boxes p-3 rounded">
                        <p className="m-0">Total paid</p>
                        <h5 className="font-xxl text-primary m-0 font-weight-bold">
                          $ {payouts?.data?.headerData?.totalPaid?.toFixed(2)}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}

                {/* {loaded && orderLoaded && ( */}
                <>
                  <Link
                    className={classnames({
                      'btn btn-primary mx-auto col-12 col-sm-8 col-md-6 search-btn ml-1 btn-sm font-base px-4  mt-3 btn-block d-lg-none': true,
                      disabled: isButtonDisabled,
                    })}
                    to="/payout-request"
                  >
                    Request for Payout
                  </Link>
                  <nav className="tabs-header myaccount d-lg-flex justify-content-lg-between align-items-center border-bottom mt-3 mt-lg-4">
                    <div
                      className="nav nav-tabs border-bottom"
                      id="nav-tab"
                      role="tablist"
                    >
                      <a
                        className={
                          myOrderTab === PaymentTab.Payments
                            ? 'nav-item nav-link active cursor-pointer'
                            : 'nav-item nav-link cursor-pointer'
                        }
                        id="nav-home-tab"
                        data-toggle="tab"
                        onClick={() => setMyOrderTab(PaymentTab.Payments)}
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        Payments{' '}
                        {paymentResponse?.count > 0 && (
                          <span className="count">
                            ({paymentResponse?.count})
                          </span>
                        )}
                      </a>
                      <a
                        className={
                          myOrderTab === PaymentTab.Payouts
                            ? 'nav-item nav-link active cursor-pointer'
                            : 'nav-item nav-link cursor-pointer'
                        }
                        id="nav-profile-tab"
                        data-toggle="tab"
                        onClick={() => setMyOrderTab(PaymentTab.Payouts)}
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Payouts{' '}
                        {payouts?.count > 0 && (
                          <span className="count">({payouts?.count})</span>
                        )}
                      </a>
                    </div>
                    <Link
                      className={classnames({
                        'btn btn-primary search-btn ml-1 btn-sm font-base px-4 py-1 d-none d-lg-block': true,
                        disabled: isButtonDisabled,
                      })}
                      to="/payout-request"
                    >
                      Request for Payout
                    </Link>
                  </nav>
                  <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
                    <div
                      className={`tab-pane fade show ${
                        myOrderTab === PaymentTab.Payments ? 'active' : ''
                      } `}
                      id="nav-payments"
                      role="tabpanel"
                    >
                      {/* <PaymentFilter
                        onSubmit={(e) => {
                          updateFilterByType(e);
                        }}
                        searchPlaceHolder="Payments"
                        values={filter[PaymentTab.Payments]}
                        initialValues={initialFilter[PaymentTab.Payments]}
                      ></PaymentFilter> */}
                      <div className="ads-item">{renderPayments()}</div>
                    </div>
                    <div
                      className={`tab-pane fade show ${
                        myOrderTab === PaymentTab.Payouts ? 'active' : ''
                      } `}
                      id="nav-payouts"
                      role="tabpanel"
                    >
                      {/* <PaymentFilter
                        onSubmit={(e) => {
                          updateFilterByType(e);
                        }}
                        searchPlaceHolder="Payouts"
                        values={filter[PaymentTab.Payouts]}
                        initialValues={initialFilter[PaymentTab.Payouts]}
                      ></PaymentFilter> */}
                      <div className="ads-item">{renderPayouts()}</div>
                    </div>
                    {/* {myOrderTab === PaymentTab.Payments ? (
                        <>
                          <code>
                            Payments:{' '}
                            {JSON.stringify(filter[PaymentTab.Payments])}
                          </code>
                          <PaymentFilter
                            onSubmit={(e) => {
                              updateFilterByType(e);
                            }}
                            searchPlaceHolder="Payments"
                            values={filter[PaymentTab.Payments]}
                            initialValues={initialFilter[PaymentTab.Payments]}
                          ></PaymentFilter>
                        </>
                      ) : (
                        <>
                          <code>
                            Payouts:{' '}
                            {JSON.stringify(filter[PaymentTab.Payments])}
                          </code>
                          <PaymentFilter
                            onSubmit={(e) => {
                              updateFilterByType(e);
                            }}
                            searchPlaceHolder="Payouts"
                            values={filter[PaymentTab.Payouts]}
                            initialValues={initialFilter[PaymentTab.Payouts]}
                          ></PaymentFilter>
                        </>
                      )} */}
                    {/* {myOrderTab === PaymentTab.Payments
                        ? renderPayments()
                        : renderPayouts()} */}
                  </div>
                </>
                {/* )} */}
              </div>
            </div>
          </div>
          {/* // ) : ( // <div className="col-lg-9">{renderContainerLoader()}</div>
          // )} */}
        </div>
      </div>
    </div>
  );
};
export default InvoicessScreen;
