import React, { FunctionComponent, Ref, useEffect, useState } from 'react';
import { getProductLink } from '../../../utils/link-utils';
import { format } from 'date-fns';
import {
  AuctionType,
  OrderStatus,
  OrderStatusSyllables,
  Product,
} from '../../../api/models';
import { Dropdown } from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import { RouteKeys } from '../../routes/route-keys';
import { useHistory } from 'react-router';
import moment from 'moment';

interface CustomProps {
  product: Product;
  type: AuctionType;
  onBiddingHistoryClick?: (product: Product) => void;
  activateProduct?: (productId: string) => void;
  deActivateProduct?: (productId: string) => void;
  choosePlanWhenNoSubscription?: (product: Product) => void;
  boostAction?: () => void;
  sellFasterAction?: () => void;
}

const newDate = new Date();
const showStatusLabel = (product: Product) => {
  if (product?.orderStatus) {
    if (
      product?.isActive &&
      product.orderStatus === OrderStatus.Pending &&
      product.validTill &&
      new Date(product.validTill) > newDate
    ) {
      return (
        <span
          className={`status ${
            OrderStatusSyllables?.[product.orderStatus]?.class
          }`}
        >
          {OrderStatusSyllables?.['active']?.label}
        </span>
      );
    } else if (
      product.orderStatus === OrderStatus.Pending &&
      product.validTill &&
      new Date(product.validTill) < newDate
    ) {
      return (
        <span
          className={`status ${
            OrderStatusSyllables?.[product.orderStatus]?.class
          }`}
        >
          {OrderStatusSyllables?.['expired']?.label}
        </span>
      );
    }
    return (
      <span
        className={`status ${
          OrderStatusSyllables?.[product.orderStatus]?.class
        }`}
      >
        {OrderStatusSyllables?.[product.orderStatus]?.label}
      </span>
    );
  } else {
    return '';
  }
};
const CustomToggle = React.forwardRef(
  ({ children, onClick }: any, ref: any) => (
    <div
      className="dropdown filter position-absolute"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

const MyAuctionProduct: FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const { product } = props;
  const [isComplete, setIsComplete] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    const { product } = props;
    console.log('Console ~ product', product);
    if (product.orderStatus === OrderStatus.Sold) setIsComplete(true);
  }, [props.product]);

  return (
    <>
      <div
        className={`bg-white shadow px-md-4 pt-md-3 position-relative mb-3 rounded ${
          isComplete || !product.isActive ? 'inactive' : ''
        }`}
      >
        {props.type === AuctionType.Selling ? (
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <span className="icon-more text-muted font-lg"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {product.bidCount || 0 > 0 ? (
                <Dropdown.Item
                  onClick={() => props?.onBiddingHistoryClick?.(props.product)}
                >
                  Bidding History
                </Dropdown.Item>
              ) : (
                ''
              )}
              {!isComplete ? (
                product.isActive ? (
                  <Dropdown.Item
                    onClick={() =>
                      props?.deActivateProduct?.(props.product._id)
                    }
                  >
                    Deactivate
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      if (props?.product?.subscriptionPlan) {
                        props?.activateProduct?.(props.product._id);
                      } else {
                        props?.choosePlanWhenNoSubscription?.(product);
                      }
                    }}
                  >
                    Activate
                  </Dropdown.Item>
                )
              ) : (
                ''
              )}
              {moment(product.auctionDetails?.endDate).isSameOrAfter(
                moment()
              ) &&
                (!props.product.bidCount || props.product.bidCount === 0) && (
                  <Dropdown.Item
                    onClick={() =>
                      history.push(
                        RouteKeys.EditProduct.replace(
                          ':slug',
                          props.product.slug
                        )
                      )
                    }
                  >
                    Edit
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          ''
        )}

        <div className="px-md-0 px-3 auction">
          <div className="row mt-3 mt-md-0">
            <div className="col-12 d-md-none d-flex justify-content-between bg-gray-100 border-bottom border-gray-300">
              <span className="date d-block font-sm py-2">
                <span className="text-muted font-xs">Posted on:</span>{' '}
                {format(new Date(product.createdAt), 'dd MMM yyyy')}
              </span>
              <div className="d-block d-md-none mr-4 mt-2">
                {showStatusLabel(product)}
              </div>
            </div>
            <div className="col-md-5 pb-3 pb-md-0 pt-md-0 pt-3">
              <figure className="mb-0 position-relative noresize">
                <a
                  href={getProductLink(product.slug)}
                  className="image overflow-hidden bg-light noresize"
                >
                  <img className="img-fluid" src={product.defaultImage?.path} />
                </a>
                <figcaption>
                  <a href={getProductLink(product.slug)}>
                    <span className="date font-sm text-gray-700 mr-2 d-md-block d-none">
                      <span className="text-muted font-xs">Posted on:</span>{' '}
                      {format(new Date(product.createdAt), 'dd MMM yyyy')}
                    </span>
                    <span className="title font-base d-block text-gray-700 pb-1 text-truncate d-block">
                      {product.title}{' '}
                    </span>
                    <span className="price font-lg text-dark font-weight-bold d-block d-md-none line-height-sm">
                      $
                      {product.auctionDetails?.buyNowPrice &&
                        product.auctionDetails?.buyNowPrice}
                    </span>
                    <span className="font-sm text-muted mt-1 text-truncate d-block">
                      In:{' '}
                      <span className="text-gray-700">
                        {product.subcategory?.title} | {product.category?.title}
                      </span>
                    </span>
                  </a>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-3 col-12">
              <span className="price font-lg text-dark font-weight-bold d-none d-md-block">
                $
                {product.auctionDetails?.buyNowPrice &&
                  product.auctionDetails?.buyNowPrice}
              </span>
              <div className="d-flex justify-content-between d-md-block">
                {product.auctionDetails?.startPrice && (
                  <span className="date font-sm text-gray-700 d-block">
                    <span className="text-muted">Starting Price:</span> $
                    {product.auctionDetails?.startPrice}
                  </span>
                )}
                {!isComplete && product.auctionDetails?.bidIncrementAmount && (
                  <span className="date font-sm text-gray-700 d-block">
                    <span className="text-muted">Bid Increment Amount:</span>$
                    {product.auctionDetails?.bidIncrementAmount}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-4 col-12 col-sm-3 px-md-0  py-2 py-md-0 ">
              {showStatusLabel(product)}
              <div className="d-flex justify-content-between d-md-block">
                {product.auctionDetails?.startDate && (
                  <span className="date font-sm text-gray-700 d-block">
                    <span className="text-muted">Starts At:</span>{' '}
                    {format(
                      new Date(product.auctionDetails?.startDate),
                      'dd MMM yyyy'
                    )}
                  </span>
                )}
                {product.auctionDetails?.endDate && (
                  <span className="date font-sm text-gray-700 d-block">
                    <span className="text-muted">Ends At:</span>{' '}
                    {format(
                      new Date(product.auctionDetails?.endDate),
                      'dd MMM yyyy'
                    )}
                  </span>
                )}

                {product.createdBy.id === auth.profile?._id &&
                  !product.isFeatured &&
                  product.orderStatus === OrderStatus.Pending &&
                  product.validTill &&
                  new Date(product.validTill) > newDate && (
                    <>
                      {/* <span className="d-block text-muted font-sm">
                        This Ad is currently live
                      </span> */}

                      {!product.isFeatured && (
                        <>
                          <button
                            className="btn btn-outline-primary btn-sm mt-lg-1 py-1 text-capitalize border-width-1"
                            type="button"
                            onClick={props.sellFasterAction}
                          >
                            Sell Faster
                          </button>
                        </>
                      )}
                    </>
                  )}

                {product.createdBy.id === auth.profile?._id &&
                  product.orderStatus === OrderStatus.Pending &&
                  product.validTill &&
                  new Date(product.validTill) < newDate && (
                    <>
                      {/* <span className="d-block text-muted font-sm">
                          
                          This Ad is expired
                        </span> */}
                      <button
                        className="btn btn-sm mt-lg-1 font-xxxs text-capitalize btn-primary"
                        type="button"
                        onClick={props.boostAction}
                      >
                        Boost Back
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="views-block border-top mt-md-3 py-2 px-3 px-md-0 border-gray-400">
            <div className="row ">
              <div className="col">
                <span className="title font-sm  text-gray-600">
                  <span className="icon-location pr-2"></span>
                  {product.location.state}
                  {', '}
                  {product.location.country}
                </span>
              </div>
              <div className="col-auto  d-flex">
                <span className="font-sm text-muted mr-2 mr-md-4 d-flex align-items-center">
                  <i className="icon-visible font-lg"></i>
                  {product.viewCount || 0}&nbsp;
                  <span className="d-none d-md-block">views</span>
                </span>
                <span className="font-sm text-muted mr-2 mr-md-4 d-flex align-items-center">
                  <i className="icon-heart-filled font-base"></i>
                  {product.likeCount || 0}&nbsp;
                  <span className="d-none d-md-block">likes</span>
                </span>
                <span className="font-sm text-muted d-flex align-items-center">
                  <i className="icon-bid font-base"></i>
                  {product.bidCount || 0}&nbsp;
                  <span className="d-none d-md-block">bids</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAuctionProduct;
