import { PayoutStatus } from '../../containers/invoices/components/payment-filter';
import { Dict } from '../../models';
import { ProductDetails } from './catalog';
import { MediaFile } from './media';
import { MinimalProductDetails } from './order';
import { AccountType, BusinessDetails, CreatedBy } from './user';

export interface VerifyPost {
  email: string;
  tokenId?: string;
  invoicenumber?: string;
  subscriptionPlanId?: string;
  isCustomPlan?: boolean;
}
export interface Payment {
  _id?: string;
  userId: string;
  email: string;
  amountPaid: number;
  tokenId?: string;
  invoiceNumber?: string;
  accountType: AccountType;
  serviceDetails?: ServiceDetails;
  status: PaymentStatus;
  paymentMode?: PaymentMode;
  paymentType?: string;
  referenceNumber?: number;
  authorizationNumber?: number;
  paymentDetails?: PaymentDetails;
  createdBy: {
    name: string;
    avatar?: any;
  };
  currency?: string;
  createdAt: Date;
  payoutStatus?: string;
  productDetails?: MinimalProductDetails;
}
interface ServiceDetails {
  id: string;
  title: string;
}
export enum PaymentStatus {
  Pending = 'pending',
  Success = 'success',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export enum PaymentMode {
  Cash = 'cash',
  Online = 'online',
  Cheque = 'cheque',
}

export enum PaymentType {
  Subscription = 'subscription',
  Purchase = 'purchase',
}
interface PaymentDetails {
  result?: number;
  message?: string;
  referenceNumber?: string;
  authorizationNumber?: string;
  amount?: number;
  cardType?: string;
  invoiceNumber?: string;
}

export interface PaymentRequestModel<T> {
  pagination?: { page?: number; perPage?: number };
  sort: { field?: string; order?: string };
  queryParams?: T;
}

export interface Payout {
  requestedBy: string;
  requestedUser?: {
    id?: string;
    name?: string;
    avatar?: MediaFile;
    businessDetails?: BusinessDetails;
    address?: Location;
    email: string;
    phone: string;
  };
  productDetails: ProductDetails[];
  paymentMode: any;
  totalAmount: number;
  totalCommission: number;
  totalPayoutAmount: number;
  currency: string;
  accountType?: AccountType;
  status?: PayoutStatus;
  payoutNumber: string;
  comment?: string;
  processedBy?: any;
  payoutProcessedOn?: Date;
  numberOfProducts: number;
  paypalPayoutDetails: {};
  payoutPaymentDetails: PayoutPaymentDetails;
  createdAt: Date;
}

export interface PayoutPaymentDetails {
  paymentMode: string;
  // payoutMode: string;
  paypalRecipient?: string;
  bankDetails?: BankDetails;
}

export interface BankDetails {
  bankName: string;
  accountNumber: string;
  bankCode: string;
  branch?: string;
  isDefault?: boolean;
}
