import React, { useEffect } from 'react';
import { ViewType } from './props';

const ViewTypeToggle: React.FunctionComponent<{
  viewType: ViewType;
  setViewType: (vt: ViewType) => void;
  className?: string;
}> = (props) => {
  useEffect(() => {
    if(props.viewType === 'map') document.body.classList.add('open-map')
    else  document.body.classList.remove('open-map')
  }, [props.viewType]);
  return (
    <div
      className={`switch-view d-inline-flex d-flex cursor-pointer mr-2 ${
        props.className || ''
      }`}
      onClick={() =>
        props.setViewType(props.viewType === 'list' ? 'map' : 'list')
      }
    >
      {props.viewType === 'list' && (
        <>
          <i className="icon-location mr-lg-2"></i>
          <span className="d-lg-block d-none">Map View</span>
        </>
      )}
      {props.viewType === 'map' && (
        <>
          <i className="icon-list mr-lg-2 "></i>
          <span className="d-lg-block d-none">List View</span>
        </>
      )}
    </div>
  );
};

export default ViewTypeToggle;
