import React from 'react';
import Config from '../../../config';

const InstagramLink: React.FunctionComponent<{ className?: string }> = (
  props
) => {
  return (
    <a href={Config.INSTAGRAM_LINK} className={props.className} target="_blank">
      <i className="icon-insta-rounded">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    </a>
  );
};

export default InstagramLink;
