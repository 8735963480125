import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const SlickSlider: React.FunctionComponent<{
  settings: Omit<Settings, 'className'>;
  className?: string;
}> = (props) => {
  return (
    <Slider {...props.settings} className={props.className}>
      {props.children}
    </Slider>
  );
};

export default SlickSlider;
