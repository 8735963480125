import React from 'react';
import AppLayout from '../layout/app';
import SettingsScreen from './components/settings';

const MyOrders: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <SettingsScreen />
    </AppLayout>
  );
};

export default MyOrders;
