import React, { useState } from 'react';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import {
  BankDetails,
  OrderStatus,
  Product,
  BillingAddress,
} from '../../../api/models';
import useAuth from '../../../hooks/useAuth';
import AppLayout from '../../layout/app';
import Bank from './bank';
import { useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';
import Modal from 'react-modal';
import AddNewAddressForm from '../../profile/components/add-new-address';

interface CustomProps {
  showModal: boolean;
  onClick: () => void;
  onPayout: (
    ids: string[],
    paymentDetails: {
      paymentMode: string;
      paypalRecipient?: string;
      bankDetails?: BankDetails;
    }
  ) => void;
  data: Product[];
}
const customStyles = {
  content: {
    margin: '20px auto',
    inset: '0px',
    width: '100%',
    maxWidth: '780px',
    borderRadius: '10px',
  },
};
const Payout: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const auth = useAuth();
  const [allProducts, setAllProduct] = useState<Product[]>([]);
  const [payoutProduct, setPayoutProduct] = useState<Product[]>([]);
  const [payoutMode, setPayoutMode] = useState(String);
  const [paypalRecipient, setPaypalRecipient] = useState(String);
  const [bankDetails, setBankDetails] = useState<BankDetails>();
  const [paypalReciever, setPaypalReciever] = useState<string>();
  const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [billingAddress, setBillingAddress] = useState<
    BillingAddress | undefined
  >(auth.profile?.billingAddress);
  const { data: products, loaded: productsLoaded } = useDataLoader<any[]>(() =>
    api.payment.getPayoutPaymentList()
  );
  const {
    data: companyDetails,
    loaded: companyDetailsLoaded,
    loading: companyDetailsLoading,
  } = useDataLoader<any>(() => api.user.getCompanyDetails());

  const toggleModal = () => {
    // var isMobileVersion = document.getElementsByClassName('snake--mobile');
    if (document.getElementsByClassName('modal-open').length > 0) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
    setShowModal(!showModal);
  };
  const hideModal = () => {
    document.body.classList.remove('modal-open');
    setShowModal(true);
  };

  if (!dataLoaded && products) {
    setAllProduct(products);
    setDataLoaded(true);
    if (products.length) {
      setIsButtonDisabled(false);
    }
  }

  const [subTotal, setSubTotal] = React.useState<number>(0);
  const [commission, setCommission] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);

  const addProductToPayout = (product: Product) => {
    const data = allProducts.filter((prod: Product) => {
      return prod._id.toString() !== product._id.toString();
    });
    setAllProduct(data);
    setPayoutProduct([...payoutProduct, product]);
    calculateAmount([...payoutProduct, product]);
  };

  const onBackButtonClick = () => {
    hideModal();
    history.push(RouteKeys.Invoices);
  };

  const removeProductToPayout = (product: Product) => {
    const data = payoutProduct.filter((prod: Product) => {
      return prod._id.toString() !== product._id.toString();
    });
    setPayoutProduct(data);
    setAllProduct([...allProducts, product]);
    calculateAmount(data);
  };

  const calculateAmount = (products: Product[]) => {
    const tot = products.length
      ? products
          .map((obj: Product) => obj.sellingPrice || 0)
          .reduce((sum: any, next: any) => {
            return sum + next;
          })
      : 0;

    const comm = products.length
      ? products
          .map(
            (obj: Product) =>
              ((obj.sellingPrice || 0) * +(obj.category?.commission || 0)) / 100
          )
          .reduce((sum: any, next: any) => {
            return sum + next;
          })
      : 0;

    const totAmt = tot - comm;

    setSubTotal(tot);
    setCommission(comm);
    setTotal(totAmt);
  };

  const addNewBankDetails = (paymentDetails: {
    bankDetails?: BankDetails;
    paypalRecipient?: string;
  }) => {
    if (paymentDetails?.bankDetails) {
      toggleModal();
      setBankDetails(paymentDetails?.bankDetails);
      requestPayout('bank', paymentDetails?.bankDetails, undefined);
    }
    if (paymentDetails?.paypalRecipient) {
      toggleModal();
      setPaypalReciever(paymentDetails?.paypalRecipient);
      requestPayout('paypal', undefined, paymentDetails?.paypalRecipient);
    }
  };

  const onPayout = async (
    ids: string[],
    paymentDetails: {
      paymentMode: string;
      paypalRecipient?: string;
      bankDetails?: BankDetails;
    }
  ) => {
    // if (paypalRecipient) {
    //   paymentDetails.paymentMode = 'paypal';
    // } else {
    //   paymentDetails.paymentMode = 'bank';
    // }
    const res = await api.payment.requestPayout(ids, paymentDetails);
    return res;
  };

  const requestPayout = async (
    paymentMode: string,
    bankDetails?: BankDetails,
    paypalReciever?: string
  ) => {
    const ids = payoutProduct.map((prod: Product) => prod._id.toString());
    try {
      let paymentDetails: any;
      if (paypalReciever) {
        paymentDetails = {
          paymentMode,
          paypalRecipient: paypalReciever,
        };
      } else if (bankDetails) {
        paymentDetails = { paymentMode, bankDetails };
      }
      if (!ids.length) {
        toastr.error('Error', 'No products selected');
      } else if (paymentDetails) {
        const response = await onPayout(ids, paymentDetails);
        if (response.status === 200) {
          toastr.success('Success', 'Payout request sent successfully');
        } else {
          toastr.error('Error', 'Payout request failed');
        }
        history.push(RouteKeys.Invoices);
      }
    } catch (e) {
      toastr.error('Error', 'Payout Request Failed');
    }
  };

  return (
    <div className="container py-4">
      <div className="pb-3 d-flex justify-content-between align-items-center">
        <a href={RouteKeys.Invoices} className="font-sm">
          {' '}
          <i className="font-xxxs mr-1">
            <small className="icon-prev"></small>
          </i>
          Back{' '}
        </a>
      </div>
      <div className="row gutter-10">
        <div className="col-lg-5 col-12">
          {paypalReciever && (
            <div className="invoice-pannel-listing pt-4 pt-md-0 ">
              <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                <div className="gutter-5 justify-content-between row">
                  <p className="m-0">Payment Mode : </p>
                  <p className="m-0">Paypal</p>
                </div>
                <div className="row gutter-5">
                  <p className="m-0">Paypal Details : </p>
                  <p className="m-0">{paypalReciever}</p>
                </div>
              </div>
            </div>
          )}
          {/* {auth.profile?.bankDetails?.length && auth.profile?.bankDetails.find((e) => e.default) && ( */}
          {bankDetails && (
            <div className="invoice-pannel-listing pt-4 pt-md-0 ">
              <div className="invoice-heads bg-gray-400 rounded d-none d-md-block">
                <div className="gutter-5 justify-content-between row">
                  <div>
                    <div className="row gutter-5">
                      <p className="m-0">Payment Mode : </p>
                      <p className="m-0">Bank Transfer</p>
                    </div>
                    <p className="m-0">
                      Bank Name: {bankDetails?.bankName}
                      <br></br>
                      Account Number: {bankDetails?.accountNumber}
                      <br></br>
                      Bank Code: {bankDetails?.bankCode}
                      <br></br>
                      Branch: {bankDetails?.branch}
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* )} */}
          <div className="payout-item-scroll position-sticky top-0 mb-4 border rounded ">
            <table className="table  text-gray-500 mb-0">
              <thead>
                <tr className=" text-gray-700">
                  <th className="font-weight-semibold bg-gray-400 position-sticky top-0 border-0">
                    Items
                  </th>
                  <th className="font-weight-semibold bg-gray-400 position-sticky top-0 border-0 text-right">
                    Qty
                  </th>
                  <th className="font-weight-semibold bg-gray-400 position-sticky top-0 border-0 text-center">
                    Amount
                  </th>
                  <th
                    className="bg-gray-400 position-sticky top-0 border-0 "
                    style={{ width: '40px' }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {dataLoaded &&
                  !!allProducts.length &&
                  allProducts.map((product: Product) => {
                    if (product.orderStatus === OrderStatus.Delivered) {
                      return (
                        <tr>
                          <td>
                            <div className="text-gray-700 text-truncate payout-item">
                              {product.title}
                            </div>
                            <span className="font-sm">
                              {product.invoiceNumber}
                            </span>
                          </td>
                          <td className="text-right">1</td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <span> {product.sellingPrice}</span>
                            </div>
                          </td>
                          <td>
                            {' '}
                            <div className="border border-danger text-danger d-inline-flex p-1 cursor-pointer">
                              <i
                                className="icon-plus"
                                onClick={() => addProductToPayout(product)}
                              >
                                {' '}
                              </i>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-7 col-12">
          <div className="border rounded p-4 shadow">
            <h5 className="font-xxxl mb-2 font-weight-bold">Invoice Details</h5>
            <div className="font-normal mb-4 pb-md-3 pb-2">
              <span className="text-gray-500">Request Date: </span>{' '}
              {format(new Date(), 'dd MMM yyyy hh:mm a')}
            </div>

            <div className="row">
              <div className="col-sm-6 col-12 font-normal text-gray-700 mb-sm-5 mb-4">
                <div className="mb-1">For</div>
                <div className="font-weight-bold text-gray-900">
                  {billingAddress?.firstName} {billingAddress?.lastName}
                </div>
                {billingAddress?.address} {billingAddress?.city},{' '}
                {billingAddress?.state}, {billingAddress?.country},{' '}
                {billingAddress?.zip}
                <div
                  className="text-danger mt-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(false);
                    setIsModalOpen(true);
                  }}
                >
                  Edit
                </div>
              </div>
              {companyDetailsLoaded && !companyDetailsLoading && (
                <div className="col-sm-6 col-12 font-normal text-gray-700 mb-sm-5 mb-4">
                  <div className="mb-1">From</div>
                  <div className="font-weight-bold text-gray-900">
                    {companyDetails.companyName}
                  </div>
                  {companyDetails.address}
                  {companyDetails.city}, {companyDetails.state} -{' '}
                  {companyDetails.zip}, {companyDetails.email}
                  {', '}
                  {companyDetails.phone}
                </div>
              )}
            </div>

            <div className="table-responsive">
              <table className="table text-gray-700 border-bottom mb-5">
                <thead>
                  <tr className="text-gray-700">
                    <th className="font-weight-normal border-0 pl-0">Item</th>
                    <th className="font-weight-normal border-0 text-right">
                      Qty
                    </th>
                    <th className="font-weight-normal border-0 text-right">
                      Rate ($)
                    </th>
                    <th className="font-weight-normal border-0 text-right">
                      Commission (%)
                    </th>
                    <th className="font-weight-normal border-0 text-right">
                      Amount ($)
                    </th>
                    <th className="font-weight-normal border-0 text-righ px-0"></th>
                  </tr>
                </thead>
                <tbody className="font-weight-semibold">
                  {!!payoutProduct.length &&
                    payoutProduct.map((prod: Product) => {
                      return (
                        <>
                          {' '}
                          <tr>
                            <td className="py-3 text-gray-900 pl-0">
                              {prod.title}
                            </td>
                            <td className="py-3 text-right">1</td>
                            <td className="py-3 text-right">
                              $ {prod.sellingPrice}
                            </td>
                            <td className="py-3 text-right">
                              {prod.category?.commission || 0}
                            </td>
                            <td className="py-3 text-right text-gray-900">
                              $ {prod.sellingPrice}
                            </td>
                            <td className="py-3 px-0">
                              <i
                                className="icon-close cursor-pointer font-xxs font-weight-bolder ml-1 text-dark"
                                onClick={() => {
                                  removeProductToPayout(prod);
                                }}
                              ></i>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="row justify-content-end py-2">
              <div className="col-md-6 col-sm-8 col-12">
                <div className="border-bottom font-xs text-gray-500 py-2 mb-2">
                  INVOICE SUMMARY
                </div>
                <div className="d-flex flex-wrap no-gutters py-1">
                  <div className="col-6 text-gray-700">Subtotal</div>
                  <div className="col-6 text-gray-900 text-right">
                    $ {subTotal || 0}
                  </div>
                </div>
                <div className="d-flex flex-wrap no-gutters py-1">
                  <div className="col-6 text-gray-700">Dealden commission</div>
                  <div className="col-6 text-gray-900 text-right">
                    -$ {commission || 0}
                  </div>
                </div>
                <div className="d-flex flex-wrap no-gutters py-2 border-top mt-2 font-weight-semibold">
                  <div className="col-6 text-gray-700">Total</div>
                  <div className="col-6 text-gray-900 text-right">
                    $ {total || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 text-right mt-3">
            <button
              type="button"
              className="btn btn-primary py-2 px-3"
              onClick={() => toggleModal()}
              disabled={isButtonDisabled}
            >
              Send a Payout Request
            </button>
          </div>
        </div>
      </div>
      <Bank
        showModal={showModal}
        onClick={onBackButtonClick}
        data={products}
        onAddNewBank={addNewBankDetails}
      />
      {isModalOpen}
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen || false}
        contentLabel={''}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <AddNewAddressForm
          onSave={(data: BillingAddress) => {
            setBillingAddress(data);
            setIsModalOpen(false);
          }}
          onClose={() => setIsModalOpen(false)}
          firstName={billingAddress?.firstName}
          lastName={billingAddress?.lastName}
          address={billingAddress?.address}
          city={billingAddress?.city}
          state={billingAddress?.state}
          zip={billingAddress?.zip}
          country={billingAddress?.country}
        />
      </Modal>
    </div>
  );
};
export default Payout;
