import React from 'react';
import "../../styles/cms.scss";

const TrustAndSafety: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 h-100 position-relative">
                    <div className="row h-100">
                        <div className="col-md-6 col-12">
                            <div className="h-100 d-flex justify-content-center align-items-start flex-column">
                                <h1 className="text-white mb-3">Trust is the valued
                                    most in our social
                                    classified marketplace.
                                </h1>
                                <a href="#"
                                    className="btn btn-secondary mb-5 px-4 rounded-sm text-primary font-md font-weight-bold">
                                    <span className="px-4">Sell Now</span>
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-md-6 col-12 justify-content-center banner-right-block py-md-5 py-4 d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/trust-banner-img.png')} className="img-fluid position-relative my-md-4" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h4 className="mt-5 text-center text-dark mb-4 pb-3 font-weight-normal trust-content"><b>DealDen</b> is
                                a social
                                classified marketplace
                                where
                                the
                                well-being of
                                buyers and sellers is a top-priority concern. So that our buyers and sellers may have a more
                                secure
                                connection, we'll keep focusing on every aspect of the experience. And we'll keep having high
                                expectations
                                for ourselves and the people in our communities we serve</h4>
                            <h2 className="mt-5 text-center text-dark mb-5 font-weight-bold">In Deals We Trust</h2>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/trust-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">In Deals We Trust</h5>
                            <p className="font-md">Your profile gives you the chance to tell others about yourself</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/verified-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">Verified Individuals & Businesses</h5>
                            <p className="font-md">Utilize an ID, Facebook Profile, Phone Number and Email to verify your identity
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/rating-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">User Ratings</h5>
                            <p className="font-md">See what others have to say about the users, and provide your own positive
                                feedback on individuals and business sellers</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/message-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">In-App Messaging</h5>
                            <p className="font-md">Communicate securely with buyers and sellers without disclosing personal
                                information</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/meetup-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">Deal Dean Community MeetUp Spots</h5>
                            <p className="font-md">Use the meetup scheduler in chat to find well-lit, surveilled exchange locations
                                near you</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="recommended-blk py-4">
                <div className="container pb-2">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="text-white text-center mb-4 pb-2">Recommendations for Buyers</h5>
                        </div>
                        <div className="col-sm-6 col-12 text-center mb-sm-0 mb-5">
                            <img src={require('../../assets/images/cms/buying-tips-img.png')} className="mb-2 pb-1" alt="" />
                            <h2 className="mb-1 text-white font-weight-bold">Buying Tips</h2>
                            <p className="mb-0 text-white">Examine the best ways to check and buy products</p>
                        </div>
                        <div className="col-sm-6 col-12 text-center border-left">
                            <img src={require('../../assets/images/cms/selling-tips-img.png')} className="mb-2 pb-1" alt="" />
                            <h2 className="mb-1 text-white font-weight-bold">Selling Tips</h2>
                            <p className="mb-0 text-white">Tips on engaging buyers and selling with confidence will be provided</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 font-md">
                            <h2 className="font-weight-bold mb-5 text-center">DealDen Users Ethical Guideline</h2>
                            <h5 className="text-uppercase mb-3 pb-1 font-weight-bold">BE SOCIABLE.</h5>
                            <p className="mb-4">Even though we don't all live in the same neighborhood, we believe that if we must
                                treat each
                                other with respect, set reasonable pricing, be honest and ontime to meetup. If we do this, every
                                Deal Den experience will be enhanced for buyers and sellers.</p>
                            <p className="font-weight-bold mb-0">Set reasonable pricing and make reasonable deals.</p>
                            <p className="mb-4">We're aiming for a market equilibrium in which everyone is satisfied with their
                                purchase. We can
                                get there with prices and offerings that represent the true value of things.</p>
                            <p className="font-weight-bold mb-0">Be Honest & Transparent</p>
                            <p className="mb-4">Transparent talks regarding the specifics of an posting or deal, as well as thorough
                                descriptions of advertised products, can help everyone achieve greater success more rapidly.</p>
                            <p className="font-weight-bold mb-0">Be on-time to the meetups</p>
                            <p className="mb-4">We believe that being on time accounts for the remaining 20% of success. Please
                                don't disappear without a trace. For whatever reason, please notify the other party as soon as
                                possible. We don't want to waste anyone's time.</p>

                            <h5 className="font-weight-bold mb-3 py-1">BE ETHICAL IN YOUR ACTIONS.</h5>
                            <p className="mb-4">There is no room for compromise when it comes to upholding the values of good
                                citizenship. This is a place where you should be your most authentic self. Members of the Deal
                                Den community have the right to feel safe and secure in our marketplace.</p>
                            <p className="font-weight-bold mb-0">No bullying, no violence, no threats.</p>
                            <p className="mb-4">It is against our policy to engage in violent behavior or to make threats of
                                violence. We also forbid harassing, intimidating, or harming another person in any other
                                way.There will be no exceptions.</p>
                            <p className="font-weight-bold mb-0">No discrimination.</p>
                            <p className="mb-4">Anybody who follows our rules and adheres to our standards is allowed to use our
                                marketplace. It is unacceptable to treat anybody unfairly because of their color, ethnicity,
                                gender identity, sexual orientation, or religious affiliation.</p>
                            <p className="font-weight-bold mb-0">No misrepresentation.</p>
                            <p className="mb-4">Don't be afraid to be who you truly are. We won't accept anything else in its place.
                                It is prohibited to impersonate another person, entity, or DealDen staff, regardless of
                                connection. The publishing of a third party's personal information is also against the law.</p>
                            <p className="font-weight-bold mb-0">No use of indecent or offensive language.</p>
                            <p className="mb-4">Keep it clean; don't use crude or provocative language. We don't tolerate any form
                                of sexual harassment here. After receiving a report from a member of our community, we will
                                remove the offending user from our marketplace.</p>
                            <p className="font-weight-bold mb-0">No scams.</p>
                            <p className="mb-4">There are no con artists here. We will terminate the accounts of users who send
                                unsolicited messages to potential purchasers, impersonate other people or entities, or transmit
                                misleading, false, or harmful information. Please use the website or app to report any
                                questionable activity you come across.</p>
                            <p className="font-weight-bold mb-0">No prohibited items.</p>
                            <p className="mb-4">For the sake of the members of our community, we have placed restrictions on some
                                content and item postings. Others may be harmful to your health or safety, so keep that in mind
                                when shopping. We've also decided not to accept products that we believe are improper for our
                                community or might be considered insulting or immoral. Please check our Rules for <a
                                    href="">Prohibited
                                    Items</a>, <a href="">Posting Rules</a>, and <a href="">Community Guidelines</a>.</p>

                            <h5 className="font-weight-bold mb-3 py-1">BE PROACTIVE.</h5>
                            <p className="mb-4">We ask members of our community to take their personal wellbeing as seriously as we
                                do by making good decisions about how and where to buy and sell.</p>
                            <p className="font-weight-bold mb-0">Avoid phishyness.</p>
                            <p className="mb-4">Please don’t give away personal information such as email, phone number, or address.
                                Use in-app messaging to communicate securely. Pay with cash or use <a href="">DealDen Payments
                                    available</a> in
                                some markets. Do not make wire transfers or send money by mail.</p>
                            <p className="font-weight-bold mb-0">Meet in a sensible location.</p>
                            <p className="mb-4">Please agree to meet in well-lit, well-crowded public locations, and let a friend or
                                family member know where you’re going.</p>
                            <p className="font-weight-bold mb-0">Inspect items in person Please.</p>
                            <p className="mb-4">Please thoroughly inspect and test items before you complete a sale.</p>
                            <p className="font-weight-bold mb-0">Report problems.</p>
                            <p className="mb-4">If you see something on DealDen that violates our policy, please report it through
                                the app. If you have an experience with a buyer or seller that violates our community standards,
                                please take the appropriate action to safeguard your personal wellbeing, and then contact us.
                            </p>
                            <p className="font-weight-bold mb-0">Rate each other.</p>
                            <p className="mb-4">We expect members of our community to earn high marks, but our in-app rating tool is
                                your opportunity to give each other honest feedback on the buying and selling experience and
                                help improve that experience for others.</p>
                            <p className="mb-4">DealDen will take action against known violators of this Code of Conduct. If you see
                                something or experience something that doesn’t live up to these standards, please contact us.
                            </p>

                            <h4 className="text-primary font-weight-bold mb-5 pt-4">LET US KNOW IF WE CAN HELP</h4>

                            <h4 className="font-weight-bold mb-3">CUSTOMER CARE EXPERTS</h4>
                            <p className="mb-5">We are here to help solve problems and investigate issues when they arise. Please <a
                                href="">contact us</a> for assistance.</p>

                            <h4 className="font-weight-bold mb-3">LAW ENFORCEMENT PARTNERS</h4>
                            <p className="mb-5">We work closely with our Law Enforcement Partners to ensure incidents requiring
                                further investigation are handled accordingly. To learn more about how we assist Law Enforcement
                                Officers, please visit our <a href="">Law Enforcement Resource Page</a></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustAndSafety;
