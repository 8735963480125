import {
  ApiResponse,
  Filters,
  FilterType,
  PaginatedApiResponse,
  Product,
  ProductDetails,
  ProductReview,
  SortBy,
} from '../models';
import ApiService from './service';
import moment from 'moment';
import { Dict } from '../../models';
import { wait } from '../utils';
import * as http from './http';
import { PaymentRequestModel, VerifyPost } from '../models/payment';
import { store } from '../../store';
import { PaymentDetails } from '../../containers/payment/components/checkout-form';
import { stringify } from 'querystring';

export default class PaymentService extends ApiService {
  public async paymentVerifyPost(data: VerifyPost): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/payment/verifyPost`;
    const response = await http.post<ApiResponse<any>>(url, data, this.store);
    return response.data.data;
  }
  public async updateToken(
    tokenId: string,
    paymentId: string
  ): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/payment/${paymentId}/update-token`;
    const response = await http.put<ApiResponse<any>>(
      url,
      { tokenId },
      this.store
    );
    return response.data.data;
  }

  public async createPaymentIntent(
    paymentDetails: PaymentDetails
  ): Promise<any> {
    const url = `${this.apiDomain}/payment/create-payment-intent`;
    const response = await http.post<ApiResponse<any>>(
      url,
      paymentDetails,
      this.store
    );
    return response.data;
  }

  public async processPayment(
    paymentId: string,
    payload: any
  ): Promise<ApiResponse<any>> {
    const url = `${this.apiDomain}/payment/${paymentId}/process-payment`;
    const response = await http.put<ApiResponse<any>>(url, payload, this.store);
    return response.data.data;
  }

  public async payment(paymentId: string): Promise<ApiResponse<Product>> {
    const url = `${this.apiDomain}/payment/${paymentId}`;
    const response = await http.put<ApiResponse<any>>(url, {}, this.store);
    return response.data.data;
  }
  public async getMyPayments(
    request?: PaymentRequestModel<any>
  ): Promise<PaginatedApiResponse<any>> {
    const state = store.getState();
    let query = {};
    if (request) {
      const { page = 1, perPage = 10 } = request.pagination || {};
      const { field, order } = request.sort;
      query = {
        _sort: field,
        _order: order,
        page,
        perPage,
        ...request.queryParams,
      };
    }
    const url = `${this.apiDomain}/payment/my-payments?${stringify(query)}`;
    const response = await http.get<PaginatedApiResponse<any>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async getPayoutPaymentList(): Promise<{
    data: any[];
    headerData: any;
  }> {
    const state = store.getState();
    const url = `${this.apiDomain}/payout/payment-list`;
    const response = await http.get<
      ApiResponse<{ data: any[]; headerData: any }>
    >(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data.data;
  }

  public async getPayoutList(
    request?: PaymentRequestModel<any>
  ): Promise<PaginatedApiResponse<{ data: any[]; headerData: any }>> {
    const state = store.getState();
    let query = {};
    if (request) {
      const { page = 1, perPage = 10 } = request.pagination || {};
      const { field, order } = request.sort;
      query = {
        _sort: field,
        _order: order,
        page,
        perPage,
        ...request.queryParams,
      };
    }
    const url = `${this.apiDomain}/payout?${stringify(query)}`;
    const response = await http.get<
      PaginatedApiResponse<{ data: any[]; headerData: any }>
    >(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data;
  }

  public async requestPayout(ids: string[], paymentDetails: any): Promise<any> {
    const state = store.getState();
    const url = `${this.apiDomain}/payout/request-payout`;
    const response = await http.post<ApiResponse<any>>(
      url,
      { productIds: ids, payoutPaymentDetails: paymentDetails },
      this.store
    );
    return response.data;
  }
}
