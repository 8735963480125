/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from 'react';
import classnames from 'classnames';
import useSearch from '../../hooks/useSearch';
import { SortBy } from '../../api/models';

const options = [SortBy.Relevence, SortBy.LowToHigh, SortBy.HighToLow];

const Sort: React.FunctionComponent = (props) => {
  const [open, setOpen] = useState(false);

  const search = useSearch();

  const handleSelection = (sortBy: SortBy) => {
    search.setSortBy(sortBy);
    setOpen(false);
  };

  return (
    <div
      className={classnames({
        'custom-dropdown d-none d-lg-inline-block position-relative': true,
        'custom-dropdown--open': open,
      })}
    >
      <div
        className="custom-dropdown__label border p-2 bg-white rounded d-flex align-items-center cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <span className="font-xxs text-gray-500 mr-2">Sort By:</span>
        <span className="font-normal text-gray-700">{search.sortBy}</span>
        <i className="icon-arrow-down text-gray-500 ml-4 font-xxxs"></i>
      </div>
      <div
        className="custom-dropdown__menu bg-white position-absolute top-100 right-0 bg-white shadow w-100 rounded text-left py-2"
        style={{ zIndex: 1 }}
      >
        {options
          .filter((sb) => sb !== search.sortBy)
          .map((sb) => {
            return (
              <a
                href="javascript:void(0)"
                className="d-block py-1 px-3"
                onClick={() => handleSelection(sb)}
              >
                {sb}
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default Sort;
