import { MediaFile } from './media';
import { Subscription } from './subscription';

export enum AccountType {
  Individual = 'Individual',
  Business = 'Business',
}

export enum OnBoardStatus {
  SignedUpWithPhone = 'SignedUpWithPhone',
  BasicInfoAdded = 'BasicInfoAdded',
  Completed = 'Completed',
}

export interface BusinessDetails {
  name: string;
  email: string;
  registrationNumber: string;
  phone: string;
  address: string;
  city: string;
  state: string;
}

export interface BankDetails {
  bankName: string;
  accountNumber: string;
  bankCode: string;
  branch?: string;
  isDefault?: boolean;
}

export interface User {
  _id: string;
  name?: string;
  email?: string;
  phone?: string;
  isAdmin?: boolean;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  dob?: Date | string;
  role?: string;
  avatar?: MediaFile;
  username?: string;
  isActive?: boolean;
  isBlocked?: boolean;
  failedLoginAttemptCount?: number;
  productCount?: number;
  isArchived?: boolean;
  isProfileSet?: boolean;
  accountType: AccountType;
  business: BusinessDetails;
  subscriptionDetails?: Subscription;
  followedCount?: number;
  followingCount?: number;
  allowNotification?: boolean;
  subscriptions: Subscription[];
  paypalReciever?: string;
  bankDetails?: BankDetails[];
  interestedCategories?: string[];
  gender?: string;
  billingAddress?: BillingAddress;
  stripeCustomerId?: any;
  fbVerified?: boolean;
  googleVerified?: boolean;
}
export interface BillingAddress {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip?: string;
  country: string;
}
export interface FollowedUser {
  follower: {
    userId?: string;
    createdBy?: CreatedBy;
    createdAt: Date;
  };
  followerCount: number;
  isFollowing: boolean;
}
export interface FollowingUser {
  followingUser: {
    name: string;
    _id: string;
    createdAt: Date;
    avatar: MediaFile;
  };
  followerCount: number;
}
export interface CreatedBy {
  id?: string;
  name?: string;
  avatar?: MediaFile;
}
