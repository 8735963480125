import React from 'react';
import useSignup from '../../../../hooks/useSignup';
import HelpLink from '../../../../components/links/help-link';
import Banner from './banner';
import ChooseAccountType from './choose-account-type';
import AuthLayout from '../../../layout/auth';

const AccountTypeWidget: React.FunctionComponent = () => {
  const { setAccountType } = useSignup();
  return (
    <AuthLayout loginContainerClass="choose-account pb-5 pb-md-0 mb-lg-0">
      <Banner />
      <div className="col-lg-6 col-xl-7 col-xl enterphone-right-box content-box otp-block  right-radius">
        <div className="p-sm-4 p-2">
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <ChooseAccountType
            onAccountTypeSelection={setAccountType}
          ></ChooseAccountType>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AccountTypeWidget;
