import React from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { Product } from '../../../api/models';
import { ContainerLoader } from '../../../components/loader';
import ProductTile from '../../../components/product/ProductTile';
import SlickSlider from '../../../components/slick-slider';

const RelatedAds: React.FunctionComponent<{
  categoryId: string;
  parentProductId: string;
}> = (props) => {
  const { data: related, loaded } = useDataLoader<Product[]>(() =>
    api.catalog.getRelatedAds(props.categoryId, props.parentProductId)
  );

  if (!loaded && !related) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }
  if (loaded && related && related?.length === 0) {
    return <></>;
  }
  return (
    <div className="related-ads-block pt-lg-4 pb-lg-5 py-4">
      <div className="container">
        <h5>Related Ads</h5>

        <SlickSlider
          settings={{
            dots: false,
            infinite: false,
            arrows: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerPadding: '10px',
            // prevArrow: (
            //   <div className="position-absolute bg-white rounded-circle shadow d-flex align-items-center justify-content-center">
            //     <i className="icon-prev big"></i>
            //   </div>
            // ),
            // nextArrow: (
            //   <div className="slick-arrow slick-arrow-next position-absolute bg-white rounded-circle shadow d-flex align-items-center justify-content-center">
            //     <i className="icon-next big"></i>
            //   </div>
            // ),
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                  arrows: false,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  arrows: false,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  arrows: false,
                },
              },
            ],
          }}
          className="related-ads"
        >
          {related.map((product) => {
            return (
              <ProductTile
                target="_blank"
                className="col d-flex m-0 p-0"
                product={product}
              />
            );
          })}
        </SlickSlider>
      </div>
    </div>
  );
};

export default RelatedAds;
