import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import api from "../../../api";
import { AccountType, PaymentType } from "./payment";
import { useHistory } from "react-router-dom";
import "./stripe.scss"

export interface PaymentDetails {
  isAuction?: boolean;
  isReccuring?: boolean;
  email: string;
  tokenId?: string;
  invoicenumber?: string;
  accountType: AccountType;
  amount?: number;
  subscriptionPlanId?: string;
  isCustomPlan?: boolean;
  paymentType: PaymentType;
  isSinglePayment?: boolean;
  productId?: string;
  successhandler: (payment: any, stripePaymentToken?: any) => void;
}

const CheckoutForm: React.FunctionComponent<PaymentDetails> = (props: PaymentDetails) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<string | boolean>('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  useEffect(() => {
    createPaymentIntent(props)
  }, []);

  const createPaymentIntent = async (args: PaymentDetails) => {
    const data = await api.payment.createPaymentIntent(args)
    setClientSecret(data?.clientSecret);
    setPaymentId(data?.paymentId)

  }
  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: { preventDefault: () => void; }) => {
    ev.preventDefault();
    setProcessing(true);

    if (elements?.getElement(CardElement)) {
      const card = elements.getElement(CardElement)
      if (card) {
        if (props?.isAuction) {
          const token = await stripe?.createToken(card);
          props.successhandler(token)
          setError(null);
          // setProcessing(false);
          setSucceeded(true);
        } else {
          const payload = await stripe?.confirmCardPayment(clientSecret, {
            payment_method: {
              card,
            }
          });

          if (payload?.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
          } else {
            await api.payment.processPayment(paymentId, payload).then(async () => {
              if (props?.isReccuring) {
                const token = await stripe?.createToken(card);
                props.successhandler(paymentId, token)
              } else {
                props.successhandler(paymentId)
              }
            })
            setError(null);
            // setProcessing(false);
            setSucceeded(true);
          }
        }
      }
    }
  }

  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none  align-items-center row">
        <i className="icon-prev font-lg mr-3" onClick={() => history.goBack()} ></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
          Enter your card details
        </h6>
      </div>
      <div className="post-col pt-4 pt-lg-0">
        <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center pb-3">
          <h3 className="m-0 text-center text-gray-900">Enter your card details</h3>
        </div>

        <form id="payment-form" className="rounded" onSubmit={handleSubmit}>
          <div className="form-inner">
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            {error && (
              <div className="card-error error" role="alert">
                {error}
              </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
              Payment succeeded, you will be redirected.
            </p>
            <button
              disabled={!!(processing || disabled || succeeded)}
              id="submit"
            >
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
            {/* Show any error that happens when processing the payment */}
          </div>
        </form>
      </div>
    </>
  );
}
export default CheckoutForm;
