import React from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";

interface CustomProps {
    showModal: boolean;
    onClick: () => void;
    onSubmit: () => void;
    phone: string;
    phoneNumber: string;
    phoneApproved:any;
  }

const SharePhoneNo: React.FunctionComponent<CustomProps> = (props: CustomProps) => { 
  const { control, handleSubmit, errors, setValue, watch } = useForm(
        {
          reValidateMode: 'onChange',
          mode: 'onSubmit',
        }
      );
    const auth = useAuth();

    const formSubmit = () => {
        props.onSubmit()
    }    
    return (
        <>
          <div className={`modal fade show ${props.showModal ? 'd-block' : ''}`}>
            <div className="modal-dialog  md mx-lg-auto my-lg-4 m-0">
          
                 
              <div className="modal-content p-lg-3">
              <button
                type="button"
                className="btn m-3 p-0 position-absolute right-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onClick()}
              >
                    <i className="icon-close"></i>
                  </button>
                  <div className="p-4">
                  
                    {props.phoneApproved &&(
                      <>
                      <h6>SELLER CONTACT NEMBER</h6>
                      <a href="tel:{props.phoneNumber}"className="font-weight-bold text-dark font-lg">{props.phoneNumber}</a>
                      </>
                    )}
                    {!props.phoneApproved &&(
                      <>
                      <h6>REQUEST NUMBER</h6>
                      <p>To request the seller's contact, you need to share your details with DealDen  for security purpose.</p>
                      
                    <form onSubmit={handleSubmit(formSubmit)}>
                    <button type="submit" className="btn btn-primary font-weight-bold  col-12 col-sm-auto px-5 py-2 profile-edit-save">
                      Submit
                    </button>
                      <div className="col-lg-6 mx--lg-auto px-0">
                      </div>
                    </form>
                    </>
                    )}
                    
                  </div>
                </div>
                </div>
                </div>
                {props.showModal && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default SharePhoneNo;