/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useCallback } from 'react';

const useWindowSrollHandler = (
  onScroll: (direction: 'pageUp' | 'pageDown') => void,
  deps: any[]
) => {
  let ticking: boolean = false;

  const triggerPageChange = () => {
    const el = document.querySelector('footer') as HTMLElement;
    if (window.scrollY >= el.offsetTop - window.innerHeight) {
      onScroll('pageDown');
    } else if (Math.ceil(window.pageYOffset) === 0) {
      onScroll('pageUp');
    }

    // if (
    //   window.innerHeight + Math.ceil(window.pageYOffset + 1) >=
    //   document.body.offsetHeight - 80
    // ) {
    //   onScroll('pageDown');
    // } else if (Math.ceil(window.pageYOffset) === 0) {
    //   onScroll('pageUp');
    // }
  };

  const handleScroll = useCallback(() => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        triggerPageChange();
        ticking = false;
      });
      ticking = true;
    }
  }, [ticking, window, triggerPageChange]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, ...deps]);
};

export default useWindowSrollHandler;
