import { number } from 'prop-types';
import React, { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import { usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import {
  Category,
  CreatePostFormProps,
  MediaFile,
  Product,
  ProductType,
  Subcategory,
} from '../../../api/models';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';
import SwitchInput from '../../../components/form-group/switch-input';
import SelectInput from '../../../components/select-input';
import FileUploadController from '../../../components/upload/FileUploadController';
import AttributeInputController from '../../attributes';
import { Multiselect } from 'react-widgets';
interface DefaultImage {
  index: number;
  src: string;
}
interface Exchangeables {
  name: string;
  id: number;
}

const CreatePostSummary: React.FunctionComponent<{
  onSubmit: (data: any) => void;
  product: Product;
  category?: Category;
  subcategory?: Subcategory;
  onBackClick: () => void;
  productType: ProductType;
  isExchange?: boolean;
  exchangeables?: string[];
  //   onClearCategory: () => void;
}> = (props) => {
  const [locationError, setLocationError] = useState<boolean>(false);
  const [isNegotiable, setIsNegotiable] = useState<boolean>(false);
  const [isExchange, setIsExchange] = useState<boolean>(
    props.isExchange || false
  );
  const [exchangeables, setExchangeables] = useState<string[]>(
    props.exchangeables || []
  );
  const [exchangeableValue, setExchangeableValue] = useState<string>('');
  const [defaultImage, setDefaultImage] = useState<DefaultImage>({
    index: 0,
    src:
      props.product && props.product.images ? props.product.images[0].path : '',
  });
  const { control, setValue, errors, handleSubmit } = useForm<
    CreatePostFormProps
  >({
    shouldFocusError: true,
  });

  const createPost = () => {
    const data = {
      ...props.product,
    };
    data.isExchange = isExchange;
    data.isNegotiable = isNegotiable;
    data.exchangeables = exchangeables.map((obj) => {
      return obj;
    });
    data.defaultImage = props.product.images?.[defaultImage.index];
    props.onSubmit(data);
  };
  const handleCreate = (name: string) => {
    const newOption = {
      name,
      id: exchangeables.length + 1,
    };
    setExchangeables([...exchangeables, name]);
  };
  const renderBreadCrumbs = () => {
    return (
      <div className="breadcrumb-post mt-lg-4 border-bottom pb-3">
        <nav aria-label="breadcrumb ">
          <ol className="breadcrumb m-0 rounded-0 bg-white px-0">
            <li className="breadcrumb-item">
              <a href="#" className="font-normal">
                {props.category?.title}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" className="font-normal">
                {props.subcategory?.title}
              </a>
            </li>
            {/* <li
              className="ml-4 change text-primary cursor-pointer border-bottom border-primary font-normal"
              onClick={props.onClearCategory}
            >
              Change
            </li> */}
          </ol>
        </nav>
      </div>
    );
  };
  const renderCreateButton = () => {
    return (
      <div className="bottom-0 main-button row">
        <div className="col-md-4">
          <input
            type="submit"
            className="btn py-2 btn-primary font-weight-bold btn-block"
            value="Update"
          />
        </div>
      </div>
    );
  };
  const renderImages = () => {
    return (
      <div className="row gutter-5 summary-images pt-2 pt-lg-4 mb-3">
        {props.product?.images &&
          props.product?.images
            .filter((_, index) => index < 5)
            .map((im, index) => (
              <div className="col-md-3 col-sm-4 col-6 position-relative mb-2">
                <img
                  src={im.path}
                  className="img-fluid img-cover w-100 rounded"
                  alt=""
                  onClick={() => setDefaultImage({ index, src: im.path })}
                />
                {defaultImage.index === index && (
                  <span className="text-white rounded position-absolute font-xs px-1 text-nowrap main-image">
                    Main Image
                  </span>
                )}
              </div>
            ))}
      </div>
    );
  };
  const renderExchange = () => {
    return (
      <div className="py-2">
        <div className="form-group mb-3">
          <div className="d-flex align-items-center mb-4">
            <label className="font-normal text-gray-500">Exchange</label>
            <Controller
              name={`isExchange`}
              control={control}
              defaultValue={isExchange}
              render={({ onChange, name, value }) => {
                return (
                  <SwitchInput
                    key={`isExchange`}
                    onChange={(val) => {
                      onChange(val);
                      setIsExchange(val);
                    }}
                    default={value}
                    name={name}
                    error={errors?.isExchange?.message}
                  ></SwitchInput>
                );
              }}
            ></Controller>
          </div>
          {isExchange && (
            <Multiselect
              filter={'startsWith'}
              data={exchangeables}
              defaultValue={exchangeables}
              allowCreate={'onFilter'}
              onCreate={(name) => handleCreate(name)}
              onChange={(value) => setExchangeables([...value])}
              textField="name"
            />
          )}
        </div>
      </div>
    );
  };
  const renderNegotiable = () => {
    return (
      <div className="d-flex align-items-center py-3">
        <h5 className="text-gray-500 font-normal font-weight-normal mb-3">
          Negotiable
        </h5>
        <div className="confirm-location d-flex ml-auto">
          <div className="form-check custom-radio pl-0">
            <Controller
              name={`isNegotiable`}
              control={control}
              defaultValue={isNegotiable}
              render={({ onChange, name, value }) => {
                return (
                  <SwitchInput
                    key={`isNegotiable`}
                    onChange={(val) => {
                      onChange(val);
                      setIsNegotiable(val);
                    }}
                    default={value}
                    name={name}
                    error={errors?.isNegotiable?.message}
                  ></SwitchInput>
                );
              }}
            ></Controller>
          </div>
        </div>
      </div>
    );
  };
  const renderAttributes = (key: string) => {
    const result = props.subcategory?.properties?.filter((pro) => {
      return pro.key === key;
    });
    {
      return (
        result &&
        result.length > 0 && (
          <>
            <div className="col-3 text-gray-500 mb-3">{result[0].label}</div>
          </>
        )
      );
    }
  };
  const renderProductType = () => {
    if (props.productType === 'auction') {
      return 'Auction';
    }
    if (props.productType === 'sale') {
      return 'Sale';
    }
    if (props.productType === 'lead') {
      return 'Lead Generation';
    }
    if (props.productType === 'branding') {
      return 'BrandAwareness';
    }
  };
  return (
    <>
      <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center">
        <a
          className="font-sm mr-3 mt-2 cursor-pointer position-absolute right-0 top-0"
          onClick={() => props.onBackClick()}
        >
          <i className="font-xxxs mr-1">
            <small className="icon-prev"></small>
          </i>
          Back
        </a>
        <h3 className="m-0 text-center text-gray-900">Summary</h3>
      </div>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i className="icon-prev font-lg mr-3"></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
          Summary
        </h6>
      </div>
      <div className="post-product-form">
        <div className="container">
          <div className="row d-flex justify-content-center my-lg-4">
            <div className="col-lg-auto col-12 post-col">
              <div className="post-product-inner rounded bg-white">
                <div className="selected-category border-bottom pb-3 d-none d-lg-block">
                  <h5 className="text-gray-900 m-0 mb-3">Summary</h5>
                  {renderBreadCrumbs()}
                </div>
                <form
                  onSubmit={handleSubmit(createPost, (err) => {
                    toastr.error('Error', 'Please correct errors in form');
                  })}
                >
                  <div className="block border-bottom">
                    {renderImages()}
                    <div className="bg-primary d-inline-block font-sm mb-3 px-2 rounded-pill text-white">
                      {renderProductType()}
                    </div>
                    <h6 className="font-base text-gray-900 mb-1">
                      {props.product.title}
                    </h6>
                    <div className="font-normal text-gray-500 mb-4 pb-2">
                      <i className="icon-location mr-1 font-xxs"></i>
                      {props.product.location?.address}
                    </div>
                    <h6 className="font-base text-gray-700 mb-3">Details</h6>
                    <div className="border-bottom pb-2 mb-4">
                      <div className="row font-normal">
                        <div className="col-3 text-gray-500 mb-3">Brand</div>
                        <div className="col-9 text-gray-700 mb-3 font-weight-semibold">
                          {props.product.brand?.title}
                        </div>
                        {props.product.customAttributes &&
                          Object.keys(props.product.customAttributes).map(
                            (key, index) => {
                              return (
                                <>
                                  {renderAttributes(key.toString())}
                                  <div className="col-9 text-gray-700 mb-3 font-weight-semibold">
                                    {props.product.customAttributes![key]}
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    </div>
                    <div className="border-bottom pb-2 mb-4">
                      <h6 className="font-base text-gray-700 mb-2">
                        Description
                      </h6>
                      <p className="font-normal text-gray-500">
                        {props.product.description}
                      </p>
                    </div>
                    <div className="border-bottom py-3 d-flex no-gutters font-normal align-items-center">
                      <div className="col-6 pr-3 text-gray-500">
                        Product Category
                      </div>
                      <div className="col-6 text-right text-primary">
                        {props.category?.title}-{props.subcategory?.title}
                        <i className="icon-next ml-md-3 ml-2 text-gray-700 font-xxxs"></i>
                      </div>
                    </div>
                    <div className="border-bottom py-3 d-flex no-gutters font-normal align-items-center">
                      <div className="col-6 pr-3 text-gray-500">Price</div>
                      <div className="col-6 text-right text-gray-900 font-weight-semibold font-base">
                        ${props.product.price?.toFixed(2)}
                      </div>
                    </div>
                    {renderNegotiable()}
                  </div>
                  {renderExchange()}
                  {renderCreateButton()}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePostSummary;
