import React, { useState } from 'react';
import { LatLng, Product } from '../../../api/models/catalog';
// import GoogleMapView from '../../../components/map/GoogleMapView';
import Map from '../../../components/google-autocomplete/GoogleAutoComplete';
import InfoWindow from '../../../components/map/InfoWindow';
import Marker from '../../../components/map/Marker';
import ProductCard from '../../search/ProductCard';

const LocationView: React.FunctionComponent<{ product: Product }> = (props) => {
  const markerProduct = [];
  markerProduct.push(props.product);
  const center: LatLng = {
    lat: 24.0877465,
    lng: -76.5935076,
  };
  // const center = props.product.location?.location?.coordinates
  //   ? {
  //     lat: props.product.location?.location?.coordinates[0] || 0,
  //     lng: props.product.location?.location?.coordinates[1] || 0,
  //   }
  //   : { lat: 0, lng: 0 };
  const latLong: LatLng = props.product.location?.location?.coordinates
    ? {
        lat: props.product.location.location.coordinates[0],
        lng: props.product.location.location.coordinates[1],
      }
    : { lat: 24.0877465, lng: -76.5935076 };

  const [showCard, setshowCard] = useState(false);
  const toggleProductCard = () => {
    setshowCard((v) => !v);
  };
  const { product } = props;
  return (
    // <GoogleMapView
    //   id="listing-map-view"
    //   center={center}
    //   lat={center?.lat}
    //   lng={center?.lng}
    //   zoom={10}
    //   containerStyle={{ width: '100%', height: '208px' }}
    // >
    //   {markerProduct?.map((pr: Product) => {
    //     const latLng = pr.location?.location?.coordinates
    //       ? {
    //         lat: pr.location?.location?.coordinates[0],
    //         lng: pr.location?.location?.coordinates[1],
    //       }
    //       : { lat: 0, lng: 0 };

    //     return (
    //       <Marker key={pr._id} {...latLng} onClick={() => toggleProductCard()}>
    //         <span className="bg-white p-2 rounded font-lg">${pr.price}</span>
    //       </Marker>
    //     );
    //   })}
    //   {showCard && (
    //     <InfoWindow {...latLong}>
    //       <ProductCard product={props.product} />
    //     </InfoWindow>
    //   )}
    // </GoogleMapView>
    <Map
      name="locationMap"
      id="locationMap"
      center={latLong}
      lat={latLong?.lat}
      lng={latLong?.lng}
      height="280px"
      zoom={10}
      city={product.location?.city}
      street={product.location?.street}
      state={product.location?.state}
      draggable="false"
      value={`${product.location?.street},${product.location?.city},${product.location?.state},${product.location?.country}`}
    />
  );
};

export default LocationView;
