import React, { useState, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import {
  Category,
  CreatePostFormProps,
  MediaFile,
  Subcategory,
} from '../../../api/models';
import SelectInput from '../../../components/select-input';
import FileUploadController from '../../../components/upload/FileUploadController';
import AttributeInputController from '../../attributes';
import GoogleAutocompleteController from './GoogleAutoComplete';
import { ProductType } from './ProductTypeTabs';
import classnames from 'classnames';
export enum LocationType {
  CurrentLocation = 'current_location',
  SearchLocation = 'search_location',
}

const CreatePostForm: React.FunctionComponent<{
  onSubmit: (data: CreatePostFormProps) => void;
  category: Category;
  subcategory: Subcategory;
  onClearCategory: () => void;
  productDetails?: CreatePostFormProps;
}> = (props) => {
  const [locationError, setLocationError] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [locationType, setLocationType] = useState<LocationType>(
    LocationType.SearchLocation
  );
  const [showImageSection, setShowImageSection] = useState<boolean>(false);
  const [inputSection, setInputSection] = useState<boolean>(true);
  const [locationSection, setLocationSection] = useState<boolean>(false);
  const location = {
    location: { coordinates: [24.7063805, -78.0195387] },
    address: 'North Andros, The Bahamas',
    street: undefined,
    city: undefined,
    state: 'North Andros',
    country: 'The Bahamas',
  };

  const [brand, setBrand] = useState<{ title: string; _id: string }>({
    _id: props.productDetails?.brandId || '',
    title: props.productDetails?.brand || '',
  });
  const { control, setValue, errors, handleSubmit, watch, getValues } = useForm<
    CreatePostFormProps
  >({
    shouldFocusError: true,
    defaultValues: {
      // title: formData?.title || '',
      // description: formData?.description || '',
      productType: ProductType.Sale,
      images: props?.productDetails?.images || Array(1).fill({}),
      location_address: location,
    },
  });
  const watchImages =
    watch('images').length && watch('images')[0].fieldname
      ? [...watch('images'), {}]
      : watch('images');
  const [imagesArray, setImagesArray] = useState<any[]>(watchImages);

  const { fields: images } = useFieldArray({
    name: 'images',
    control,
  });
  const onImageArrayValueSelect = (a: any, b: any, index: number) => {
    const newImagesArray = [...imagesArray];
    if (b === undefined) {
      newImagesArray.splice(index, 1);
      setImagesArray([
        ...newImagesArray.filter((obj) =>
          obj.b
            ? obj.b.hasOwnProperty('fieldname')
            : obj.hasOwnProperty('fieldname')
        ),
        {},
      ]);
    } else {
      setImagesArray([...imagesArray, { a, b }]);
    }
  };
  const createPost = (
    postData: Omit<CreatePostFormProps, 'images'> & {
      images: { image: MediaFile }[];
    }
  ) => {
    const data = {
      ...postData,
      images:
        postData.images?.filter((im) => im.image)?.map((im) => im.image) || [],
      categoryId: props.category._id,
      subcategoryId: props.subcategory._id,
    };
    data.selectedBrand = brand;
    data.images = data.images.filter((obj) => obj.hasOwnProperty('fieldname'));
    data.price = Number(data.price);
    if (data.images.length === 0 || imagesArray.length === 0) {
      setShowImageSection(true);
      setInputSection(false);
      setLocationSection(false);
      setImageError(true);
      // toastr.error('Error', 'Please correct errors in form');
      return;
    }
    if (!data.location_address) {
      // setShowImageSection(false);
      // setInputSection(false);
      // setLocationSection(true);
      // setLocationError(true);
      data.location_address = location;
      // toastr.error('Error', 'Please correct errors in form');
      // return;
    }

    // data.location = data.location_address.location.coordinates;
    // data.location = data.location_address.location.coordinates;
    data.location = {
      street: data.location_address?.street,
      suburb: data.location_address?.suburb,
      city: data.location_address?.city,
      state: data.location_address?.state,
      country: data.location_address?.country,
      address: data.location_address?.address,
      location: {
        type: 'Point',
        coordinates: data.location_address.location.coordinates,
      },
    };
    delete data.location_address;
    data.brandId = brand._id;
    delete data.brand;
    props.onSubmit(data);
  };

  const renderBreadCrumbs = () => {
    return (
      <div className="breadcrumb-post mt-lg-4 border-bottom pb-3 ">
        <nav aria-label="breadcrumb ">
          <ol className="breadcrumb m-0 rounded-0 bg-white px-0">
            <li className="breadcrumb-item">
              <a href="#" className="font-normal">
                {props.category.title}
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="#" className="font-normal">
                {props.subcategory.title}
              </a>
            </li>
            <li
              className="ml-4 change text-primary cursor-pointer border-bottom border-primary font-normal"
              onClick={props.onClearCategory}
            >
              Change
            </li>
          </ol>
        </nav>
      </div>
    );
  };

  const renderTitleInput = () => {
    return (
      <Controller
        control={control}
        name="title"
        rules={{ required: 'Ad Title is required' }}
        defaultValue={props.productDetails?.title}
        render={({ onChange, name, value }) => {
          return (
            <div className="form-group mb-4">
              <label className="mb-1">
                Ad title <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control bg-white"
                onChange={onChange}
                name={name}
                value={value}
              />
              <span className="d-block text-muted font-xxs">
                Mention the key features of your item (e.g. brand, model, age,
                type)
              </span>
              <span className="error">{errors?.title?.message}</span>
            </div>
          );
        }}
      ></Controller>
    );
  };

  const renderDescriptionInput = () => {
    return (
      <Controller
        control={control}
        name="description"
        rules={{ required: 'Description is required' }}
        defaultValue={props.productDetails?.description}
        render={({ onChange, name, value }) => {
          return (
            <div className="form-group mb-2">
              <label className="mb-1">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control bg-white"
                rows={4}
                onChange={onChange}
                name={name}
                value={value}
              ></textarea>
              <span className="text-muted font-xxs">
                Include condition, features and reason for selling
              </span>
              <span className="error">{errors?.description?.message}</span>
            </div>
          );
        }}
      ></Controller>
    );
  };

  const brandRequired = () => {
     if(props.subcategory?.brandRequired == true){
     
     }
    
  };


  const renderBrandInput = () => {


    return (
      <Controller
        control={control}
        name="brand"
        // rules={{ required: 'Brand is required' }}
        rules={{ required: props.subcategory?.brandRequired? "Brand is required":false  }}
        defaultValue={props.productDetails?.selectedBrand?._id}
        render={({ onChange, name, value }) => {
          return (
            <div className="form-group mb-4">
              <label className="mb-1">
                Brand {props.subcategory?.brandRequired == true  && (
                  <span className="text-danger">*</span>
                )}
                <span className="tooltip ml-1 position-relative">
                  <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
                    ?
                  </span>
                  <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
                    Please select other or N/A if your brand is not listed or
                    not applicable to a brand because it's a services, event or
                    intangible product.
                  </span>
                </span>
              </label>
              <SelectInput
                // isMulti
                name={name}
                api={'brand/brands'}
                onChange={(val, selected) => {
                  onChange(val);
                  setBrand(selected);
                }}
                default={value}
                optionLabel={'title'}
                optionValue={'_id'}
                queryParams={{ subcategoryId: props.subcategory._id }}
              />
              <span className="error">{errors?.brand?.message}</span>
            </div>
          );
        }}
      ></Controller>
    );
  };

  const renderPriceInput = () => {
    return (
      <Controller
        name="price"
        control={control}
        rules={{
          required: 'Price is required',
          validate: {
            isValid: (value) => !isNaN(value) || 'Invalid Price',
            minValue: (value) => value > 0 || 'Prices should be greater than 0',
          },
        }}
        defaultValue={props.productDetails?.price}
        render={({ onChange, name, value }) => {
          return (
            <div className="pt-4 border-top price-outer">
              <h5 className="text-gray-900">Set a Price</h5>
              <div className="form-group mb-3">
                <label className="mb-1">
                  Price <span className="text-danger">*</span>
                </label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend  ">
                    <div className="input-group-text bg-white pr-0">$</div>
                  </div>
                  <input
                    type="number"
                    className="form-control bg-white border-left-0 pl-1"
                    onChange={onChange}
                    name={name}
                    value={value}
                  />
                </div>
                <span className="error">{errors?.price?.message}</span>
              </div>
            </div>
          );
        }}
      ></Controller>
    );
  };
  const renderImages = () => {
    return (
      <div
        className={classnames({
          'block border-bottom p-lg-4': true,
          active: showImageSection,
        })}
        id="upload-photo"
      >
        <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
          <i
            className="icon-prev font-lg mr-3"
            onClick={() => {
              setInputSection(true);
              setShowImageSection(false);
              // setLocationSection(false);
            }}
          ></i>
          <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white ">
            Upload Photos
          </h6>
        </div>
        <h5 className="text-gray-900 d-none d-lg-block">
          Upload Photos (max 8)
        </h5>
        <div className="uploading-group pt-3 pb-2">
          <div className="gutter-7 row savedimages">
            {imagesArray
              .filter((_, index) => index < 8)
              .map((val: any, index: number) => {
                return (
                  <div className="col-6 col-md-3 mb-3">
                    <FileUploadController
                      control={control}
                      // className="rounded"
                      value={val}
                      title="Add Photo (max 2MB)"
                      errorMsg="Upload photo"
                      setValue={(a, b) => {
                        setValue(a, b);
                        onImageArrayValueSelect(a, b, index);
                      }}
                      name={`images[${index}].image`}
                      error={
                        (errors as any)?.[`images[${index}]`]?.image?.message
                      }
                    />{' '}
                  </div>
                );
              })}

            {/* <div className="col-6 col-md-3 mb-3">
              <div className="border p-1 position-relative  text-center cursor-pointer h-100 error">
                <figure className="align-items-center bg-gray-200 d-flex flex-column h-100 justify-content-center m-0 overflow-hidden font-weight-semibold">
                  <img src={addPhoto} className="img-fluid" />
                  <span className="normal">Add Image</span>
                  <span className="font-sm text-danger error-msg">
                    Add at least one image
                  </span>
                </figure>
              </div>
            </div> */}
          </div>
        </div>
        {imageError && (
          <span className="error">Please choose atleast an image</span>
        )}
        <div className="bottom-0 position-sticky row">
          <input
            type="submit"
            className="btn btn-block btn-primary d-lg-none rounded-0 upload-photo"
            value="Next"
          />
        </div>
      </div>
    );
  };

  const renderAttributes = () => {
    return (
      <>
        {props.subcategory.properties?.map((pro) => {
          return (
            <AttributeInputController
              attribute={pro}
              control={control}
              errors={errors}
              prefix="customAttributes."
              defaultValue={
                props?.productDetails?.customAttributes
                  ? props?.productDetails?.customAttributes[pro.key]
                  : ''
              }
              defaultMultiValue={
                props?.productDetails?.customAttributes
                  ? props?.productDetails?.customAttributes[pro.key]
                  : ''
              }
            ></AttributeInputController>
          );
        })}
      </>
    );
  };
  const renderLocation = () => {
    return (
      <>
        <div
          className={classnames({
            'block  p-lg-4': true,
            active: locationSection,
          })}
          id="choose-location"
        >
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
            <i
              className="icon-prev font-lg mr-3"
              onClick={() => {
                setInputSection(false);
                setShowImageSection(true);
                setLocationSection(false);
              }}
            ></i>
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white ">
              Confirm your Location
            </h6>
          </div>
          <h5 className="text-gray-900 d-none d-lg-block">
            Confirm your Location
          </h5>
          <div className="py-4 py-lg-3 ">
            <Controller
              control={control}
              // rules={{
              //   required: 'Address is required',
              // }}
              defaultValue={location}
              name="location_address"
              render={({ onChange, name, value }) => {
                return (
                  <>
                    <div className="RadioSelect p-0 m-0 ">
                      <input
                        type="radio"
                        id="search-location"
                        name="search_location"
                        value={locationType}
                        onChange={() =>
                          setLocationType(LocationType.SearchLocation)
                        }
                        checked={locationType === LocationType.SearchLocation}
                      />{' '}
                      <label htmlFor="search-location" className="mr-3 mb-3">
                        Search for your Location
                      </label>
                      <input
                        type="radio"
                        id="current-location"
                        name="current_location"
                        value={locationType}
                        onChange={() => {
                          setLocationType(LocationType.CurrentLocation);
                        }}
                        checked={locationType === LocationType.CurrentLocation}
                      />{' '}
                      <label htmlFor="current-location" className="mb-3">
                        Use Current Location
                      </label>
                    </div>
                    <GoogleAutocompleteController
                      name={name}
                      onChange={onChange}
                      control={control}
                      value={value}
                      locationType={locationType}
                    />
                  </>
                );
              }}
            ></Controller>
            {locationError && (
              <span className="error">Please choose a location</span>
            )}
          </div>
          {renderCreateButton()}
        </div>
      </>
    );
  };

  const renderCreateButton = () => {
    return (
      <div className="bottom-0 main-button row m-lg-0">
        <div className="col-md-4 px-0 ">
          <input
            type="submit"
            className="btn py-2 btn-primary font-weight-bold btn-block"
            value="Post Now"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center">
        <a
          onClick={props.onClearCategory}
          className="font-sm mr-3 mt-2 cursor-pointer position-absolute right-0 top-0"
        >
          <i className="font-xxxs mr-1">
            <small className="icon-prev"></small>
          </i>
          Back
        </a>
        <h3 className="m-0 text-center text-gray-900">Post Your Ad</h3>
      </div>
      <div className="post-product-form pt-lg-4 mb-lg-4">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-auto col-12 post-col">
              <div className="post-product-inner rounded bg-white">
                <div className="selected-category border-bottom pb-3 d-none d-lg-block">
                  <h5 className="text-gray-900 m-0 mb-3">Product Details</h5>
                  {renderBreadCrumbs()}
                </div>

                <form
                  onSubmit={handleSubmit(createPost, (err) => {
                    toastr.error('Error', 'Please correct errors in form');
                  })}
                  className="createform"
                >
                  <div
                    className={classnames({
                      'block border-bottom p-lg-4 basic-from': true,
                      active: inputSection,
                    })}
                  >
                    <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
                      <i
                        className="icon-prev font-lg mr-3"
                        onClick={props.onClearCategory}
                      ></i>
                      <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white ">
                        Include Some Details
                      </h6>
                    </div>
                    <h5 className="text-gray-900 d-none d-lg-block">
                      Include Some Details
                    </h5>
                    <div className="py-4 py-lg-0">
                      {renderTitleInput()}
                      {renderDescriptionInput()}
                      {renderBrandInput()}
                      {renderAttributes()}
                      {renderPriceInput()}
                    </div>
                    <div className="bottom-0 position-sticky row">
                      <input
                        type="submit"
                        className="btn btn-block btn-primary d-lg-none rounded-0 upload-photo"
                        value="Next"
                      />
                      {/* <input type="" className="btn btn-block btn-primary d-lg-none rounded-0 upload-photo">
                        Next */}
                      {/* <a
                        onClick={}
                        className="btn btn-block btn-primary d-lg-none rounded-0 upload-photo"
                      >
                        Next
                      </a> */}
                    </div>
                  </div>
                  {renderImages()}
                  {renderLocation()}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePostForm;
