import React from 'react';
import { RouteKeys } from '../../routes/route-keys';

const SignupBanner: React.FunctionComponent = () => {
  return (
    <div className="text-center mx-auto white">
      <h1 className="mb-3 font-weight-bold text-white">Hello, Friend</h1>
      <h6 className="mb-4 font-weight-normal text-white">
        Enter your personal details and
        <br />
        Start making money from selling!
      </h6>
      <a
        href={RouteKeys.Signup}
        className="btn-outline-secondary  btn-block btn font-weight-bold mt-5"
      >
        Sign Up
      </a>
    </div>
  );
};

export default SignupBanner;
