import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContainerLoader } from '../../components/loader';

import { RouteKeys } from './route-keys';

import SignupScreen from '../signup';
import Home from '../home';
import NotFound from '../notfound';
import LoginScreen from '../login';
import LoginWithPhoneScreen from '../login-with-phone';
import LoginOtpScreen from '../signup-otp';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import OnBoardScreen from '../on-board';
import Search from '../search';
import ProductDetailsScreen from '../product-details';
import ForgotPasswordScreen from '../forgot-password';
import ForgotPasswordOtpScreen from '../forgot-password-otp';
import CreatePostScreen from '../create';
import LoginWithPhoneOtpScreen from '../login-with-phone-otp';
import SignupWithPhoneScreen from '../signup-with-phone';
import ChoosePlanScreen from '../choose-plan';
import Profile, { PageType } from '../profile';
import Bank from '../bank';
import UpdatePhone from '../update-phone';
import MyAdsScreen from '../my-ads';
import MyAuctionScreen from '../my-auction';
import ChatScreen from '../chat';
import MyOrdersScreen from '../my-orders';
import MyEnquiriesScreen from '../my-enquiry';
import InvoicesScreen from '../invoices';
import BoostBackScreen from '../boost-back';
import TopFeaturedPlansScreen from '../top-featured-plans';
import CustomPlanScreen from '../custom-plan';
import NotificationScreen from '../notification';
import SettingsScreen from '../settings';
import Subscription from '../subscription';
import Payout from '../invoices/components/payout';
import ChooseInterestedCategories from '../on-board/components/choose-interested-categories';
import ResponsiveMenu from '../common/responsive-menu';
import ChangePassword from '../login-with-phone/components/change-password';
import DeliverProduct from '../my-orders/components/deliver-product';
import EnquireNow from '../product-details/components/enquireNow';
import MyLeads from '../my-leads';
import MyFollowing from '../myFollowing';
import MyOrderDetail from '../order-details';
import BuyProduct from '../buy-product';
import VerifyEmail from '../profile/components/verify-email';
import EditProduct from '../product-edit';
import CmsPage from '../common/cms-pages';
import InvoiceDetails from '../invoices/components/InvoiceDetails';
import ScanQR from '../scan-qr';
import PayoutDetails from '../invoices/components/payout-detail';
import AuthenticateCmsRoute from './AuthenticateCmsRoute';
import ContactUs from '../cms/contact';
import HowItWorks from '../cms/howItWorks';
import PostAnItem from '../cms/postAnItem';
import TrustAndSafety from '../cms/trustAndSafety';
import Business from '../cms/business';
import Career from '../cms/career';
import CommunityGuidelines from '../cms/communityGuidelines';
import PostingRules from '../cms/postingRules';
import ProhibitedItemsGuidelines from '../cms/prohibitedItemsGuidelines';
import MeetupSpots from '../cms/meetupSpots';
import LocalBusiness from '../cms/localBusiness';

class Routes extends React.Component {
  render() {
    return (
      <Suspense fallback={<ContainerLoader height={500} text={'Loading...'} />}>
        <Switch>
          <Route exact path={RouteKeys.Home} component={Home} />
          <Route exact path={RouteKeys.Search} component={Search} />
          <Route exact path={RouteKeys.EnquireNow} component={EnquireNow} />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.Signup}
            component={SignupScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.Login}
            component={LoginScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.SignupOtp}
            component={LoginOtpScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.PhoneLogin}
            component={LoginWithPhoneScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.OnBoard}
            component={OnBoardScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Create}
            component={CreatePostScreen}
          />
          <Route
            exact
            path={RouteKeys.ProductDetails}
            component={ProductDetailsScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.BuyProduct}
            component={BuyProduct}
          />
          {/* <AuthenticateRoute
            exact
            path={RouteKeys.ChoosePlan}
            component={ChoosePlanScreen}
          /> */}
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.ForgotPassword}
            component={ForgotPasswordScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.ForgotPasswordOtp}
            component={ForgotPasswordOtpScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.LoginWithPhoneOtp}
            component={LoginWithPhoneOtpScreen}
          />
          <RedirectIfAuthenticatedRoute
            exact
            path={RouteKeys.PhoneSignup}
            component={SignupWithPhoneScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Profile}
            component={() => <Profile pageType={PageType.Profile} />}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.EditProfile}
            component={() => <Profile pageType={PageType.EditProfile} />}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.EditBankDetails}
            component={() => <Bank />}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.PayoutRequest}
            component={Payout}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.UpdatePhone}
            component={UpdatePhone}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.MyAuction}
            component={MyAuctionScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.MyEnquiries}
            component={MyEnquiriesScreen}
          />
          {/* <AuthenticateRoute
            exact
            path={RouteKeys.MyAdsBrandAwareness}
            component={MyAdsBrandAwarenessScreen}
           />
           <AuthenticateRoute
            exact
            path={RouteKeys.MyAdsLeads}
            component={MyAdsLeadsScreen}
           /> */}
          <AuthenticateRoute
            exact
            path={RouteKeys.MyAds}
            component={MyAdsScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.MyLeads}
            component={MyLeads}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Following}
            component={MyFollowing}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Chats}
            component={ChatScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.ChatsDetail}
            component={ChatScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.MyOrders}
            component={MyOrdersScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.OrderDetail}
            component={MyOrderDetail}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Invoices}
            component={InvoicesScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.BoostBack}
            component={BoostBackScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.TopFeaturedPlans}
            component={TopFeaturedPlansScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.CustomSubscription}
            component={CustomPlanScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Notifications}
            component={NotificationScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Settings}
            component={SettingsScreen}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.Subscription}
            component={Subscription}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.ChooseInterestedCategories}
            component={ChooseInterestedCategories}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.ResponsiveMenu}
            component={ResponsiveMenu}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.DeliverProduct}
            component={DeliverProduct}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.ChangePassword}
            component={ChangePassword}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.EditProduct}
            component={EditProduct}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.InvoiceDetail}
            component={InvoiceDetails}
          />
          <AuthenticateRoute
            exact
            path={RouteKeys.PayoutDetail}
            component={PayoutDetails}
          />
          <AuthenticateRoute exact path={RouteKeys.ScanQR} component={ScanQR} />
          <Route path={RouteKeys.VerifyEmail} component={VerifyEmail} />
          <Route path={RouteKeys.PageDetails} component={CmsPage} />
          <AuthenticateCmsRoute path={RouteKeys.ContactUs} component={ContactUs} />
          <AuthenticateCmsRoute path={RouteKeys.HowItWorks} component={HowItWorks} />
          <AuthenticateCmsRoute path={RouteKeys.PostAnItem} component={PostAnItem} />
          <AuthenticateCmsRoute path={RouteKeys.TrustAndSafety} component={TrustAndSafety} />
          <AuthenticateCmsRoute path={RouteKeys.Business} component={Business} />
          <AuthenticateCmsRoute path={RouteKeys.Career} component={Career} />
          <AuthenticateCmsRoute path={RouteKeys.CommunityGuidelines} component={CommunityGuidelines} />
          <AuthenticateCmsRoute path={RouteKeys.PostingRules} component={PostingRules} />
          <AuthenticateCmsRoute path={RouteKeys.ProhibitedItemsGuidelines} component={ProhibitedItemsGuidelines} />
          <AuthenticateCmsRoute path={RouteKeys.MeetupSpots} component={MeetupSpots} />
          <AuthenticateCmsRoute path={RouteKeys.LocalBusiness} component={LocalBusiness} />
          <Route exact path={RouteKeys.NotFound} component={NotFound} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
