import React, { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PaymentStatuses, PayoutStatuses } from '../../../api/models/leads';
import { DateInput } from '../../../components/form-group';
import SelectInput from '../../../components/select-input';
import { formatDate } from '../../../utils/date-utils';

export interface PaymentFilterModel {
  query: string;
  status: string;
  startDate: string;
  endDate: string;
  page?: number;
}

export enum PayoutStatus {
  Requested = 'requested',
  Processed = 'processed',
  Rejected = 'rejected',
  Pending = 'pending',
}

interface CustomProps {
  onSubmit: (data: PaymentFilterModel) => void;
  searchPlaceHolder: string;
  values?: any;
  initialValues: PaymentFilterModel;
  data?: any;
}

const InitialState = {
  query: '',
  status: '',
  startDate: '',
  endDate: '',
};

const PaymentFilter: FunctionComponent<CustomProps> = (props: CustomProps) => {
  const { control, handleSubmit, errors, setValue, reset, getValues } = useForm<
    PaymentFilterModel
  >({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: props.initialValues || InitialState,
  });
  const endDate = new Date();

  const onFilterSubmit = () => {
    const data = getValues();
    props.onSubmit({
      ...data,
      startDate: data?.startDate
        ? formatDate(data?.startDate, 'MM/DD/yyyy')
        : '',
      endDate: data?.endDate ? formatDate(data?.endDate, 'MM/DD/yyyy') : '',
    });
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.charCode === 13) {
      setTimeout(handleSubmit(onFilterSubmit), 100);
    }
  };

  useEffect(() => {
    Object.keys(InitialState).map((e: string) => {
      if (props?.values?.hasOwnProperty(e)) {
        setValue(e, props.values[e]);
      }
    });
  }, [props.values]);

  return (
    <form noValidate autoComplete="off">
      <div className="product-filter">
        <div className="d-lg-flex justify-content-between mb-3 ">
          {/* <FilterHeading heading={'Invoices'} /> */}
          <div className="d-lg-flex search-field">
            <div className="mt-3 mt-lg-0">
              <div className="row gutter-5">
                <div className="col form-group">
                  <label className="mb-1 text-gray-500">{props.searchPlaceHolder}</label>
                  <Controller
                    control={control}
                    name="query"
                    render={({ value, onChange, name }) => (
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          name={name}
                          value={value}
                          onChange={onChange}
                          className="form-control"
                          onKeyPress={(e: React.KeyboardEvent) => handleKeyPress(e)}
                          placeholder={`Search ${props.searchPlaceHolder}`}
                        />
                        <div className="input-group-append">
                          {getValues().query ? (
                            <button
                              type="button"
                              className="btn bg-white d-flex align-items-center"
                              onClick={() => reset(props.initialValues)}
                            >
                              <i className="icon-close font-xs"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn bg-white d-flex align-items-center"
                              onClick={handleSubmit(onFilterSubmit)}
                            >
                              <i className="icon-search"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  ></Controller>
                </div>
                <div className="col form-group">
                  <label className="mb-1 text-gray-500">Status</label>
                  <Controller
                    control={control}
                    name="status"
                    render={({ onChange, name, value }) => (
                      <SelectInput
                        placeholder="Status"
                        data={props.searchPlaceHolder === 'Payouts' ? PayoutStatuses : PaymentStatuses}
                        onChange={(val, selected) => {
                          onChange(val);
                        }}
                        optionLabel={'name'}
                        optionValue={'id'}
                      />
                    )}
                  ></Controller>
                </div>

                <div className="col form-group">
                  <label className="mb-1 text-gray-500">Start Date</label>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ value, onChange, name }) => (
                      <DateInput
                        key={`product-search-${name}`}
                        placeholder="dd/mm/yyyy"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        error={errors.startDate?.message}
                        default={value}
                        dateFormat="dd/MM/yyyy"
                        maxDate={endDate}
                      ></DateInput>
                    )}
                  ></Controller>
                </div>
                <div className="col form-group">
                  <label className="mb-1 text-gray-500">End Date</label>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ value, onChange, name }) => (
                      <DateInput
                        key={`product-search-${name}`}
                        placeholder="dd/mm/yyyy"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        error={errors.endDate?.message}
                        default={value}
                        dateFormat="dd/MM/yyyy"
                        maxDate={endDate}
                      ></DateInput>
                    )}
                  ></Controller>
                </div>
                <div className="col-auto form-group">
                  <label className="m-0 d-block">&nbsp; </label>
                  <input
                    onClick={handleSubmit(onFilterSubmit)}
                    className="btn btn-primary search-btn"
                    value="Search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentFilter;
