import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTop } from '../../utils/window-utils';
import { PageType } from '../profile';

import { RouteKeys } from '../routes/route-keys';
interface MenuModel {
  to: string;
  icon: string;
  label: string;
  type: any;
}

const Menu: React.FunctionComponent<{ pageType: PageType }> = (props) => {
  const MENUS: MenuModel[] = [
    {
      to: '/profile',
      icon: 'icon-profile',
      label: 'My Profile',
      type: PageType.Profile,
    },
    {
      to: '/my-ads',
      icon: 'icon-my-ads',
      label: 'My Ads',
      type: PageType.MyAds,
    },
    {
      to: '/my-auction',
      icon: 'icon-auction',
      label: 'My Auction',
      type: PageType.MyAuction,
    },
    {
      to: '/my-leads',
      icon: 'icon-leads',
      label: 'My Leads',
      type: PageType.MyLeads,
    },
    {
      to: '/my-orders',
      icon: 'icon-my-orders',
      label: 'My Orders',
      type: PageType.MyOrders,
    },
    {
      to: '/invoices',
      icon: 'icon-invoice',
      label: 'Payments And Payouts',
      type: PageType.Invoices,
    },
    {
      to: '/notifications',
      icon: 'icon-notification1',
      label: 'Notifications',
      type: PageType.Notifications,
    },
    {
      to: '/subscription',
      icon: 'icon-subscription',
      label: 'Subscription',
      type: PageType.Subscription,
    },
    {
      to: '/settings',
      icon: 'icon-settings',
      label: 'Settings',
      type: PageType.Settings,
    },
  ];
  return (
    <ul className="list-unstyled pl-3 pb-4 pt-2">
      {MENUS.map((menu: MenuModel) => (
        <li>
          <Link
            onClick={() => scrollTop()}
            to={menu.to}
            className={`${
              props.pageType === menu.type ? 'active' : ''
            } font-base text-body d-block position-relative`}
          >
            <span className={`${menu.icon} position-absolute`}></span>
            {menu.label}
          </Link>
        </li>
      ))}
      {/* <li>
        <Link
          className="font-base text-body d-block position-relative "
          to="saved-cards.html"
        >
          <span className="icon-save-card position-absolute"></span>
          Saved Cards
        </Link>
      </li>
      <li>
        <Link
          className="font-base text-body d-block position-relative"
          to="insights.html"
        >
          <span className="icon-insights position-absolute"></span>
          Insights
        </Link>
      </li> 
      
      */}
      {/* <li>
        <Link
          className={
            props.pageType === PageType.MyEnquiries
              ? 'font-base text-body d-block position-relative active'
              : 'font-base text-body d-block position-relative'
          }
          to="/my-enquiry"
        >
          <span className="icon-my-orders position-absolute"></span>My Enquiries
        </Link>
      </li> */}
      <li>
        <Link className="font-base text-body d-block position-relative" to="">
          <span className="icon-support position-absolute"></span>Help & Support
        </Link>
      </li>
    </ul>
  );
};

export default Menu;
