import React from 'react';
import sanitizeHtml, { IOptions } from 'sanitize-html';

const SanitizedHTML: React.FunctionComponent<{
  html?: string;
  options?: IOptions;
  className?: string;
}> = (props) => {
  if (!props.html) {
    return <></>;
  }
  const defaultOptions: IOptions = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
    allowedAttributes: {
      p: ['class'],
      a: ['href'],
    },
    allowedIframeHostnames: [],
  };

  const sanitize = (dirty: string, options: IOptions) => ({
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
  });

  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={sanitize(props.html, props.options || {})}
    />
  );
};

export default SanitizedHTML;
