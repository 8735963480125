import React from 'react';
import AppLayout from '../layout/app';
import MyOrdersScreen from './components/my-orders';

const MyOrders: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyOrdersScreen />
    </AppLayout>
  );
};

export default MyOrders;
