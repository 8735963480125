import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LatLng, Subcategory } from '../../../api/models';
import useEntities from '../../../hooks/useEntities';
import useSearch from '../../../hooks/useSearch';
import { RouteKeys } from '../../routes/route-keys';
import Categories from './Categories';
import TopHeader from './TopHeader';
import classnames from 'classnames';

export interface Location {
  latLng: LatLng;
  address: {
    street?: string;
    zip?: number;
    country?: string;
    state?: string;
    city?: string;
  };
}

const Header: React.FunctionComponent<{
  hideCategories?: boolean;
  showHeader?: boolean;
}> = (props) => {
  const entities = useEntities();
  const search = useSearch();
  const [location, setLocation] = useState('');
  const [address, setAddress] = useState<Location>({
    latLng: {
      lat: 0,
      lng: 0,
    },
    address: {},
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const itemSelected = async (categoryId: string) => {
    search.setSearchParams('', location, address, categoryId);
    // history.push({
    //   pathname: RouteKeys.Search,
    //   search: `?category=${categoryId}`,
    // });
  };
  const toggleClassActive = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(-1);
      return;
    }
    setSelectedIndex(index);
  };
  const renderSubcategory = (subcategory: Subcategory, categoryId: string) => {
    return (
      <li className="pb-1">
        <Link
          onClick={() => {
            document.body.classList.remove('open-allcategory');
            itemSelected(categoryId);
          }}
          to={`${RouteKeys.Search}?category=${categoryId}&subCategory=${subcategory._id}`}
          className="d-block  text-gray-700 font-sm"
        >
          {subcategory.title}
        </Link>
      </li>
    );
  };
  const renderCategoriesDropdownResponsive = () => {
    return (
      <div className="bg-white category-responsive d-lg-none h-100 position-fixed top-0 w-100">
        <div className="align-items-center bg-primary d-flex justify-content-between px-3 py-2 category-head">
          <h6 className="text-white py-1 m-0">All Categories</h6>{' '}
          <a
            className="icon-close my-2 text-white"
            onClick={() => document.body.classList.remove('open-allcategory')}
          ></a>
        </div>
        <ul className="m-0 list-unstyled p-0">
          {entities.categoriesWithoutProduct.map((cat, index) => {
            return (
              <li
                className={classnames({
                  'py-2 px-3 border-bottom  main-list': true,
                  active: index === selectedIndex,
                })}
              >
                <div className="position-relative main-category d-flex align-items-center">
                  <Link
                    onClick={() => {
                      document.body.classList.remove('open-allcategory');
                      itemSelected(cat._id);
                    }}
                    to={`${RouteKeys.Search}?category=${cat._id}`}
                    className="d-block  text-gray-700 font-base font-weight-semibold"
                  >
                    {cat.title}
                    <span className="expand-menu  d-lg-none position-absolute right-0 top-0"></span>
                  </Link>
                  <span
                    onClick={() => toggleClassActive(index)}
                    className="expand-menu d-lg-none position-absolute right-0 top-0"
                  ></span>
                </div>
                <ul className="m-0 list-unstyled p-0 sub-cat">
                  {entities
                    .getSubcategories(cat._id)
                    .map((subCategory: Subcategory) =>
                      renderSubcategory(subCategory, cat._id)
                    )}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <header className={props.showHeader ? 'show-header' : 'hide-header'}>
        <TopHeader />
        {!props.hideCategories && <Categories />}
      </header>
      {renderCategoriesDropdownResponsive()}
    </>
  );
};

export default Header;
