import { Product, ProductDetails } from './catalog';
import { MediaFile } from './media';
import { AccountType, CreatedBy } from './user';

export enum PostType {
  City = 'City Post',
  National = 'National Post',
}

export enum OrderStatus {
  Pending = 'pending',
  OfferAccepted = 'offer_accepted',
  Ordered = 'ordered',
  PaymentSuccess = 'payment_received',
  InTransit = 'in_transit',
  Delivered = 'delivered',
}

export interface Order {
  orderStatus: OrderStatus;
  paymentId: string;
  _id: string;
  amount: number;
  createdAt: Date;
  offeredAmount: number;
  productDetails: ProductDetails;
}

export interface RequestCustomPlan {
  subscriptionPlan: string;
}

export enum SubscriptionPeriod {
  Monthly = 'Monthly',
}

export interface SubscribeCustomPlan {
  subscriptionId: string;
  paymentId: string;
}

export interface OrderDetail {
  createdBy: CreatedBy;
  offeredBy: CreatedBy;
  enquiredBy: CreatedBy;
  offeredAmount: number;
  orderStatus: OrderStatus;
  askedAmount?: number;
  finalDifference?: number;
  productId?: string;
  offerId?: string;
  productDetails: MinimalProductDetails;
  acceptedOn?: Date;
  deliveredOn?: Date;
  paymentReference?: string;
  orderId: string;
  paymentId: any;
  messageId?: string;
  chatId?: string;
  createdAt?: Date;
}
export interface MinimalProductDetails {
  id?: string;
  categoryId?: string;
  subcategoryId?: string;
  title?: string;
  description?: string;
  slug?: string;
  defaultImage?: MediaFile;
  images?: MediaFile[];
  createdBy?: CreatedBy;
  price: number;
  image?: string;
}
