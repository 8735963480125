import React, { useEffect, useState } from 'react';
import { Category } from '../../api/models';
import { Product } from '../../api/models/catalog';
import GoogleMapView from '../../components/map/GoogleMapView';
import InfoWindow from '../../components/map/InfoWindow';
import Marker from '../../components/map/Marker';
import ProductCard from './ProductCard';

const MapView: React.FunctionComponent<{
  products: Product[];
  category?: Category;
}> = (props) => {
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  // const center = props.products?.[0]?.location?.location?.coordinates
  //   ?
  //   : { lat: 0, lng: 0 };

  useEffect(() => {
    const coords = props.products?.[0]?.location?.location?.coordinates;
    setCenter({
      lat: coords?.[0] || 0,
      lng: coords?.[1] || 0,
    });
  }, [props.products]);

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const toggleProductSelection = (pr: Product) => {
    if (selectedProduct?._id === pr._id) {
      setSelectedProduct(undefined);
    } else {
      setSelectedProduct(pr);
      setCenter({
        lat: pr.location.location.coordinates[0],
        lng: pr.location.location.coordinates[1],
      });
    }
  };
  const selectedlatLong = selectedProduct?.location?.location?.coordinates
    ? {
        lat: selectedProduct?.location?.location?.coordinates[0],
        lng: selectedProduct?.location?.location?.coordinates[1],
      }
    : { lat: 0, lng: 0 };
  return (
    <GoogleMapView
      id="listing-map-view"
      center={center}
      zoom={10}
      containerStyle={{ width: '100%', height: '700px' }}
    >
      {props.products?.map((pr) => {
        const latLong = pr.location?.location?.coordinates
          ? {
              lat: pr.location?.location?.coordinates[0],
              lng: pr.location?.location?.coordinates[1],
            }
          : { lat: 0, lng: 0 };

        return (
          <Marker
            key={pr._id}
            {...latLong}
            isActive={pr._id === selectedProduct?._id}
            onClick={() => toggleProductSelection(pr)}
          >
            <span
              className={`font-base font-weight-bold py-2 px-3 map-pointer-listing shadow ${
                selectedProduct?._id === pr._id
                  ? 'bg-primary text-white active'
                  : 'bg-white'
              }`}
            >
              ${pr.price}
            </span>
          </Marker>
        );
      })}
      {selectedProduct && (
        <InfoWindow {...selectedlatLong}>
          <ProductCard
            onClose={() => {
              setSelectedProduct(undefined);
            }}
            product={selectedProduct}
            categoryName={props.category?.title || ''}
          />
        </InfoWindow>
      )}
    </GoogleMapView>
  );
};

export default MapView;
