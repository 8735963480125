import AuthLayout from '../layout/auth';
import React from 'react';
import LogoSVG from '../../components/logo/logo-svg';
import LeftPane from './components/left-pane';
import PhoneNumberForm from './components/phone-number-form';
import HelpLink from '../../components/links/help-link';
import useAuth from '../../hooks/useAuth';
import useSignup from '../../hooks/useSignup';

const LoginWithPhoneScreen: React.FunctionComponent = () => {
  const { sendOtp, updating } = useSignup();
  return (
    <AuthLayout>
      <LeftPane />
      <div className="col-lg-7 enterphone-right-box content-box signup p-md-3">
          <a href="/" title="DealDen">
            <LogoSVG className="d-lg-none" />
          </a>
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
        <div className="py-5 py-lg-4 login-form mx-auto">
          <PhoneNumberForm
            onSubmit={(data) => sendOtp(data.phone)}
            isSubmitting={updating}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginWithPhoneScreen;
