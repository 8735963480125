import classnames from 'classnames';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDataLoader } from 'react-remote-data-hooks';
import api from '../../../api';
import { BankDetails, Product } from '../../../api/models';
import useAuth from '../../../hooks/useAuth';
import BankUpdateForm from '../../bank/components/bank-new';

interface CustomProps {
  showModal: boolean;
  onClick: () => void;
  onAddNewBank: (paymentDetails: {
    bankDetails?: BankDetails;
    paypalRecipient?: string;
  }) => void;
  data: Product[];
}
interface FormProps {
  bankDetails?: {
    bankName: string;
    accountNumber: string;
    bankCode: string;
    branch?: string;
    isDefault?: boolean;
  };
  paypalRecipient?: string;
}

enum PaymentTab {
  'Bank',
  'Paypal',
}
const Bank: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const [myPaymentTab, setMyPaymentTab] = useState<PaymentTab>(
    PaymentTab.Paypal
  );
  const [addBank, setAddBank] = useState<boolean>(false);
  const [paypalEdit, setPaypalEdit] = useState<boolean>(true);
  const { control, handleSubmit, errors, setValue, watch } = useForm<FormProps>(
    {
      reValidateMode: 'onChange',
      mode: 'onSubmit',
    }
  );

  const auth = useAuth();
  const { updating, error, updateBankDetails, updatePaypalDetails } = useAuth();
  const renderAddBank = () => {
    setAddBank(true);
  };
  const addBankAndProceed = (bankDetails: BankDetails) => {
    updateBankDetails(bankDetails);
    setAddBank(false);
  };

  const formSubmit = (paymentDetails: {
    bankDetails?: BankDetails;
    paypalRecipient?: string;
  }) => {
    if (paymentDetails?.bankDetails) {
      props.onAddNewBank({ bankDetails: paymentDetails?.bankDetails });
    }
    if (paymentDetails?.paypalRecipient) {
      props.onAddNewBank({ paypalRecipient: paymentDetails?.paypalRecipient });
      updatePaypalDetails(paymentDetails?.paypalRecipient);
    }
  };

  const renderBank = () => {
    return (
      <>
        {/* auth.profile?.bankDetails.find((e) => e.default) */}
        {/* {auth.profile?.bankDetails?.length && ( */}
        <div className="bankaccount py-4">
          {auth?.profile?.bankDetails?.map((e) => {
            let accountNumber = '';
            if (e.isDefault) {
              accountNumber = e.accountNumber;
            }
            return (
              <div className="align-items-center border RadioSelect selected d-flex justify-content-between mb-3 p-3 rounded account-selection">
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="bankDetails"
                  defaultValue={accountNumber}
                  render={({ onChange, value, name }) => (
                    <>
                      <input
                        type="radio"
                        id={e.accountNumber}
                        name={name}
                        value={value}
                        onChange={(v) => onChange(e)}
                      />
                      <label
                        className="font-base font-weight-semibold text-left m-0"
                        htmlFor={e.accountNumber}
                      >
                        {e.bankName}-{e.accountNumber}
                        <span className="d-block font-normal font-weight-normal text-muted">
                          {e?.branch}
                        </span>
                      </label>
                    </>
                  )}
                />
                {/* <a className="cursor-pointer font-sm icon-close text-dark"></a> */}
              </div>
            );
            // }
          })}

          <div className="py-2">
            {!addBank && (
              <button
                className="font-base btn-link px-0 btn focus-inacive"
                type="button"
                onClick={() => renderAddBank()}
              >
                + Add New Account
              </button>
            )}
          </div>
          {addBank && (
            <BankUpdateForm
              onSubmit={(bankData) => {
                addBankAndProceed(bankData);
              }}
              isSubmitting={updating}
            ></BankUpdateForm>
          )}
        </div>

        {/* )} */}
      </>
    );
  };

  const renderPaypal = () => {
    return (
      <div className="paypal pt-4">
        <div className="form-group mb-4 ">
          <label>Paypal ID</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="paypalRecipient"
            defaultValue={auth.profile?.paypalReciever}
            render={({ onChange, name, value }) => {
              return (
                <>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className={classnames({
                        'form-control bg-light': true,
                        error: !!errors.paypalRecipient,
                      })}
                      id="paypalRecipient"
                      placeholder="Paypal Id/ Email/ Phone Number"
                      name={name}
                      defaultValue={auth.profile?.paypalReciever}
                      value={value}
                      onChange={onChange}
                      disabled={paypalEdit}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        {!paypalEdit && (
                          <i
                            className="icon-close cursor-pointer"
                            onClick={(e) => {
                              setPaypalEdit(true);
                            }}
                          ></i>
                        )}
                        {paypalEdit && (
                          <i
                            className="icon-edit cursor-pointer"
                            onClick={(e) => {
                              setPaypalEdit(false);
                            }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </div>
                </>
              );
            }}
          ></Controller>
          {errors.paypalRecipient && (
            <div className="error">Paypal Recipient is required</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`modal fade show ${props.showModal ? 'd-block' : ''}`}>
        <div className="modal-dialog  md mx-lg-auto my-lg-4 m-0">
          <div className="modal-content p-lg-3">
            <button
              type="button"
              className="btn m-3 p-0 position-absolute right-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onClick()}
            >
              <i className="icon-close"></i>
            </button>
            <div className="p-4">
              <div className="text-center">
                <h4>Choose Account</h4>
                <p className="font-base text-gray-900">
                  Choose your account to receive the payment
                </p>
              </div>

              <nav className="tabs-header myaccount">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className={
                      myPaymentTab === PaymentTab.Paypal
                        ? 'nav-item nav-link active cursor-pointer font-base'
                        : 'nav-item nav-link cursor-pointer font-base'
                    }
                    id="nav-profile-tab"
                    data-toggle="tab"
                    onClick={() => {
                      setMyPaymentTab(PaymentTab.Paypal);
                      setAddBank(false);
                    }}
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Paypal <span className="count"></span>
                  </a>
                  <a
                    className={
                      myPaymentTab === PaymentTab.Bank
                        ? 'nav-item nav-link active cursor-pointer font-base'
                        : 'nav-item nav-link cursor-pointer font-base'
                    }
                    id="nav-home-tab"
                    data-toggle="tab"
                    onClick={() => setMyPaymentTab(PaymentTab.Bank)}
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Bank Account{' '}
                    {!!auth?.profile?.bankDetails?.length && (
                      <span className="count">
                        ({auth?.profile?.bankDetails?.length})
                      </span>
                    )}
                  </a>
                </div>
              </nav>
              <div>
                <form onSubmit={handleSubmit(formSubmit)}>
                  {myPaymentTab === PaymentTab.Bank
                    ? renderBank()
                    : renderPaypal()}

                  <div className="col-lg-6 mx--lg-auto px-0">
                    {!addBank && (
                      <button
                        type="submit"
                        className="btn btn-primary font-weight-bold btn-block px-5 py-2 profile-edit-save"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default Bank;
