import ConfigDev from './config.dev';
import ConfigProd from './config.prod';
import ConfigStaging from './config.staging';

const root =
  process.env.NODE_ENV === 'production'
    ? ConfigProd
    : process.env.NODE_ENV === 'test'
    ? ConfigStaging
    : ConfigDev;

export default root;
