import React from 'react';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import { SubscriptionPlan, PostType, Subscription } from '../../../api/models';
import { ContainerLoader } from '../../../components/loader';
import useAuth from '../../../hooks/useAuth';
import { useParams } from 'react-router-dom';

interface PlanProps {
  onPlanSelect: (data: Subscription) => void;
}

const Plan: React.FunctionComponent<PlanProps> = (props) => {
  const auth = useAuth();
  const params = useParams<{ subscriptionId: string }>();
  const { data: customPlan, loading, loaded } = useDataLoader<Subscription>(() =>
    api.subscription.getCustomPlan({ subscriptionId: params.subscriptionId })
  );

  const { performAction: onPlanSelect } = usePerformAction((data): any => {
    props.onPlanSelect(data);
  });

  if (!loaded || loading) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }

  const renderPlanDetails = (plan: Subscription) => {
    return (
      <>
        {' '}
        <div className="ads-item bg-white shadow px-5 pt-lg-4 pt-3 pb-2 pb-lg-3 position-relative mb-3">
          <div className="border-bottom mb-4 pb-3 row flex-column">
            <h6 className="font-weight-bold mb-1">{plan.planName}</h6>
            <h6 className="font-weight-bold mb-1">{`$ ${plan.amount} ${plan.isRecurring ? plan.period : ''
              }`}</h6>
          </div>

          <div className="views-block pb-2 d-block">
            <div className="row mt-3">
              <ul className="align-items-center d-flex list-unstyled m-0 p-0">
                <li className="d-flex pr-3 align-items-center">
                  <span className="icon-verified2 font-lg pr-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>{' '}
                  <span className="font-base text-gray-500">
                    Maximum {plan.noOfPosts} posts{' '}
                    {plan.isRecurring
                      ? plan.period
                      : `for ${plan.listingDays} days`}
                  </span>
                </li>
              </ul>
            </div>
            <div className="row mt-3">
              <ul className="align-items-center d-flex list-unstyled m-0 p-0">
                <li className="d-flex pr-3 align-items-center">
                  <span className="icon-verified2 font-lg pr-1">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>{' '}
                  <span className="font-base text-gray-500">
                    Post limit inside{' '}
                    {plan.postType === PostType.City ? 'City' : 'Nation'}
                  </span>
                </li>
              </ul>
            </div>
            <div className="row mt-3">
              <button
                type="button"
                className="btn btn-primary btn-block font-weight-bold "
                onClick={() => {
                  onPlanSelect(plan);
                }}
              >
                {`Pay $ ${plan.amount}`}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i className="icon-prev font-lg mr-3"></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Custom Plan Details
        </h6>
      </div>

      <div className="post-product">
        <div className="container">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-lg-auto col-12 post-col">
              <div className="post-product-inner rounded bg-white">
                <h3 className="text-center text-gray-900 m-0 d-lg-block d-none mb-3">
                  Custom Plan Details
                </h3>
                {loaded && !loading && customPlan && (
                  <div className="right-panel w-100 px-0">
                    {renderPlanDetails(customPlan)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
