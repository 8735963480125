import React from 'react';
import LogoSVG from '../../../../components/logo/logo-svg';

const Banner: React.FunctionComponent = () => {
  return (
    <div className="col-lg-6 col-xl-5 col-md-12 col-xl bg-box  d-lg-flex align-items-center text-white px-4 d-none d-lg-flex left-radius">
      <a href="/" title="DealDen" className="">
        <LogoSVG
          type="white"
          className="d-none d-lg-block position-absolute logo-white"
        />
      </a>
      <div className="text-center mx-auto">
        <span className="icon-individual d-block mb-4"></span>
        <h1 className="mb-4 font-weight-bold h2 text-left text-white">
          Include Some Detail
        </h1>
        <h6 className="mb-4 font-weight-normal h5 text-left text-white">
          We collect more details for security reasons.
        </h6>
      </div>
    </div>
  );
};

export default Banner;
