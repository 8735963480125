import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AvatarIcon from '../../../components/icons/avatar-icon';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorMessage from '../../../components/text/ErrorMessage';

interface FormProps {
  phone: string;
}

const PhoneNumberForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
}> = (props) => {
  const { control, handleSubmit, errors } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const renderPhoneNumber = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-phone" className="sr-only">
          Phone
        </label>
        <Controller
          control={control}
          name="phone"
          // defaultValue={props.default?.phone || ''}
          rules={{
            required: true,
          }}
          key={`phone`}
          render={({ onChange, name, value }) => (
            <PhoneInput
              autoFormat={false}
              country={'bs'}
              preferredCountries={['bs', 'in']}
              enableSearch={false}
              placeholder={'Phone Number'}
              inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
              containerClass={'textField'}
              dropdownStyle={{ borderColor: '#e5e6e7' }}
              disableCountryCode={false}
              countryCodeEditable={true}
              inputStyle={{
                width: '100%',
                borderRadius: '0px',
                borderColor: '#e5e6e7',
              }}
              disableSearchIcon={true}
              onChange={(val, data: { dialCode: string }) => {
                onChange(val);
              }}
              value={value}
            />
          )}
        />
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.type === 'required'
              ? 'Phone is required'
              : 'Invalid Phone'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderNextButton = () => {
    return (
      <div className="form-group mt-4 pt-3 col-12 col-lg-4 col-md-4 px-0 form-group mt-4 px-0 pt-2">
        <a href="otp-varification.html">
          <button
            type="submit"
            className="btn btn-primary btn-block font-weight-bold "
            disabled={props.isSubmitting}
          >
            Next
          </button>
        </a>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="top-head-block">
        <AvatarIcon className="d-block mb-4 mb-lg-5" />
        <h3 className="text-dark mb-3 h3">Enter your phone</h3>
        <p className="text-muted font-weight-lighter mb-4 pb-3 f-16">
          Please enter your mobile number to receive a verification code
        </p>
      </div>
    );
  };

  return (
    <div className="px-lg-5 mx-auto py-5 py-lg-4">
      {renderHeader()}
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {renderPhoneNumber()}
        {renderNextButton()}
      </form>
    </div>
  );
};

export default PhoneNumberForm;
