import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
// import PhoneInput from '../../../components/phone-input';
import ErrorMessage from '../../../components/text/ErrorMessage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
interface FormProps {
  phone: string;
}

const ForgotPasswordForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
  defaultEmail?: string;
  error?: string;
}> = (props) => {
  const { control, handleSubmit, errors } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const renderPhoneNumber = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-phone" className="sr-only">
          Phone
        </label>
        <Controller
          control={control}
          name="phone"
          // defaultValue={props.default?.phone || ''}
          rules={{
            required: true,
          }}
          key={`phone`}
          render={({ onChange, name, value }) => (
            <PhoneInput
              autoFormat={false}
              country={'bs'}
              preferredCountries={['bs', 'in']}
              enableSearch={false}
              placeholder={'Phone Number'}
              inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
              containerClass={'textField'}
              dropdownStyle={{ borderColor: '#e5e6e7' }}
              disableCountryCode={false}
              countryCodeEditable={true}
              inputStyle={{
                width: '100%',
                borderRadius: '0px',
                borderColor: '#e5e6e7',
              }}
              disableSearchIcon={true}
              onChange={(val, data: { dialCode: string }) => {
                onChange(val);
              }}
              value={value}
            />
          )}
        />
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.type === 'required'
              ? 'Phone is required'
              : 'Invalid Phone'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderBannerResponsive = () => {
    return (
      <div className="form-group mt-4 pt-2 d-lg-none">
        Already have an account
        <a href="/login" className="text-underline font-weight-bold pl-1">
          Sign In
        </a>
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          disabled={props.isSubmitting}
        >
          Submit
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {renderPhoneNumber()}
      <ErrorMessage>{props.error}</ErrorMessage>
      {renderSubmitButton()}
      {renderBannerResponsive()}
    </form>
  );
};

export default ForgotPasswordForm;
