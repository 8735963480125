import { Banner, Category, Subcategory } from '../../api/models';
import { AllEntitiesResponse } from '../../api/models/entity';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const ACTION_LOAD_ENTITIES_REQUEST = 'ENTITIES/LOAD_ENTITIES_REQUEST';
const ACTION_LOAD_ENTITIES_SUCCESS = 'ENTITIES/LOAD_ENTITIES_SUCCESS';
const ACTION_LOAD_ENTITIES_ERROR = 'ENTITIES/LOAD_ENTITIES_ERROR';

type LoadEntitiesRequestActionType = IAction<
  typeof ACTION_LOAD_ENTITIES_REQUEST
>;

type LoadEntitiesSuccessActionType = IActionWithPayload<
  typeof ACTION_LOAD_ENTITIES_SUCCESS,
  AllEntitiesResponse
>;

type LoadEntitiesErrorActionType = IAction<typeof ACTION_LOAD_ENTITIES_ERROR>;

type Actions =
  | LoadEntitiesRequestActionType
  | LoadEntitiesSuccessActionType
  | LoadEntitiesErrorActionType;

export const loadEntitiesRequestAction = (): LoadEntitiesRequestActionType => {
  return createAction(ACTION_LOAD_ENTITIES_REQUEST);
};

export const loadEntitiesSuccessAction = (
  data: AllEntitiesResponse
): LoadEntitiesSuccessActionType => {
  return createActionWithPayload(ACTION_LOAD_ENTITIES_SUCCESS, data);
};

export const loadEntitiesErrorAction = (): LoadEntitiesErrorActionType => {
  return createAction(ACTION_LOAD_ENTITIES_ERROR);
};

export interface EntitiesState {
  categories: Category[];
  subcategories: Subcategory[];
  categoriesWithoutProduct: Category[];
  banners: Banner[];
  isLoading?: boolean;
  isLoaded?: boolean;
}

const initialState: EntitiesState = {
  categories: [],
  categoriesWithoutProduct: [],
  subcategories: [],
  banners: [],
  isLoaded: false,
  isLoading: false,
};

// reducer
export default (
  state: EntitiesState = initialState,
  action: Actions
): EntitiesState => {
  switch (action.type) {
    case ACTION_LOAD_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case ACTION_LOAD_ENTITIES_ERROR:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    case ACTION_LOAD_ENTITIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories || [],
        categoriesWithoutProduct: action.payload.categoriesWithoutProduct || [],
        subcategories: action.payload.subcategories || [],
        banners: action.payload.banners || [],
        isLoading: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};
