import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorMessage from '../../../components/text/ErrorMessage';

interface FormProps {
  name: string;
  email: string;
  phone: string;
}

const SignupForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isSubmitting?: boolean;
  email?: string;
  name?: string;
  error?: string;
  isSocialMedia?: boolean;
}> = (props) => {
  const { control, handleSubmit, errors, setValue } = useForm<FormProps>({
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      name: '',
      phone: '',
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (props.isSocialMedia) {
      if (props.name) {
        setValue('name', props.name);
      }
      if (props.email) {
        setValue('email', props.email);
      }
    }
  }, [props.name, props.email]);

  const renderEmail = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-email" className="sr-only">
          Email
        </label>
        <Controller
          control={control}
          rules={{ required: true, validate: { isEmail: validator.isEmail } }}
          name="email"
          // defaultValue={props.email}
          render={({ onChange, value, name }) => (
            <input
              type="text"
              className={classnames({
                'form-control': true,
                error: !!errors.email,
              })}
              // defaultValue={props.email}
              // ref={register}
              placeholder="Email"
              name={name}
              value={value}
              onChange={onChange}
              id="signup-email"
            />
          )}
        />
        {errors.email && (
          <ErrorMessage>
            {errors.email.type === 'required'
              ? 'Email is required'
              : 'Invalid Email'}
          </ErrorMessage>
        )}
      </div>
    );
  };
  const renderName = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-name" className="sr-only">
          Name
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="name"
          // defaultValue={props.name}
          render={({ onChange, value, name }) => (
            <input
              type="text"
              className={classnames({
                'form-control': true,
                error: !!errors.name,
              })}
              id="signup-name"
              placeholder="Name"
              name={name}
              value={value}
              // ref={register}
              onChange={onChange}
            />
          )}
        />
        {errors.name && (
          <ErrorMessage>
            {errors.name.type === 'required'
              ? 'Name is required'
              : 'Invalid Name'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderPhoneNumber = () => {
    return (
      <div className="form-group">
        <label htmlFor="signup-phone" className="sr-only">
          Phone
        </label>
        <Controller
          control={control}
          name="phone"
          // defaultValue={props.default?.phone || ''}
          rules={{
            required: true,
          }}
          key={`phone`}
          render={({ onChange, name, value }) => (
            <PhoneInput
              autoFormat={false}
              country={'bs'}
              preferredCountries={['bs', 'in']}
              enableSearch={false}
              placeholder={'Phone Number'}
              inputClass={`textField__input ${errors.phone ? 'error' : ''}`}
              containerClass={'textField'}
              dropdownStyle={{ borderColor: '#e5e6e7' }}
              disableCountryCode={false}
              countryCodeEditable={true}
              inputStyle={{
                width: '100%',
                borderRadius: '0px',
                borderColor: '#e5e6e7',
              }}
              disableSearchIcon={true}
              onChange={(val, data: { dialCode: string }) => {
                onChange(val);
              }}
              value={value}
            />
          )}
        />
        {errors.phone && (
          <ErrorMessage>
            {errors.phone.type === 'required'
              ? 'Phone is required'
              : 'Invalid Phone'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderLoginBannerResponsive = () => {
    return (
      <div className="form-group mt-4 pt-2 d-lg-none">
        Already have an account
        <a href="/login" className="text-underline font-weight-bold pl-1">
          Sign In
        </a>
      </div>
    );
  };

  const renderSignupButton = () => {
    return (
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          disabled={props.isSubmitting}
        >
          Sign Up
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {renderName()}
      {renderEmail()}
      {renderPhoneNumber()}
      <ErrorMessage>{props.error}</ErrorMessage>
      {renderSignupButton()}
      {renderLoginBannerResponsive()}
    </form>
  );
};

export default SignupForm;
