import { AccountType, OnBoardStatus } from '../../api/models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// action types
// signup
const SIGNUP_SIGNUP_CHECK_SUCCESS_ACTION = 'SIGNUP/SIGNUP_CHECK_SUCCESS';
const SIGNUP_FACEBOOK_SIGNUP_CHECK_SUCCESS_ACTION =
  'SIGNUP/SIGNUP_FACEBOOK_SIGNUP_CHECK_SUCCESS';
const SIGNUP_OTP_VERIFIED_ACTION = 'SIGNUP/OTP_VERIFIED';
const SIGNUP_SIGNUP_COMPLETED_ACTION = 'SIGNUP/SIGNUP_COMPLETED';
const SIGNUP_SET_ACCOUNT_TYPE_ACTION = 'SIGNUP/SET_ACCOUNT_TYPE';
const SIGNUP_BASIC_INFO_UPDATED_ACTION = 'SIGNUP/SIGNUP_BASIC_INFO_UPDATED';
const SIGNUP_OTP_SENT_ACTION = 'SIGNUP/OTP_SENT';

export interface SignupState {
  userId?: string;
  email?: string;
  name?: string;
  phone?: string;
  otpVerified?: boolean;
  accountType?: AccountType;
  onBoardStatus?: OnBoardStatus;
  isSocialMedia?: boolean;
}

type SignupCheckSuccessActionType = IActionWithPayload<
  typeof SIGNUP_SIGNUP_CHECK_SUCCESS_ACTION,
  { email: string; name: string; phone: string; userId: string }
>;

type FacebookSignupCheckSuccessActionType = IActionWithPayload<
  typeof SIGNUP_FACEBOOK_SIGNUP_CHECK_SUCCESS_ACTION,
  { email: string; name: string, isSocialMedia?: boolean }
>;

type SignupOtpVerifiedActionType = IAction<typeof SIGNUP_OTP_VERIFIED_ACTION>;
type SignupCompletedActionType = IAction<typeof SIGNUP_SIGNUP_COMPLETED_ACTION>;
type SetAccountTypeActionType = IActionWithPayload<
  typeof SIGNUP_SET_ACCOUNT_TYPE_ACTION,
  AccountType
>;

type BasicInfoUpdatedActionType = IAction<
  typeof SIGNUP_BASIC_INFO_UPDATED_ACTION
>;

type OtpSentActionType = IActionWithPayload<
  typeof SIGNUP_OTP_SENT_ACTION,
  { phone: string; userId: string }
>;

type Actions =
  | SignupCheckSuccessActionType
  | SignupOtpVerifiedActionType
  | SignupCompletedActionType
  | SetAccountTypeActionType
  | FacebookSignupCheckSuccessActionType
  | BasicInfoUpdatedActionType
  | OtpSentActionType;

export const signupCheckSuccessAction = (data: {
  email: string;
  name: string;
  phone: string;
  userId: string;
}): SignupCheckSuccessActionType => {
  return createActionWithPayload(SIGNUP_SIGNUP_CHECK_SUCCESS_ACTION, data);
};

export const facebookSignupCheckSuccessAction = (data: {
  email: string;
  name: string;
  isSocialMedia?: boolean;
}): FacebookSignupCheckSuccessActionType => {
  return createActionWithPayload(
    SIGNUP_FACEBOOK_SIGNUP_CHECK_SUCCESS_ACTION,
    data
  );
};

export const signupOtpVerifiedAction = (): SignupOtpVerifiedActionType => {
  return createAction(SIGNUP_OTP_VERIFIED_ACTION);
};

export const signupCompletedAction = (): SignupCompletedActionType => {
  return createAction(SIGNUP_SIGNUP_COMPLETED_ACTION);
};

export const basicInfoUpdatedAction = (): BasicInfoUpdatedActionType => {
  return createAction(SIGNUP_BASIC_INFO_UPDATED_ACTION);
};

export const otpSentAction = (data: {
  phone: string;
  userId: string;
}): OtpSentActionType => {
  return createActionWithPayload(SIGNUP_OTP_SENT_ACTION, data);
};

export const setAccountTypeAction = (
  accountType: AccountType
): SetAccountTypeActionType => {
  return createActionWithPayload(SIGNUP_SET_ACCOUNT_TYPE_ACTION, accountType);
};

// initial state
const initialState: SignupState = {};

// reducer
export default (
  state: SignupState = initialState,
  action: Actions
): SignupState => {
  switch (action.type) {
    // signup
    case SIGNUP_SIGNUP_CHECK_SUCCESS_ACTION:
      return {
        ...state,
        userId: action.payload.userId,
        email: action.payload.email,
        name: action.payload.name,
        phone: action.payload.phone,
      };
    case SIGNUP_FACEBOOK_SIGNUP_CHECK_SUCCESS_ACTION:
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
        isSocialMedia: action.payload?.isSocialMedia,
      };
    case SIGNUP_OTP_VERIFIED_ACTION:
      return {
        ...state,
        otpVerified: true,
        accountType: undefined,
        onBoardStatus: OnBoardStatus.SignedUpWithPhone,
      };
    case SIGNUP_SIGNUP_COMPLETED_ACTION:
      return {
        ...initialState,
      };
    case SIGNUP_OTP_SENT_ACTION:
      return {
        ...state,
        userId: action.payload.userId,
        phone: action.payload.phone,
      };

    case SIGNUP_SET_ACCOUNT_TYPE_ACTION:
      return {
        ...state,
        accountType: action.payload,
        onBoardStatus: OnBoardStatus.SignedUpWithPhone,
      };

    case SIGNUP_BASIC_INFO_UPDATED_ACTION:
      return {
        ...state,
        onBoardStatus: OnBoardStatus.BasicInfoAdded,
      };

    default:
      return state;
  }
};
