import ApiService from './service';
import * as http from './http';
import { ApiResponse, MediaFile } from '../models';

export default class UploadService extends ApiService {
  public async upload(
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<ApiResponse<MediaFile>> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.apiDomain}/upload`;
    const response = await http.upload<ApiResponse<MediaFile>>(
      url,
      formData,
      onUploadProgress,
      this.store
    );
    return response.data;
  }

  public async uploadMultiple(
    files: File[],
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<ApiResponse<MediaFile[]>> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    const url = `${this.apiDomain}/upload/multiple`;
    const response = await http.upload<ApiResponse<MediaFile[]>>(
      url,
      formData,
      onUploadProgress,
      this.store
    );
    return response.data;
  }
}
