import React, { FunctionComponent } from 'react';
interface CustomProps {
  heading: string;
}
const FilterHeading: FunctionComponent<CustomProps> = (props: CustomProps) => {
  return (
    <>
      <h2 className="text-dark font-weight-bold title-head d-none d-lg-block">
        {props.heading}
      </h2>
    </>
  );
};

export default FilterHeading;
