import React from 'react';
import useSearch from '../../hooks/useSearch';
import { FilterType } from '../../api/models';
import Sort from './Sort';
import { ViewType } from './props';
import ViewTypeToggle from './ViewTypeToggle';

const Header: React.FunctionComponent<{
  viewType: ViewType;
  setViewType: (vt: ViewType) => void;
}> = (props) => {
  const appliedFilters: {
    key: string;
    value: any;
    text?: string;
    onRemove: () => void;
  }[] = [];

  const search = useSearch();
  Object.keys(search.filterOptions).forEach((filterKey) => {
    const filter = search.filterOptions[filterKey];

    if (filter.type === FilterType.MultiSelect) {
      const value = search.filters[filterKey] as string[];
      if (value) {
        appliedFilters.push(
          ...value.map((val) => ({
            text: filter.meta.options.find(
              (op) => op[filter.meta.valueField] === val
            )?.[filter.meta.textField],
            key: `${filterKey}-${val}`,
            value: val,
            onRemove: () =>
              search.setFilterValue(
                filterKey,
                value.filter((v) => v !== val),
                FilterType.MultiSelect
              ),
          }))
        );
      }
    } else if (filter.type === FilterType.MinMaxSlider) {
      const value = search.filters[filterKey] as number[];
      if (value) {
        appliedFilters.push({
          key: filterKey,
          text: `${filter.meta.name} from ${filter.meta.valuePrefix || ''}${
            value[0] || ''
          }${filter.meta.valueSuffix || ''} to ${
            filter.meta.valuePrefix || ''
          }${value[1] || ''}${filter.meta.valueSuffix || ''}`,
          value,
          onRemove: () =>
            search.setFilterValue(
              filterKey,
              undefined,
              FilterType.MinMaxSlider
            ),
        });
      }
    }
  });

  const renderAppliedFilters = () => {
    if (!appliedFilters.length) {
      return (
        <div className="col-lg col-12 px-lg-3 px-0 filter-outer d-none d-lg-flex d-none align-items-center flex-lg-wrap text-nowrap">
          <div className="font-xxs text-gray-700 d-inline-block pr-2 mb-1 position-sticky left-0 pl-lg-0 pl-3 py-lg-0 py-2 bg-gray-100">
            {/* No filters applied */}
          </div>
        </div>
      );
    }
    return (
      <div className="col-lg col-12 px-lg-3 px-0 filter-outer d-lg-flex d-none align-items-center flex-lg-wrap text-nowrap">
        <div className="font-xxs text-gray-700 d-inline-block pr-2 mb-1 position-sticky left-0 pl-lg-0 pl-3 py-lg-0 py-2 bg-gray-100">
          Filtered By :
        </div>
        {appliedFilters.map((filter) => {
          return (
            filter.text && (
              <div className="bg-white border font-xxs text-gray-500 py-1 px-2 d-inline-flex align-items-center rounded mb-1 mr-1 filter-tag">
                {filter.text}
                <i
                  className="icon-close-sm ml-1 cursor-pointer"
                  onClick={filter.onRemove}
                ></i>
              </div>
            )
          );
        })}
      </div>
    );
  };
  return (
    <div className="row  mb-3">
      {renderAppliedFilters()}
      <div className="col-lg-auto col-12 d-flex align-items-center">
        <ViewTypeToggle
          viewType={props.viewType}
          setViewType={props.setViewType}
          // className="d-inline-flex border py-2 cursor-pointer mr-2"
        />
        <Sort />
      </div>
    </div>
  );
};

export default Header;
