import api from '../api';
import unauthorizedApiResponseInterceptor from '../api/interceptor';

import {
  logoutAction,
  profileUpdatedAction,
  setTimeOffsetAction,
} from './reducers/authUser';
import { clearDataAction } from './reducers/common';

// const initializeReccomendations = async (store: any) => {
//   const data = await api.catalog.getProductReccomendations(1);
//   store.dispatch(reccomendationsLoadedAction(data.data));
// };
const loadProfile = async (store: any) => {
  const profile = await api.user.getProfile();
  store.dispatch(profileUpdatedAction(profile.data!));
};
const loadTimeOffset = async (store: any) => {
  try {
    const response = await api.user.getTimeOffset();
    if (response?.data) {
      store.dispatch(setTimeOffsetAction(response?.data!));
    }
  } catch (er) {}
};

const initialize = (store: any) => {
  const state = store.getState();
  unauthorizedApiResponseInterceptor(() => {
    store.dispatch(logoutAction());
    store.dispatch(clearDataAction());
    api.setToken(undefined);
  }, ['/auth/login', '/auth/phone-login']);

  api.setToken(state.authUser.token);
  if (state.authUser.token) {
    api.setToken(state.authUser.token);
    loadProfile(store);
    loadTimeOffset(store);
  }
  // initializeReccomendations(store);
};

export default initialize;
