import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from '../../../components/otp-input';
import ErrorMessage from '../../../components/text/ErrorMessage';
import validator from 'validator';
import classnames from 'classnames';

interface FormProps {
  otp: string;
  password: string;
  confirmPassword: string;
}

const OtpForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isUpdating?: boolean;
  onResendButtonClicked: () => void;
  error?: string;
}> = (props) => {
  const { control, handleSubmit, errors, watch } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const renderPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-password" className="sr-only">
          Password
        </label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="password"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.password,
                })}
                id="register-password"
                placeholder="Password"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.password && <div className="error">Password is required</div>}
      </div>
    );
  };
  const renderConfirmPassword = () => {
    return (
      <div className="form-group">
        <label htmlFor="register-confirm-password" className="sr-only">
          Confirm Password
        </label>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: { isMatching: (value) => value === watch('password') },
          }}
          name="confirmPassword"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.password,
                })}
                id="register-confirm-password"
                placeholder="Confirm Password"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.confirmPassword && (
          <>
            {errors.confirmPassword.type === 'required' && (
              <div className="error">Confirm Password is required</div>
            )}
            {errors.confirmPassword.type === 'isMatching' && (
              <div className="error">Passwords should match</div>
            )}
          </>
        )}
      </div>
    );
  };
  const renderOtpInput = () => {
    return (
      <div className="form-group d-flex d-lg-block justify-content-center">
        <label htmlFor="otp-input" className="sr-only">
          OTP
        </label>
        <Controller
          control={control}
          name="otp"
          rules={{
            required: true,
            validate: { isValid: (value) => value && value.length === 4 },
          }}
          render={({ onChange, value, name }) => (
            <OtpInput
              numInputs={4}
              value={value}
              onChange={onChange}
              className="col-lg-2 col-3 col-md-2"
              inputStyle="form-control text-center"
              containerStyle="row otp-form gutter-10 px-lg-0 mt-lg-0 mt-4 justify-content-center justify-content-lg-start"
            />
          )}
        />
        {errors.otp && (
          <ErrorMessage>
            {errors.otp.type === 'required' ? 'OTP is required' : 'Invalid OTP'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderVerifyButton = () => {
    return (
      <div className="form-group mt-lg-3 pt-lg-3 col-12 col-md-12 col-lg-4 col-md-4 form-group">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          disabled={props.isUpdating}
        >
          Verify
        </button>
      </div>
    );
  };

  return (
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {renderOtpInput()}
        {renderPassword()}
        {renderConfirmPassword()}
        <div className="row flex-column-reverse flex-direction flex-lg-row row">
          {renderVerifyButton()}
          <div className="form-group mt-lg-4 pt-1 pt-lg-3 col-12 col-md-12 col-lg-8 col-md-4  form-group  pl-2">
            <p className="text-muted mt-2 text-center text-lg-left">
              Didn’t receive OTP?{' '}
              <span
                className="btn-link cursor-pointer"
                onClick={
                  props.isUpdating ? undefined : props.onResendButtonClicked
                }
              >
                Resend
              </span>
            </p>
          </div>
        </div>
        <ErrorMessage>{props.error}</ErrorMessage>
      </form>
  );
};

export default OtpForm;
