import React from 'react';
import useSignup from '../../hooks/useSignup';
import AccountTypeWidget from './components/account-type';
import { OnBoardStatus } from '../../api/models';
import PersonalDetailsWidget from './components/personal-details';
import BusinessDetailsWidget from './components/business-details';
import { Redirect } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';

const OnBoardScreen: React.FunctionComponent = () => {
  const { accountType, onBoardStatus } = useSignup();
  if (!accountType) {
    return <AccountTypeWidget></AccountTypeWidget>;
  }
  if (onBoardStatus === OnBoardStatus.SignedUpWithPhone) {
    return <PersonalDetailsWidget></PersonalDetailsWidget>;
  }
  if (onBoardStatus === OnBoardStatus.BasicInfoAdded) {
    return <BusinessDetailsWidget></BusinessDetailsWidget>;
  }
  return <Redirect to={RouteKeys.Home} />;
};

export default OnBoardScreen;
