import React, { FunctionComponent } from 'react';
import {
  OrderStatus,
  OrderStatusSyllables,
  Product,
  ProductDetails,
} from '../../api/models';

interface CustomProps {
  product: Product | ProductDetails;
}
const ProductStatus: FunctionComponent<CustomProps> = (props: CustomProps) => {
  const { product } = props;
  const newDate = new Date();
  const showStatusLabel = (product: Product) => {
    if (product?.orderStatus) {
      if (
        product?.isActive &&
        product.orderStatus === OrderStatus.Pending &&
        product.validTill &&
        new Date(product.validTill) > newDate
      ) {
        return (
          <span
            className={`status ${
              OrderStatusSyllables?.[product.orderStatus]?.class
            }`}
          >
            {OrderStatusSyllables?.['active']?.label}
          </span>
        );
      } else if (
        product.orderStatus === OrderStatus.Pending &&
        product.validTill &&
        new Date(product.validTill) < newDate
      ) {
        return (
          <span
            className={`status ${
              OrderStatusSyllables?.[product.orderStatus]?.class
            }`}
          >
            {OrderStatusSyllables?.['expired']?.label}
          </span>
        );
      }
      return (
        <span
          className={`status ${
            OrderStatusSyllables?.[product.orderStatus]?.class
          }`}
        >
          {OrderStatusSyllables?.[product.orderStatus]?.label}
        </span>
      );
    } else {
      return '';
    }
  };
  return <>{showStatusLabel(product)}</>;
};

export default ProductStatus;
