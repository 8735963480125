import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import { RouteKeys } from '../../routes/route-keys';
import useSearch from '../../../hooks/useSearch';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import api from '../../../api';
import { LatLng } from '../../../api/models';
import DropdownList from 'react-widgets/lib/DropdownList';
import { debounce } from 'debounce';
import { stringify } from 'querystring';
import useOnClickOutside from 'use-onclickoutside';
import { scrollIntoView } from 'react-select/src/utils';

interface Location {
  latLng: LatLng;
  address: {
    street?: string;
    zip?: number;
    country?: string;
    state?: string;
    city?: string;
  };
}

interface SearchResults {
  id: string;
  title: string;
  category: string;
  subCategory: string;
  description: string;
  categoryId: string;
  subCategoryId: string;
}

const SearchBox: React.FunctionComponent = (props) => {
  const search = useSearch();
  const locationParams = useLocation();
  const queryParameters = qs.parse(locationParams.search);
  const category =
    queryParameters.category && Array.isArray(queryParameters.category)
      ? queryParameters.category.join(' ')
      : queryParameters.category || '';

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [productValue, setProductValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState<Location>({
    latLng: {
      // lat: 24.638185,
      // lng: -78.055734,
      lat: 0,
      lng: 0,
    },
    address: {},
  });
  const [show, setShow] = useState<boolean>(true);
  const [locationSuggestionsOpen, setLocationSuggestionsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  const onOutsideClickNoShow = () => {
    setShow(false);
  };
  useOnClickOutside(ref, onOutsideClickNoShow);

  const searchOptions = {
    types: ['(regions)'],
    componentRestrictions: { country: 'BS' },
  };

  const handleChange = (addressName: any) => {
    setLocation(addressName);
  };

  const setValue = (place: any, addressName: string) => {
    const componentForm = {
      locality: 'long_name',
      administrative_area_level_2: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
    };
    const addressDetails: any = {};
    /* tslint:disable:prefer-for-of */
    for (let i = 0; i < place.address_components.length; i += 1) {
      const addressType:
        | 'locality'
        | 'administrative_area_level_2'
        | 'administrative_area_level_1'
        | 'country' = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = place.address_components[i][componentForm[addressType]];
        addressDetails[addressType] = val;
      }
    }
    const addressLocation: any = {
      address: {
        address: addressName,
        country: addressDetails.country,
        state: addressDetails.administrative_area_level_1,
        city: addressDetails.administrative_area_level_2,
        street: addressDetails.locality,
      },
      latLng: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    };

    setAddress(addressLocation);
  };

  const itemSelected = async (data: any) => {
    setCategoryId(data.categoryId);
    search.setSearchParams(data.title, location, address, data.categoryId);
    document.body.classList.remove('open-search');
    history.push({
      pathname: RouteKeys.Search,
      search: `?category=${data.categoryId}&searchTerm=${data.title}`,
    });
    // history.push(
    //   `/${RouteKeys.Search}?category=${data.categoryId}&searchTerm=${data.title}`
    // );
  };

  const handleSearchTextChange = async (e: any) => {
    setSearchTerm(e);
    const res = await api.catalog.suggest(e, address);
    const data: SearchResults[] = res?.products || [];
    setSearchResults(data);
    setIsLoading(false);
  };

  const searchDebounce = debounce(handleSearchTextChange, 500);

  const handleKeyDown = (e: string) => {
    if (e.length > 2) {
      setIsLoading(true);
      searchDebounce(e);
    } else {
      setShow(false);
    }
  };

  const searchButtonClick = () => {
    if (searchTerm || location) {
      search.setSearchParams(searchTerm, location, address, category);
      const q = stringify({ searchTerm, location, category });
      document.body.classList.remove('open-search');
      history.push({
        pathname: RouteKeys.Search,
        search: `?${q}`,
      });
      document.body.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSelect = (addressName: any) => {
    geocodeByAddress(addressName)
      .then((results) => {
        setValue(results[0], addressName);
        setLocation(addressName);
      })

      .catch((error) => {
        // tslint:disable-next-line: no-console
        console.error('Error', error);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className="searchBox__input px-lg-0 px-3 position-relative">
        <input
          type="text"
          placeholder="What are you looking for?"
          className="h-100 w-100"
          // value={search.searchTerm}
          // onChange={(e) => {
          //   setSearchTerm(e.target.value);
          // }}
          onKeyUp={(e: any) => {
            setShow(true);
            handleKeyDown(e.target.value);
          }}
        />
        {renderSearchResult()}
      </div>
    );
  };

  const renderSearchResult = () => {
    if (searchResults.length && show) {
      return (
        <ul
          ref={ref}
          className="search-dropdown search-dropdown--open list-unstyled bg-white py-2 overflow-auto"
        >
          {searchResults.map((result) => {
            const image: any = result?.images?.length
              ? JSON.parse(result.images[0])
              : '';

            return (
              <>
                <li>
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center px-3 py-2"
                    onClick={() => {
                      setShow(true);
                      itemSelected(result);
                    }}
                  >
                    <span>
                      <span className="font-sm d-block font-weight-semibold text-gray-900">
                        {result.title}
                      </span>
                      <span className="font-xs">in {result.category}</span>
                    </span>
                  </a>
                </li>
              </>
            );
          })}
        </ul>
      );
    }
  };

  const renderSearchButton = () => {
    return (
      <div className="searchBox__button">
        <button
          type="submit"
          className="btn btn-dark h-100 w-100 p-lg-0 p-2 d-inline-flex justify-content-center align-items-center"
          onClick={() => searchButtonClick()}
        >
          <i className="icon-search"></i>
          <span className="d-lg-none ml-2">Search</span>
        </button>
      </div>
    );
  };

  const renderSearchWithAutoComplete = () => {
    return (
      <div className="searchBox__input px-lg-0 px-3">
        <input
          type="text"
          placeholder="What are you looking for?"
          className="h-100 w-100"
          value={search.searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleKeyDown(e.target.value);
          }}
        />
        <div className="h-100 w-100">
          <DropdownList
            busy={isLoading}
            data={searchResults}
            containerClassName={'h-100 w-100'}
            // onCreate={(name) => handleCreate(name)}
            onChange={(value) => {
              setProductValue(value);
            }}
            // open={isOpen}
            textField="name"
          />
        </div>
      </div>
    );
  };

  const renderLocationInput = () => {
    return (
      <div className="d-flex align-items-center searchBox__dropdown w-100 h-100 text-gray-500">
        <i className="icon-location mr-2"></i>
        <input
          type="text"
          className="border-0"
          placeholder="Location"
          onChange={(e: any) => setLocation(e.target.value)}
        />
        <i
          className="icon-arrow-down ml-auto font-xxs d-md-inline-block d-none"
          onClick={() => setLocationSuggestionsOpen(!locationSuggestionsOpen)}
        ></i>
      </div>
    );
  };

  const renderGoogleLocationInput = () => {
    return (
      <div className="d-flex align-items-center searchBox__dropdown w-100 h-100 text-gray-500">
        <i className="icon-location mr-2"></i>

        <PlacesAutocomplete
          value={location}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input w-100 border-0',
                })}
              />
              <div className="autocomplete-dropdown-container w-100 shadow position-absolute left-0 rounded shadow bg-white text-gray-800 overflow-auto">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <div className="py-2 px-3 mb-1">
                        <i className="icon-location mr-2"></i>
                        <span>{suggestion.description}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  };
  const renderLocationSuggestions = () => {
    return (
      <div
        className={classnames({
          'searchBox__location-dropdown shadow bg-white rounded overflow-auto text-gray-900 font-normal position-absolute': true,
          'searchBox__location-dropdown--open': locationSuggestionsOpen,
        })}
      >
        <div className="p-3 d-md-flex d-none">
          <i className="icon-gps mr-2 font-xl pt-1 text-gray-900"></i>
          <div>
            <p className="font-base text-gray-900 mb-0">
              Use your current location
            </p>
            <p className="font-normal mb-0 text-gray-500">Marsh Harbour</p>
          </div>
        </div>
        <div className="py-1 px-3 border-top border-bottom bg-gray-100 text-gray-500 font-normal mb-2">
          Recent Locations
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
        <div className="py-1 px-3 border-top border-bottom bg-gray-100 text-gray-500 font-normal mb-2">
          Popular Locations
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
        <div className="py-2 px-3 mb-1">
          <i className="icon-location mr-2"></i>Clarence Town
        </div>
      </div>
    );
  };

  const renderCurrentLocationOption = () => {
    return (
      <div className="p-3 d-lg-none d-flex border-top bg-white border-bottom mb-3">
        <i className="icon-gps mr-2 font-xl pt-1 text-primary"></i>
        <div>
          <p className="font-md text-primary mb-0">Use your current location</p>
          <p className="font-normal mb-0 text-gray-500">Marsh Harbour</p>
        </div>
      </div>
    );
  };
  return (
    <div className="col-lg col-12 searchBox--outer">
      <div className="searchBox__close mt-3 d-lg-none">
        <i
          className="icon-close top-0 left-0"
          onClick={() => {
            document.body.classList.toggle('open-search');
          }}
        ></i>
      </div>
      <div className="searchBox bg-white rounded d-flex no-gutters flex-lg-row flex-column">
        {renderSearchBox()}

        {/* {renderSearchWithAutoComplete()} */}
        <div className="searchBox__location position-relative px-lg-0 px-3">
          {/* {renderLocationInput()}
          {renderLocationSuggestions()} */}
          {renderGoogleLocationInput()}
        </div>
        {renderCurrentLocationOption()}
        {renderSearchButton()}
      </div>
    </div>
  );
};

export default SearchBox;
