import React from 'react';
import "../../styles/cms.scss";

const MeetupSpots: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="bg-light-blue">
                <div className="cms-banner pt-5 position-relative overflow-hidden">
                    <div className="container pt-3 position-relative">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12">
                                <h1 className="text-white mb-3">Deal Den Safe<br />
                                    Meetup Spots
                                </h1>
                                <p className="font-lg font-weight-semibold text-white mb-5">A safer place to meet after making a deal on DealDen</p>
                            </div>
                            <div
                                className="col-md-6 col-12 text-center banner-right-block py-md-5 py-4 d-flex align-items-center justify-content-center">
                                <img src={require('../../assets/images/cms/meetupspots-banner-img.png')} className=" img-fluid position-relative my-md-5" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-5 pb-4">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-12 text-center">
                            <p className="h5 font-weight-normal mb-3">DealDen Safe Meetup Spots are designated locations at law enforcement offices where buyers and sellers can meet in public under surveillance to complete in-person transactions. There’s no charge for the service.</p>
                            <p className="h5 font-weight-normal mb-3">Use the Safe Meetup Spot Locator to find a location in your area</p>
                            <p className="h5 font-weight-bold mb-3">(The Bahamas police stations listing show be shown:)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row py-5">
                    <div className="col-md-6 col-12 px-5 pb-4">
                        <div className="border-bottom mb-3 pb-4 pr-3">
                            <h5 className="font-weight-bold mb-2 pb-1">Central Detective Unit</h5>
                            <div className="d-flex align-items-start font-md text-gray-900 mb-2 pb-1">
                                <i className="icon-location text-gray-500 mr-3"></i>
                                The Bahamas, Thompson Boulevard, Nassau, Bahamas
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <a href="tel:+1 242-502-9991" className="font-md text-gray-900">
                                        <i className="icon-phone1 text-gray-700 mr-3"></i>
                                        +1 242-502-9991
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.google.com/search?q=police+stations+in+bahamas&biw=1600&bih=700&tbm=lcl&ei=63CmYf7CJoacseMPocmJ2A4&oq=police+stations+in+bahamas&gs_l=psy-ab.3...0.0.0.5137.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.ZQ1i3XWJvP8#" className="font-md text-primary" target="_blank">
                                        <i className="icon-direction mr-3"></i>
                                        Get Direction
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 pb-4">
                        <div className="border-bottom mb-3 pb-4 pr-3">
                            <h5 className="font-weight-bold mb-2 pb-1">Wulff Road Police Station</h5>
                            <div className="d-flex align-items-start font-md text-gray-900 mb-2 pb-1">
                                <i className="icon-location text-gray-500 mr-3"></i>
                                Wulff Rd, Nassau N 458, Bahamas
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <a href="tel:+1 242-394-4540" className="font-md text-gray-900">
                                        <i className="icon-phone1 text-gray-700 mr-3"></i>
                                        +1 242-394-4540
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.google.com/search?q=police+stations+in+bahamas&biw=1600&bih=700&tbm=lcl&ei=63CmYf7CJoacseMPocmJ2A4&oq=police+stations+in+bahamas&gs_l=psy-ab.3...0.0.0.5137.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.ZQ1i3XWJvP8#" className="font-md text-primary" target="_blank">
                                        <i className="icon-direction mr-3"></i>
                                        Get Direction
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 pb-4">
                        <div className="border-bottom mb-3 pb-4 pr-3">
                            <h5 className="font-weight-bold mb-2 pb-1">Paradise Island Police Station</h5>
                            <div className="d-flex align-items-start font-md text-gray-900 mb-2 pb-1">
                                <i className="icon-location text-gray-500 mr-3"></i>
                                3MJH+QHX, Nassau, Bahamas
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <a href="tel:+1 242-363-3011" className="font-md text-gray-900">
                                        <i className="icon-phone1 text-gray-700 mr-3"></i>
                                        +1 242-363-3011
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.google.com/search?q=police+stations+in+bahamas&biw=1600&bih=700&tbm=lcl&ei=63CmYf7CJoacseMPocmJ2A4&oq=police+stations+in+bahamas&gs_l=psy-ab.3...0.0.0.5137.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.ZQ1i3XWJvP8#" className="font-md text-primary" target="_blank">
                                        <i className="icon-direction mr-3"></i>
                                        Get Direction
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 pb-4">
                        <div className="border-bottom mb-3 pb-4 pr-3">
                            <h5 className="font-weight-bold mb-2 pb-1">Elizabeth Estates Police Station</h5>
                            <div className="d-flex align-items-start font-md text-gray-900 mb-2 pb-1">
                                <i className="icon-location text-gray-500 mr-3"></i>
                                Prince Charles Dr, Nassau, Bahamas
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <a href="tel:+1 242-364-1700" className="font-md text-gray-900">
                                        <i className="icon-phone1 text-gray-700 mr-3"></i>
                                        +1 242-364-1700
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.google.com/search?q=police+stations+in+bahamas&biw=1600&bih=700&tbm=lcl&ei=63CmYf7CJoacseMPocmJ2A4&oq=police+stations+in+bahamas&gs_l=psy-ab.3...0.0.0.5137.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.ZQ1i3XWJvP8#" className="font-md text-primary" target="_blank">
                                        <i className="icon-direction mr-3"></i>
                                        Get Direction
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 pb-4">
                        <div className="border-bottom mb-3 pb-4 pr-3">
                            <h5 className="font-weight-bold mb-2 pb-1">Central Police Station</h5>
                            <div className="d-flex align-items-start font-md text-gray-900 mb-2 pb-1">
                                <i className="icon-location text-gray-500 mr-3"></i>
                                East St, Nassau, Bahamas
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <a href="tel:+1 242-322-4775" className="font-md text-gray-900">
                                        <i className="icon-phone1 text-gray-700 mr-3"></i>
                                        +1 242-322-4775
                                    </a>
                                </div>
                                <div>
                                    <a href="https://www.google.com/search?q=police+stations+in+bahamas&biw=1600&bih=700&tbm=lcl&ei=63CmYf7CJoacseMPocmJ2A4&oq=police+stations+in+bahamas&gs_l=psy-ab.3...0.0.0.5137.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.ZQ1i3XWJvP8#" className="font-md text-primary" target="_blank">
                                        <i className="icon-direction mr-3"></i>
                                        Get Direction
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MeetupSpots;
