import React from 'react';

const OtpIcon: React.FunctionComponent<{ className?: string }> = (props) => {
  return (
    <span className={`icon-otp-new ${props.className}`}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
      <span className="path11"></span>
      <span className="path12"></span>
      <span className="path13"></span>
      <span className="path14"></span>
      <span className="path15"></span>
      <span className="path16"></span>
      <span className="path17"></span>
      <span className="path18"></span>
      <span className="path19"></span>
      <span className="path20"></span>
      <span className="path21"></span>
    </span>
  );
};

export default OtpIcon;
