import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import useAuth from '../../../hooks/useAuth';
import api from '../../../api';
import userImage from '../../../assets/images/Profile-PNG-Icon.png';
import { useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firestore from '../../../components/firebase/firebase';
import CustomScrollbars from '../../../components/scrollbar/custom-scrollbar';
import Scrollbars from 'react-custom-scrollbars';
import { parseUrl } from 'query-string';
import { useHistory } from 'react-router-dom';
import { Dict } from '../../../models';
import ReportUser from '../../product-details/components/ReportUser';
import Modal from 'react-modal';
import SharePhoneNo from './sharePhoneNo';
import { AvatarView } from '../../../components/avatar';
import { Dropdown } from 'react-bootstrap';
import { ProductType } from '../../../api/models';
import classNames from 'classnames';
import _ from 'lodash';
interface OfferPricingModel {
  percentage: number;
  amount: number;
  className: string;
  heading: string;
  subText: string;
  icon: string;
}

const OfferPricing: Dict<OfferPricingModel> = {
  best: {
    percentage: 10,
    amount: 0,
    className: 'best',
    heading: 'Very good offer!',
    subText: 'High chances of seller response.',
    icon: 'icon-thumb',
  },
  average: {
    percentage: 30,
    amount: 0,
    className: 'average',
    heading: 'Average offer!',
    subText: 'Medium chance of seller response.',
    icon: 'icon-thumb',
  },
  bad: {
    percentage: 60,
    amount: 0,
    className: 'bad',
    heading: 'Bad offer!',
    subText: 'Little chance of seller response.',
    icon: 'icon-thumb',
  },
};

export enum AdsType {
  'Ads',
  'Favourite',
}
export enum OfferStatus {
  Offered = 'offered',
  Pending = 'pending',
  Accepted = 'offer_accepted',
  Paid = 'paid',
  Rejected = 'offer_rejected',
  Stalled = 'offer_stalled',
}

interface FormProps {
  product: {
    _id: string;
    title: string;
  };
  offerId: string;
  amount: number;
}

const ChatMessagesScreen: React.FunctionComponent<{
  onPayment: (data: FormProps) => void;
  chat: any;
}> = (props) => {
  const authState = useSelector((state: State) => state.authUser);
  const auth = useAuth();
  const { getCurrentTimeWithTimeOffset } = useAuth();
  const userId = auth.profile?._id?.toString() || '';
  const phone = auth.profile?.phone || '';
  const [
    currentOfferPricing,
    setCurrentOfferPricing,
  ] = useState<OfferPricingModel | null>(null);
  const customStyles = {
    content: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      margin: '20px auto',
      width: '100%',
      maxWidth: '500px',
      border: '0',
      padding: '0',
      background: 'transparent',
    },
    overlay: {
      overflow: 'auto',
    },
  };

  const [showPhoneModal, setShowPhoneModal] = React.useState(false);

  const [showFlagModal, setShowFlagModal] = React.useState(false);
  const [disableMakeOffer, setDisableMakeOffer] = React.useState(false);

  const [sharedPhone, setSharedPhone] = React.useState('');

  const [messages, setConversations] = useState<any[]>([]);

  const [localMessages, setLocalMessages] = useState<any[]>([]);
  const [mergedMessages, setMergedMessages] = useState<any>(messages);

  const toggleModal = () => {
    // var isMobileVersion = document.getElementsByClassName('snake--mobile');
    if (document.getElementsByClassName('modal-open').length > 0) {
      document.body.classList.remove('modal-open');
    } else {
      document.body.classList.add('modal-open');
    }
  };
  const hideModal = () => {
    document.body.classList.remove('modal-open');
    setShowFlagModal(false);
    setShowPhoneModal(false);
  };

  useEffect(() => {
    if (props?.chat?.productType) {
      setDisableMakeOffer(
        [ProductType.Auction, ProductType.Lead].indexOf(
          props?.chat?.productType
        ) > -1
      );
    }
    if (userId === props.chat.ownerId) {
      setDisableMakeOffer(true);
    }
  }, [props.chat]);

  const fetchBlogs = async () => {
    const response = firestore
      .collection('Chats')
      .doc(props.chat.id)
      .collection('Messages')
      .orderBy('createdAt', 'asc');
    setLoading(true);
    await response.onSnapshot({
      next: (querySnapshot) => {
        const items = querySnapshot.docs.map((docSnapshot) => {
          return {
            ...docSnapshot.data(),
            id: docSnapshot.id,
          };
        });
        setConversations(items);
        setLocalMessages(items);
        scrollToBottom();
        setLoading(false);
      },
      complete: () => {
        setMessageSet(true);
        setLoading(false);
      },
      error: () => {
        setMessageSet(false);
        setLoading(false);
      },
    });
  };

  const phoneApproved =
    messages.some((message) => {
      if (message.phoneRequestApproved) {
        return phone;
      } else {
        return '';
      }
    }) || '';
  // const fetchBlogs = async () => {
  //   const tempMessages: any[] = [];
  //   const response = firestore
  //     .collection('Chats')
  //     .doc(props.chat.id)
  //     .collection('Messages');
  //   const data = await response.get();
  //   data.docs.forEach(async (item) => {
  //     const message = item.data();
  //     tempMessages.push(message);
  //     console.log(tempMessages);
  //   });
  //   setConversations(tempMessages);
  // };

  const [offerClass, setOfferClass] = useState('tab-pane');
  const [questionClass, setQuestionClass] = useState('tab-pane active');
  const [offerTabClass, setOfferTabClass] = useState('nav-link text-center');
  const [questionTabClass, setQuestionTabClass] = useState(
    'nav-link text-center active'
  );
  const [messageText, setMessageText] = useState('');
  const [offerAmount, setOfferAmount] = useState('');
  // const [messages, setMessages] = useState<any[]>();
  const [messageSet, setMessageSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageSending, setMessageSending] = useState(false);

  const otherUser = props.chat.member_array?.find(
    (usr: any) => usr !== auth.profile?._id?.toString()
  );
  const isBuyer = otherUser === props.chat.buyerId ? true : false;

  const userDetails = isBuyer
    ? props.chat.member_metadata.offeredBy
    : props.chat.member_metadata.createdBy;
  // useEffect(() => {
  //   console.log(props.chat);
  // }, []);

  const userPhone = userDetails.phone;

  const productImageView = props.chat?.productImage;
  const userImageView = userDetails?.avatar;

  const userRef = firestore.collection('Users');

  const ownerRef = userRef.where('userId', '==', props.chat.ownerId);

  const buyerRef = userRef.where('userId', '==', props.chat.buyerId);

  const [owner] = useCollectionData(ownerRef, { idField: 'id' });
  const [buyer] = useCollectionData(buyerRef, { idField: 'id' });

  const offerClicked = () => {
    setOfferClass('tab-pane active');
    setQuestionClass('tab-pane');
    setOfferTabClass('nav-link text-center active');
    setQuestionTabClass('nav-link text-center');
  };

  const questionsClicked = () => {
    setOfferClass('tab-pane');
    setQuestionClass('tab-pane active');
    setOfferTabClass('nav-link text-center');
    setQuestionTabClass('nav-link text-center active');
  };

  const handleKeyDown = (e: string) => {
    setMessageText(e);
  };
  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.charCode === 13) {
      if (messageText && messageText.length > 0) {
        // setMessageSending(true);
        const data = {
          createdAt: getCurrentTimeWithTimeOffset(),
          createdDate: new Date(),
          isOffer: false,
          offerStatus: 'pending',
          sentBy: userId.toString(),
          text: messageText,
          type: 'text',
        };
        setLocalMessages([...localMessages, data]);
        hardScrollToBottom();
        await api.chat.createMessage(props.chat.id, data);
        if (messages) {
          setLoading(true);
          setMessageText('');
          setLoading(false);
          setMessageSending(false);
        }
      }
      scrollToBottom();
    }
  };

  const sendMessage = async () => {
    setLoading(true);
    if (messageText && messageText.length > 0) {
      setMessageSending(true);
      setLoading(true);
      // const data = {
      //   chatId: props.chat.id,
      //   productId: props.chat?.productId,
      //   offeredBy: userId,
      //   buyerId: props.chat.buyerId,
      //   type: 'text',
      //   text: messageText,
      //   isOffer: false,
      // };

      const data = {
        createdAt: getCurrentTimeWithTimeOffset(),
        isOffer: false,
        offerStatus: 'pending',
        sentBy: userId.toString(),
        text: messageText,
        type: 'text',
      };
      setLocalMessages([...localMessages, data]);
      await api.chat.createMessage(props.chat.id, data);
      setMessageText('');
      setLoading(false);
      setMessageSending(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    hardScrollToBottom();
    //   const list = [...messages];

    //   const combine = list.concat(localMessages);

    //   const sortedList = _.orderBy(combine, ['createdAt'], 'desc');
    //   setMergedMessages(_.uniqBy(sortedList, 'createdAt'));
  }, [localMessages]);

  const handleOfferChange = (e: string) => {
    if (!Number(e)) {
      return;
    }
    const price = +e;
    const data: OfferPricingModel = Object.values(OfferPricing).reduce(
      (prev, curr) => {
        return Math.abs(curr.amount - price) < Math.abs(prev.amount - price)
          ? curr
          : prev;
      }
    );
    setCurrentOfferPricing(data);
  };

  const suggestionClicked = (message: string) => {
    setMessageText(message);
  };

  const shareContact = () => {
    const data = {
      createdAt: getCurrentTimeWithTimeOffset(),
      createdDate: new Date(),
      isOffer: false,
      offerStatus: 'pending',
      phoneRequestApproved: true,
      phoneNoRequested: false,
      sentBy: userId.toString(),
      text: `Please feel free to contact me on my Phone Number : ${phone} `,
      type: 'text',
    };
    setLocalMessages([...localMessages, data]);
    api.chat.createMessage(props.chat.id, data);
  };

  const phoneNoRequest = () => {
    setShowPhoneModal(false);
    const data = {
      createdAt: getCurrentTimeWithTimeOffset(),
      createdDate: new Date(),
      isOffer: false,
      offerStatus: 'pending',
      phoneRequestApproved: false,
      phoneNoRequested: true,
      sentBy: userId.toString(),
      text: `Please share your phone number..`,
      type: 'text',
    };
    setLocalMessages([...localMessages, data]);
    api.chat.createMessage(props.chat.id, data);
  };

  const acceptOrRejectOffer = (
    offerId: string,
    status: string,
    messageId: string,
    message: any
  ) => {
    let allowApiCall = false;
    if (userId === props.chat.ownerId) {
      if (message.sentBy !== userId) {
        allowApiCall = true;
      }
    } else {
      if (message.sentBy !== userId) {
        allowApiCall = true;
      }
    }
    if (allowApiCall) {
      api.chat.acceptOrRejectOffer({
        chatId: props.chat.id,
        productId: props.chat?.productId,
        offerId,
        status,
        messageId,
      });
    }
  };

  const makePayment = (message: any) => {
    const data: FormProps = {
      amount: message.offerAmount,
      offerId: message.offerId,
      product: {
        _id: props.chat?.productId,
        title: props.chat?.productTitle,
      },
    };

    props.onPayment(data);
  };

  const sendOffer = () => {
    if (offerAmount && Number(offerAmount) > 0) {
      const data = {
        createdAt: getCurrentTimeWithTimeOffset(),
        chatId: props.chat.id,
        offeredBy: userId,
        buyerId: props.chat.buyerId,
        productId: props.chat?.productId,
        type: 'offer',
        text: `offer $ ${offerAmount}`,
        offerAmount: Number(offerAmount),
        isOffer: true,
        offerStatus: 'pending',
        sentBy: userId.toString(),
      };
      const data1 = {
        createdAt: getCurrentTimeWithTimeOffset(),
        isOffer: false,
        offerStatus: 'pending',
        sentBy: userId.toString(),
        text: messageText,
        type: 'text',
      };
      const d = [...localMessages, data];
      setLocalMessages(d);
      api.chat.sendOffer(data);
    }
    setMessageText('');
  };

  const scrollRef = useRef<Scrollbars>();
  const divRref = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (scrollRef.current) scrollRef?.current?.scrollToBottom();
  };
  const hardScrollToBottom = () => {
    if (divRref.current)
      divRref?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const history = useHistory();
  React.useEffect(() => {
    const { query } = parseUrl(window.location.href);
    if (query?.view === 'make-offer' && !disableMakeOffer) {
      offerClicked();
      history.replace({
        search: '',
      });
    }
    updateOfferPricing(Object.keys(OfferPricing));
  }, [disableMakeOffer.toString()]);
  // React.useEffect(() => {
  //   if (!messageSet) {
  //     fetchBlogs();
  //   }
  //   scrollToBottom();
  // }, []);
  React.useEffect(() => {
    // if(!messageSet)
    fetchBlogs();
    scrollToBottom();
    updateOfferPricing(Object.keys(OfferPricing));
  }, [props.chat.id]);

  const updateOfferPricing: any = async (data: string[]) => {
    if (data.length > 0) {
      const key = data.shift();
      if (key) {
        const pricing = OfferPricing[key];
        const calculatedPrice =
          props.chat.productPrice -
          Math.ceil(props.chat.productPrice * (pricing.percentage / 100));
        OfferPricing[key].amount = calculatedPrice;
        return updateOfferPricing(data);
      }
    }
    return true;
  };

  // need to show
  const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    )
  );
  const renderChat = () => {
    let prevUserId: string | null = null;
    if (!messages) {
      return (
        <div className="col-lg col-12 chat-align px-0 px-lg-3">
          <div className="chat-panel ">
            <div className="chat-hed bg-white rounded">
              <div className="chat-inner-head d-flex mb-1 mb-1">
                <div className="align-items-center d-flex mob-prev d-lg-none">
                  <i
                    className="icon-prev font-lg text-white mr-3"
                    onClick={() => {
                      document.body.classList.remove('open-chatwindow');
                    }}
                  ></i>
                </div>
                <div className="avatar position-relative">
                  <img src={productImageView} className="className-fluid" />
                  <AvatarView
                    className="avatar-sub"
                    src={userImageView}
                    size="medium"
                  />
                </div>
                <div className="chat-name col-7 d-flex flex-lg-column px-0">
                  <p className="font-md m-0 d-lg-block d-none text-truncate">
                    {props.chat.productTitle}
                  </p>
                  <h6 className="mb-0 text-gray-900 d-lg-block d-none">
                    ${props.chat?.productPrice}
                  </h6>
                  <span className="d-none d-lg-block">{userDetails.name}</span>
                  <h6 className="align-self-center d-lg-none mb-0 text-gray-900">
                    {userDetails.name}
                  </h6>
                </div>
                <div className="chat-actions ml-auto align-items-center d-flex">
                  <div className="actions">
                    <ul className="m-0 list-unstyled d-flex">
                      <li>
                        <i
                          className="icon-phone cursor-pointer"
                          data-toggle="modal"
                          data-target="#numberrequest-modal"
                          onClick={() => toggleModal()}
                        ></i>
                      </li>
                      <li>
                        <i className="icon-flag cursor-pointer"></i>
                      </li>
                      <li>
                        <i className="icon-more cursor-pointer"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mob-product-view d-flex align-items-center d-lg-none px-3">
                <div className="mob-product-detail">
                  <figure className="m-0">
                    <img src={productImageView} />
                  </figure>
                  <h6 className="text-gray-900 mb-0 text-truncate">
                    ${props.chat?.productPrice}
                  </h6>
                  <p className="m-0">{props.chat.productTitle}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="col-lg col-12 chat-align px-0 px-lg-3 ">
          <div className="chat-panel ">
            <div className="chat-hed bg-white rounded">
              <div className="chat-inner-head  mb-1">
                <div className="chat-user-details">
                  <i
                    className="d-lg-none font-lg icon-prev left-0 mob-prev p-2 position-absolute text-white"
                    onClick={() => {
                      document.body.classList.remove('open-chatwindow');
                    }}
                  ></i>
                  <div className="product-image">
                    <div className="avatar position-relative">
                      <img
                        src={productImageView}
                        className="className-fluid avatar-main"
                      />
                      <AvatarView
                        className="avatar-sub"
                        src={userImageView}
                        size="medium"
                      />
                    </div>
                  </div>
                  <div className="chat-name d-flex flex-lg-column px-0">
                    <p className="font-md m-0 d-lg-block d-none text-truncate title">
                      {props.chat.productTitle}
                    </p>
                    <h6 className="mb-0 text-gray-900 d-lg-block d-none">
                      ${props.chat?.productPrice}
                    </h6>
                    <span className="d-none d-lg-block">
                      {userDetails.name}
                    </span>
                    <h6 className="align-self-center d-lg-none mb-0 text-gray-900 text-truncate d-block mt-2">
                      {userDetails.name}
                    </h6>
                  </div>

                  <div className="chat-actions align-items-center d-flex px-0">
                    <div className="actions">
                      <ul className="m-0 list-unstyled d-flex">
                        <li>
                          {/* {sharePhone && (
                          <Tooltip placement="left" trigger={['click']} overlay={<span>tooltip</span>}>
                            <i
                              className="icon-phone1 cursor-pointer"
                              data-toggle="modal"
                              data-target="#numberrequest-modal"
                              onClick={() => {
                                if (!sharePhone) {
                                  setShowPhoneModal(true)
                                  toggleModal()
                                }
                              }}
                            ></i>
                          </Tooltip>
                        )} */}
                          <i
                            className="icon-phone1 cursor-pointer"
                            data-toggle="modal"
                            data-target="#numberrequest-modal"
                            onClick={() => {
                              // if (!sharedPhone.length) {
                              setShowPhoneModal(true);
                              toggleModal();
                              // }
                            }}
                          ></i>
                        </li>
                        <li>
                          <i
                            className="icon-flag cursor-pointer"
                            onClick={() => {
                              setShowFlagModal(true);
                              toggleModal();
                            }}
                          ></i>
                        </li>
                        {/* <li>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="icon-more cursor-pointer"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>Block User</Dropdown.Item>

                              <Dropdown.Item>Delete Chat</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mob-product-view d-block  d-lg-none px-3">
                <div className="mob-product-detail">
                  <figure className="m-0">
                    <img src={productImageView} />
                  </figure>
                  <h6 className="text-gray-900 mb-0 text-truncate">
                    ${props.chat?.productPrice}
                  </h6>
                  <p className="m-0 text-truncate-multiple">
                    {props.chat.productTitle}
                  </p>
                </div>
              </div>
            </div>

            <div className="chat-inner">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                ref={scrollRef}
              >
                {localMessages.map((message: any) => {
                  let showImage = false;
                  if (!prevUserId) {
                    prevUserId = message.sentBy;
                    showImage = true;
                  } else {
                    if (prevUserId !== message.sentBy) {
                      showImage = true;
                      prevUserId = message.sentBy;
                    }
                  }
                  const chatClass =
                    message.sentBy === userId
                      ? 'chat-in chat-send'
                      : 'chat-in chat-receive';
                  const isOffer = message.isOffer;
                  // const image =
                  //   props.chat.member_metadata[message.sentBy]?.avatar;
                  let image = userImage;

                  if (message.sentBy === props.chat.ownerId) {
                    const ownerUser: any | undefined =
                      owner?.find((t: any) => t.userId === message.sentBy) ||
                      undefined;

                    image = ownerUser?.image || userImage;
                  }
                  if (message.sentBy === props.chat.buyerId) {
                    const ownerUser: any | undefined =
                      buyer?.find((t: any) => t.userId === message.sentBy) ||
                      undefined;
                    image = ownerUser?.image || userImage;
                  }

                  let showAcceptButton = false;
                  let showPayButton = true;
                  let sharePhoneNoButton = false;

                  if (userId === props.chat.ownerId) {
                    showPayButton = false;
                    if (message.sentBy !== userId) {
                      showAcceptButton = true;
                      sharePhoneNoButton = true;
                    }
                  } else {
                    if (message.sentBy !== userId) {
                      showAcceptButton = true;
                      sharePhoneNoButton = true;
                    }
                  }

                  if (message.phoneNoRequested) {
                    return (
                      <div
                        key={`message-accepted-${message.id}`}
                        className={chatClass}
                      >
                        <div className="chat-propic">
                          <AvatarView
                            src={
                              props.chat.member_metadata[message.sentBy]?.avatar
                            }
                          />
                          {/* <img src={props.chat.member_metadata[message.sentBy]?.avatar} className="img-fluid" /> */}
                        </div>
                        <div className="chat-area accepted-action">
                          <div className="chat-content">
                            <div className="chat-time-read">
                              {moment(message.createdAt).format('HH:mm')}
                              <i className="icon-read chat-tick"></i>
                            </div>
                            <p>Please share your phone number.</p>
                          </div>
                          {sharePhoneNoButton && (
                            <div className="chat-in-buttons">
                              <button
                                className="border border-primary btn btn-sm px-3 text-primary mr-2"
                                onClick={() => {
                                  setSharedPhone(phone);
                                  shareContact();
                                }}
                              >
                                Share Phone Number
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (!isOffer) {
                    return (
                      <div key={`message-${message.id}`}>
                        <div className=" position-relative text-center">
                          <span></span>
                        </div>

                        <div className={chatClass}>
                          <div className="chat-propic">
                            <AvatarView
                              src={
                                props.chat.member_metadata[message.sentBy]
                                  ?.avatar
                              }
                            />
                            {/* <img
                              src={props.chat.member_metadata[message.sentBy]?.avatar}
                              className="className-fluid"
                            /> */}
                          </div>
                          <div className="chat-area">
                            <div className="chat-content">
                              <div className="chat-time-read">
                                {moment(message.createdAt).format('HH:mm')}{' '}
                                {/* <i className="icon-chat-tick chat-tick"></i> */}
                                <i className="icon-read chat-tick"></i>
                                {/* <i className="icon-read red chat-tick"></i> */}
                              </div>
                              <p>{message.text}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    if (message.offerStatus === OfferStatus.Pending) {
                      return (
                        <div
                          key={`message-pending-${message.id}`}
                          className={chatClass}
                        >
                          <div className="chat-propic">
                            <AvatarView
                              src={
                                props.chat.member_metadata[message.sentBy]
                                  ?.avatar
                              }
                            />
                            {/* <img src={props.chat.member_metadata[message.sentBy]?.avatar} className="img-fluid" /> */}
                          </div>
                          <div className="chat-area offer-action">
                            <div className="chat-content">
                              <div className="chat-time-read">
                                {moment(message.createdAt).format('HH:mm')}{' '}
                                <i className="icon-read chat-tick"></i>
                              </div>
                              <p> Offer</p>
                              <span className="offer-price">
                                $ {message.offerAmount}
                              </span>
                            </div>
                            {showAcceptButton && (
                              <div className="chat-in-buttons">
                                <button
                                  className="border border-primary btn btn-sm edit-offer px-3 text-primary mr-2"
                                  onClick={() =>
                                    acceptOrRejectOffer(
                                      message.offerId,
                                      OfferStatus.Accepted,
                                      message.id,
                                      message
                                    )
                                  }
                                >
                                  Accept
                                </button>
                                <button
                                  className="border border-primary btn btn-sm edit-offer px-3 text-primary"
                                  onClick={() =>
                                    acceptOrRejectOffer(
                                      message.offerId,
                                      OfferStatus.Rejected,
                                      message.id,
                                      message
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else if (
                      message.offerStatus === OfferStatus.Accepted ||
                      message.offerStatus === OfferStatus.Paid
                    ) {
                      return (
                        <div
                          key={`message-accepted-${message.id}`}
                          className={chatClass}
                        >
                          <div className="chat-propic">
                            <AvatarView
                              src={
                                props.chat.member_metadata[message.sentBy]
                                  ?.avatar
                              }
                            />
                            {/* <img src={props.chat.member_metadata[message.sentBy]?.avatar} className="img-fluid" /> */}
                          </div>
                          <div className="chat-area accepted-action">
                            <div className="chat-content">
                              <div className="chat-time-read">
                                {moment(message.createdAt).format('HH:mm')}
                                <i className="icon-read chat-tick"></i>
                              </div>
                              <div className="accept-content d-flex justify-content-between ">
                                <span className="accept-amount text-gray-600 font-xs">
                                  $ {message.offerAmount}
                                </span>
                                <span className="accept-text font-xs">
                                  Accepted
                                </span>
                              </div>
                              <p>
                                I accept your offer, Let's move towards the
                                final deal
                              </p>
                            </div>
                            {showPayButton && (
                              <div className="chat-in-buttons">
                                <button
                                  className="border border-primary btn btn-sm px-3 text-primary mr-2"
                                  onClick={() => phoneNoRequest()}
                                >
                                  Ask Contact
                                </button>
                                {!(
                                  message.offerStatus === OfferStatus.Paid
                                ) && (
                                  <button
                                    className="border border-primary btn btn-sm px-3 text-primary"
                                    onClick={() => makePayment(message)}
                                  >
                                    Pay Now
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else if (message.offerStatus === OfferStatus.Rejected) {
                      return (
                        <div
                          key={`message-rejected-${message.id}`}
                          className={chatClass}
                        >
                          <div className="chat-propic">
                            <AvatarView
                              src={
                                props.chat.member_metadata[message.sentBy]
                                  ?.avatar
                              }
                            />
                            {/* <img src={props.chat.member_metadata[message.sentBy]?.avatar} className="img-fluid" /> */}
                          </div>
                          <div className="chat-area rejected-action">
                            <div className="chat-content">
                              <div className="chat-time-read">
                                {moment(message.createdAt).format('HH:mm')}
                                <i className="icon-read chat-tick"></i>
                              </div>
                              <div className="reject-content d-flex justify-content-between ">
                                <span className="reject-amount text-gray-600 font-xs">
                                  $ {message.offerAmount}
                                </span>
                                <span className="reject-text font-xs">
                                  Rejected
                                </span>
                              </div>
                              <p>
                                Sorry I cannot sell the item at this price,
                                Please give me a better offer.
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
                <div ref={divRref} />
              </CustomScrollbars>
            </div>
          </div>

          <div className="fix-chat-footer">
            <div className="que-offer-tab">
              <div className="tab styleTwo">
                <div className="tab-hed ">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className={classNames('nav-item', {
                        'w-100': disableMakeOffer,
                        'w-50': !disableMakeOffer,
                      })}
                    >
                      <a
                        className={questionTabClass}
                        id="questions-tab"
                        data-toggle="tab"
                        // href="#questions"
                        href="javascript:void(0)"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => questionsClicked()}
                      >
                        <i className="icon-questions pr-2"></i>Questions
                        <div className="font-normal text-gray-600 font-weight-normal d-none d-md-block">
                          Discuss about the product
                        </div>
                      </a>
                    </li>
                    {!disableMakeOffer ? (
                      <li className="nav-item w-50">
                        <a
                          className={offerTabClass}
                          id="offer-tab"
                          data-toggle="tab"
                          href="javascript:void(0)"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          onClick={() => offerClicked()}
                        >
                          <i className="icon-offer pr-2"></i>Make an Offer
                          <div className="font-normal text-gray-600 font-weight-normal d-none d-md-block">
                            Product price: ${props.chat?.productPrice}
                          </div>
                        </a>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </div>

                <div className="tab-content">
                  <div
                    className={questionClass}
                    id="questions"
                    role="tabpanel"
                    aria-labelledby="questions-tab"
                  >
                    <div className="chat-words-block">
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked('Hello')}
                      >
                        Hello
                      </span>
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked(' Is it available?')}
                      >
                        Is it available?
                      </span>
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked('Okey')}
                      >
                        Okay
                      </span>
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked('No problem')}
                      >
                        No problem
                      </span>
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked(' Please reply')}
                      >
                        Please reply
                      </span>
                      <span
                        className="chat-word rounded-pill"
                        onClick={() => suggestionClicked('  Not interested')}
                      >
                        Not interested
                      </span>
                    </div>

                    <div className="chat-text">
                      <div className="file-attach-chat d-flex align-items-center">
                        {/* <div className="file-attach">
                          <label className="fileup m-0">
                            <i className="icon-attach"></i>
                            <input type="file" hidden className="upload1" />
                          </label>
                        </div> */}
                        <div className="type-chat w-100 d-flex align-items-center">
                          <input
                            type="text"
                            placeholder="Write a message"
                            className="form-control"
                            onChange={(e: any) => handleKeyDown(e.target.value)}
                            onKeyPress={(e: React.KeyboardEvent) =>
                              handleKeyPress(e)
                            }
                            value={messageText}
                          />
                          <div className="ofr-send">
                            <button
                              disabled={loading || props.chat.isSold}
                              className="btn btn-primary px-4 py-2"
                              onClick={() => sendMessage()}
                            >
                              {messageSending ? 'Sending..' : 'Send'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={offerClass}
                    id="offer"
                    role="tabpanel"
                    aria-labelledby="offer-tab"
                  >
                    <div className="price-offering-section">
                      <div className="offering-input">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-transparent border-0">
                              $
                            </span>
                          </div>
                          <span>
                            <input
                              type="number"
                              className="offer-add"
                              value={offerAmount}
                              onKeyUp={(e: any) => {
                                handleOfferChange(e.target.value);
                              }}
                              onChange={(event) => {
                                setOfferAmount(event.target.value);
                                handleOfferChange(event.target.value);
                              }}
                            />
                          </span>
                        </div>
                        <div className="offeradd-inner mb-2 mt-4 d-flex">
                          {currentOfferPricing ? (
                            <div
                              className={`about-offer align-items-center ${currentOfferPricing.className} d-flex mr-2`}
                            >
                              <span className="pr-2">
                                <i
                                  className={`${currentOfferPricing.icon} text-white`}
                                ></i>
                              </span>
                              <div>
                                <p className="text-white m-0">
                                  {currentOfferPricing.heading}
                                </p>
                                <span className="text-white font-xs">
                                  {currentOfferPricing.subText}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="ofr-send">
                            <button
                              disabled={loading || props.chat.isSold}
                              className="btn btn-primary px-4 py-2"
                              onClick={() => sendOffer()}
                            >
                              {messageSending ? 'Sending..' : 'Send'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SharePhoneNo
            showModal={showPhoneModal}
            onClick={hideModal}
            phone={sharedPhone}
            phoneApproved={phoneApproved}
            phoneNumber={userPhone}
            onSubmit={phoneNoRequest}
          />
          <Modal
            isOpen={showFlagModal || false}
            contentLabel={'Report this User'}
            onRequestClose={() => toggleModal()}
            shouldCloseOnOverlayClick={true}
            style={customStyles}
          >
            <ReportUser userId={otherUser} onRequestClose={() => hideModal()} />
          </Modal>
        </div>
      </>
    );
  };

  return <>{renderChat()}</>;
};
export default ChatMessagesScreen;
