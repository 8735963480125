import React, { useState } from 'react';
import noRecord from '../../assets/images/no-result.png';

const Empty = () => {
  const [isImageLoad, setIsImageLoad] = useState(false);
  return (
    <div>
      <div className="row my-3 gutter-0">
        <div className="col-12 align-items-center col-12 d-flex flex-column justify-content-center py-4 py-lg-0">
          <img
            onLoad={() => setIsImageLoad(true)}
            className="img-fluid mb-2"
            src={noRecord}
          />
          {isImageLoad ? (
            <span className="text-gray-500">No records found</span>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Empty;
