import React, { useState } from 'react';
import { Category, Subcategory } from '../../../api/models';
import useEntities from '../../../hooks/useEntities';
import classnames from 'classnames';
import './CategorySelection.scss';
import { ContainerLoader } from '../../../components/loader';
import { scrollTop, scrollTopDefault } from '../../../utils/window-utils';
const CategorySeletion: React.FunctionComponent<{
  onSelect: (category?: Category, subCategory?: Subcategory) => void;
  onClearCategory: () => void;
}> = (props) => {
  const entities = useEntities();
  const [selected, setSelected] = useState<Category | undefined>(
    entities.categories[0]
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState<Subcategory>();

  const renderSubcategory = (subcategory: Subcategory) => {
    return (
      <div
        className={classnames({
          'category-selection_list justify-content-between p-3 row': true,
          selected: selectedSubCategory?._id === subcategory._id,
        })}
        onClick={() => [
          setSelectedSubCategory(subcategory),
          props.onSelect(selected || entities.categories[0], subcategory!),
        ]}
      >
        <p className="font-base m-0">{subcategory.title}</p>
        <i className="next-arrow-bold d-lg-none"></i>
      </div>
    );
  };
  const renderCategory = (category: Category) => {
    return (
      <div
        onClick={() => {
          setSelected(category);
          scrollTopDefault();
        }}
        className={classnames({
          'row  category-selection_list main p-3': true,
          selected: selected?._id
            ? selected?._id === category._id
            : entities.categories[0]._id === category._id,
        })}
      >
        <figure>
          <img src={category.image?.path} className="img-fluid" height={50} />
        </figure>
        <p className="font-base m-0  col">{category.title}</p>
        <i className="next-arrow-bold"></i>
      </div>
    );
  };
  return (
    <>
      <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center">
        <h3 className="m-0 text-center text-gray-900">Post Your Ad</h3>
      </div>
      {entities.categories && entities.categories.length > 0 && (
        <>
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
            {selected && (
              <i
                className="icon-prev font-lg mr-3"
                onClick={(e) => {
                  props.onClearCategory();
                  setSelected(undefined);
                }}
              ></i>
            )}
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
              {selected ? selected.title : 'Choose Category'}
            </h6>
          </div>
          <div className="post-product">
            <div className="container">
              <div className="row d-flex justify-content-center my-lg-4">
                <div className="col-lg-auto col-12 post-col">
                  <div className="bg-white overflow-hidden post-product-inner category-outer">
                    <h5 className="text-gray-900 m-0 mb-4 d-none d-lg-block">
                      Choose Category
                    </h5>
                    <div
                      className={
                        selected
                          ? 'category-selection d-flex show-sub-category'
                          : 'category-selection d-flex'
                      }
                    >
                      <div className="col-12 col-lg-6 main-category">
                        {entities.categories
                          .sort((a, b) => {
                            return a.title > b.title ? 1 : -1;
                          })
                          .map(renderCategory)}
                      </div>
                      <div className="col-12 col-lg-6 sub-category">
                        {entities
                          .getSubcategories(
                            selected?._id || entities.categories[0]._id
                          )
                          .map(renderSubcategory)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <input
            disabled={!selected || !selectedSubCategory}
            type="submit"
            className="btn py-2 btn-primary font-weight-bold btn-block"
            value="Next"
            onClick={() => props.onSelect(selected!, selectedSubCategory!)}
          /> */}
        </>
      )}
      {!entities.categories ||
        (entities.categories && entities.categories.length <= 0 && (
          <ContainerLoader height={500}></ContainerLoader>
        ))}
    </>
  );
};

export default CategorySeletion;
