import React, { useRef } from 'react';
import { PageType } from '../../profile';
import { Link, useHistory, useParams } from 'react-router-dom';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import { useDataLoader } from 'react-remote-data-hooks';
import { RouteKeys } from '../../routes/route-keys';
import { OrderDetail } from '../../../api/models/order';
import { format } from 'date-fns';
import { OrderStatusSyllables } from '../../../api/models';
import useAuth from '../../../hooks/useAuth';
import ComponentToPrint from './Order-Details-Page';
import PdfDownloadButton from '../../../components/pdf-download-button';

const OrderDetails: React.FunctionComponent<{}> = () => {
  const componentRef = useRef();

  const history = useHistory();
  const auth = useAuth();

  const params = useParams<{ id: string }>();
  if (!params.id) {
    history.push(RouteKeys.MyOrders);
  }
  const { data, reload, loading, loaded } = useDataLoader<OrderDetail>(() =>
    api.order.getOrderDetails(params.id)
  );
  function downloadPdf(id: string) {
    return api.order.downloadOrderDetails(id)
  }
  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/profile">My Account</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/my-orders">My Orders</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Order Details
          </li>
        </ol>
      </nav>
    );
  };
  return (
    <div className="my-ads py-0 py-lg-4">
      <div className="container">
        {renderBreadCrumbs()}
        <div className="row">
          <MyAccount pageType={PageType.MyOrders} />
          <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
              <Link to="/my-orders" className="text-white">
                <i className="icon-prev font-lg mr-3"></i>My Order Details
              </Link>
            </h6>
          </div>
          {loaded && !loading && (
            <div className="col-lg-9">
              <div className="right-panel">
                <div className="justify-content-between align-items-center line-height-sm d-lg-flex d-none">
                  <h5 className="follow-head">My Order Details</h5>
                  <div className="back-btn">
                    <a className="text-gray-500" href="/my-orders">
                      <span className="icon-prev pr-1 font-xxxs"></span>Back
                    </a>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="rounded bg-white p-3 p-lg-4 shadow">
                    <div className="p-lg-1">
                      <div className="d-flex justify-content-between border-bottom align-items-center pb-3">
                        <h6 className="p-0 m-0">
                          Invoice No: {data?.paymentId?.invoiceNumber}
                        </h6>
                        <div className="right-icons">
                          {/* <a className="icon-print mx-2 text-gray-600"></a> */}
                          <PdfDownloadButton
                            id={params.id}
                            downloadFn={downloadPdf}
                          ></PdfDownloadButton>
                          {/* <a className="icon-share mx-2 text-gray-600"></a> */}
                        </div>
                      </div>
                      <div className="d-lg-flex justify-content-between  mt-4">
                        <div className="row">
                          <div className="col-12 col-md border-bottom pb-4 pb-lg-5">
                            <div className="row">
                              <div className="col-5 col-md-4 text-muted pb-2">
                                Order ID{' '}
                              </div>
                              <div className="col-7 col-md-8">
                                #{data.orderId}
                              </div>

                              <div className="col-5 col-md-4 text-muted pb-2">
                                Order On{' '}
                              </div>
                              <div className="col-7 col-md-8">
                                {format(
                                  new Date(data.createdAt!),
                                  'dd-MMM-yyyy hh:mm a'
                                )}
                              </div>

                              <div className="col-5 col-md-4 text-muted pb-2">
                                Order Status{' '}
                              </div>
                              <div className="col-7 col-md-8 text-success">
                                {OrderStatusSyllables?.[data.orderStatus].label}
                              </div>

                              <div className="col-5 col-md-4 text-muted pb-2">
                                Delivered On{' '}
                              </div>
                              <div className="col-7 col-md-8">
                                {data.deliveredOn
                                  ? format(
                                    new Date(data.deliveredOn!),
                                    'dd-MMM-yyyy hh:mm a'
                                  )
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-5 border-bottom pb-4 pb-lg-5 pt-4 pt-lg-0">
                            <h6 className="font-normal">
                              {`${auth.profile?.billingAddress?.firstName},
                                ${auth.profile?.billingAddress?.lastName}`}
                            </h6>
                            <span>
                              {auth.profile?.billingAddress?.address}
                              {auth.profile?.billingAddress?.city},{' '}
                              {auth.profile?.billingAddress?.state},{' '}
                              {auth.profile?.billingAddress?.country},{' '}
                              {auth.profile?.billingAddress?.zip}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3 pt-lg-4">
                        <div className="row align-items-center ads-item border-bottom pb-3 pb-lg-4 ">
                          <div className="align-items-center col-12 col-md-6 d-flex pl-lg-0">
                            <figure className="mb-0 position-relative d-flex align-items-center noresize">
                              <div className="image overflow-hidden bg-light noresize">
                                <img
                                  className="img-fluid"
                                  src={data.productDetails.defaultImage?.path}
                                />
                              </div>
                              <figcaption>
                                <span className="title font-base d-block text-gray-700">
                                  {data.productDetails.title}
                                </span>
                                <span className="date font-sm text-gray-700">
                                  <span className="text-muted pr-1">
                                    Seller:{' '}
                                  </span>
                                  {data.productDetails.createdBy?.name}
                                  <span className="d-lg-none d-block">
                                    Quantity: 1
                                  </span>
                                </span>
                              </figcaption>
                            </figure>
                          </div>
                          <div className="col-12 col-md-3 d-lg-block d-none">
                            <span className="text-muted">Quantity: </span> 1
                          </div>
                          <div className="col-12 col-md-3 d-lg-block d-none text-right">
                            $ {data.productDetails.price}
                          </div>
                        </div>

                        <div className="row align-items-center pt-3">
                          <div className="col-12 col-md-6 d-flex align-items-center ">
                            <span className="text-muted">
                              Payment Method :{' '}
                            </span>{' '}
                            {data?.paymentId?.paymentMode}-
                            {data?.paymentId?.stripePaymentDetails
                              ?.payment_method_types?.length &&
                              data?.paymentId?.stripePaymentDetails
                                ?.payment_method_types[0]
                            }
                          </div>
                          {/* <div className="col-12 col-md-6 d-flex align-items-center ">
                            <span className="text-muted">
                              Payment ReferenceId :{' '}
                            </span>{' '}
                            {data.paymentId.stripePaymentDetails?.id}
                          </div> */}
                          <div className="col-12 col-md-6 ">
                            <div className="align-items-center row">
                              <div className="col-7 mb-2 text-muted">
                                Subtotal Amount
                              </div>
                              <div className="col-5 text-right mb-2 font-base">
                                $ {data.offeredAmount}
                              </div>
                              <div className="col-7 mb-2">Total</div>
                              <div className="col-5 text-right mb-2 font-weight-bold font-base">
                                $ {data.offeredAmount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}{' '}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
