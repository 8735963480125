import React from 'react';

const ErrorMessage: React.FunctionComponent<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <div className="col-12 error-outer">
      <span className={`error text-danger ${className || ''}`}>{children}</span>
    </div>
  );
};

export default ErrorMessage;
