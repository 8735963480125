import React, { FunctionComponent, useEffect } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { convertToBahamasTimezone } from '../../utils/date-utils';

export enum CountDownType {
  block = 'block',
  text = 'text',
}
interface CustomProps {
  startDate: Date;
  endDate: Date;
  completedMessage?: React.ReactNode;
  onCompleted?: () => void;
  startLabel?: string;
  endLabel?: string;
  type?: CountDownType;
  isComplete?: boolean;
}

const initialProps = {
  isComplete: false,
  startDate: new Date(),
  endDate: new Date(),
  startLabel: 'Starts in',
  endLabel: 'Left',
  type: CountDownType.block,
};

const DealCountDown: FunctionComponent<CustomProps> = (
  props: CustomProps = initialProps
) => {
  // const startDate = convertToBahamasTimezone(props.startDate)
  // const endDate = convertToBahamasTimezone(props.endDate)
  const startDate = props.startDate
  const endDate = props.endDate

  const hasStarted = new Date().getTime() > new Date(startDate).getTime();
  const hasEnded = new Date().getTime() < new Date(endDate).getTime();

  const CompletionList = () => {
    return props.completedMessage ? (
      <>{props.completedMessage}</>
    ) : (
      <span>Auction Closed!</span>
    );
  };

  // if (new Date(startDate).getTime() < new Date().getTime()) return (<></>);
  return (
    <>
      {/* {!hasEnded && !props.isComplete ? (
        <span>{hasStarted ? 'Ends In' : 'Starts In'}</span>
      ) : (
        ''
      )} */}
      <Countdown
        date={hasStarted ? new Date(endDate) : new Date(startDate)}
        onComplete={props.onCompleted}
        renderer={({
          days,
          hours,
          minutes,
          seconds,
          completed,
        }: CountdownRenderProps) => {
          if (completed) {
            return <CompletionList />;
          }
          if (props.type === CountDownType.text) {
            return (
              <>
                <span>{hasStarted ? 'Ends In' : 'Starts In'}</span>{' '}
                <span className="font-weight-bold">{days} </span> Days{' '}
                <span className="font-weight-bold">{hours}</span> Hours{' '}
                <span className="font-weight-bold">{minutes}</span> Mins{' '}
                <span className="font-weight-bold">{seconds}</span> Sec{' '}
                {props.endLabel || ''}
              </>
            );
          }
          if (props.type === CountDownType.block) {
            return (
              <div className="relative">
                <span
                  style={{
                    top: '-15px',
                    position: 'absolute',
                    left: '20px',
                  }}
                >
                  {hasStarted ? 'Ends In' : 'Starts In'}
                </span>
                <div className="row gutter-5 text-center">
                  <div className="col">
                    <div className="count-down text-primary">{days}</div>
                    Day
                  </div>
                  <div className="col">
                    <div className="count-down text-primary">{hours}</div>
                    Hours
                  </div>
                  <div className="col">
                    <div className="count-down text-primary">{minutes}</div>
                    Minute
                  </div>
                  <div className="col">
                    <div className="count-down text-primary">{seconds}</div>
                    Secs
                  </div>
                </div>
              </div>
            );
          }
        }}
      />
    </>
  );
};

export default DealCountDown;
