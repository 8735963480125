import React from 'react';
import LogoSVG from '../../../components/logo/logo-svg';
import { RouteKeys } from '../../routes/route-keys';
import SearchBox from './SearchBox';
import SellButton from './SellButton';
import UserOptions from './UserOptions';

const TopHeader: React.FunctionComponent = (props) => {
  return (
    <div className="header-top bg-primary position-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-2 col-6">
            <a href={RouteKeys.Home} className="logo">
              <LogoSVG type="white" className="img-fluid" />
            </a>
          </div>
          <SearchBox />
          <UserOptions />
          {/* <SellButton /> */}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
