import React from 'react';
import { RatingSummary } from '../../api/models';

const RatingSummaryView: React.FunctionComponent<{
  summary: RatingSummary;
}> = (props) => {
  return (
    <div className="d-flex mb-4">
      <div className="text-center total-rating border-right px-sm-3 pr-3 py-2">
        <h3 className="text-gray-900">
          {props.summary.overallRating}
          <i className="icon-star ml-2"></i>
        </h3>
        <div className="font-normal text-gray-500">
          {props.summary.totalRatings} rating and
          <br />
          {props.summary.totalReviews} reviews
        </div>
      </div>
      <div className="rating-progress-box w-100 px-sm-4 pl-4">
        <div className="d-flex align-items-center">
          <div className="text-gray-900">
            <i className="icon-star mr-2"></i>5
          </div>
          <div className="rating-progress position-relative flex-fill mx-2">
            <div className="rating-fill rating-5 w-100 h-100 position-absolute left-0 top-0"></div>
          </div>
          <div className="text-gray-500">{props.summary.ratingSplit['5']}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="text-gray-900">
            <i className="icon-star mr-2"></i>4
          </div>
          <div className="rating-progress position-relative flex-fill mx-2">
            <div className="rating-fill rating-4 w-75 h-100 position-absolute left-0 top-0"></div>
          </div>
          <div className="text-gray-500">{props.summary.ratingSplit['4']}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="text-gray-900">
            <i className="icon-star mr-2"></i>3
          </div>
          <div className="rating-progress position-relative flex-fill mx-2">
            <div className="rating-fill rating-3 w-50 h-100 position-absolute left-0 top-0"></div>
          </div>
          <div className="text-gray-500">{props.summary.ratingSplit['3']}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="text-gray-900">
            <i className="icon-star mr-2"></i>2
          </div>
          <div className="rating-progress position-relative flex-fill mx-2">
            <div className="rating-fill rating-2 w-25 h-100 position-absolute left-0 top-0"></div>
          </div>
          <div className="text-gray-500">{props.summary.ratingSplit['2']}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="text-gray-900">
            <i className="icon-star mr-2"></i>1
          </div>
          <div className="rating-progress position-relative flex-fill mx-2">
            <div className="rating-fill rating-1 w-12-5 h-100 position-absolute left-0 top-0"></div>
          </div>
          <div className="text-gray-500">{props.summary.ratingSplit['1']}</div>
        </div>
      </div>
    </div>
  );
};

export default RatingSummaryView;
