import React, { useState } from 'react';
import { LatLng } from '../../../api/models';
import useEntities from '../../../hooks/useEntities';
import useSearch from '../../../hooks/useSearch';
import { RouteKeys } from '../../routes/route-keys';
interface Location {
  latLng: LatLng;
  address: {
    street?: string;
    zip?: number;
    country?: string;
    state?: string;
    city?: string;
  };
}
const CategoryLinks: React.FunctionComponent = (props) => {
  const entities = useEntities();
  const search = useSearch();
  const [address, setAddress] = useState<Location>({
    latLng: {
      lat: 0,
      lng: 0,
    },
    address: {},
  });
  const [location, setLocation] = useState('');

  const itemSelected = async (categoryId: string) => {
    search.setSearchParams('', location, address, categoryId);
    // history.push({
    //   pathname: RouteKeys.Search,
    //   search: `?category=${categoryId}`,
    // });
  };
  return (
    <div className="col-2">
      <h6 className="font-weight-semibold font-base mb-3">Categories</h6>
      <ul className="list-unstyled p-0 m-0 font-md">
        {entities.categoriesWithoutProduct
          .filter((cat) => cat.highlighted)
          .map((cat, index) => {
            return (
              <li className="mb-2" key={`cat-link-${index}`}>
                <a
                  onClick={() => itemSelected(cat._id)}
                  href={`${RouteKeys.Search}?category=${cat._id}`}
                  className="text-gray-700"
                >
                  {cat.title}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CategoryLinks;
