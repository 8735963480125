import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import api from '../api';
import { SortBy } from '../api/models';
import { State } from '../store/interfaces';
import {
  clearFilterAction,
  resetDataAction,
  searchingAction,
  searchResultsLoadedAction,
  setCityByAction,
  setFilterValueAction,
  setParamsAction,
  setSortByAction,
} from '../store/reducers/search';
import { clearDataAction } from '../store/reducers/common';

const useSearch = () => {
  const searchState = useSelector((state: State) => state.search);
  const dispatch = useDispatch();
  const queryParams = useLocation();
  const locationParams = qs.parse(queryParams.search);

  const setSearchParams = (
    searchTerm?: string,
    location?: any,
    address?: any,
    categoryId?: string,
    _page?: number
  ) => {
    dispatch(clearFilterAction());
    dispatch(searchingAction());
    dispatch(setParamsAction(searchTerm, location, address, categoryId));
  };

  const setSortBy = (sortBy: SortBy) => {
    dispatch(setSortByAction(sortBy));
  };

  const setCity = (city: string) => {
    dispatch(setCityByAction(city));
  };

  const setFilterValue = (filterKey: string, value?: any, type?: string) => {
    dispatch(resetDataAction());
    dispatch(searchingAction());
    dispatch(setFilterValueAction(filterKey, value, type));
  };

  const getFilterValue = (filterKey: string) => {
    return searchState.filters[filterKey];
  };

  const loadResults = async (_page?: number) => {
    if (_page && _page > 1) {
      dispatch(searchingAction());
    }
    const urlCategory =
      locationParams.category && Array.isArray(locationParams.category)
        ? locationParams.category.join(' ')
        : locationParams.category || '';
    const category = searchState.categoryId || urlCategory;

    const urlSubCategory =
      locationParams.subCategory && Array.isArray(locationParams.subCategory)
        ? locationParams.subCategory.join(' ')
        : locationParams.subCategory || '';
    const subCategory = searchState.subcategoryId || urlSubCategory;

    const urlSearchTerm =
      locationParams.searchTerm && Array.isArray(locationParams.searchTerm)
        ? locationParams.searchTerm.join(' ')
        : locationParams.searchTerm || '';
    const searchTerm = searchState.searchTerm || urlSearchTerm;

    const res = await api.catalog.search(
      searchState.filters,
      searchState.filterType,
      searchState.sortBy,
      searchTerm,
      searchState.address,
      category,
      subCategory,
      _page || 1,
      searchState.city
    );
    if (!_page || _page === 1) {
      dispatch(resetDataAction());
    }
    dispatch(
      searchResultsLoadedAction(
        res.products,
        res.filters,
        res.category,
        res.count
      )
    );
    return res.products;
  };

  const loadSuggestions = async () => {
    dispatch(searchingAction());
    const res = await api.catalog.search(
      searchState.filters,
      searchState.filterType,
      searchState.sortBy,
      searchState.searchTerm,
      searchState.address,
      searchState.categoryId
    );

    dispatch(
      searchResultsLoadedAction(
        res.products,
        res.filters,
        res.category,
        res.count
      )
    );
  };

  return {
    ...searchState,
    loadResults,
    loadSuggestions,
    setSearchParams,
    setFilterValue,
    getFilterValue,
    setSortBy,
    setCity,
  };
};

export default useSearch;
