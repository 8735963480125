import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from '../../../components/otp-input';
import ErrorMessage from '../../../components/text/ErrorMessage';

interface FormProps {
  otp: string;
}

const OtpForm: React.FunctionComponent<{
  onSubmit: (data: FormProps) => void;
  isUpdating?: boolean;
  onResendButtonClicked: () => void;
}> = (props) => {
  const { control, handleSubmit, errors } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const renderOtpInput = () => {
    return (
      <div className="form-group d-flex d-lg-block justify-content-center">
        <label htmlFor="otp-input" className="sr-only">
          OTP
        </label>
        <Controller
          control={control}
          name="otp"
          rules={{
            required: true,
            validate: { isValid: (value) => value && value.length === 4 },
          }}
          render={({ onChange, value, name }) => (
            <OtpInput
              numInputs={4}
              separator={<span className="p-2"> </span>}
              value={value}
              onChange={onChange}
              className=""
              inputStyle="form-control text-center"
              containerStyle="row gutter-5 otp-form  px-lg-0 mt-lg-0 mt-4 justify-content-center justify-content-lg-start"
            />
          )}
        />
        {errors.otp && (
          <ErrorMessage>
            {errors.otp.type === 'required' ? 'OTP is required' : 'Invalid OTP'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderVerifyButton = () => {
    return (
      <div className="form-group col-12 col-md-12 col-lg-4 col-md-4 form-group  pl-lg-2">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          disabled={props.isUpdating}
        >
          Verify
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {renderOtpInput()}
      <div className="row flex-column-reverse flex-direction flex-lg-row row">
        {renderVerifyButton()}
        <div className="form-group col-12 col-md-12 col-lg-8 col-md-4  form-group  pl-2">
          <p className="text-muted mt-3 text-center text-lg-left">
            Didn’t receive OTP?{' '}
            <span
              className="btn-link cursor-pointer"
              onClick={
                props.isUpdating ? undefined : props.onResendButtonClicked
              }
            >
              Resend
            </span>
          </p>
        </div>
      </div>
    </form>
  );
};

export default OtpForm;
