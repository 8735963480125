import React from 'react';
import { GoogleLogin } from 'react-google-login';
import Config from '../../config';
const GoogleLoginButton: React.FunctionComponent<{
  onClick: (accessToken: string) => void;
}> = (props) => {
  const onResponseFromGoogle = (response: any) => {
    console.log(response);
    const accessToken = response?.tokenId || undefined;
    props.onClick(accessToken);
  };

  const onFailureResponseFromGoogle = (response: any) => {
    console.log(response);
    const accessToken = response?.accessToken || undefined;
    if (accessToken) {
      props.onClick(accessToken);
    }
  };
  return (
    <>
      <GoogleLogin
        clientId={Config.GOOGLE_CLIENT_ID}
        autoLoad={false}
        isSignedIn={false}
        prompt={'select_account'}
        render={(renderProps: any) => (
          <i
            className="icon-google mx-2"
            title="Google login"
            onClick={renderProps.onClick}
          >
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>
        )}
        buttonText="Login"
        onSuccess={onResponseFromGoogle}
        onFailure={onFailureResponseFromGoogle}
      />
    </>
  );
};

export default GoogleLoginButton;
