import React, { useState } from 'react';
import { Auction, ProductType } from '../../../api/models';
import useEntities from '../../../hooks/useEntities';
import './typetoselect.scss';
import ProductTypeTabs from './ProductTypeTabs';
import AuctionForm from './AuctionForm';

const ProductTypeForm: React.FunctionComponent<{
  onSelect: (productType: ProductType) => void;
  onBackClick: () => void;
  auctionProductDetails?: Auction | undefined;
  onAuctionSubmit: (auctionDetails: Auction) => void;
}> = (props) => {
  const entities = useEntities();
  const [productType, setProductType] = useState<ProductType>(
    ProductType.Auction
  );
  return (
    <>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center">
        <i
          className="icon-prev font-lg mr-3"
          onClick={() => props.onBackClick()}
        ></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Post Your Ad
        </h6>
      </div>
      <div className="d-lg-flex  d-none mt-lg-5 mx-auto position-relative post-header px-3 justify-content-center">
        <a
          className="font-sm mr-3 mt-2 cursor-pointer position-absolute right-0 top-0"
          onClick={() => props.onBackClick()}
        >
          {' '}
          <i className="font-xxxs mr-1">
            <small className="icon-prev"></small>
          </i>
          Back{' '}
        </a>
        <h3 className="m-0 text-center text-gray-900">Post Your Ad</h3>
      </div>

      <div className="post-product">
        <div className="container">
          <div className="row d-flex justify-content-center my-lg-3">
            <div className="col-lg-auto col-12 post-col">
              <div className="post-product-inner rounded bg-white">
                <div className="selected-category border-bottom pb-3 mb-3">
                  <h5 className="text-gray-900 m-0 mb-2">
                    Choose your Post Type
                  </h5>
                  {/* {renderBreadCrumbs()} */}
                </div>
                <div className="post-product-inner-list pt-lg-2">
                  <ProductTypeTabs
                    onSelect={(val: ProductType) => {
                      props.onSelect(val);
                      setProductType(val);
                    }}
                  ></ProductTypeTabs>
                  {/* <BrandAwarenessForm></BrandAwarenessForm> */}
                  {productType === ProductType.Auction && (
                    <AuctionForm
                      onAuctionSubmit={props.onAuctionSubmit}
                      productDetails={props.auctionProductDetails}
                    ></AuctionForm>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTypeForm;
