import { Category, Filters, Product, SortBy } from '../../api/models';
import { Dict } from '../../models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// action types
const SEARCH_RESULTS_LOADED_ACTION = 'SEARCH/RESULTS_LOADED';
const SEARCH_SEARCHING_ACTION = 'SEARCH/SEARCHING';
const SEARCH_SET_PARAMS_ACTION = 'SEARCH/SET_SEARCH_PARAMS';
const SEARCH_SET_FILTER_VALUE_ACTION = 'SEARCH/SET_FILTER_VALUE';
const SEARCH_SET_SORT_BY_ACTION = 'SEARCH/SET_SORT_BY';
const SEARCH_SET_CITY_BY_ACTION = 'SEARCH/SET_CITY_BY';
const SEARCH_CLEAR_FILTER = 'SEARCH/CLEAR_FILTER';
const SEARCH_RESET_DATA_ACTION = 'SEARCH/RESET_DATA_ACTION';
interface SearchResults {
  id: string;
  title: string;
  category: string;
  subCategory: string;
  description: string;
  categoryId: string;
  subCategoryId: string;
}

export interface SearchState {
  results: Product[];
  category?: Category;
  isSearching?: boolean;
  filterOptions: Dict<Filters>;
  filters: Dict;
  filterType: Dict;
  searchTerm?: string;
  location?: string;
  searchResults?: any[];
  address?: any;
  categoryId?: string;
  subcategoryId?: string;
  sortBy: SortBy;
  count: number;
  page: number;
  city: string;
}

type SearchResultsLoadedActionType = IActionWithPayload<
  typeof SEARCH_RESULTS_LOADED_ACTION,
  {
    products: Product[];
    filters: Dict<Filters>;
    category: Category;
    count: number;
  }
>;

type SearchSetParamsActionType = IActionWithPayload<
  typeof SEARCH_SET_PARAMS_ACTION,
  { searchTerm?: string; location?: string; address?: any; categoryId?: string }
>;

type SearchSetFilterValueActionType = IActionWithPayload<
  typeof SEARCH_SET_FILTER_VALUE_ACTION,
  { filterKey: string; value?: any; type?: string }
>;

type SearchSetSortByActionType = IActionWithPayload<
  typeof SEARCH_SET_SORT_BY_ACTION,
  SortBy
>;

type SearchSetCityByActionType = IActionWithPayload<
  typeof SEARCH_SET_CITY_BY_ACTION,
  string
>;

type SearchClearFilterActionType = IAction<typeof SEARCH_CLEAR_FILTER>;
type SearchResetDataActionType = IAction<typeof SEARCH_RESET_DATA_ACTION>;

type SearchingActionType = IAction<typeof SEARCH_SEARCHING_ACTION>;

type Actions =
  | SearchResultsLoadedActionType
  | SearchingActionType
  | SearchSetParamsActionType
  | SearchSetFilterValueActionType
  | SearchSetSortByActionType
  | SearchClearFilterActionType
  | SearchSetCityByActionType
  | SearchResetDataActionType;

export const searchResultsLoadedAction = (
  products: Product[],
  filters: Dict<Filters>,
  category: Category,
  count: number
): SearchResultsLoadedActionType => {
  return createActionWithPayload(SEARCH_RESULTS_LOADED_ACTION, {
    products,
    filters,
    category,
    count,
  });
};

export const searchingAction = (): SearchingActionType => {
  return createAction(SEARCH_SEARCHING_ACTION);
};

export const clearFilterAction = (): SearchClearFilterActionType => {
  return createAction(SEARCH_CLEAR_FILTER);
};

export const resetDataAction = (): SearchResetDataActionType => {
  return createAction(SEARCH_RESET_DATA_ACTION);
};

export const setParamsAction = (
  searchTerm?: string,
  location?: string,
  address?: any,
  categoryId?: any,
  page?: number
): SearchSetParamsActionType => {
  return createActionWithPayload(SEARCH_SET_PARAMS_ACTION, {
    searchTerm,
    location,
    address,
    categoryId,
    page,
  });
};

export const setFilterValueAction = (
  filterKey: string,
  value?: any,
  type?: string
): SearchSetFilterValueActionType => {
  return createActionWithPayload(SEARCH_SET_FILTER_VALUE_ACTION, {
    filterKey,
    value,
    type,
  });
};

export const setSortByAction = (sortBy: SortBy): SearchSetSortByActionType => {
  return createActionWithPayload(SEARCH_SET_SORT_BY_ACTION, sortBy);
};
export const setCityByAction = (city: string): SearchSetCityByActionType => {
  return createActionWithPayload(SEARCH_SET_CITY_BY_ACTION, city);
};

// initial state
const initialState: SearchState = {
  results: [],
  isSearching: false,
  filters: {},
  filterType: {},
  filterOptions: {},
  categoryId: undefined,
  sortBy: SortBy.Relevence,
  count: 0,
  page: 1,
  city: '',
};

// reducer
export default (
  state: SearchState = initialState,
  action: any
): SearchState => {
  switch (action.type) {
    case SEARCH_RESULTS_LOADED_ACTION:
      return {
        ...state,
        results: [...state.results, ...action.payload.products],
        filterOptions: action.payload.filters,
        category: action.payload.category,
        count: action.payload.count,
        isSearching: false,
      };
    case SEARCH_SEARCHING_ACTION:
      return {
        ...state,
        isSearching: true,
      };
    case SEARCH_SET_PARAMS_ACTION:
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
        location: action.payload.location,
        address: action.payload.address,
        categoryId: action.payload.categoryId,
        page: action.payload.page,
      };
    case SEARCH_SET_FILTER_VALUE_ACTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterKey]: action.payload.value,
        },
        filterType: {
          ...state.filterType,
          [action.payload.filterKey]: action.payload.type,
        },
      };
    case SEARCH_SET_SORT_BY_ACTION:
      return {
        ...state,
        sortBy: action.payload,
      };
    case SEARCH_SET_CITY_BY_ACTION:
      return {
        ...state,
        city: action.payload,
      };
    case SEARCH_CLEAR_FILTER:
      return {
        ...state,
        filters: {},
      };
    case SEARCH_RESET_DATA_ACTION:
      return {
        ...state,
        results: [],
      };
    default:
      return state;
  }
};
