import React, { useState } from 'react';
import { getChunks } from '../../../utils/array-utils';
import useEntities from '../../../hooks/useEntities';
import useSearch from '../../../hooks/useSearch';
import { Link, useHistory } from 'react-router-dom';
import { RouteKeys } from '../../routes/route-keys';
import { LatLng, Subcategory } from '../../../api/models';
import CustomScrollbars from '../../../components/scrollbar/custom-scrollbar';
import Masonry from 'react-masonry-css';

interface Location {
  latLng: LatLng;
  address: {
    street?: string;
    zip?: number;
    country?: string;
    state?: string;
    city?: string;
  };
}
const breakpointColumnsObj = {
  default: 5,
  1100: 4,
  700: 4,
  500: 1,
};
const Categories: React.FunctionComponent = (props) => {
  const entities = useEntities();
  const search = useSearch();
  const history = useHistory();
  const [location, setLocation] = useState('');
  const [address, setAddress] = useState<Location>({
    latLng: {
      lat: 0,
      lng: 0,
    },
    address: {},
  });

  const itemSelected = async (categoryId: string) => {
    search.setSearchParams('', location, address, categoryId);
    // history.push({
    //   pathname: RouteKeys.Search,
    //   search: `?category=${categoryId}`,
    // });
  };
  const renderSubcategory = (subcategory: Subcategory, categoryId: string) => {
    return (
      <li className="pb-1">
        <Link
          onClick={() => itemSelected(categoryId)}
          to={`${RouteKeys.Search}?category=${categoryId}&subCategory=${subcategory._id}`}
          className="d-block  text-gray-700 font-sm"
        >
          {subcategory.title}
        </Link>
      </li>
    );
  };
  const renderResponsiveCategoriesDropDown = () => {
    return (
      <div className="bg-white category-responsive d-lg-none h-100 position-fixed top-0 w-100">
        <div className="align-items-center bg-primary d-flex justify-content-between px-3 py-2 category-head">
          <h6 className="text-white py-1 m-0">All Categories</h6>{' '}
          <a
            className="icon-close my-2 text-white"
            onClick={() => document.body.classList.remove('open-allcategory')}
          ></a>
        </div>
        <ul className="m-0 list-unstyled p-0 category-list-responsive">
          {entities.categoriesWithoutProduct.map((cat, index) => {
            return (
              <li className="py-2 px-3 border-bottom main-list">
                <div className="position-relative main-category d-flex align-items-center">
                  <Link
                    onClick={() => itemSelected(cat._id)}
                    to={`${RouteKeys.Search}?category=${cat._id}`}
                    className="d-block  text-gray-700 font-base font-weight-semibold"
                  >
                    {cat.title}
                    <span className="expand-menu  d-lg-none position-absolute right-0 top-0"></span>
                  </Link>
                </div>
                <ul className="m-0 list-unstyled p-0 sub-cat">
                  {entities
                    .getSubcategories(cat._id)
                    .map((subCategory: Subcategory) =>
                      renderSubcategory(subCategory, cat._id)
                    )}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  const renderCategoriesDropDown = () => {
    return (
      <div className="category-dropdown-menu position-absolute top-100 left-0 w-100 bg-white">
        <div className="category-menu-list px-lg-5 px-3 py-4">
          <CustomScrollbars
            autoHide
            autoHideTimeout={500}
            autoHideDuration={200}
            style={{ maxHeight: '500px', minHeight: '250px' }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {entities.categoriesWithoutProduct.map((cat, index) => {
                return (
                  <ul className="m-0 list-unstyled mb-3">
                    <li>
                      <Link
                        onClick={() => itemSelected(cat._id)}
                        to={`${RouteKeys.Search}?category=${cat._id}`}
                        className="d-block  text-gray-700 font-normal"
                      >
                        {cat.title}
                      </Link>
                      <ul className="m-0 list-unstyled font-sm font-weight-normal">
                        {entities
                          .getSubcategories(cat._id)
                          .map((subCategory: Subcategory) =>
                            renderSubcategory(subCategory, cat._id)
                          )}
                      </ul>
                    </li>
                  </ul>
                );
              })}
            </Masonry>
          </CustomScrollbars>
        </div>
      </div>
    );
  };
  return (
    <div className="header-bottom shadow-sm">
      <div className="container">
        <div className="row position-relative">
          <div className="col-auto d-lg-block d-none position-revert">
            <div className="d-flex align-items-center category-dropdown font-base font-weight-bold py-3 cursor-pointer position-revert">
              <span className="text-gray-900">All Categories</span>
              <i className="icon-arrow-down font-xs text-gray-700 ml-4 pl-2"></i>
              {renderCategoriesDropDown()}
            </div>
          </div>
          <div className="col px-0">
            <ul className="caterogy-list list-unstyled p-0 m-0 d-flex font-base font-weight-semibold h-100 text-nowrap justify-content-lg-around">
              {entities.categoriesWithoutProduct
                .filter((cat) => cat.highlighted)
                .map((cat) => {
                  return (
                    <li>
                      <Link
                        onClick={() => itemSelected(cat._id)}
                        to={`${RouteKeys.Search}?category=${cat._id}`}
                        className=" py-3 d-inline-flex align-items-center flex-lg-row flex-column text-gray-900 px-2"
                      >
                        <div className="caterogy-img d-flex align-items-center justify-content-center mr-lg-2 mb-lg-0 mb-1">
                          {cat.image?.path && (
                            <img
                              src={cat.image?.path}
                              className="img-fluid"
                              alt={cat.title}
                              width={24}
                              height={24}
                            />
                          )}
                        </div>
                        {cat.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
