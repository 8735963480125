import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TimeInput.scss';
import { format } from 'date-fns';
import moment from 'moment';
import { combineDateAndTime } from '../../../utils/CombineDateAndTime';

interface CustomProps {
  default?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  gap?: 'sm' | 'md' | 'lg';
  name?: string;
  label?: string;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;
  disabled?: boolean;
  startTime?: string;
  endTime?: string;
  avoidToday?: boolean;
}

interface CustomState {
  value?: Date;
}

class DateInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    gap: 'md',
    name: '',
    label: '',
    error: '',
    placeholder: '',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: this.props.default
        ? this.createDate(this.props.default)
        : undefined,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.state = {
      value: this.props.default
        ? this.createDate(this.props.default)
        : undefined,
    };
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    this.setState({
      value: this.props.default
        ? this.createDate(this.props.default)
        : undefined,
    });
  }

  createDate(value: string) {
    const date = new Date();

    let hours = Number(value?.match(/^(\d+)/)![1]);
    const minutes = Number(value.match(/:(\d+)/)![1]);
    const AMPM = value.match(/\s(.*)$/)![1];

    if (AMPM.toUpperCase() === 'PM' && hours < 12) hours = hours + 12;
    if (AMPM.toUpperCase() === 'AM' && hours === 12) hours = hours - 12;

    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
  }

  handleChange(date: Date) {
    this.setState({
      value: date,
    });

    this.props.onChange(moment(date).format('hh:mm a'));
  }

  calculateMinTime = (date: Date) => {
    if (this.props.startTime) {
      date = combineDateAndTime(date, this.props.startTime);
      const nowAddOneHour = moment(date).add({ minute: 5 }).toDate();
      return nowAddOneHour;
    }

    const isToday = moment(date).isSame(moment(), 'day');
    if (isToday && !this.props.avoidToday) {
      const nowAddOneHour = moment(new Date()).add({ minute: 5 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf('day').toDate(); // set to 12:00 am today
  };

  calculateMaxTime = (date: Date) => {
    if (this.props.endTime) {
      date = combineDateAndTime(date, this.props.endTime);
      const nowAddOneHour = moment(date).subtract({ minute: 5 }).toDate();

      return nowAddOneHour;
    }

    return moment().endOf('day').toDate();
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      disabled,
      minDate,
      maxDate,
    } = this.props;
    const inputIdCandidate = name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className="input-group time-picker">
        <DatePicker
          id={inputId}
          selected={this.state.value}
          onChange={this.handleChange}
          placeholderText={placeholder}
          dropdownMode="select"
          disabled={disabled}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={5}
          timeCaption="Time"
          dateFormat="h:mm aa"
          openToDate={this.calculateMinTime(
            minDate ? new Date(minDate) : new Date()
          )}
          minTime={this.calculateMinTime(
            minDate ? new Date(minDate) : new Date()
          )}
          maxTime={this.calculateMaxTime(
            minDate ? new Date(minDate) : new Date()
          )}
          customInput={<CustomDateInput />}
        />

        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}
export default DateInput;

const CustomDateInput = forwardRef((props, ref) => {
  const { id, placeholder, value, onClick }: { [key: string]: any } = props;
  return (
    <div>
      <input
        id={id}
        className="form-control rounded-sm font-weight-semibold"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        autoComplete="off"
      />
    </div>
  );
});
