import { subMinutes } from 'date-fns';
import React, { useState } from 'react';
import { Category } from '../../../api/models';
import HelpLink from '../../../components/links/help-link';
import useSignup from '../../../hooks/useSignup';
import AuthLayout from '../../layout/auth';
import InterestedCategories from '../../profile/components/interested-category';
import Banner from './account-type/banner';

const ChooseInterestedCategories: React.FunctionComponent = (props) => {
  const [categories, setCategories] = useState<string[]>([]);
  const signup = useSignup();

  const onCategorySelect = (val: Category) => {
    const newCategories = [...categories];
    if (categories.length > 0) {
      const index = categories.findIndex((e) => e === val._id);
      if (index > -1) {
        newCategories.splice(index, 1);
      } else {
        newCategories.push(val._id);
      }
    } else {
      newCategories.push(val._id);
    }
    setCategories([...newCategories]);
  };
  const onSubmit = () => {
    signup.updateInterestedCategories({ interestedCategories: categories });
  };
  const renderNextButton = () => {
    return (
      <div className="col-12 form-group pt-4">
        <button
          type="submit"
          className="btn btn-primary btn-block font-weight-bold "
          value=""
          onClick={onSubmit}
        >
          Next
        </button>
      </div>
    );
  };
  return (
    <AuthLayout
      loginContainerClass="account-details pb-5 pb-md-0 mb-lg-0"
    >
      <Banner></Banner>
      <div className="col-lg-6 col-xl-7 col-12 enterphone-right-box content-box bg-white">
        <div className="p-sm-4 p-2">
          <p className="text-muted text-right mt-2 d-none d-lg-block">
            Having troubles? <HelpLink>Get Help</HelpLink>
          </p>
          <div className="login-categories px-xl-5  py-lg-5 py-3 row mx-lg-auto">
            <InterestedCategories
              onSelect={(e) => onCategorySelect(e)}
              selectedCategories={[]}
            ></InterestedCategories>
            {renderNextButton()}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ChooseInterestedCategories;
