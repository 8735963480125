import React from 'react';
import './Upload.scss';

import uploadedIcon from './file-upload-done.svg';
import Button from '../button';

interface CustomProps {
  id: string;
  title: string;
  description?: string;
  filename: string;
  fileSize: string;
  onClose?: () => void;
  disabled?: boolean;
  filePath?: string;
  className?:string;
}

const FileUploaded: React.SFC<CustomProps> = (props: CustomProps) => {
  return (
    <>
      <div className={`uploadfile ${props.className || ''}`}>
        <figure>
          <img src={props.filePath || uploadedIcon}
            //alt=""
            //width={50}
            //height={50}
            //style={{ objectFit: 'cover' }} className="py-2"
             />
            
          {!props.disabled && (
            <span className="bg-danger bottom-0 font-xxxs icon-close m-1 p-2 position-absolute right-0 text-white cursor-pointer" onClick={props.onClose}></span>
          )}
         </figure>
      </div>
    </>
  );
};

export default FileUploaded;
