import React from 'react';
import api from '../../../api';
import Modal from 'react-modal';
import useAuth from '../../../hooks/useAuth';

const ReportAdResponsive: React.FunctionComponent<{
  isOpen?: boolean;
  onRequestClose: () => void;
}> = (props) => {
  const auth = useAuth();
  const setDeactivate = () => {
    api.user.updateSettings(undefined, undefined, true).then((response) => {
      props.onRequestClose();
      auth.logout();
    });
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '500px',
      border: '0',
      padding: '0',
    },
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen || false}
        contentLabel={'Logout from all devices'}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        onRequestClose={() => props.onRequestClose()}
      >
        <div
          className="px-0"
          id="DeactivateAccount"
          role="dialog"
          aria-labelledby="Loguout-modalLabel"
          aria-hidden="true"
        >
          <div className="p-4" role="document">
            <div
              className="close position-absolute right-0 top-0 mr-3 mt-3 cursor-pointer"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => props.onRequestClose()}
            >
              <span className="icon-close font-md"></span>
            </div>
            <h4 className="mb-2 pb-md-2">Deactivate account and forget me</h4>
            <p className="font-base">
              Are you sure you want to deactivate your account? this action
              cannot be undone
            </p>

            <div className="row pt-4 gutter-10">
              <div className="col-md-6 col-6">
                <input
                  type="submit"
                  className="btn btn-primary btn-block font-weight-bold py-2"
                  value="Deactivate"
                  onClick={(e) => {
                    setDeactivate();
                  }}
                />
              </div>
              <div className="col-md-6 col-6">
                <input
                  type="submit"
                  className="btn btn-outline-primary btn-block font-weight-bold py-2"
                  value="Cancel"
                  onClick={(e) => props.onRequestClose()}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReportAdResponsive;
