import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AccountType } from '../../api/models';
import { ContainerLoader } from '../../components/loader';
import useAuth from '../../hooks/useAuth';
import AppLayout from '../layout/app';
import { RouteKeys } from '../routes/route-keys';
import Banner from './Banner';
import Reccomendations from './Reccomendations';

const Home: React.FunctionComponent = () => {
  const auth = useAuth();
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);
  if (auth.isAuthenticated) {
    if (
      !auth.profile?.accountType ||
      !auth.profile?.name ||
      !auth.profile?.email
    ) {
      return <Redirect to={RouteKeys.OnBoard} />;
    }
    if (
      auth.profile.accountType === AccountType.Business &&
      !auth.profile.business
    ) {
      return <Redirect to={RouteKeys.OnBoard} />;
    }
  }

  return (
    <AppLayout showHeader>
      <Banner></Banner>
      <div className="container py-lg-5 py-md-4 py-3 px-2 px-lg-3">
        {show && <Reccomendations></Reccomendations>}
        {!show && <ContainerLoader height={500}></ContainerLoader>}
      </div>
    </AppLayout>
  );
};

export default Home;
