import firebase from 'firebase';
const firebaseConfig = {
  apiKey: 'AIzaSyDJW0ndtespBFpTChDUR2HcPo-yvuLnWiU',
  authDomain: 'dealden-chat.firebaseapp.com',
  databaseURL: 'https://dealden-chat-default-rtdb.firebaseio.com',
  projectId: 'dealden-chat',
  storageBucket: 'dealden-chat.appspot.com',
  messagingSenderId: '351003391460',
  appId: '1:351003391460:web:c1bcb9f9df7503ace843f1',
  measurementId: 'G-8463H4ZXJ7',
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const firestore = firebase.firestore();
export let messaging = firebase.messaging();
if ('serviceWorker' in navigator) {          
  navigator.serviceWorker.register('../../../public/firebase-messaging-sw.js')    
  .then((registration) => { 
     messaging.useServiceWorker(registration); 
     // request notification permission and get token     
    //  console.log('Registration successful, scope is:',  
    //  registration.scope); 
    // TODO: ask For Permission To Receive Notifications   
  }).catch((err) => { 
    //  console.log('Service worker registration failed, error:', err); 
    }); 
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});

export default firestore;
