import React, { useState } from 'react';
import autionImg from '../../../assets/images/auction.png';
import saleImg from '../../../assets/images/sale.png';
import leadImg from '../../../assets/images/lead-generation.png';
import brandImg from '../../../assets/images/brand.png';

export enum ProductType {
  Auction = 'auction',
  Sale = 'sale',
  Lead = 'lead',
  Branding = 'branding',
}
const ProductTypeTabs: React.FunctionComponent<{
  onSelect: (productType: ProductType) => void;
  productType?: ProductType;
}> = (props) => {
  return (
    <>
      <div className="post-product-inner-list pt-lg-2">
        <div className="row gutter-5 d-flex mb-4">
          <div className="col-md-3 col-6 mb-10">
            <div
              className={
                props.productType === ProductType.Auction || !props.productType
                  ? 'p-3 rounded-lg text-center list-category_selected h-100'
                  : 'p-3 rounded-lg text-center list-category h-100'
              }
              onClick={() => props.onSelect(ProductType.Auction)}
            >
              <img src={autionImg} className="img-fluid pt-1" height={50} />
              <p className="font-md pt-3 font-weight-semibold mb-0  text-gray-900ray-900 mb-0">
                Auction
              </p>
            </div>
          </div>
          <div className="col-md-3 col-6 mb-10">
            <div
              className={
                props.productType === ProductType.Sale
                  ? 'p-3 rounded-lg text-center list-category_selected h-100'
                  : 'p-3 rounded-lg text-center list-category h-100'
              }
              onClick={() => props.onSelect(ProductType.Sale)}
            >
              <img src={saleImg} className="img-fluid pt-1" height={50} />
              <p className="font-md pt-3 font-weight-semibold mb-0  text-gray-900ray-900 mb-0">
                Sale
              </p>
            </div>
          </div>
          <div className="col-md-3 col-6 mb-10">
            <div
              className={
                props.productType === ProductType.Sale
                  ? 'p-3 rounded-lg text-center list-category_selected h-100'
                  : 'p-3 rounded-lg text-center list-category h-100'
              }
              onClick={() => props.onSelect(ProductType.Lead)}
            >
              <img src={leadImg} className="img-fluid pt-1" height={50} />
              <p className="font-md pt-3 font-weight-semibold mb-0  text-gray-900ray-900 mb-0 line-height-sm">
                Lead Generation
              </p>
            </div>
          </div>
          {/* <div className="col-md-3 col-6 mb-10">
            <div
              className={
                props.productType === ProductType.Sale
                  ? 'p-3 rounded-lg text-center list-category_selected h-100'
                  : 'p-3 rounded-lg text-center list-category h-100'
              }
              onClick={() => props.onSelect(ProductType.Branding)}
            >
              <img src={brandImg} className="img-fluid pt-1" height={50} />
              <p className="font-md pt-3 font-weight-semibold mb-0  text-gray-900ray-900 mb-0 line-height-sm">
                Brand Awareness
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="message error mb-4">
        When you list an item for sale in an Deal Den auction, you choose a
        starting price & time, and interested buyers place bids. When the
        auction ends, you agree to sell to the highest bidder.
      </div>
    </>
  );
};
export default ProductTypeTabs;
