/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import { Product } from '../../api/models';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RouteKeys } from '../routes/route-keys';

const ProductCard: React.FunctionComponent<{
  product: Product;
  categoryName: string;
  onClose: () => void;
}> = (props) => {
  return (
    <div className="card info-window bg-white p-4">
      <div className="card-body d-flex flex-column font-normal">
        <a
          onClick={props.onClose}
          className="cursor-pointer font-weight-bold icon-close position-absolute right-0 text-dark top-0"
        ></a>
        <Link
          to={RouteKeys.ProductDetails.replace(':slug', props.product.slug)}
          className="imageblock"
        >
          <img
            className="bd-placeholder-img"
            width="100%"
            height="150"
            src={
              props.product.defaultImage?.path ||
              props.product?.images?.[0].path
            }
            aria-label="Placeholder: Image"
            role="img"
          />
        </Link>
        <h5 className="card-title">${props.product.price}</h5>
        <Link
          to={RouteKeys.ProductDetails.replace(':slug', props.product.slug)}
          className="font-base text-gray-800 d-block mb-2"
        >
          {props.product.title}
        </Link>

        <p className="card-text font-sm text-muted ">
          <span className="mb-2">
            {moment(props.product.createdAt).fromNow()} ,{' '}
            {props.product.location.state}
            {', '}
            {props.product.location.country}
          </span>
          <span className="mb-2"></span>
        </p>
        <div className="d-flex justify-content-between align-items-center mt-auto">
          <div className="mb-2">
            <span className="font-sm text-muted">
              In: {props.product.subcategory?.title} | {props.categoryName}
            </span>
          </div>
          <div className="d-flex mb-2">
            <span className="font-sm text-muted mr-2 mr-lg-3 d-flex align-items-center">
              <i className="icon-visible font-lg mr-2"></i>{' '}
              {props.product.viewCount || 0}&nbsp;
              <span className="d-none d-lg-inline-block">views</span>
            </span>
            <span className="font-sm text-muted d-flex align-items-center">
              <i className="icon-heart-filled font-base mr-2"></i>
              {props.product.likeCount || 0}&nbsp;
              <span className="d-none d-lg-inline-block">likes</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
