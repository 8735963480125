import React, { useState } from 'react';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import { SubscriptionPlan, PostType } from '../../../api/models';
import Empty from '../../../components/empty';
import { ContainerLoader } from '../../../components/loader';
import useAuth from '../../../hooks/useAuth';

interface PlanProps {
  onPlanSelect: (data: SubscriptionPlan) => void;
  categoryId?: string;
  subCategoryId?: string;
}

const Plans: React.FunctionComponent<PlanProps> = (props) => {
  const auth = useAuth();
  const accountType = auth.profile?.accountType;

  const [cityTabSelected, setCityTabSelected] = useState(true);
  const [nationalTabSelected, setNationalTabSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const [showPayButton, setShowPayButton] = useState(false);

  const { data: plans, loading, loaded } = useDataLoader<SubscriptionPlan[]>(
    () =>
      api.subscription.getSubscriptionPlans({
        accountType,
        isTopFeatured: true,
        categoryId: props.categoryId,
        subCategoryId: props.subCategoryId,
      })
  );

  const { performAction: payForSelectPlan } = usePerformAction((data): any => {
    props.onPlanSelect(data);
  });

  if (!loaded || loading) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }

  const renderPlanDetails = (plan: SubscriptionPlan) => {
    return (
      <>
        {' '}
        <div className="ads-item bg-white border rounded py-3 px-4 position-relative mb-3">
          <div className="RadioSelect d-block py-0 font-base">
            <input
              type="radio"
              id={plan._id}
              checked={plan._id === selectedPlan?._id}
              onClick={() => {
                setSelectedPlan(plan);
                setShowPayButton(true);
              }}
            />
            <label
              htmlFor={plan._id}
              className="d-flex justify-content-between w-100"
            >
              {plan.name}
              <span className="font-weight-bold text-primary">
                {`$ ${plan.amount}`}
              </span>
            </label>
          </div>
        </div>
      </>
    );
  };

  const renderPayButton = () => {
    return (
      <>
        <div className="col-12">
          <button
            type="button"
            className="btn btn-primary btn-block font-weight-bold "
            onClick={() => {
              payForSelectPlan(selectedPlan);
            }}
          >
            Pay $ {selectedPlan?.amount}
          </button>{' '}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="right-panel w-100 ">
        <nav className="tabs-header  myaccount mb-3">
          <div className="nav nav-tabs bg-white" id="nav-tab" role="tablist">
            <a
              className={`nav-item nav-link ${
                cityTabSelected ? 'active bg-white' : ''
              }`}
              id="nav-city-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-city"
              aria-selected="true"
              onClick={() => {
                setCityTabSelected(true);
                setNationalTabSelected(false);
                setSelectedPlan(undefined);
                setShowPayButton(false);
              }}
            >
              {PostType.City}
            </a>
            <a
              className={`nav-item nav-link ${
                nationalTabSelected ? 'active bg-white' : ''
              }`}
              id="nav-national-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-national"
              aria-selected="false"
              onClick={() => {
                setCityTabSelected(false);
                setNationalTabSelected(true);
                setSelectedPlan(undefined);
                setShowPayButton(false);
              }}
            >
              {PostType.National}
            </a>
          </div>
        </nav>
        <div>
          <h6>Featured Ad</h6>
          Get Noticed with "Featured" tag in top position
        </div>

        {plans && plans.length > 0 && (
          <div className="tab-content pt-lg-4 pt-2" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                cityTabSelected ? 'show active' : ''
              }`}
              id="nav-city"
              role="tabpanel"
              aria-labelledby="nav-city-tab"
            >
              {plans.filter((plan) => {
                return plan.postType === PostType.City;
              }).length === 0 ? (
                <Empty></Empty>
              ) : (
                plans
                  .filter((plan) => {
                    return plan.postType === PostType.City;
                  })
                  .map((plan) => {
                    return <>{renderPlanDetails(plan)}</>;
                  })
              )}
            </div>
            <div
              className={`tab-pane fade ${
                nationalTabSelected ? 'show active' : ''
              }`}
              id="nav-national"
              role="tabpanel"
              aria-labelledby="nav-national-tab"
            >
              {plans.filter((plan) => {
                return plan.postType === PostType.National;
              }).length === 0 ? (
                <Empty></Empty>
              ) : (
                plans
                  .filter((plan) => {
                    return plan.postType === PostType.National;
                  })
                  .map((plan) => {
                    return <>{renderPlanDetails(plan)}</>;
                  })
              )}
            </div>
          </div>
        )}
        {showPayButton && renderPayButton()}
      </div>
    </>
  );
};

export default Plans;
