import React from 'react';
import useAuth from '../../hooks/useAuth';
import AppLayout from '../layout/app';
import MyFollowingScreen from './components/my-following';

const MyFollowing: React.FunctionComponent = (props) => {
  const auth = useAuth();

  return (
    <AppLayout hideCategories>
      <MyFollowingScreen
        followers={auth.profile?.followedCount || 0}
        following={auth.profile?.followingCount || 0}
      />
    </AppLayout>
  );
};

export default MyFollowing;
