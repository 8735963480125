import { Category } from './category';
import { AccountType } from './user';

export interface SubscriptionPlan {
  _id: string;
  name: string;
  accountType: AccountType;
  postType: string;
  noOfPosts: number;
  amount: number;
  listingDays: number;
  period?: string;
  isActive: boolean;
  isRecurring?: boolean;
  isCustom?: boolean;
}

export enum PostType {
  City = 'City Post',
  National = 'National Post',
}

export interface Subscribe {
  subscriptionPlan: string;
  paymentId: string;
  isBoostBackToTop?: boolean;
  productId?: string;
  isTopFeatured?: boolean;
  paymentType: string;
  categoryId?: string;
  stripePaymentToken?: any;
  isFirstTimeSubcription?: boolean;
}

export interface Subscription {
  _id: string;
  amount: number;
  accountType: AccountType;
  status: SubscriptionStatus;
  noOfPostsRemaining?: number;
  postType?: PostType;
  noOfPosts?: number;
  listingDays?: number;
  isRecurring?: boolean;
  expireOn?: Date;
  isCustom?: boolean;
  period?: SubscriptionPeriod;
  planName?: string;
  user: string;
  subscriptionPlan: string;
  paymentId?: string;
  products?: string[];
  isBoostBackToTop?: boolean;
  isTopFeatured?: boolean;
  paymentType: string;
  categories?: Category[];
}

export enum SubscriptionStatus {
  CustomRequestPending = 'custom-request-pending',
  CustomPlanGenerated = 'custom-plan-generated',
  PaymentPending = 'payment-pending',
  PaymetSuccess = 'payment-success',
  PaymentFailed = 'payment-failed',
  PaymentCancelled = 'payment-cancelled',
}

export interface RequestCustomPlan {
  subscriptionPlan: string;
}

export enum SubscriptionPeriod {
  Monthly = 'Monthly',
}

export interface SubscribeCustomPlan {
  subscriptionId: string;
  paymentId: string;
  categoryId?: string;
}

export interface RemainingPostsCountInCategoryResponseData {
  userId: string;
  categoryId: string;
  noOfPostsRemaining: number;
  subscriptionId?: string;
}
