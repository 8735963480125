import { Product } from '../../api/models';

import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

const ACTION_LOAD_PRODUCT_REQUEST = 'PRODUCT/LOAD_PRODUCT_REQUEST';
const ACTION_LOAD_PRODUCT_SUCCESS = 'PRODUCT/LOAD_PRODUCT_SUCCESS';
const ACTION_LOAD_PRODUCT_ERROR = 'PRODUCT/LOAD_PRODUCT_ERROR';

type LoadProfileRequestActionType = IAction<typeof ACTION_LOAD_PRODUCT_REQUEST>;

type LoadProfileSuccessActionType = IActionWithPayload<
  typeof ACTION_LOAD_PRODUCT_SUCCESS,
  Product[]
>;

type LoadProfileErrorActionType = IAction<typeof ACTION_LOAD_PRODUCT_ERROR>;

type Actions =
  | LoadProfileRequestActionType
  | LoadProfileSuccessActionType
  | LoadProfileErrorActionType;

export const loadProductsRequestAction = (): LoadProfileRequestActionType => {
  return createAction(ACTION_LOAD_PRODUCT_REQUEST);
};

export const loadProductsSuccessAction = (
  data: Product[]
): LoadProfileSuccessActionType => {
  return createActionWithPayload(ACTION_LOAD_PRODUCT_SUCCESS, data);
};

export const loadProductsErrorAction = (): LoadProfileErrorActionType => {
  return createAction(ACTION_LOAD_PRODUCT_ERROR);
};

export interface ProfileState {
  products: Product[];
  isLoading?: boolean;
  isLoaded?: boolean;
}

const initialState: ProfileState = {
  products: [],
  isLoaded: false,
  isLoading: false,
};

// reducer
export default (
  state: ProfileState = initialState,
  action: Actions
): ProfileState => {
  switch (action.type) {
    case ACTION_LOAD_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case ACTION_LOAD_PRODUCT_ERROR:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    case ACTION_LOAD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload || [],
        isLoading: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};
