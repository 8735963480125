import React from 'react';
import AppLayout from '../layout/app';
import EditBank from './components/bank-new';
import '../../styles/my-account.scss';
import useAuth from '../../hooks/useAuth';

const BankScreen: React.FunctionComponent = () => {
  const { updating, error, updateBankDetails } = useAuth();
  return (
    <AppLayout hideCategories hideFooter>
        <EditBank
          onSubmit={(bankData) => {
            updateBankDetails(bankData);
          }}
          isSubmitting={updating}
        ></EditBank>
    </AppLayout>
  );
};

export default BankScreen;
