import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../api';
import { ContainerLoader } from '../../components/loader';
import ProductTile from '../../components/product/ProductTile';
import useGridBreakPoint from '../../hooks/useGridBreakPoint';
import useWindowSrollHandler from '../../hooks/useWindowSrollHandler';
import { store } from '../../store';
import { State } from '../../store/interfaces';
import {
  reccomendationsLoadedAction,
  reccomendationsResetDataAction,
} from '../../store/reducers/catalog';
import HomePageAd from './HomePageAd';
import loaderSmall from '../../assets/images/loader-small.gif';
import { LatLng } from '../../api/models';
import Geocode from 'react-geocode';
import useCurrentLocationHandler from '../../hooks/useCurrentLocationToGetCity';

const Reccomendations: React.FunctionComponent = () => {
  const reccomended = useSelector(
    (state: State) => state.catalog.reccomendations
  );
  const searchState = useSelector((state: State) => state.search);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<any>(reccomended);
  const [list, setList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false);
  const [isLocation, setIsLocation] = useState<boolean>(false);
  const [currentCity, setCurrentCity] = useState<string>('');
  const loadPage = useCallback(
    async (_page: number, city?: string) => {
      if (loading || allPagesLoaded) {
        return;
      }
      if (_page < page) {
        return;
      }
      setLoading(true);
      setLoaded(false);
      try {
        setPage(_page);
        const data = await api.catalog.getProductReccomendations(
          _page,
          city || searchState.city || ''
        );
        if (_page >= data.totalPages) {
          setAllPagesLoaded(true);
        }
        if (_page === 1) {
          setFirstPageLoaded(true);
          store.dispatch(reccomendationsResetDataAction());
        }
        store.dispatch(reccomendationsLoadedAction(data.data));
      } catch (e) {
        setError(e);
      }
      setLoaded(true);
      setLoading(false);
    },
    [
      loading,
      allPagesLoaded,
      setLoading,
      setLoaded,
      setPage,
      setData,
      setList,
      setError,
      list,
      page,
      currentCity,
      searchState.city,
    ]
  );

  const handleScroll = (direction: 'pageUp' | 'pageDown') => {
    if (direction === 'pageDown') {
      loadPage(page + 1, currentCity);
    }
  };
  const onCity = (city: string) => {
    if (!loading && !loaded && !error) {
      setIsLocation(true);
      setCurrentCity(city);
      loadPage(1, city);
    }
  };
  useCurrentLocationHandler(onCity, [onCity]);
  useWindowSrollHandler(handleScroll, [handleScroll]);

  useEffect(() => {
    if (!loading && !loaded && !error && !isLocation) {
      loadPage(1);
    }
  }, [loadPage, isLocation, loading, loaded, error]);
  const breakPoint = useGridBreakPoint();
  const numberOfRows = 2;
  const adPoistion =
    breakPoint === 'xl'
      ? numberOfRows * 5
      : breakPoint === 'lg'
      ? numberOfRows * 4
      : breakPoint === 'md'
      ? numberOfRows * 3
      : numberOfRows * 2;

  return (
    <>
      {firstPageLoaded && <h3 className="mb-3">Recommendation</h3>}
      <div className="rec-list-outer">
        <div className="row gutter-5">
          {reccomended
            .filter((p, index) => index < adPoistion)
            .map((pr, index) => {
              return (
                <ProductTile
                  key={index}
                  product={pr}
                  className="col-lg-3 col-sm-4 col-6 d-flex"
                ></ProductTile>
              );
            })}

          {firstPageLoaded && <HomePageAd />}
          {reccomended
            .filter((p, index) => index >= adPoistion)
            .map((pr, index) => {
              return (
                <ProductTile
                  key={index}
                  product={pr}
                  className="col-lg-3 col-sm-4 col-6 d-flex"
                ></ProductTile>
              );
            })}
        </div>
      </div>

      {loading && !loaded && page === 1 && (
        <ContainerLoader height={500}></ContainerLoader>
      )}
      {loading && !loaded && page > 1 && (
        <div className="d-flex inline-loader justify-content-center">
          <div className="align-items-center border border-primary d-inline-flex font-base px-3 py-1 rounded-sm text-primary">
            Loading
            <img src={loaderSmall} width="26" height="26" alt="loader" />
          </div>
        </div>
      )}
    </>
  );
};

export default Reccomendations;
