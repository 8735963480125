import React from 'react';
import AppLayout from '../layout/app';
import MyAdsScreen from './components/my-ads';

const MyAds: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyAdsScreen />
    </AppLayout>
  );
};

export default MyAds;
