import React, { useState } from 'react';
import { usePerformAction } from 'react-remote-data-hooks';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../api';
import {
  Subscribe,
  SubscriptionPlan,
  SubscriptionStatus,
} from '../../api/models';
import AppLayout from '../layout/app';
import Payment, {
  AccountType,
  PaymentType,
} from '../payment/components/payment';
import BoostBackToTop from './components/BoostBackToTop';
import useAuth from '../../hooks/useAuth';
import ThankYou from '../create/components/ThankYou';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import { selectProductToBoostBackSuccessAction } from '../../store/reducers/catalog';
import { RouteKeys } from '../routes/route-keys';

const ChoosePlanScreen: React.FunctionComponent = () => {
  const auth = useAuth();
  const params = useParams<{ productId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isThankYou, setIsThankYou] = useState<boolean>(false);

  const productSelectedToBoostBack = useSelector(
    (state: State) => state.catalog.productSelectedToBoostBack
  );

  const { performAction: payment } = usePerformAction(
    (data: SubscriptionPlan): any => {
      setSubscriptionPlanSelected(data);
      setIsRedirectPayment(true);
    }
  );

  const { performAction: subscribe } = usePerformAction((paymentId): any => {
    const subscribeData: Subscribe = {
      subscriptionPlan: subscriptionPlanSelected?._id || '',
      paymentId,
      isBoostBackToTop: true,
      productId: params.productId,
      paymentType: PaymentType.Subscription,
    };
    api.subscription.subscribe(subscribeData).then((data) => {
      if (data.status === SubscriptionStatus.PaymetSuccess) {
        dispatch(selectProductToBoostBackSuccessAction());
        setIsThankYou(true);
      }
    });
  });

  if (!productSelectedToBoostBack) {
    history.push(RouteKeys.MyAds);
  }
  return (
    <AppLayout hideCategories hideFooter>
      {!isRedirectPayment && (
        <BoostBackToTop
          onPlanSelect={(data: SubscriptionPlan) => payment(data)}
          categoryId={productSelectedToBoostBack?.category?._id}
          subCategoryId={productSelectedToBoostBack?.subcategory?._id}
        />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          paymentType={PaymentType.Subscription}
          email={auth.profile?.email!}
          amount={subscriptionPlanSelected?.amount}
          subscriptionPlanId={subscriptionPlanSelected?._id}
          successhandler={subscribe}
          planName={subscriptionPlanSelected?.name}
          period={subscriptionPlanSelected?.period}
          listingDays={subscriptionPlanSelected?.listingDays}
        ></Payment>
      )}
      {isThankYou && <ThankYou />}
    </AppLayout>
  );
};

export default ChoosePlanScreen;
