/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useCallback, useState } from 'react';
import Geocode from 'react-geocode';
import { useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import useSearch from './useSearch';

const useCurrentLocationHandler = (
  onCity: (city: string) => void,
  deps: any[]
) => {
  const [isLocation, setIsLocation] = useState<boolean>(false);
  const search = useSearch();
  const triggerPageChange = (city: string) => {
    onCity(city);
    search.setCity(city);
    setIsLocation(true);
  };
  const codeLatLng = useCallback(
    (lat: any, lng: any) => {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const addressArray = response.results[0].address_components;
          let city = '';
          for (let i = 0; i < addressArray.length; i++) {
            if (
              addressArray[i].types[0] &&
              'administrative_area_level_2' === addressArray[i].types[0]
            ) {
              city = addressArray[i].long_name;
              triggerPageChange(city);
            }
          }
        },
        (error) => {
          // console.error(error);
          triggerPageChange('');
        }
      );
    },
    [triggerPageChange]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        if (isLocation) return;
        codeLatLng(position.coords.latitude, position.coords.longitude);
      },
      function (error) {
        triggerPageChange('');
      }
    );
  }, [codeLatLng, ...deps]);
};

export default useCurrentLocationHandler;
