import React from 'react';
import AppLayout from '../layout/app';
import MyAuctionScreen from './components/my-auction';

const MyAds: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyAuctionScreen />
    </AppLayout>
  );
};

export default MyAds;
