import React, { FunctionComponent, useState } from 'react';
import AppLayout from '../../layout/app';
import enquireImage from '../../../assets/images/enquire.png';
import useAuth from '../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useDataLoader } from 'react-remote-data-hooks';
import { EnquiryFormModel, ProductDetails } from '../../../api/models';
import { State } from '../../../store/interfaces';
import api from '../../../api';
import { ContainerLoader } from '../../../components/loader';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import { toastr } from 'react-redux-toastr';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { RouteKeys } from '../../routes/route-keys';
import classnames from 'classnames';
import ErrorMessage from '../../../components/text/ErrorMessage';
const EnquireNow: FunctionComponent = () => {
  const auth = useAuth();
  const authState = useSelector((state: State) => state.authUser);
  const params = useParams<{ slug: string }>();
  const [checkIsPhone, setCheckIsPhone] = useState(true);
  const isValidPhone = () => checkIsPhone;
  const { slug } = params;
  const { data: product, loading, loaded } = useDataLoader<ProductDetails>(() =>
    api.catalog.getProductDetails(slug)
  );
  const userId = authState.profile?._id || '';
  const history = useHistory();

  const { control, errors, handleSubmit, watch, setValue } = useForm<
    EnquiryFormModel
  >({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onFormSubmit = async (data: EnquiryFormModel) => {
    await api.catalog.enquireProduct({
      ...data,
      productId: product._id,
      productType: product.productType,
      userId,
    });
    toastr.success('Success', 'Enquiry successfully completed');
    history.push(`/product/${slug}`);
  };

  const renderProductDetails = () => {
    return (
      <div className="col-12 col-md-6 pl-lg-5 order-first order-md-last mb-4 mb-lg-0 px-0 px-lg-3">
        <figure className="ml-lg-auto enquire-img">
          <a
            href={RouteKeys.ProductDetails.replace(':slug', product.slug)}
            target="_blank"
            className="image overflow-hidden bg-light border-0"
          >
            <img src={product.defaultImage?.path} className="img-fluid" />
          </a>
        </figure>
        <figcaption className="ml-lg-auto px-3 px-md-0">
          <p className="font-xl mb-0">{product.title}</p>
          <span className="font-xs text-black-50">
            <i className="icon-location pr-2"></i>
            {product.location.address}
          </span>
        </figcaption>
      </div>
    );
  };

  const renderContainerLoader = () => (
    <ContainerLoader height={300}></ContainerLoader>
  );

  return (
    <AppLayout>
      <div className="header-mob bg-primary text-white px-2 d-lg-none d-flex align-items-center position-sticky top-0">
        <i
          className="icon-prev font-lg mr-3"
          onClick={() => history.goBack()}
        ></i>
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
          Inquire Now
        </h6>
      </div>
      <div className="container pt-lg-4 pb-lg-5 pb-4 pt-0 mt-lg-4 mb-lg-5 enquire-now">
        <div className="row">
          <div className="col-12 col-md-6 pr-lg-4">
            <h5 className="font-xxl text-gray-900">Inquire Now</h5>
            <p>Fill the below form to get a quick response.</p>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="row gutter-10 pt-3">
                <div className="col-12 col-sm-6 form-group mb-4 pb-2">
                  <label className="font-sm mb-1">Name</label>
                  <Controller
                    control={control}
                    name="name"
                    defaultValue={''}
                    rules={{ required: true }}
                    render={({ onChange, name, value }) => (
                      <input
                        className={classnames({
                          'form-control bg-white': true,
                          error: !!errors.name,
                        })}
                        type="text"
                        name={name}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {errors.name && (
                    <ErrorMessage>{'Name is required'}</ErrorMessage>
                  )}{' '}
                </div>
                <div className="col-12 col-sm-6 form-group mb-4 pb-2">
                  <label className="font-sm mb-1">Email</label>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue={''}
                    rules={{
                      required: true,
                      validate: {
                        isEmail: (value) => !value || validator.isEmail(value),
                      },
                    }}
                    render={({ onChange, name, value }) => (
                      <input
                        type="text"
                        className={classnames({
                          'form-control bg-white': true,
                          error: !!errors.email,
                        })}
                        name={name}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {errors.email && (
                    <ErrorMessage>
                      {errors.email.type === 'required'
                        ? 'Email is required'
                        : 'Invalid Email'}
                    </ErrorMessage>
                  )}
                </div>
                <div className="col-12 col-sm-6 form-group mb-4 pb-2">
                  <label className="font-sm mb-1">Phone</label>
                  <Controller
                    control={control}
                    name="phone"
                    // defaultValue={props.default?.phone || ''}
                    rules={{
                      required: true,
                    }}
                    key={`phone`}
                    render={({ onChange, name, value }) => (
                      <PhoneInput
                        autoFormat={false}
                        country={'bs'}
                        preferredCountries={['bs', 'in']}
                        enableSearch={false}
                        placeholder={'Phone Number'}
                        inputClass={`textField__input ${
                          errors.phone ? 'error' : ''
                        }`}
                        containerClass={'textField'}
                        dropdownStyle={{ borderColor: '#e5e6e7' }}
                        disableCountryCode={false}
                        countryCodeEditable={true}
                        inputStyle={{
                          width: '100%',
                          borderRadius: '0px',
                          borderColor: '#e5e6e7',
                        }}
                        disableSearchIcon={true}
                        onChange={(val, data: { dialCode: string }) => {
                          onChange(val);
                        }}
                        value={value}
                      />
                    )}
                  />
                  {errors.phone && (
                    <ErrorMessage>
                      {errors.phone.type === 'required'
                        ? 'Phone is required'
                        : 'Invalid Phone'}
                    </ErrorMessage>
                  )}
                </div>
                <div className="col-12 col-sm-6 form-group mb-4 pb-2">
                  <label className="font-sm mb-1">Address</label>
                  <Controller
                    control={control}
                    name="address"
                    defaultValue={''}
                    rules={{ required: false }}
                    render={({ onChange, name, value }) => (
                      <input
                        type="text"
                        className="form-control bg-white"
                        name={name}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
                <div className="col-12 form-group mb-4 pb-2">
                  <label className="font-sm mb-1">Message</label>
                  <Controller
                    control={control}
                    name="description"
                    defaultValue={''}
                    rules={{ required: false }}
                    render={({ onChange, name, value }) => (
                      <textarea
                        className="form-control bg-white"
                        id="description"
                        rows={4}
                        name={name}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-2 col-lg-3 col-12 col-sm-6 pt-lg-3 pb-5 pb-md-0">
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold btn-block py-2 enquire-now-btn mb-3 mb-md-0"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          {loaded ? renderProductDetails() : renderContainerLoader()}
        </div>
      </div>
    </AppLayout>
  );
};

export default EnquireNow;
