import {
  ApiResponse,
  Subscribe,
  SubscriptionPlan,
  Subscription,
  AccountType,
  RequestCustomPlan,
  SubscribeCustomPlan,
  RemainingPostsCountInCategoryResponseData,
  ProductType,
} from '../models';
import ApiService from './service';
import * as http from './http';
import { store } from '../../store';

export default class SubscriptionService extends ApiService {
  public async getSubscriptionPlans(requestData: {
    accountType?: AccountType;
    isBoostBackToTop?: boolean;
    isTopFeatured?: boolean;
    categoryId?: string;
    subCategoryId?: string;
    productType?: string;
  }): Promise<SubscriptionPlan[]> {
    let url = `${this.apiDomain}/subscription-plan`;
    if (requestData.accountType) {
      url = url + `?accountType=${requestData.accountType}`;
    }
    if (requestData.isBoostBackToTop) {
      url = url + `&isBoostBackToTop=${requestData.isBoostBackToTop}`;
    }
    if (requestData.isTopFeatured) {
      url = url + `&isTopFeatured=${requestData.isTopFeatured}`;
    }
    if (requestData.categoryId) {
      url = url + `&categoryId=${requestData.categoryId}`;
    }
    if (requestData.subCategoryId) {
      url = url + `&subCategoryId=${requestData.subCategoryId}`;
    }
    if (requestData.productType) {
      url = url + `&productType=${requestData.productType}`;
    }
    const response = await http.get<ApiResponse<SubscriptionPlan[]>>(url);
    return response.data.data;
  }

  public async subscribe(data: Subscribe): Promise<Subscription> {
    const url = `${this.apiDomain}/subscription`;

    const response = await http.post<ApiResponse<Subscription>>(
      url,
      data,
      this.store
    );

    return response.data.data;
  }

  public async requestCustomPlan(
    data: RequestCustomPlan
  ): Promise<Subscription> {
    const url = `${this.apiDomain}/subscription/request-custom-plan`;

    const response = await http.post<ApiResponse<Subscription>>(
      url,
      data,
      this.store
    );

    return response.data.data;
  }

  public async getCustomPlan(requestData: {
    subscriptionId: string;
  }): Promise<Subscription> {
    const state = store.getState();
    const url = `${this.apiDomain}/subscription/${requestData.subscriptionId}/generated-custom-plan-details`;

    const response = await http.get<ApiResponse<Subscription>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data.data;
  }

  public async subscribeCustomPlan(
    data: SubscribeCustomPlan
  ): Promise<Subscription> {
    const url = `${this.apiDomain}/subscription/subscribe-custom-plan`;

    const response = await http.post<ApiResponse<Subscription>>(
      url,
      data,
      this.store
    );

    return response.data.data;
  }

  public async getRemainingPostsCountInCategory(requestData: {
    categoryId?: string;
    productType?: ProductType;
  }): Promise<RemainingPostsCountInCategoryResponseData> {
    const state = store.getState();
    const url = `${this.apiDomain}/subscription/remaining-posts-count-in-category?categoryId=${requestData.categoryId}&productType=${requestData.productType}`;

    const response = await http.get<
      ApiResponse<RemainingPostsCountInCategoryResponseData>
    >(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data.data;
  }
  public async subscribeAndActivateProduct(requestData: {
    productId?: string;
    subscriptionId?: string;
  }): Promise<any> {
    const state = store.getState();
    const url = `${this.apiDomain}/subscription/add-subscription-and-activate-product?subscriptionId=${requestData.subscriptionId}&productId=${requestData.productId}`;

    const response = await http.get<ApiResponse<any>>(url, {
      apiDomain: this.store.apiDomain,
      token: state.authUser.token,
    });
    return response.data.data;
  }
}
