import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Modal } from 'react-bootstrap';
import AppLayout from '../layout/app';
import { RouteKeys } from '../routes/route-keys';
import '../../styles/detail.scss';
import { ContainerLoader } from '../../components/loader';
import { useHistory, useParams, Link } from 'react-router-dom';
import NotFound from '../notfound';
import { useDataLoader, usePerformAction } from 'react-remote-data-hooks';
import api from '../../api';
import {
  AccountType,
  BidNowData,
  ProductDetails,
  ProductType,
  SubscriptionPlan,
  SubscriptionStatus,
} from '../../api/models';
import ProductImages from './components/ProductImages';
import ProductHighLightInfo from './components/ProductHighlightInfo';
import SanitizedHTML from '../../components/html/sanitized-html';
import ProductKeyFeatures from './components/ProductKeyFeatures';
import BankInfoList from './components/BankInfoList';
import ProductRatingSection from './components/ProductRatingSection';
import ReportAdResponsive from './components/ReportAdResponsive';
import ReportUserResponsive from './components/ReportUserResponsive';
import RelatedAds from './components/RelatedAds';
import { format } from 'date-fns';
import LocationView from './components/LocationView';
import useAuth from '../../hooks/useAuth';
import Payment from '../payment/components/payment';
import { PaymentType } from '../../api/models/payment';
import ThankYou from '../create/components/ThankYou';
import { useSelector } from 'react-redux';
import { State } from '../../store/interfaces';
import VerifiedImg from '../../assets/images/verified.png';
import DealCountDown, {
  CountDownType,
} from '../../components/deal-count-down/CountDown';
import { scrollTop } from '../../utils/window-utils';
import { AvatarView } from '../../components/avatar';
import {
  messaging,
  onMessageListener,
} from '../../components/firebase/firebase';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { OrderStatus } from '../../api/models/catalog';
import maskCharacter from '../../utils/mask-characters';

enum StripeActionType {
  Auction = 'auction',
  Purchase = 'purchase',
}

const ProductDetailsScreen: React.FunctionComponent = () => {
  const auth = useAuth();
  const authState = useSelector((state: State) => state.authUser);
  const params = useParams<{ slug: string }>();
  const [modal, setModal] = React.useState<{ isOpen?: boolean; error?: Error }>(
    {}
  );
  const history = useHistory();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isBiddingComplete, setIsBiddingComplete] = useState(true);
  const [hasBiddingStarted, setHasBiddingStarted] = useState(false);

  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [bidNowPrice, setBidNowPrice] = useState(0);
  const slug = params.slug;
  const [isFav, setIsFav] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [show, setShow] = useState(false);
  const [newBidNow, setNewBidNow] = useState('');
  const [isAuctionClosed, setIsAuctionClosed] = useState(false);
  const [isAuctionClosedMessage, setIsAuctionClosedMessage] = useState(false);
  const [stripeActionType, setStripeActionType] = useState(
    StripeActionType.Purchase
  );
  const [isFirstBid, setIsFirstBid] = useState(false);

  const [changeDetails, setChangeDetails] = useState('');
  const [currentBidder, setCurrentBidder] = useState<string | undefined>('');
  const [bidCount, setBidCount] = useState<number | undefined>(0);
  const { data: product, loading, loaded } = useDataLoader<ProductDetails>(() =>
    api.catalog.getProductDetails(slug)
  );
  const userId = authState.profile?._id || '';
  const userStripeCustomerId = authState.profile?.stripeCustomerId;

  useEffect(() => {
    if (product) {
      if (
        product.orderStatus === OrderStatus.Delivered ||
        product.orderStatus === OrderStatus.OfferAccepted ||
        product.orderStatus === OrderStatus.Sold
      ) {
        setIsButtonDisabled(true);
      }
      setChangeDetails(
        'Minimum bid increment amount: $' +
          product.auctionDetails?.bidIncrementAmount
      );
      var selectDisplay = 0;
      const timer = setInterval(() => {
        selectDisplay += 1;
        if (selectDisplay % 2 == 0) {
          setChangeDetails(
            'Minimum bid increment amount: $' +
              product.auctionDetails?.bidIncrementAmount
          );
        } else {
          if (currentBidder) {
            setChangeDetails(
              'The last bidder is : ' + `${maskCharacter(currentBidder, '*')}`
            );
          } else {
            setChangeDetails('Currently there are no bids on this product.');
          }
        }
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [currentBidder]);

  useEffect(() => {
    if (product?.productType === ProductType.Auction) {
      if (product.auctionDetails) {
        setBidCount(product?.bidCount);
        setIsBiddingComplete(
          new Date().getTime() >
            new Date(product.auctionDetails.endDate).getTime() ||
            product?.isSold ||
            false
        );
        if (product?.auctionDetails?.bidNowPrice) {
          setBidNowPrice(+product.auctionDetails.bidNowPrice);
        }
        if (product.lastBidder?.id === userId) {
          setIsButtonDisabled(true);
        }
        if (
          product?.auctionDetails?.startDate &&
          new Date().getTime() >
            new Date(product?.auctionDetails?.startDate).getTime()
        ) {
          setHasBiddingStarted(true);
        }
        const { endDate } = product.auctionDetails;
        const auctionBidCount = product?.bidCount || 0;
        if (new Date().getTime() > new Date(endDate).getTime()) {
          setIsAuctionClosedMessage(true);
          if (auctionBidCount === 0) {
            setIsAuctionClosed(true);
          }
        }
      }
      // if (userId && product.createdBy.id === userId) setIsButtonDisabled(true);
    }
    // setIsBiddingComplete(
    //   product?.isSold || isBiddingComplete || isAuctionClosed
    // );
    setIsFav(product?.favorited || false);
    setIsFollowing(product?.isFollow || false);
    setTimeout(() => {
      scrollTop();
    }, 1000);
  }, [product]);

  useEffect(() => {
    if (product) {
      setCurrentBidder(product.lastBidder?.name);
    }
    if (userId && product?.createdBy?.id === userId) setIsButtonDisabled(true);
  }, [product, userId]);

  const onMakeOffer = async () => {
    if (!userId) history.push(RouteKeys.Login);
    if (userId && product.createdBy.id !== userId) {
      const { data } = await api.chat.createChat(product?._id);
      if (data?.chat?.id) {
        history.push(
          RouteKeys.ChatsDetail.replace(':chatId', data.chat.id) +
            '?view=make-offer'
        );
      } else {
        toastr.warning('Warning', 'Something went wrong');
      }
    }
  };

  const onBidSuccess = async (paymentToken?: any) => {
    const token = await getToken();
    setIsButtonDisabled(true);
    let bidData: BidNowData;
    if (token) {
      if (isFirstBid) {
        bidData = {
          productId: product?._id,
          userId,
          bidAmount: Number(bidNowPrice),
          notificationToken: token,
          paymentToken,
        };
      } else {
        bidData = {
          productId: product?._id,
          userId,
          bidAmount: Number(bidNowPrice),
          notificationToken: token,
        };
      }
      const response = await api.catalog.bidNow(bidData);
      if (response.status === 200) {
        setShow(true);
        setNewBidNow(response?.data?.bidAmountForData);
        setCurrentBidder(response?.data?.lastBidderName);
        if (response.data.bidCount) {
          setBidCount(Number(response.data.bidCount));
        }
        setThankYouHeading('Thank You');
        setThankYouMessage('You have successfully placed the bid.');
        setIsThankYou(true);
      }
    } else {
      toastr.error('Error', 'Bidding notification token error');
    }
  };

  const onClickOkayButton = async () => {
    setIsRedirectPayment(false);
    setIsThankYou(false);
  };

  const onBidNow = async (bidAmount: number) => {
    if (!userId) history.push(RouteKeys.Login);
    if (userId && product.createdBy.id !== userId) {
      if (userStripeCustomerId) {
        setIsFirstBid(false);
        onBidSuccess();
      } else {
        setIsFirstBid(true);
        setStripeActionType(StripeActionType.Auction);
        setIsRedirectPayment(true);
      }
    }
  };

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('../firebase-messaging-sw.js')
      .then((registration) => {
        // console.log('Registration successful, scope is:', registration.scope);
      })
      .catch((err) => {
        // console.log('Error', 'Something went wrong');
      });
  }

  const getToken = async () => {
    return await messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        return token;
      })
      .catch(() => {
        toastr.error('Error!', 'Something went wrong.');
      });
  };
  onMessageListener()
    .then((payload: any) => {
      if (payload?.data?.productId === product?._id) {
        toastr.success('Alert!', 'The bid was updated');
        setShow(true);
        setBidCount(payload?.data?.bidCount || (bidCount || 0) + 1);
        if (payload.data.lastBidderId !== userId.toString()) {
          setIsButtonDisabled(false);
        }
        setNewBidNow(payload?.data.bidAmountForData);
        setBidNowPrice(payload?.data.bidAmountForData);
        setCurrentBidder(payload?.data?.lastBidderName);
      }
    })
    .catch((err) => toastr.error('Error!', 'Something went wrong.'));

  const onChat = async () => {
    if (!userId) history.push(RouteKeys.Login);
    if (userId && product.createdBy.id !== userId) {
      const { data } = await api.chat.createChat(product?._id);
      if (data?.chat?.id) {
        history.push(RouteKeys.ChatsDetail.replace(':chatId', data.chat.id));
      } else {
        toastr.warning('Warning', 'Something went wrong');
      }
    }
    // history.push(RouteKeys.Chats);
  };

  const { performAction: purchase } = usePerformAction((paymentId): any => {
    const purchaseData = {
      productId: product?._id,
      paymentId,
      paymentType: PaymentType.Purchase,
    };
    api.order.purchase(purchaseData).then((data) => {
      if (data.status === 'success') {
        setThankYouHeading('Thank You');
        setThankYouMessage('You have successfully purchased the product');
        setIsThankYou(true);
      }
    });
  });

  if (!slug) {
    return <NotFound></NotFound>;
  }

  const setFavourite = (productId: string) => {
    if (!userId) history.push(RouteKeys.Login);
    setIsFav(!isFav);
    api.catalog.setFavourite(productId).then((response) => {
      // do
    });
  };
  const setFollow = (userIdVal: string) => {
    if (!userId) history.push(RouteKeys.Login);
    setIsFollowing(!isFollowing);
    api.user.setFollow(userIdVal);
  };
  const setUnFollow = (userIdVal: string) => {
    if (!userId) history.push(RouteKeys.Login);
    setIsFollowing(!isFollowing);
    api.user.setUnFollow(userIdVal);
  };
  const renderBreadCrumbs = () => {
    return (
      <div className="d-lg-block d-none pt-1 mb-4 pb-1">
        <Breadcrumb>
          <BreadcrumbItem href={RouteKeys.Home}>Home</BreadcrumbItem>
          <BreadcrumbItem
            href={`${RouteKeys.Search}?category=${product.category?._id}`}
          >
            {product.category?.title}
          </BreadcrumbItem>
          <BreadcrumbItem
            href={`${RouteKeys.Search}?category=${product.category?._id}&subCategory=${product.subcategory?._id}`}
          >
            {product.subcategory?.title}
          </BreadcrumbItem>
          <BreadcrumbItem active>{product.title} </BreadcrumbItem>
        </Breadcrumb>
      </div>
    );
  };

  const renderProductDetails = () => {
    return (
      <>
        <h5 className="text-gray-700 font-weight-normal">{product.title}</h5>
        <div className="text-gray-500 font-xs">
          <i className="icon-location mr-2"></i>
          {product.city
            ? `${product.city}, ${product.location.country}`
            : `${product.location.state}, ${product.location.country}`}
          <i className="icon-heart-filled mr-2 ml-4"></i>
          {product.likeCount || 0} Likes
          <i className="icon-visible mr-2 ml-4"></i>
          {product.viewCount || 0} Views
        </div>
      </>
    );
  };

  const renderOwnerInfo = () => {
    return (
      <div className="d-flex no-gutters align-items-center pt-3 mb-3">
        <div className="col-9 d-flex align-items-center">
          <div className="avatar avatar--md mr-3">
            <AvatarView
              src={product.createdBy.avatar?.path}
              name="user"
              size="medium"
            />
            {/* <img
              src={product.createdBy.avatar?.path}
              className="w-100 h-100 img-cover"
              alt=""
            /> */}
          </div>
          <div className="px-0 col">
            <h6 className="text-truncate m-0">{product.createdBy.name}</h6>
            {/* <div className="mt-1"><span className="status active">Individual</span></div> */}
            {product.createdBy?.emailVerified &&
            product.createdBy?.phoneVerified ? (
              <img src={VerifiedImg} width="64" height="20" alt="verified" />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="col-3 text-right">
          {product.createdBy.id !== auth.profile?._id && (
            <button
              className="btn btn-outline-primary py-1 border-width-1 font-xs"
              onClick={(e) => {
                if (!isFollowing) setFollow(product.createdBy.id);
                else setUnFollow(product.createdBy.id);
              }}
            >
              {isFollowing ? 'UnFollow' : 'Follow'}
            </button>
          )}
          {product.productType === ProductType.Auction ||
          product.productType === ProductType.Lead ? (
            <button
              className={`btn btn-link py-2 font-weight-semibold d-flex align-items-center justify-content-center cursor-pointer`}
              onClick={() => onChat()}
              disabled={isButtonDisabled}
            >
              <i className="icon-chat mr-2 font-xxl text-gray-600"></i>
              <span className="font-base text-gray-900 font-weight-semibold">
                Chat
              </span>
            </button>
          ) : (
            ''
          )}
          {/* {product.productType === ProductType.Auction ? (
            <a
              className="py-2 font-weight-semibold d-flex align-items-center justify-content-center cursor-pointer"
              onClick={() => onMakeOffer()}
            >
              <i className="icon-chat mr-2 font-xxl text-gray-600"></i>
              <span className="font-base text-gray-900 font-weight-semibold">
                Chat
              </span>
            </a>
          ) : (
            ''
          )} */}
        </div>
      </div>
    );
  };
  const renderBidSection = () => {
    if (!product?.auctionDetails?.endDate) return <></>;
    return (
      <div className="row details text-gray-700 pb-3 auction-details font-sm  gutter-10  border-bottom">
        {/* Countdown timer */}
        <div className="col-12 col-md-8 ">
          <DealCountDown
            startDate={new Date(product?.auctionDetails?.startDate)}
            endDate={new Date(product?.auctionDetails?.endDate)}
            onCompleted={() => setIsBiddingComplete(true)}
            type={CountDownType.block}
            isComplete={product.isSold}
          />
        </div>
        {/* End Countdown timer */}

        <div className="col-12 col-md-4">
          {product.bidCount ? (
            <div className="bid-count">
              {bidCount} {product?.bidCount > 1 ? 'Bids' : 'Bid'}
            </div>
          ) : (
            <div className="bid-count">{bidCount || 0} Bid</div>
          )}
          Bidding History
        </div>
      </div>
    );
  };

  const renderBidButton = (display: string) => {
    return (
      <button
        className="btn btn-outline-primary btn-block py-2 font-weight-semibold"
        disabled={isButtonDisabled || !hasBiddingStarted || isBiddingComplete}
        onClick={() => onBidNow(bidNowPrice)}
      >
        {display}
      </button>
    );
  };

  const renderLocation = () => {
    return (
      <div className="map-block">
        <h5 className="mb-1">Posted in</h5>
        <p className="text-muted">{product.location.address}</p>
        <div className="map ">
          {product && <LocationView product={product}></LocationView>}
        </div>
      </div>
    );
  };

  if (!loaded || loading) {
    return (
      <AppLayout>
        <ContainerLoader height={500}></ContainerLoader>{' '}
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      {!isRedirectPayment && (
        <>
          <div className="header-mob bg-primary text-white px-2 d-lg-none d-flex align-items-center position-sticky top-0">
            <i
              className="icon-prev font-lg mr-3"
              onClick={() => history.goBack()}
            ></i>
            <h6 className="mb-0 font-weight-bold col p-0 text-truncate">
              {product.title}
            </h6>
          </div>
          <div className="container pt-lg-2 pb-lg-5 pb-0">
            {renderBreadCrumbs()}
            <div className="row d-lg-block clearfix detail-block">
              <div className="col-lg-4 col-md-8 mx-md-auto mt-md-4  mx-lg-0 mt-lg-0 col-12 float-lg-right order-1 px-0 px-lg-3 right-pane">
                <div className="bg-white lg-rounded shadow p-lg-4 p-3 mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="font-weight-bold mb-0 display-3">
                      $
                      {product.productType === ProductType.Auction
                        ? `${product.auctionDetails?.buyNowPrice}`
                        : `${product.price}`}
                    </h2>
                    <div className="d-flex align-items-center ">
                      {product.createdBy.id !== auth.profile?._id && (
                        <div className="cursor-pointer">
                          <i
                            className={`ml-lg-2 font-xxl d-lg-inline d-none text-gray-700 cursor-pointer mr-2 ${
                              isFav ? 'icon-heart-filled' : 'icon-heart-outline'
                            }`}
                            onClick={(e) => setFavourite(product._id)}
                          ></i>
                        </div>
                      )}

                      <div className="share-popup position-relative">
                        <div className="d-inline-flex align-items-center font-xs text-gray-500">
                          <i className="icon-share ml-lg-2 font-xxl  text-gray-700 cursor-pointer"></i>
                        </div>
                        <div className="share-popup-list position-absolute top-100 right-0">
                          <div className="d-flex share-popup-listinner bg-white shadow rounded position-relative">
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                                window.location.href
                              )}`}
                              className="d-inline-flex mr-3"
                            >
                              <i className="icon-fb"></i>
                            </a>
                            <a
                              href={`https://twitter.com/share?url=${encodeURI(
                                window.location.href
                              )}&text=${product.title}`}
                              className="d-inline-flex mr-3"
                            >
                              <i className="icon-rounded-twitter">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </a>
                            <a
                              href={`https://pinterest.com/pin/create/button/?url=${encodeURI(
                                window.location.href
                              )}&description=${product.title}`}
                              className="d-inline-flex mr-3"
                            >
                              <i className="icon-pinterest">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                            </a>
                            {/* <a href="" className="d-inline-flex">
                  <i className="icon-tumblr">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="font-sm text-gray-500 pb-4 mb-0">
                    Posted on:{' '}
                    <span className="text-gray-700 font-weight-semibold">
                      {format(new Date(product.createdAt), 'dd MMM yyyy')}
                    </span>
                  </p>
                  {renderProductDetails()}
                </div>
                <div className="bg-white lg-rounded shadow p-lg-4 p-3 mb-4">
                  {!isBiddingComplete && renderBidSection()}

                  {renderOwnerInfo()}

                  <div className="row gutter-5">
                    {product.productType === ProductType.Auction ? (
                      <>
                        {!isAuctionClosed ? (
                          <>
                            {isAuctionClosedMessage && (
                              <p className="col-12">Auction Closed!</p>
                            )}
                            <div className={`col-12`}>
                              {show &&
                                renderBidButton(
                                  `Bid Now @ ${Number(newBidNow).toFixed(2)}`
                                )}
                              {!show &&
                                renderBidButton(
                                  `Bid Now @ ${bidNowPrice.toFixed(2)}`
                                )}
                            </div>
                            {product.auctionDetails ? (
                              <div className="col-12 my-3">
                                <span className="text-center w-100">
                                  {/* Minimum bid increment amount: $
                                  {product.auctionDetails?.bidIncrementAmount} */}
                                  {changeDetails}
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <>
                            <p className="col-12">Auction Closed!</p>
                            <div className={`col-12`}>
                              <button
                                disabled={isButtonDisabled}
                                className="btn btn-primary btn-block py-2 font-weight-semibold mb-3"
                                onClick={() => {
                                  history.push(
                                    RouteKeys.BuyProduct.replace(':slug', slug)
                                  );
                                }}
                              >
                                Buy Now @ $
                                {product?.auctionDetails?.buyNowPrice}
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {product.productType === ProductType.Lead ? (
                      <div className="col-12">
                        <Link
                          to={`/enquire-now/${params.slug}`}
                          className={`btn btn-primary btn-block py-2 font-weight-semibold mb-3 ${
                            isButtonDisabled ? 'disabled' : ''
                          }`}
                        >
                          Inquire Now
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}

                    {product.productType === ProductType.Sale ? (
                      <>
                        <div className="col-6">
                          <button
                            className="btn btn-outline-dark  btn-block py-2 font-weight-semibold mb-3 d-flex align-items-center justify-content-center"
                            onClick={() => onChat()}
                            disabled={isButtonDisabled}
                          >
                            <i className="icon-chat mr-2 font-xxl"></i>
                            <span className="font-base font-weight-semibold">
                              Chat
                            </span>
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            disabled={isButtonDisabled}
                            className="btn btn-primary btn-block py-2 font-weight-semibold mb-3"
                            onClick={() => onMakeOffer()}
                          >
                            Make Offer
                          </button>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>

                  {product.createdBy.id !== auth.profile?._id && (
                    <ReportUserResponsive userId={product.createdBy.id} />
                  )}
                </div>
                <div className="bg-white lg-rounded shadow p-lg-4 p-3 mb-4">
                  {renderLocation()}
                </div>
                {product.createdBy.id !== auth.profile?._id && (
                  <ReportAdResponsive adId={product._id} />
                )}
              </div>

              <div className="col-lg-8 col-12 order-0 px-0 px-lg-3 left-pane">
                <ProductImages
                  images={product.images}
                  title={product.title}
                  featured={
                    product.isFeaturedValidTill &&
                    moment(product.isFeaturedValidTill).isSameOrAfter(moment())
                      ? true
                      : false
                  }
                  className="mb-lg-3  top-0"
                ></ProductImages>
              </div>

              <div className="col-lg-8 col-12 product-detail-box pb-lg-0 pb-4 order-2 px-0 px-lg-3 left-pane">
                <ProductHighLightInfo
                  product={product}
                  highlightFields={product.highlightedFields!}
                />
                <div className="product-description font-normal pb-lg-2 mb-4 px-3 px-lg-0">
                  <h5 className="mb-3">Product Description</h5>
                  <SanitizedHTML
                    html={product.description?.replaceAll('\n', '<br />')}
                  />
                </div>
                <ProductKeyFeatures
                  product={product}
                  keyFields={product.keyFields}
                />

                {product.banks && <BankInfoList banks={product.banks} />}

                {product.rating && (
                  <ProductRatingSection
                    productId={product._id}
                    rating={product.rating}
                  />
                )}
                {product.isExchange && (
                  <div className="product-description font-normal pb-lg-2 mb-4 px-3 px-lg-0">
                    <h5 className="mb-3">Exchange With</h5>
                    {product.exchangeables?.join(', ')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <RelatedAds
            parentProductId={product._id}
            categoryId={product.category?._id!}
          />
        </>
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          isAuction={true}
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          paymentType={PaymentType.Purchase}
          email={auth.profile?.email!}
          amount={product.price}
          isSinglePayment={true}
          productId={product._id}
          successhandler={
            stripeActionType === StripeActionType.Purchase
              ? purchase
              : onBidSuccess
          }
          planName={product.title}
        ></Payment>
      )}

      {isThankYou && (
        <ThankYou
          productId={product._id}
          heading={thankYouHeading}
          message={thankYouMessage}
          isCustomPlan={false}
          isPurchase={true}
          isBid={true}
          onClickOkayButton={onClickOkayButton}
        />
      )}
    </AppLayout>
  );
};

export default ProductDetailsScreen;
