import React from 'react';
import "../../styles/cms.scss";

const Business: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 position-relative">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <h1 className="text-white mb-3">All Businesses Can
                                Make A Deal on
                                <br />Deal Den
                            </h1>
                            <a href="#" className="btn btn-secondary mb-5 px-4 rounded-sm text-primary font-md font-weight-bold">
                                <span className="px-4">Sell Now</span>
                            </a>
                        </div>
                        <div
                            className="col-md-6 col-12 text-center banner-right-block py-md-5 py-4 d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/business-banner-img.png')} className="img-fluid position-relative my-md-5" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-12 text-center">
                            <h2 className="mt-5 text-dark mb-4">Connect your products and services to
                                locals where they buy
                            </h2>
                            <p className="font-md text-gray-700">The local communities use DealDen to buy, sell, auction and get
                                leads. Now as a business, you can drive discovery and sales of your products or services where
                                locals are already actively buying.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light-blue py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center mb-4 pb-2">
                            <h2 className="font-weight-bold">Here’s what you can do with DealDen</h2>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/followers-img.png')} alt="" />
                            </div>
                            <p className="font-md">Get new followers & customers through our social classified.</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/store-img.png')} alt="" />
                            </div>
                            <p className="font-md">Inspire and engage shoppers through visual merchandising with images and videos.
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/transact-img.png')} alt="" />
                            </div>
                            <p className="font-md">Seamlessly transact with consumers on desktop or mobile with cashless payments,
                                negotiation & messaging.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12 mb-5">
                            <img src={require('../../assets/images/cms/about-img.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 col-12 mb-5">
                            <h2>About DealDen</h2>
                            <p className="font-md text-gray-700 mb-0">We built a social classified marketplace to make it easier for
                                people to buy, sell, auction and get leads in their community. We invite all businesses to use
                                Deal Den as a new way to engage with consumers and sell products. DealDen continues to grow as
                                more business owners, marketers and salespeople use our marketplace to capture leads,
                                communicate with interested buyers and drive sales.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 mb-4 pb-2">
                            <div className="benefits p-4 h-100">
                                <div className="p-3">
                                    <img src={require('../../assets/images/cms/benefits-img.png')} className="mb-3 pb-1" alt="" />
                                    <h2 className="font-weight-bold mb-3">Benefits of Selling on
                                        Marketplace</h2>
                                    <p className="font-md text-gray-700">DealDen is where people can discover, buy, sell, auction,
                                        and get leads locally. Here are some of the benefits of selling or listing your items
                                        and services on DealDen as a business.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-4 pb-2">
                            <div className="community p-4 h-100">
                                <div className="p-3">
                                    <img src={require('../../assets/images/cms/community-img.png')} className="mb-3 pb-1" alt="" />
                                    <h2 className="font-weight-bold mb-3">Reach an active, growing
                                        community</h2>
                                    <p className="font-md text-gray-700">When you sell as a business on DealDen, you get access to a
                                        growing community of active, motivated buyers. Each month, thousands of people are
                                        browsing and buying items from other people and businesses on DealDen. With us, your
                                        businesses have the opportunity to create an additional distribution & revenue channel
                                        that can reach this community and support your business growth.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-4 pb-2">
                            <div className="messenger p-4 h-100">
                                <div className="p-3">
                                    <img src={require('../../assets/images/cms//messenger-img.png')} className="mb-3 pb-1" alt="" />
                                    <h2 className="font-weight-bold mb-3">DealDen Messenger</h2>
                                    <p className="font-md text-gray-700">Businesses benefit from the ability to directly respond to
                                        questions or inquiries from interested buyers with the help of DealDen Messenger.
                                        Messenger makes it possible to communicate in real-time with leads and prospective
                                        buyers before they lose interest or decide to move along and browse other products or
                                        services.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 mb-4 pb-2">
                            <div className="listings p-4 h-100">
                                <div className="p-3">
                                    <img src={require('../../assets/images/cms/listings-img.png')} className="mb-3 pb-1" alt="" />
                                    <h2 className="font-weight-bold mb-3">Valuable Listings</h2>
                                    <p className="font-md text-gray-700">Unlike many traditional classifieds and marketplaces like
                                        newspaper or Facebook marketplace, DealDen allows businesses to create and manage
                                        listings at reasonable cost with maximum exposure to interested buyers and followers
                                        There may be commission costs associated with each completed sale.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-5 justify-content-center feature-block">
                        <div className="col-12 mt-5">
                            <h2 className="font-weight-bold mb-4 pb-1 text-center">
                                We have custom features and plans designed for 4 types of<br /> Business that sells sell
                                inventory on
                                Marketplace:
                            </h2>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 col-12 text-center mb-3">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/automotive-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 font-weight-bold">Automotive</h5>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 col-12 text-center mb-3">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/realestate-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 font-weight-bold">Real Estate</h5>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 col-12 text-center mb-3">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/service-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 font-weight-bold">Service Providers</h5>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 col-12 text-center mb-3">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/retail-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 font-weight-bold">Retail & Ecommerce</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-dark py-5">
                <div className="container py-2 px-md-4">
                    <div className="row">
                        <div className="col-md-7 col-12">
                            <h2 className="mb-4 pb-3 text-white">Let's do great things together.</h2>
                            <img src={require('../../assets/images/cms/demo-img.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-5 col-12">
                            <div className="bg-white rounded-lg p-4">
                                <h5 className="font-weight-bold mb-3 pb-1 pt-2">Book a Demo</h5>
                                <div className="form-group mb-3 pb-1">
                                    <input type="text" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group mb-3 pb-1">
                                    <input type="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group mb-3 pb-1">
                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                </div>
                                <div className="pt-2 pb-3">
                                    <button className="btn btn-primary btn-block">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Business;
