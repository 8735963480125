import { format } from 'date-fns';
import React, { FunctionComponent } from 'react';

interface CustomProps {
  plan: any;
}

const SubscriptionPlan: FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const { plan } = props;
  return (
    <div className="col-12 col-sm-6 mb-3 pb-1">
      <div className="subscription-box text-white rounded px-4 py-3">
        <div className="row align-items-center mb-2">
          <div className="col-6">
            <p className="font-md text-uppercase mb-0">You are on</p>
          </div>
          <div className="col-6 text-right">
            <div className="d-inline-flex align-items-baseline">
              <span className="h3 mb-0 font-weight-normal text-white">$</span>
              <span className="h3 mb-0 font-weight-bold text-white">
                {plan.amount}
              </span>
              {plan.isRecurring && plan.expireOn && (
                <span className="font-normal">/{plan.period}</span>
              )}
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-12 mb-1">
            <h3 className="font-weight-light text-white mb-0">
              {plan.planName}
            </h3>
          </div>
          <div className="col-12 d-flex align-items-center font-weight-light">
            <span className="font-md">
              {plan.categories?.map((c: any, i: number) =>
                i === plan.categories!.length - 1
                  ? c.title.concat('')
                  : c.title.concat(', ')
              )}
            </span>
            <span className="font-md pr-2">
              {plan.noOfPostsRemaining} posts remaining
            </span>
            {plan.isRecurring && plan.expireOn && (
              <span className="font-md">
                {format(new Date(plan.expireOn), 'dd MMM yyyy')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
