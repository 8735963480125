import React from 'react';
import "../../styles/cms.scss";

const Career: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 position-relative">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <h1 className="text-white mb-3">Come build the future
                                of local with us
                            </h1>
                            <p className="font-lg font-weight-semibold text-white mb-5">Our mission is to enhance people's lives by
                                offering a innovative social classified online marketplace where people can connect, sell, buy,
                                auction, get leads and chat with one another in their local communities.</p>
                            <a href="#" className="btn btn-secondary mb-5 px-4 rounded-sm text-primary font-md font-weight-bold">
                                <span className="px-4">Opportunities</span>
                            </a>
                        </div>
                        <div
                            className="col-md-6 col-12 text-center banner-right-block py-md-5 py-4 d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/career-banner-img.png')} className=" img-fluid position-relative my-md-5" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12 mb-5">
                            <img src={require('../../assets/images/cms/about-img.png')} className=" img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 col-12 mb-5">
                            <h2>About DealDen</h2>
                            <p className="font-md text-gray-700 mb-0">We built a social classified marketplace to make it easier for
                                people to buy, sell, auction and get leads in their community. We invite all businesses to use
                                Deal Den as a new way to engage with consumers and sell products. DealDen continues to grow as
                                more business owners, marketers and salespeople use our marketplace to capture leads,
                                communicate with interested buyers and drive sales.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light-blue py-5">
                <div className="container my-2">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h2 className="mb-4 pb-3">Want to help grow your local community where<br />
                                all useful things are used?
                            </h2>
                            <button className="btn btn-primary px-5 rounded-sm"><span className="d-inline-block px-md-5 px-3">See Job
                                Openings
                            </span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Career;
