import React from 'react';
import "../../styles/cms.scss";

const LocalBusiness: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 h-100 position-relative">
                    <div className="row h-100">
                        <div className="col-md-6 col-12">
                            <div className="h-100 d-flex justify-content-center align-items-start flex-column">
                                <h1 className="text-white mb-3">Local shopping and
                                    classified reimagined
                                </h1>
                            </div>
                        </div>
                        <div
                            className="col-md-6 col-12 justify-content-center banner-right-block py-md-5 py-4 d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/local-business-banner-img.png')} className="img-fluid position-relative my-md-4" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-sm-5 my-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h3 className="text-center font-weight-bold mb-5">In Deals We Trust</h3>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/save-time-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">Save time</h5>
                            <p className="font-md">No need to coordinate with strangers. Pick the time that works for you.</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/cashless-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">Cashless Payment</h5>
                            <p className="font-md">Pay securely in the website or app,  no need to exchange cash or stop at the ATM.
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 text-center mb-4">
                            <div
                                className="trust-iconbox rounded-circle d-inline-flex align-items-center justify-content-center mb-4">
                                <img src={require('../../assets/images/cms/locker-img.png')} alt="" />
                            </div>
                            <h5 className="mb-2 pb-1 font-weight-bold">Convenient Locker Pick Up & Delivery</h5>
                            <p className="font-md">Small to large items picked up and delivered using ClickBox lockers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-sm-5 pb-sm-5 pt-0 pb-5">
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center font-weight-bold mb-5">Hassle-free local shopping</h3>
                    </div>
                    <div className="col-lg-7 col-12 lb-left-block position-relative pt-3 px-sm-4 px-3 mb-lg-0 mb-4">
                        <div className="lb-box px-md-5 px-4 py-md-4 py-3 mt-4">
                            <h4 className="font-weight-bold mb-2 text-sm-left text-center">DealDen Local</h4>
                            <p className="font-md text-gray-700 mb-3 text-sm-left text-center">Fits to your schedule, easy drop off, secure payment.</p>
                            <ul className="lb-timeline list-unstyled p-0 m-0 d-sm-inline-flex d-flex flex-wrap position-relative flex-sm-row flex-column">
                                <li className="text-center position-relative d-flex flex-column align-items-sm-start align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/store-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Shop</p>
                                </li>
                                <li className="text-center position-relative d-flex flex-column align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/schedule-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Schedule</p>
                                </li>
                                <li className="text-center position-relative d-flex flex-column align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/locker-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Pick Up from locker</p>
                                </li>
                                <li className="text-center position-relative d-flex flex-column align-items-sm-end align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/save-time-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Time saved</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lb-box px-md-5 px-4 py-md-4 py-3 mt-2">
                            <h4 className="font-weight-bold mb-2 text-sm-left text-center">Local meetup</h4>
                            <p className="font-md text-gray-700 mb-3 text-sm-left text-center">Coordination headaches, in-person meetups.</p>
                            <ul className="lb-timeline list-unstyled p-0 m-0 d-sm-inline-flex d-flex flex-wrap position-relative flex-sm-row flex-column">
                                <li className="text-center position-relative d-flex flex-column align-items-sm-start align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/store-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Browse</p>
                                </li>
                                <li className="text-center position-relative d-flex flex-column align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/schedule-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Coordinate with seller</p>
                                </li>
                                <li className="text-center position-relative d-flex flex-column align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/locker-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Travel, meet up,  stop to ATM, exchange cash</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lb-box px-md-5 px-4 py-md-4 py-3 mt-2">
                            <h4 className="font-weight-bold mb-2 text-sm-left text-center">Browse deals near you</h4>
                            <p className="font-md text-gray-700 mb-3 text-sm-left text-center">Check out some top categories to get started</p>
                            <ul className="lb-timeline list-unstyled p-0 m-0 d-sm-inline-flex d-flex flex-wrap position-relative flex-sm-row flex-column lb-timeline-one">
                                <li className="text-center position-relative d-flex flex-column align-items-sm-start align-items-center mb-sm-0 mb-3">
                                    <div className="rounded-circle bg-white text-primary d-inline-flex align-items-center justify-content-center mb-2 lb-icon">
                                        <img src={require('../../assets/images/cms/store-img.png')} alt="" />
                                    </div>
                                    <p className="font-md text-gray-700 m-0 d-inline-block text-center">Browse</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 py-3 text-center lb-img-block">
                        <img src={require('../../assets/images/cms/support-local-business-img.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div className="available-block py-5">
                <div className="container pb-md-4">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="font-weight-bold text-center mb-5">Deal Den Is Now Available </h2>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/anguilla.png')} className="mr-2 pr-1" alt="" />Anguilla
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/antigua.png')} className="mr-2 pr-1" alt="" />Antigua
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/aruba.png')} className="mr-2 pr-1" alt="" />Aruba
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/bahamas.png')} className="mr-2 pr-1" alt="" />Bahamas
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/barbados.png')} className="mr-2 pr-1" alt="" />Barbados
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/barbuda.png')} className="mr-2 pr-1" alt="" />Barbuda
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/bvi.png')} className="mr-2 pr-1" alt="" />British Virgin Islands
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/carriacou.png')} className="mr-2 pr-1" alt="" />Carriacou
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/curacao.png')} className="mr-2 pr-1" alt="" />Curacao
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/dominica.png')} className="mr-2 pr-1" alt="" />Dominica
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/dominicanrepublic.png')} className="mr-2 pr-1" alt="" />Dominican Republic
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/grandcayman.png')} className="mr-2 pr-1" alt="" />Grand Cayman
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/grenada.png')} className="mr-2 pr-1" alt="" />Grenada
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/guadeloupe.png')} className="mr-2 pr-1" alt="" />Guadeloupe
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/haiti.png')} className="mr-2 pr-1" alt="" />Haiti
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/jamaica.png')} className="mr-2 pr-1" alt="" />Jamaica
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/martinique.png')} className="mr-2 pr-1" alt="" />Martinique
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/montserrat.png')} className="mr-2 pr-1" alt="" />Montserrat
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/nevis.png')} className="mr-2 pr-1" alt="" />Nevis
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/saintbarthelemy.png')} className="mr-2 pr-1" alt="" />Saint Barthelemy
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/saintkitts.png')} className="mr-2 pr-1" alt="" />Saint Kitts
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/saintlucia.png')} className="mr-2 pr-1" alt="" />Saint Lucia
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/saintmartin.png')} className="mr-2 pr-1" alt="" />Saint Martin
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/saintvincent.png')} className="mr-2 pr-1" alt="" />Saint Vincent
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/t&t.png')} className="mr-2 pr-1" alt="" />Trinidad & Tobago
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-12 mb-2">
                            <div className="country-box rounded p-2 font-md d-flex align-items-center">
                                <img src={require('../../assets/images/cms/flags/t&c.png')} className="mr-2 pr-1" alt="" />Turks & Caicos
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalBusiness;
