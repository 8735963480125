import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import Button from '../../../components/button';
import { RouteKeys } from '../../routes/route-keys';
interface DeliverProductProps {
    orderId?: string;
    heading?: string;
    message?: string;
}

const renderHeading = (heading?: string) => {
    return (
      <div className="font-xxl font-weight-bold mb-3 mt-4">
        {heading || 'Congratulations!'}
      </div>
    );
  };
const renderMessage = (message?: string) => {
return (
    <div className="font-normal">
    {message || 'Your product is delivered successfully'}.
    </div>
);
};

const renderGoHomeButton = () => {
    return (
        <div className="mt-3">
        {' '}
        <Button
            type="button"
            className="btn py-2 btn-outline-primary font-weight-bold btn-block"
            link={RouteKeys.Home}
        >
            Go To Home Page
        </Button>{' '}
        </div>
    );
};

const DeliverProduct: React.FunctionComponent<DeliverProductProps> = (props) => {
  const params = useParams<{ token: string }>();

  useEffect(() => {
      api.order.deliverProduct(params.token);
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center py-5">
        <div className="col-md-5 col-sm-6 col-12 bg-white shadow text-center p-5 rounded">
          <i className="icon-tick1 display-2">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          {renderHeading(props.heading)}
          {renderMessage(props.message)}
          {renderGoHomeButton()}
        </div>
      </div>
    </div>
  );
}

export default DeliverProduct;