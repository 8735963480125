import React from 'react';

import GooglePlayImage from '../../assets/images/googleplay.png';
import Config from '../../config';

const AndroidAppLink: React.FunctionComponent<{ className?: string }> = (
  props
) => {
  return (
    <a href={Config.ANDROID_APP_LINK} className={props.className}>
      <img src={GooglePlayImage} className="img-fluid" alt="googleplay" />
    </a>
  );
};

export default AndroidAppLink;
