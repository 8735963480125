import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Auction, BrandingFormProps, Location } from '../../../api/models';
import ErrorMessage from '../../../components/text/ErrorMessage';
import LocationInput from '../../../components/google-autocomplete/SearchLocationInput';
import classNames from 'classnames';
import { DateInput } from '../../../components/form-group';
import moment from 'moment';
import TimeInput from '../../../components/form-group/time-input';
import { combineDateAndTime } from '../../../utils/CombineDateAndTime';
interface AuctionFromProps {
  startDate: Date;
  endDate: Date;
  endTime: string;
  startTime: string;
  startPrice: number;
  buyNowPrice: number;
  bidIncrementAmount: number;
}
const AuctionForm: React.FunctionComponent<{
  onAuctionSubmit: (auctionDetails: Auction) => void;
  productDetails?: any;
  productId?: string;
}> = (props) => {
  const { control, setValue, errors, handleSubmit, watch } = useForm<
    AuctionFromProps
  >({
    shouldFocusError: true,
  });
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [checked, setChecked] = useState(false);
  const onSubmit = async (data: AuctionFromProps) => {
    data.bidIncrementAmount = Number(data.bidIncrementAmount);
    data.buyNowPrice = Number(data.buyNowPrice);
    data.startPrice = Number(data.startPrice);
    data.startDate = combineDateAndTime(data.startDate!, data.startTime!);
    data.endDate = combineDateAndTime(data.endDate!, data.endTime!);
    props.onAuctionSubmit(data);
  };

  const getExactTime = (date: Date, time: string): Date => {
    return moment
      .tz(`${moment(date).format('DD-MMM-YYYY')} ${time}`, 'DD-MMM-YYYY HH:mm')
      .toDate();
  };

  const getEndTime = () => {
    if (watch('startDate') == null || watch('endDate') == null) {
      return watch('startTime');
    }
    const beginDate = watch('startDate');
    const endDateTime = watch('endDate');
    if (moment(beginDate).isSame(endDateTime, 'day')) {
      return watch('startTime');
    }
    return '12:00 am';
  };

  const renderStartDate = () => {
    return (
      <div className="form-group mb-4">
        <label className="mb-1">Start Date</label>
        <span className="tooltip ml-1 position-relative">
          <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
            ?
          </span>
          <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
            Start Date and Time is when the auction will begin accepting bids.
          </span>
        </span>
        <Controller
          control={control}
          name="startDate"
          rules={{ required: 'Enter start date' }}
          defaultValue={
            props.productDetails?.startDate
              ? moment(props.productDetails?.startDate).format('MM/DD/YYYY')
              : ''
          }
          render={({ onChange, value, name }) => (
            <DateInput
              placeholder="dd/mm/yyyy"
              onChange={(val) => {
                onChange(val);
                setStartDate(val);
              }}
              error={errors.startDate?.message}
              default={value}
              dateFormat="dd/MM/yyyy"
              maxDate={endDate}
              minDate={new Date()}
            ></DateInput>
          )}
        ></Controller>
      </div>
    );
  };
  const renderStartTime = () => {
    return (
      <div className="form-group mb-4 time-picker">
        <label className="mb-1">Start Time</label>
        <Controller
          control={control}
          name="startTime"
          rules={{ required: true }}
          defaultValue={
            props.productId
              ? moment(props.productDetails.startDate).format('HH:mm a')
              : props?.productDetails?.startTime
          }
          render={({ onChange, value, name }) => (
            <TimeInput
              default={props?.productDetails?.startTime || value}
              placeholder={'HH:mm'}
              onChange={onChange}
              minDate={props?.productDetails?.startTime || watch('startDate')}
            />
          )}
        />
        {errors.endTime && (
          <div>
            <ErrorMessage>Enter start time</ErrorMessage>
          </div>
        )}
      </div>
    );
  };

  const renderEndDate = () => {
    return (
      <div className="form-group mb-4">
        <label className="mb-1">End Date</label>
        <span className="tooltip ml-1 position-relative">
          <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
            ?
          </span>
          <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
            End Date and Time is when the auctions stop accepting bids.
          </span>
        </span>
        <Controller
          control={control}
          name="endDate"
          rules={{ required: 'Enter end date' }}
          defaultValue={
            props.productDetails?.endDate
              ? moment(props.productDetails?.endDate).format('MM/DD/YYYY')
              : ''
          }
          render={({ onChange, value, name }) => (
            <DateInput
              placeholder="dd/mm/yyyy"
              onChange={(val) => {
                onChange(val);
                setEndDate(val);
              }}
              error={errors.endDate?.message}
              default={value}
              dateFormat="dd/MM/yyyy"
              minDate={startDate || new Date()}
            ></DateInput>
          )}
        ></Controller>
      </div>
    );
  };
  const renderEndTime = () => {
    return (
      <div className="form-group mb-4 time-picker">
        <label className="mb-1">End Time</label>

        <Controller
          control={control}
          name="endTime"
          rules={{ required: true }}
          defaultValue={
            props.productId
              ? moment(props.productDetails.endDate).format('HH:mm a')
              : props?.productDetails?.endTime
          }
          render={({ onChange, value, name }) => (
            <TimeInput
              default={props.productDetails?.endTime || value}
              placeholder={'HH:mm'}
              onChange={onChange}
              minDate={props?.productDetails?.endDate || watch('endDate')}
              startTime={getEndTime()}
            />
          )}
        />
        {errors.endTime && (
          <div>
            <ErrorMessage>Enter End Time</ErrorMessage>
          </div>
        )}
      </div>
    );
  };

  const renderStartPriceInput = () => {
    return (
      <Controller
        name="startPrice"
        control={control}
        defaultValue={props.productDetails?.startPrice}
        rules={{
          required: 'Price is required',
          validate: {
            isValid: (value) => !isNaN(value) || 'Invalid Price',
            minValue: (value) => value > 0 || 'Prices should be greater than 0',
          },
        }}
        render={({ onChange, name, value }) => {
          return (
            <>
              <div className="form-group mb-4">
                <label className="mb-1">
                  Starting Price
                  <span className="tooltip ml-1 position-relative">
                    <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
                      ?
                    </span>
                    <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
                      Starting Price is the opening bid. This can be any price
                      you choose but it must be a minimum of $1.
                    </span>
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control bg-white"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <span className="d-block error">
                  {errors?.startPrice?.message}
                </span>
              </div>
            </>
          );
        }}
      ></Controller>
    );
  };
  const renderBuyNowPriceInput = () => {
    return (
      <Controller
        name="buyNowPrice"
        control={control}
        defaultValue={props.productDetails?.buyNowPrice}
        rules={{
          required: 'BuyNowPrice is required',
          validate: {
            isValid: (value) => !isNaN(value) || 'Invalid Price',
            minValue: (value) => value > 0 || 'Prices should be greater than 0',
          },
        }}
        render={({ onChange, name, value }) => {
          return (
            <>
              <div className="form-group mb-4">
                <label className="mb-1">
                  Buy Now Price
                  <span className="tooltip ml-1 position-relative">
                    <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
                      ?
                    </span>
                    <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
                      When you add a Buy It Now option to auction-style
                      listings, buyers can either purchase your item right away
                      at the Buy It Now price.
                    </span>
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control bg-white"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <span className="d-block error">
                  {errors?.buyNowPrice?.message}
                </span>
              </div>
            </>
          );
        }}
      ></Controller>
    );
  };
  const renderBidIncrementAmountInput = () => {
    return (
      <Controller
        name="bidIncrementAmount"
        control={control}
        defaultValue={props.productDetails?.bidIncrementAmount}
        rules={{
          required: 'Price is required',
          validate: {
            isValid: (value) => !isNaN(value) || 'Invalid Price',
            minValue: (value) => value > 0 || 'Prices should be greater than 0',
          },
        }}
        render={({ onChange, name, value }) => {
          return (
            <>
              <div className="form-group mb-4">
                <label className="mb-1">
                  Bid Increment Amount
                  <span className="tooltip ml-1 position-relative">
                    <span className="tooltip-btn bg-gray-200 rounded-circle border d-inline-block text-center font-weight-bold font-xs cursor-pointer">
                      ?
                    </span>
                    <span className="tooltip-pop font-xs p-2 rounded position-absolute bg-gray-100 border left-0 bottom-0 mb-3">
                      The bid increment is the amount by which a bid will be
                      raised each time the current bid is outdone.
                    </span>
                  </span>
                </label>
                <input
                  type="number"
                  className="form-control bg-white"
                  onChange={onChange}
                  name={name}
                  value={value}
                />
                <span className="d-block error ">
                  {errors?.bidIncrementAmount?.message}
                </span>
              </div>
            </>
          );
        }}
      ></Controller>
    );
  };
  const renderSubmitButton = () => {
    return (
      <div className="col-md-4 p-0">
        <input
          type="submit"
          className="btn py-2 btn-primary font-weight-bold btn-block"
          value="Next"
          disabled={!checked}
        />
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <div className="mb-4">
          <div className="row">
            <div className="col-12 col-md-6">{renderStartDate()}</div>
            <div className="col-12 col-md-6">{renderStartTime()}</div>
            <div className="col-12 col-md-6">{renderEndDate()}</div>
            <div className="col-12 col-md-6">{renderEndTime()}</div>
            <div className="col-12 col-md-6">{renderStartPriceInput()}</div>
            <div className="col-12 col-md-6">{renderBuyNowPriceInput()}</div>
            <div className="col-12 col-md-6">
              {renderBidIncrementAmountInput()}
            </div>
          </div>

          <div className="form-group font-normal">
            <div className="checkbox checkbox__sm">
              <input
                type="checkbox"
                id="checkbox_agree"
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              <label htmlFor="checkbox_agree">
                {' '}
                By Clicking "Post Ad", I agree to Deal Den
                <a href="/terms-of-use">Terms of Use</a> and{' '}
                <a href="/privacy-policy">Privacy Policy</a>
              </label>
            </div>
          </div>
        </div>
        {renderSubmitButton()}
      </form>
    </>
  );
};
export default AuctionForm;
