 
import React from 'react';

import './Upload.scss';

import addImageIcon from './add-image.png';

const getFileType = (type: string) => {
  switch (type) {
    case 'png':
    case 'jpeg':
      return `image/${type}`;
    case 'jpg':
      return `image/jpeg`;
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'pdf':
      return 'application/pdf';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel';
    default:
      return type;
  }
};

interface CustomProps {
  name: string;
  id: string;
  onFileChosen?: (file?: File) => void;
  disabled?: boolean;
  title?: string;
  description?: string;
  accept?: string[];
  className?:string;
  errorMsg?:string;

}

const FileUpload: React.SFC<CustomProps> = (props: CustomProps) => {
  const handleChange = (selectedFiles: FileList) => {
    let file: File | undefined;
    if (selectedFiles && selectedFiles.length > 0) {
      file = selectedFiles[0];
    }
    if (props.onFileChosen) {
      props.onFileChosen(file);
    }
  };
  return (
    <>
      <div className={`${props.className || ''} uploadfile`}>
      <figure>
          <img src={addImageIcon} className="img-fluid" />
          <span className="align-items-center bg-primary cursor-pointer d-none justify-content-center position-absolute upld"><i className="icon-pic-upload"></i></span>
          <span className="normal">{props.title || ''}</span>
           <span className="font-sm text-danger error-msg">{props.errorMsg || ''}</span>
          <input
            type="file"
            id={props.id}
            onChange={(e: any) => handleChange(e.target.files)}
            disabled={props.disabled}
            accept={
              props.accept
                ? props.accept.map((t) => getFileType(t)).join(', ')
                : undefined
            }
          />
          </figure>
        </div>
    </>
  );
};

export default FileUpload;
 