import React from 'react';
import { useSelector } from 'react-redux';
import { Banner as BannerModel, BannerType } from '../../api/models';
import backgroundImage from '../../assets/images/home/best-platform-img.png';
import { State } from '../../store/interfaces';

const HomePageAd: React.FunctionComponent = (props) => {
  const banners = useSelector((state: State) => state.entities.banners);
  const topBanner = banners.find(
    (t: BannerModel) => t.bannerType === BannerType.Bottom
  );

  let image = backgroundImage;
  let title = ` Best Platform for`;
  let subTitle = ` Selling & Buying`;
  let description = `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
  labore dolore magna aliqua.`;
  if (topBanner) {
    image = topBanner.image?.path || backgroundImage;
    title = topBanner.title;
    subTitle = topBanner.subTitle;
    description =
      topBanner.description ||
      `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore dolore magna aliqua.`;
  }
  return (
    <div className="col-12 px-3 w-100 mb-5 d-lg-block d-none">
      <div
        className="best-selling-platform py-5 d-flex"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="pl-5 col-xl-4 col-5 mb-2">
          <h3 className="mb-0 text-gray-900">{title}</h3>
          <h2 className="text-gray-900 font-weight-bold">{subTitle}</h2>
          <p className="mb-4 text-gray-700"> {description} </p>
          <a href="/create" className="btn btn-primary py-2 px-4 mb-5">
            Start Selling
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePageAd;
