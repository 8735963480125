import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import classnames from 'classnames';
import ErrorMessage from '../../../components/text/ErrorMessage';
import api from '../../../api';
import { BillingAddress } from '../../../api/models';

interface FormProps {
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  onSave: (billingAddress: BillingAddress) => void;
  onClose: () => void;
}
const AddressForm: React.FunctionComponent<FormProps> = (props) => {
  const { control, handleSubmit, errors, setValue, watch } = useForm<FormProps>(
    {
      shouldFocusError: true,
    }
  );
  const renderFirstName = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">First Name</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="firstName"
            defaultValue={props.firstName}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.firstName,
                })}
                id="firstName"
                placeholder="First Name"
                name={name}
                value={value}
                // ref={register}
                onChange={onChange}
              />
            )}
          />
          {errors.firstName && (
            <ErrorMessage>
              {errors.firstName.type === 'required'
                ? 'First Name is required'
                : 'Invalid Name'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };
  const renderLastName = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">Last Name</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="lastName"
            defaultValue={props.lastName}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.lastName,
                })}
                id="lastName"
                placeholder="Last Name"
                name={name}
                value={value}
                // ref={register}
                onChange={onChange}
              />
            )}
          />
          {errors.lastName && (
            <ErrorMessage>
              {errors.lastName.type === 'required'
                ? 'Last Name is required'
                : 'Invalid Name'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };
  const renderAddress = () => {
    return (
      <div className="col-md-12">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">Address</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="address"
            defaultValue={props.address}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.address,
                })}
                id="address"
                placeholder="Address"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.address && (
            <ErrorMessage>
              {errors.address.type === 'required'
                ? 'Address is required'
                : 'Invalid Name'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };
  const renderCity = () => {
    return (
      <div className="col-md-12">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">City</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="city"
            defaultValue={props.city}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.city,
                })}
                id="city"
                placeholder="City"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.city && (
            <ErrorMessage>
              {errors.city.type === 'required'
                ? 'City is required'
                : 'Invalid City'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };

  const renderCountry = () => {
    return (
      <div className="col-md-12">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">Country</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="country"
            defaultValue={props.country}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.country,
                })}
                id="country"
                placeholder="Country"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.country && (
            <ErrorMessage>
              {errors.country.type === 'required'
                ? 'Country is required'
                : 'Invalid Country'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };
  const renderState = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">State/Province</label>
          <Controller
            control={control}
            rules={{ required: true }}
            name="state"
            defaultValue={props.state}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.state,
                })}
                id="state"
                placeholder="State"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.state && (
            <ErrorMessage>
              {errors.state.type === 'required'
                ? 'State is required'
                : 'Invalid State'}
            </ErrorMessage>
          )}{' '}
        </div>
      </div>
    );
  };
  const renderZip = () => {
    return (
      <div className="col-md-6">
        <div className="form-group mb-4 pb-1">
          <label className="font-sm mb-1">Zip/Postal Code</label>
          <Controller
            control={control}
            // rules={{ required: true }}
            name="zip"
            defaultValue={props.zip}
            render={({ onChange, value, name }) => (
              <input
                type="text"
                className={classnames({
                  'form-control text-gray-900 font-sm': true,
                  error: !!errors.zip,
                })}
                id="zip"
                placeholder="Zip"
                name={name}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {/* {errors.zip && (
            <ErrorMessage>
              {errors.zip.type === 'required'
                ? 'Zip is required'
                : 'Invalid Zip'}
            </ErrorMessage>
          )}{' '} */}
        </div>
      </div>
    );
  };
  const onSubmit = (data: BillingAddress) => {
    api.user.updateBillingAddress(data).then((response) => {
      props.onSave(data);
    });
  };
  return (
    <div className="new-add p-lg-4 p-2 mx-lg-3">
      <span
        className="icon-close font-sm cursor-pointer float-right"
        onClick={() => props.onClose()}
      ></span>
      <h5 className="text-center mb-4 text-gray-900 pb-2">
        Edit Billing Address
      </h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row gutter-12">
          {renderFirstName()}
          {renderLastName()}
          {renderAddress()}
          {renderCity()}
          {renderState()}
          {renderCountry()}
          {renderZip()}
        </div>
        <div className="row">
          <div className="col-md-4">
            <button
              type="submit"
              className="btn btn-primary btn-block py-2 add-new-btn"
            >
              Save & Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddressForm;
