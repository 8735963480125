import axios from 'axios';
import API_DOMAIN from './apiDomain';

const useUnauthorizedApiResponseInterceptor = (
  onUnauthorizedResponse?: () => void,
  skipOnPaths?: string[]
) => {
  axios.interceptors.response.use(undefined, (err) => {
    if (skipOnPaths) {
      const url = err.response?.config?.url;
      if (url) {
        const urlParts = url?.replace(API_DOMAIN, '');
        if (skipOnPaths.includes(urlParts)) {
          return;
        }
      }
    }
    if (err.response && err.response.status === 401) {
      if (onUnauthorizedResponse) {
        onUnauthorizedResponse();
      }
    }
    throw err;
  });
};

export default useUnauthorizedApiResponseInterceptor;
