import React from 'react';
import { RouteKeys } from '../../containers/routes/route-keys';

const ForgotPasswordLink: React.FunctionComponent<{ className?: string }> = (
  props
) => {
  return (
    <a href={RouteKeys.ForgotPassword} className={props.className}>
      {props.children}
    </a>
  );
};

export default ForgotPasswordLink;
