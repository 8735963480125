import React from 'react';
import { User } from '../../../api/models';

interface CustomProps {
  name?: string;
}
const LetterAvatar: React.FunctionComponent<CustomProps> = ({
  name,
}: CustomProps) => {
  if (!name) return <>NA</>;
  const nameSplit = name?.split(' ') || [];
  const data =
    nameSplit.length > 1
      ? nameSplit[0].charAt(0) + nameSplit[1].charAt(0)
      : name.charAt(0) + name.charAt(1);
  return <>{data.toUpperCase()}</>;
};

export default LetterAvatar;
