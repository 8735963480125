import React from 'react';
import "../../styles/cms.scss";

const HowItWorks: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="cms-banner pt-5 position-relative overflow-hidden">
                <div className="container pt-3 position-relative">
                    <div className="row">
                        <div className="col-md-6 col-12 pt-md-4">
                            <h1 className="text-white mb-4 pb-2">Deal Den is the simplest,
                                most trusted way to buy,
                                sell, auction and get
                                leads locally.
                            </h1>
                            <a href="#"
                                className="btn btn-secondary mb-5 px-4 rounded-sm text-primary font-md font-weight-bold d-inline-flex align-items-center">
                                <span className="px-3 d-inline-flex align-items-center"><img src={require('../../assets/images/cms/youtube.png')}
                                    className="mr-2 pr-1" alt="" />Watch Video</span>
                            </a>
                            <div className="pt-2 mb-2">
                                <a href="" className="btn btn-outline-secondary px-4 rounded-sm mr-3 mb-sm-0 mb-2 btn-opaque">
                                    <img src={require('../../assets/images/cms/playstore.png')} className="img-fluid" alt="" />
                                </a>
                                <a href="" className="btn btn-outline-secondary px-sm-5 px-4 rounded-sm mb-sm-0 mb-2 btn-opaque">
                                    <img src={require('../../assets/images/cms/appstore.png')} className="img-fluid" alt="" />
                                </a>
                            </div>
                            <p className="text-white font-normal mb-5">Make money selling online when you download our free app.</p>
                        </div>
                        <div className="col-md-6 col-12 banner-right-block d-flex align-items-center justify-content-center">
                            <img src={require('../../assets/images/cms/howitworks-banner-img.png')} className="img-fluid position-relative" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="howitworks-block mt-5 pb-5">
                <h2 className="mt-5 text-center text-dark mb-md-0 mb-4">How it works</h2>
                <div className="bg-white">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <div
                                    className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                    01
                                </div>
                                <h2 className="h1 font-weight-bold mb-4 text-dark">Browse deals on tons of great items nearby</h2>
                                <p className="h5 text-body mb-md-0 mb-5">like furniture, electronics, and cars and buy locally.</p>
                            </div>
                            <div className="col-md-6 col-12 text-md-right text-center">
                                <img src={require('../../assets/images/cms/howitworks-1.png')} className="img-fluid" alt="howitworks-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light-blue pt-5">
                    <div className="container">
                        <div className="row align-items-center flex-md-row-reverse">
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <div
                                    className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                    02
                                </div>
                                <h2 className="h1 font-weight-bold mb-4 text-dark">Buyer & Sellers Reliable Messaging</h2>
                                <p className="h5 text-body mb-md-0 mb-5">securely through the website and app to negotiate price and
                                    set up a time to meet.
                                </p>
                            </div>
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <img src={require('../../assets/images/cms/howitworks-2.png')} className="img-fluid" alt="howitworks-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white pt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <div
                                    className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                    03
                                </div>
                                <h2 className="h1 font-weight-bold mb-4 text-dark">Check out individual & businesses profiles</h2>
                                <p className="h5 text-body mb-md-0 mb-5">to see ratings, badges, and items listed for sale</p>
                            </div>
                            <div className="col-md-6 col-12 text-md-right text-center">
                                <img src={require('../../assets/images/cms/howitworks-3.png')} className="img-fluid" alt="howitworks-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-light-blue pt-5">
                    <div className="container">
                        <div className="row align-items-center flex-md-row-reverse">
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <div
                                    className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                    04
                                </div>
                                <h2 className="h1 font-weight-bold mb-4 text-dark">Sell something of your own</h2>
                                <p className="h5 text-body mb-md-0 mb-5">by simply taking a picture with your phone. Posting takes
                                    less than 30 seconds!
                                </p>
                            </div>
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <img src={require('../../assets/images/cms/howitworks-4.png')} className="img-fluid" alt="howitworks-1" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white pt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-12 text-md-left text-center">
                                <div
                                    className="circle-count d-inline-flex align-items-center justify-content-center rounded-circle h3 mb-4">
                                    05
                                </div>
                                <h2 className="h1 font-weight-bold mb-4 text-dark">Join the local community</h2>
                                <p className="h5 text-body mb-md-0 mb-5">on the largest Caribbean social classified marketplace
                                    for local buyers and sellers.
                                </p>
                            </div>
                            <div className="col-md-6 col-12 text-md-right text-center">
                                <img src={require('../../assets/images/cms/howitworks-5.png')} className="img-fluid" alt="howitworks-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
