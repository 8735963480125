import React from 'react';
import { RouteKeys } from '../../containers/routes/route-keys';

const HelpLink: React.FunctionComponent<{ className?: string }> = (props) => {
  return (
    <a href={RouteKeys.Help} className={props.className}>
      {props.children}
    </a>
  );
};

export default HelpLink;
