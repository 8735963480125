import React from 'react';
import { Category } from '../../../api/models';
import Checkbox from '../../../components/form-group/checkbox';
import useEntities from '../../../hooks/useEntities';

const InterestedCategories: React.FunctionComponent<{
  onSelect: (category: Category) => void;
  selectedCategories: string[];
}> = (props) => {
  const entities = useEntities();
  const selectCategory = (category: Category) => {
    const selectedCategory = props.selectedCategories.filter(
      (e) => e === category._id
    );
    if (selectedCategory.length > 0) {
      return true;
    }
  };
  return (
    <div className="col-md-12">
      <div className="mx-auto pt-3 pt-lg-2 mb-4 pb-2">
        <div className="top-head-common">
          <h5 className="text-dark mb-lg-3 mb-4">Choose your Interest</h5>
        </div>

        <div className="choose-cat">
          <div className="row gutter-5 d-flex">
            {entities.categories.map((item, i) => {
              return (
                <div className="col-auto mb-2 cat-list">
                  <div className="list-category bg-white">
                    {/* <div className="figure text-center">
                      <img src={item.image?.path} className="img-fluid" />
                    </div> */}
                    <Checkbox
                      gap="sm"
                      id={`checkbox_${i}`}
                      label={item.title}
                      labelClassName="font-medium"
                      onChange={() => {
                        props.onSelect(item);
                      }}
                      selected={selectCategory(item)}
                    />

                    {/* <p className="font-xs text-gray-700 mb-0 mt-auto">
                      {item.title}
                    </p> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

    </div>
  );
};
export default InterestedCategories;
