import React from 'react';
import AppLayout from '../layout/app';
import MyOrderDetailScreen from './components/Order-Detail';

const OrderDetails: React.FunctionComponent = (props) => {
  return (
    <AppLayout hideCategories>
      <MyOrderDetailScreen />
    </AppLayout>
  );
};

export default OrderDetails;
