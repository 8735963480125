import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AvatarIcon from '../../../components/icons/avatar-icon';
import validator from 'validator';
import PhoneInput from '../../../components/phone-input';
import ErrorMessage from '../../../components/text/ErrorMessage';
import AppLayout from '../../layout/app';
import classnames from 'classnames';
import api from '../../../api';
import { RouteKeys } from '../../routes/route-keys';
import { toastr } from 'react-redux-toastr';
import { StatusCodes } from 'http-status-codes';
import { Link, useHistory } from 'react-router-dom';
interface FormProps {
  password: string;
  oldPassword: string;
  confirmPassword: string;
}
const renderBreadCrumbs = () => {
  return (
    <nav aria-label="breadcrumb" className="d-none d-lg-block">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/profile">My Account</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          <a href="/profile">Profile</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          <a href="/edit-profile">Edit Profile</a>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Change Password
        </li>
      </ol>
    </nav>
  );
};
const ChangePasswordForm: React.FunctionComponent<{
  error?: string;
  isSubmitting?: boolean;
}> = (props) => {
  const { control, handleSubmit, errors, watch } = useForm<FormProps>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });
  const history = useHistory();

  const renderOldPassword = () => {
    return (
      <div className="form-group">
        <label className="font-sm mb-1 sr-only">Old Password</label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="oldPassword"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.oldPassword,
                })}
                id="register-password"
                placeholder="Old Password"
                name={name}
                onChange={onChange}
              />
            );
          }}
        ></Controller>

        {errors.oldPassword && (
          <ErrorMessage>
            {errors.oldPassword.type === 'required'
              ? 'Old Password is required'
              : 'Invalid password'}
          </ErrorMessage>
        )}
      </div>
    );
  };

  const renderNewPassword = () => {
    return (
      <div className="form-group">
        <label className="font-sm mb-1  sr-only">New Password</label>
        <Controller
          control={control}
          rules={{ required: true }}
          name="password"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control': true,
                  error: !!errors.password,
                })}
                id="register-password"
                placeholder="New Password"
                name={name}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.password && (
          <ErrorMessage>
            {errors.password.type === 'required'
              ? 'Password is required'
              : 'Invalid password'}
          </ErrorMessage>
        )}
      </div>
    );
  };
  const renderConfirmPassword = () => {
    return (
      <div className="form-group">
        <label className="font-sm mb-1 sr-only">Confirm Password</label>
        <Controller
          control={control}
          rules={{
            required: true,
            validate: { isMatching: (value) => value === watch('password') },
          }}
          name="confirmPassword"
          render={({ onChange, name, value }) => {
            return (
              <input
                type="password"
                className={classnames({
                  'form-control bg-white': true,
                  error: !!errors.confirmPassword,
                })}
                id="register-confirm-password"
                placeholder="Confirm Password"
                name={name}
                value={value}
                onChange={onChange}
              />
            );
          }}
        ></Controller>
        {errors.confirmPassword && (
          <>
            {errors.confirmPassword.type === 'required' && (
              <ErrorMessage>Confirm Password is required</ErrorMessage>
            )}
            {errors.confirmPassword.type === 'isMatching' && (
              <ErrorMessage>Passwords should match</ErrorMessage>
            )}
          </>
        )}
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="top-head-block mt-lg-5">
        <AvatarIcon className="d-block mb-4 mb-lg-5" />
        <h3 className="text-dark mb-3 h3">Enter Password</h3>
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <div className="row gutter-5 form-group mt-4 pt-3">
        <div className="col-5 col-md-4">
          <a href="#">
            <button
              type="submit"
              className="btn btn-primary btn-block font-weight-bold "
            >
              Submit
            </button>
          </a>
        </div>
        <div className="col-3  col-sm-2  d-flex align-items-center justify-content-center">
          <a href="/edit-profile" className="text-center font-weight-bold ">
            Cancel
          </a>
        </div>
      </div>
    );
  };

  const onSubmit = async (data: FormProps) => {
    try {
      const response = await api.auth.changePassword(
        data.password,
        data.oldPassword
      );
      if (response.status === StatusCodes.OK) {
        toastr.success('Success', 'Password changed successfully');
        history.push(RouteKeys.EditProfile);
      }
    } catch (error) {
      toastr.error('Error', error.response.data.message);
    }
  };

  return (
    <AppLayout hideCategories hideFooter>
      <div className="header-mob bg-primary text-white px-3 d-lg-none d-flex align-items-center w-100">
        <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
          <Link className="text-white" to="/edit-profile">
            <i className="icon-prev font-lg mr-3"></i>Change Password
          </Link>
        </h6>
      </div>
      <div className="container pt-md-4 pb-md-5 rmv-padd-sm">
        {renderBreadCrumbs()}
        <div className="px-lg-5 mx-auto py-5 py-lg-2 update-phone px-3 px-lg-0 bg-white">
          {renderHeader()}
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderOldPassword()}
            {renderNewPassword()}
            {renderConfirmPassword()}
            {renderSubmitButton()}
            {props.error && (
              <span className="error text-danger ">{props.error}</span>
            )}
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default ChangePasswordForm;
