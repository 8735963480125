export const combineDateAndTime = (date: Date, time: string) => {
  let hours = Number(time?.match(/^(\d+)/)![1]);
  const minutes = Number(time.match(/:(\d+)/)![1]);
  const AMPM = time.match(/\s(.*)$/)![1];
  if (AMPM.toUpperCase() === 'PM' && hours < 12) hours = hours + 12;
  if (AMPM.toUpperCase() === 'AM' && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  time = sHours + ':' + sMinutes + ':00';
  const d = new Date(date);
  const n = d.toISOString().substring(0, 10);
  const newDate = new Date(n + 'T' + time);
  return newDate;
};

export const combineDateAndTimeMoment = (date: string, time: string) => {
  let hours = Number(time?.match(/^(\d+)/)![1]);
  const minutes = Number(time.match(/:(\d+)/)![1]);
  const AMPM = time.match(/\s(.*)$/)![1];
  if (AMPM.toUpperCase() === 'PM' && hours < 12) hours = hours + 12;
  if (AMPM.toUpperCase() === 'AM' && hours === 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = '0' + sHours;
  if (minutes < 10) sMinutes = '0' + sMinutes;
  time = sHours + ':' + sMinutes + ':00';
  const newDate = new Date(date + 'T' + time);
  return newDate;
};
