/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firestore from '../../../components/firebase/firebase';
import userImage from '../../../assets/images/user.png';
import SellIcon from '../../../components/icons/sell-icon';
import useAuth from '../../../hooks/useAuth';

import { RouteKeys } from '../../routes/route-keys';
import { usePerformAction } from 'react-remote-data-hooks';
import api from '../../../api';
import { useDispatch } from 'react-redux';
import defaultAvatar from '../../../components/avatar/default-avatar.png';

const UserOptions: React.FunctionComponent = (props) => {
  const auth = useAuth();
  const history = useHistory();

  const handleChatClick = () => {
    history.push(RouteKeys.Chats);
  };
  let hasUnreadContent = false;

  const userId = auth.profile?._id?.toString();

  const notificationRef = firestore.collection('Notifications');

  const query = userId
    ? notificationRef.where('userId', '==', userId).limit(25)
    : notificationRef.limit(1);
  const [notifications] = useCollectionData(query, { idField: 'id' });
  if (notifications && notifications.length) {
    const hasData = notifications.filter((t: any) => !t.isRead);

    if (hasData.length) {
      hasUnreadContent = true;
    }
  }

  const NotificationClass = hasUnreadContent
    ? 'icon-notification-filled notify d-lg-none'
    : 'icon-notification-filled  d-lg-none';
  const NotificationClass1 = hasUnreadContent
    ? 'icon-notification notify d-lg-inline-block d-none'
    : 'icon-notification  d-lg-inline-block d-none';

  const handleNotificationClick = () => {
    api.catalog.markAsRead().then((data) => {
      history.push(RouteKeys.Notifications);
    });
  };
  const renderUser = () => {
    return (
      <div className="mr-4 text-white user-dropdown d-lg-inline-flex d-none align-items-center position-relative p-1 rounded-pill cursor-pointer">
        <div className="current-user d-flex align-items-center">
          <img
            src={auth.profile?.avatar?.path || defaultAvatar}
            className="img-fluid img-cover rounded-circle"
            alt=""
          />
          <i className="icon-arrow-down font-xxxs mx-1"></i>
        </div>
        <div className="user-dropdown-menu position-absolute bg-white shadow-lg rounded font-normal font-weight-normal py-2">
          <h6 className="border-bottom font-normal mb-1 pb-2 px-3 text-capitalize">
            <small className="text-muted font-weight-normal">Welcome</small>
            <br />
            <a href={RouteKeys.EditProfile}>{auth.profile?.name}</a>
          </h6>
          <a href={RouteKeys.Profile} className="d-block py-2 px-3">
            My Profile
          </a>
          <a href="/my-ads" className="d-block py-2 px-3">
            My Ads
          </a>
          <a href="/my-orders" className="d-block py-2 px-3">
            My Orders
          </a>
          <a
            href=""
            onClick={auth.logout}
            className="d-inline-block w-100 py-2 px-3"
          >
            Logout
          </a>
        </div>
      </div>
    );
  };

  const renderChatIcon = () => {
    return (
      <a
        href="javascript:void(0)"
        className="mr-4 text-white d-lg-inline-flex d-none"
        onClick={() => handleChatClick()}
      >
        <i className="icon-chat"></i>
      </a>
    );
  };

  const renderNotificationIcon = () => {
    return (
      <Link
        className="mr-lg-4 text-white d-inline-flex"
        to=""
        onClick={() => handleNotificationClick()}
      >
        {/* <a href="" className="mr-lg-4 text-white d-inline-flex"> */}
        <i className={NotificationClass1}></i>
        <i className={NotificationClass}></i>
        {/* </a> */}
      </Link>
    );
  };

  const renderNonLoggedInOptions = () => {
    return (
      <div className="d-flex align-items-center text-white font-normal mr-lg-4">
        <a href={RouteKeys.Login} className="text-white d-none d-lg-block">
          Login
        </a>
        <span className="mx-1 d-none d-lg-inline-block">/</span>
        <a href={RouteKeys.Signup} className="text-white d-none d-lg-block">
          Register
        </a>
        {renderScanQrCode()}
        {responsiveSearchFilter()}
      </div>
    );
  };
  const responsiveSearchFilter = () => {
    return (
      <a
        className="icon-search font-xl ml-3 text-white d-lg-none d-block"
        onClick={() => {
          document.body.classList.toggle('open-search');
        }}
      ></a>
    );
  };
  const renderScanQrCode = () => {
    return (
      <a className="ml-3 text-white d-lg-none d-block " href={RouteKeys.ScanQR}>
        <img
          src={require('../../../assets/images/qr-scan.svg')}
          width="32"
          height="32"
          alt="qrcode"
          className="mb-1 mb-lg-0"
        />
      </a>
    );
  };

  const renderSellLink = () => {
    return (
      <a
        href={RouteKeys.Create}
        className="bg-white text-dark font-weight-bold text-uppercase px-3 py-2 rounded d-lg-inline-flex align-items-center d-none sell-btn"
      >
        <SellIcon className="h4 mb-0 mr-2" />
        Sell
      </a>
    );
  };
  return (
    <div className="col-md-auto col-6 d-flex align-items-center justify-content-end ml-auto">
      {!auth.isAuthenticated && renderNonLoggedInOptions()}
      {auth.isAuthenticated && (
        <div className="d-flex h4 mb-0 align-items-center">
          {renderNotificationIcon()}
          {renderChatIcon()}
          {renderScanQrCode()}
          {responsiveSearchFilter()}
          {renderUser()}
        </div>
      )}
      {renderSellLink()}
    </div>
  );
};

export default UserOptions;
