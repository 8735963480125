import React from 'react';
import "../../styles/cms.scss";

const PostingRules: React.FunctionComponent = (props) => {
    return (
        <div className="bg-white">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-12 font-md text-gray-700">
                        <h2 className="text-center font-weight-bold mb-4 pb-3">Posting rules</h2>
                        <p className="mb-4 font-md text-gray-700">
                            Please follow these rules when listing things to make the buying and selling experience more
                            pleasurable for everyone on Deal Den. If the following  standards are violated, your content may be
                            removed. Your accounts may be suspended or cancelled if violations are severe or recurring.
                            Learn more about our <a href="#">Terms of service</a>.
                        </p>

                        <h4 className="font-weight-bold mb-2">Accounts</h4>
                        <p className="mb-3 font-md text-gray-700">DealDen is a way to connect with your local community and beyond.
                            Use your true identity and
                            honestly represent yourself. Please:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Use one account per user.</li>
                            <li>Don’t use a throw-away email address. This signals to us that you might not be here for the
                                right reasons.</li>
                            <li>Don’t create accounts designed to impersonate someone else.</li>
                            <li>Don’t use emulators or third-party sites to post on DealDen. You must be the owner of your item.
                            </li>
                            <li>Represent yourself in a honest way when creating your account and custom profile link.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Images</h4>
                        <p className="mb-3 font-md text-gray-700">To allow buyers to get a true feel for the items you’re selling,
                            please:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Always include an image of the item. Posts with no image, including those with a photo
                                containing only text, aren't allowed.</li>
                            <li>Include high-quality, well-lit photos.</li>
                            <li>Keep the photo clean by not adding extra stickers or text.</li>
                            <li>Post an image of the actual item, not a catalog or stock photo.</li>
                            <li>Use your own photos. Don't use photos that infringe on the intellectual property rights of
                                others.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Duplicate posts</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Multiple posts showing identical items from the same seller
                            can be confusing to buyers, and difficult to manage for sellers. Even if you have more than one of
                            the same item, please list one at a time. Don't post the same item in more than one listing or in
                            multiple categories.
                        </p>

                        <h4 className="font-weight-bold mb-2">Posting location</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Please list an accurate geographic location so your local
                            buyers can arrange to meet you for pickup. If you want to sell something outside your area, list it
                            for selling and shipping nationwide. <a href="#">More about shipping</a>
                        </p>

                        <h4 className="font-weight-bold mb-2">Titles, descriptions, & prices</h4>
                        <p className="mb-3 font-md text-gray-700">Titles and descriptions should give buyers a clear, honest, and
                            accurate description so they can tell if the item is what they're looking for. Some things to avoid:
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>Don't add keywords or price points intended to manipulate search results.</li>
                            <li>Do not set prices that are clearly inflated above standard market value.</li>
                            <li>Don't link to websites or post URLs.</li>
                            <li>Don't provide personal information, such as a phone number or email address.</li>
                            <li>Don't solicit risky payment methods such as Western Union or Moneygram.</li>
                        </ul>

                        <h4 className="font-weight-bold mb-2">Messaging & communication</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">DealDen messaging lets you communicate with others without
                            sharing personal information or leaving the app. Remember, the use of in-app messaging to circumvent
                            prohibited items or posting rules isn't allowed. <a href="#">Tips for messaging</a>
                        </p>

                        <h4 className="font-weight-bold mb-2">Prohibited items</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Most items are allowed on DealDen, but there are a few types
                            of items that aren’t permitted. Some may present legal issues or be dangerous, while others may be
                            offensive or not in line with the spirit of our community. Read the <a href="#">prohibited items</a>
                            guidelines for
                            details.
                        </p>

                        <h4 className="font-weight-bold mb-2">Fee avoidance</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Sometimes fees may be incurred when posting certain kinds of
                            items or making cashless transactions. Any action by a seller to avoid paying a fee is strictly
                            prohibited. It's important to remember that if you don't pay these fees, whether intentionally or by
                            mistake, your listings may be removed.
                        </p>

                        <h4 className="font-weight-bold mb-2">Non-compliance</h4>
                        <p className="mb-4 font-md text-gray-700 pb-4">Posts that violate any of our guidelines, policies, or terms
                            may be removed at our discretion. Additional actions may be taken for severe or repeat offenses.
                        </p>

                        <h4 className="font-weight-bold mb-2">What we're doing</h4>
                        <p className="mb-3 font-md text-gray-700">Our teams work around the clock to ensure the safety of our
                            marketplace and enforcement of our policies. We review posted content to ensure that transactions
                            for buyers and sellers are ethical, safe and protected.
                        </p>
                        <ul className="pl-3 mb-4 font-md text-gray-700 pb-4">
                            <li>We remove posts and take action on accounts that violate our policies.</li>
                            <li>We work with law enforcement to do our part in supporting their investigations.</li>
                            <li>We’re continuously updating our product features to create a more trustworthy marketplace
                                experience.</li>
                            <li>We understand that our work is never done.</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostingRules;
