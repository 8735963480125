import React from 'react';
import { Filters, FilterType } from '../../api/models';
import MinMaxSliderFilter from './MinMaxSlider';
import MultiSelectCheckboxFilters from './MultiSelectCheckboxFilters';

const Filter: React.FunctionComponent<{
  filter: Filters;
  value?: any;
  onChange?: (val: any) => void;
}> = (props) => {
  if (props.filter.type === FilterType.MultiSelect) {
    return (
      <MultiSelectCheckboxFilters
        className="rounded shadow bg-white p-lg-3 mb-3"
        meta={props.filter.meta}
        selected={props.value || []}
        onChange={props.onChange}
      />
    );
  } else if (props.filter.type === FilterType.MinMaxSlider) {
    return (
      <MinMaxSliderFilter
        className="mb-4"
        meta={{
          name: props.filter.meta.name,
          key: props.filter.meta.key,
          min: 0,
          max: props.filter.meta.max,
          valueSuffix: '$',
        }}
        value={props.value}
        onChange={props.onChange}
      />
    );
  }
  return <></>;
};

export default Filter;
