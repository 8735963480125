import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { PageType } from '../../profile';
import MyAccount from '../../common/my-account';
import api from '../../../api';
import {
  AccountType,
  PaginatedApiResponse,
  Product,
  RequestCustomPlan,
  Subscribe,
  SubscriptionPlan,
  SubscriptionStatus,
} from '../../../api/models';
import { useDataLoader } from 'react-remote-data-hooks';
import { ContainerLoader } from '../../../components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../store/interfaces';
import {
  getBoostBackLink,
  getTopFeaturedPlansLink,
} from '../../../utils/link-utils';
import {
  selectProductToSellFasterAction,
  selectProductToBoostBackAction,
} from '../../../store/reducers/catalog';
import MyProduct from './my-product';
import { CatalogFilter, ProductType } from '../../../api/models/catalog';
import MyFilters from './leads-filter';
import AdsPagination, {
  AdsPaginationProps,
} from '../../../components/ads-pagination/pagination';
import Empty from '../../../components/empty';
import { toastr } from 'react-redux-toastr';
import { scrollTop } from '../../../utils/window-utils';
import {
  MyLeadsType,
  PageQuery,
  OrderStatuses,
} from '../../../api/models/leads';
import ProductFilter from '../../../components/product-filter/ProductFilter';
import { ProductFilterModel } from '../../../components/product-filter/ProductFilterModel';
import classNames from 'classnames';

import ChoosePlan from '../../choose-plan/components/ChoosePlan';
import { usePerformAction } from 'react-remote-data-hooks';
import Payment, { PaymentType } from '../../payment/components/payment';
import ThankYou from '../../create/components/ThankYou';

interface MyLeadsFilter {
  [MyLeadsType.Leads]: CatalogFilter & PageQuery;
  [MyLeadsType.Favorite]: CatalogFilter & PageQuery;
  [MyLeadsType.Enquired]: CatalogFilter & PageQuery;
}

const initialFilter = {
  [MyLeadsType.Leads]: {
    status: '',
    query: '',
    page: 1,
    perPage: 10,
  },
  [MyLeadsType.Favorite]: {
    status: '',
    query: '',
    page: 1,
    perPage: 10,
  },
  [MyLeadsType.Enquired]: {
    status: '',
    query: '',
    page: 1,
    perPage: 10,
  },
};

const MyLeadsScreen: React.FunctionComponent = () => {
  const [adsType, setAdsType] = useState<MyLeadsType>(MyLeadsType.Leads);
  const authState = useSelector((state: State) => state.authUser);
  const [filter, setFilter] = useState<MyLeadsFilter>(initialFilter);
  const [adProducts, setAdProducts] = useState<Product[]>([]);
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const {
    data: productsResponse,
    loading,
    loaded,
    reload: reloadMyAds,
  } = useDataLoader<PaginatedApiResponse<Product>>(() =>
    api.catalog.getMyAds({
      pagination: {
        page: initialFilter[MyLeadsType.Leads].page,
        perPage: initialFilter[MyLeadsType.Leads].perPage,
      },
      sort: {},
      queryParams: {
        ...filter[MyLeadsType.Leads],
        productType: ProductType.Lead,
      },
    })
  );

  const [isRedirectPayment, setIsRedirectPayment] = useState<boolean>(false);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState<
    SubscriptionPlan
  >();
  const [isChoosePlan, setIsChoosePlan] = useState<boolean>(false);
  const [isThankYou, setIsThankYou] = useState<boolean>(false);
  const [thankYouHeading, setThankYouHeading] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');

  const { performAction: payment } = usePerformAction(
    (data: SubscriptionPlan): any => {
      setSubscriptionPlanSelected(data);
      setIsRedirectPayment(true);
    }
  );
  const [choosePlanProduct, setChoosePlanProduct] = useState<
    Product | undefined
  >();

  const { performAction: subscribe } = usePerformAction(
    (paymentId: any, stripePaymentToken?: any): any => {
      const subscribeData: Subscribe = {
        subscriptionPlan: subscriptionPlanSelected?._id || '',
        paymentId,
        paymentType: PaymentType.Subscription,
        categoryId: choosePlanProduct?.category?._id,
        stripePaymentToken,
        productId: choosePlanProduct?._id,
        isFirstTimeSubcription: true,
      };
      api.subscription.subscribe(subscribeData).then((data) => {
        if (data.status === SubscriptionStatus.PaymetSuccess) {
          setIsChoosePlan(false);
          setIsRedirectPayment(false);
          if (choosePlanProduct) activateProduct(choosePlanProduct?._id);
        }
      });
    }
  );

  const { performAction: requestCustomPlan } = usePerformAction((data): any => {
    setSubscriptionPlanSelected(data);
    // createProduct(productDetails);
    const subscribeData: RequestCustomPlan = {
      subscriptionPlan: data?._id || '',
    };
    api.subscription.requestCustomPlan(subscribeData).then((responseData) => {
      if (responseData.status === SubscriptionStatus.CustomRequestPending) {
        setThankYouHeading('Thank You');
        setThankYouMessage('We will contact you soon to discuss a custom plan');
        setIsThankYou(true);
      }
    });
  });

  const { performAction: checkSubscription } = usePerformAction(
    (productToActivate): any => {
      api.subscription
        .getRemainingPostsCountInCategory({
          categoryId: productToActivate?.category?._id,
          productType: productToActivate?.productType,
        })
        .then((response) => {
          // setIsLoaded(true);
          if (response.noOfPostsRemaining > 0) {
            if (productToActivate) {
              const subscriptionResponse = api.subscription.subscribeAndActivateProduct(
                {
                  subscriptionId: response.subscriptionId,
                  productId: productToActivate?._id,
                }
              );
              activateProduct(productToActivate?._id);
            }
          } else {
            setIsChoosePlan(true);
          }
        });

      // if (
      //   !authState.profile?.subscriptionDetails ||
      //   !authState.profile?.subscriptionDetails?.noOfPostsRemaining
      // ) {
      //   setIsChoosePlan(true);
      // } else {
      //   createProduct(product);
      // }
    }
  );

  const choosePlanWhenNoSubscription = (product: Product) => {
    setChoosePlanProduct(product);
    checkSubscription(product);
  };

  const {
    data: enquiredResponse,
    loading: enquiredLoading,
    loaded: enquiredLoaded,
    reload: reloadEnquiredLeads,
  } = useDataLoader<PaginatedApiResponse<Product>>(() =>
    api.catalog.getMyEnquiredLeads({
      pagination: {
        page: initialFilter[MyLeadsType.Enquired].page,
        perPage: initialFilter[MyLeadsType.Enquired].perPage,
      },
      sort: {},
      queryParams: {
        ...filter[MyLeadsType.Enquired],
        productType: ProductType.Lead,
      },
    })
  );

  const {
    data: favoritesResponse,
    loaded: favoriteLoaded,
    reload: reloadFavoriteAds,
  } = useDataLoader<PaginatedApiResponse<Product>>(() =>
    api.catalog.getFavoriteAds({
      pagination: {
        page: initialFilter[MyLeadsType.Favorite].page,
        perPage: initialFilter[MyLeadsType.Favorite].perPage,
      },
      sort: {},
      queryParams: {
        ...filter[MyLeadsType.Favorite],
        productType: ProductType.Lead,
      },
    })
  );

  const [adLength, setAdLength] = useState(0);
  const [favLength, setFavLength] = useState(0);
  const [enquiredLength, setEnquiredFavLength] = useState(0);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const updateFilter = (key: string, val: unknown) =>
    setFilter({ ...filter, [adsType]: { ...filter[adsType], [key]: val } });

  const updateFilterByType = (tmpFilter: any) =>
    setFilter({
      ...filter,
      [adsType]: {
        ...filter[adsType],
        ...tmpFilter,
      },
    });

  const resetFilter = () =>
    setFilter({ ...filter, [adsType]: { ...initialFilter[adsType] } });

  useEffect(() => {
    setTimeout(() => setIsFirstLoad(false), 500);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) reloadMyAds();
  }, [filter[MyLeadsType.Leads]]);

  useEffect(() => {
    if (!isFirstLoad) reloadFavoriteAds();
  }, [filter[MyLeadsType.Favorite]]);

  useEffect(() => {
    if (!isFirstLoad) reloadEnquiredLeads();
  }, [filter[MyLeadsType.Enquired]]);

  useEffect(() => {
    setAdLength(productsResponse?.count);
  }, [productsResponse?.count]);

  useEffect(() => {
    setAdProducts(productsResponse?.data);
  }, [productsResponse?.data]);

  useEffect(() => {
    setFavLength(favoritesResponse?.count);
  }, [favoritesResponse?.count]);

  useEffect(() => {
    setEnquiredFavLength(enquiredResponse?.count);
  }, [enquiredResponse?.count]);

  const updateProductFromResponse = (id: string, product: Product) => {
    setAdProducts([
      ...adProducts.map((p: Product) => {
        if (p._id === id) return product;
        return p;
      }),
    ]);
  };

  const deActivateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(
      productId,
      'inActive'
    );
    updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully de-activated');
  };

  const activateProduct = async (productId: string) => {
    const { data } = await api.catalog.updateProductStatus(productId, 'active');
    updateProductFromResponse(productId, data);
    toastr.success('Success', 'Product successfully activated');
  };

  const renderPagination = (props: AdsPaginationProps) => {
    return <AdsPagination {...props} />;
  };

  const renderProducts = () => {
    const newDate = new Date();
    return (
      <div className="ad-products">
        {adProducts?.map((product: Product) => {
          return (
            <MyProduct
              type={MyLeadsType.Leads}
              product={product}
              key={`my-product-${product._id}`}
              boostAction={() => {
                dispatch(selectProductToBoostBackAction(product));
                history.push(getBoostBackLink(product._id));
              }}
              newDate={newDate}
              sellFasterAction={() => {
                dispatch(selectProductToSellFasterAction(product));
                history.push(getTopFeaturedPlansLink(product._id));
              }}
              deActivateProduct={deActivateProduct}
              activateProduct={activateProduct}
              choosePlanWhenNoSubscription={choosePlanWhenNoSubscription}
            />
          );
        })}
        {productsResponse.count === 0 ? <Empty /> : ''}
        {productsResponse.count > 10 &&
          renderPagination({
            count: productsResponse.count,
            page: productsResponse.page,
            perPage: productsResponse.perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
              scrollTop();
            },
          })}
      </div>
    );
  };

  const renderFavoriteProducts = () => {
    const newDate = new Date();
    const { data: favorites, count, page, perPage } = favoritesResponse;

    return (
      <>
        {favorites.map((product: Product) => {
          return (
            <MyProduct
              type={MyLeadsType.Favorite}
              newDate={newDate}
              product={product}
              key={`my-fav-${product._id}`}
            />
          );
        })}

        {favorites?.length === 0 || count === 0 ? <Empty /> : ''}
        {count > 10 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
            },
          })}
      </>
    );
  };

  const renderEnquiredProducts = () => {
    const newDate = new Date();
    if (!enquiredResponse) return <Empty />;
    const { data: enquiries, count, page, perPage } = enquiredResponse;

    return (
      <>
        {enquiries.map((product: Product) => {
          return (
            <MyProduct
              type={MyLeadsType.Favorite}
              newDate={newDate}
              product={product}
              key={`my-fav-${product._id}`}
            />
          );
        })}

        {enquiries?.length === 0 || count === 0 ? <Empty /> : ''}
        {count > 10 &&
          renderPagination({
            count,
            page,
            perPage,
            onPageChange: (nextPage: number) => {
              updateFilter('page', nextPage);
            },
          })}
      </>
    );
  };

  const renderBreadCrumbs = () => {
    return (
      <nav aria-label="breadcrumb" className="d-none d-lg-block mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/profile">My Account</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            My Leads
          </li>
        </ol>
      </nav>
    );
  };

  const filterRef = useRef<any>(null);

  const renderContainerLoader = () => (
    <ContainerLoader height={500}></ContainerLoader>
  );

  return (
    <>
      {!isChoosePlan && (
        <div className="my-ads py-0 py-lg-4">
          <div className="container">
            {renderBreadCrumbs()}
            <div className="row">
              <MyAccount pageType={PageType.MyLeads} />
              {/* {loaded && favoriteLoaded && ( */}
              <>
                <div className="align-items-center bg-primary d-flex d-lg-none header-mob justify-content-between position-sticky px-3 text-white top-0 w-100">
                  <h6 className="mb-0 font-weight-bold col p-0 text-truncate text-white">
                    <Link
                      to="account"
                      className="icon-prev font-lg mr-3 text-white"
                    ></Link>
                    My Leads
                  </h6>
                  <i
                    className="icon-filter text-white font-xl"
                    onClick={() => {
                      setIsFilterActive(!isFilterActive);
                      filterRef?.current?.toggleExtraFilter();
                      // document.body.classList.toggle('overflow-hidden');
                    }}
                  ></i>
                </div>
                <div className="col-lg-9 rmv-padd-mob">
                  <div className="right-panel">
                    {adsType === MyLeadsType.Leads ? (
                      <ProductFilter
                        title="My Leads"
                        ref={filterRef}
                        defaultValues={initialFilter[MyLeadsType.Leads]}
                        values={filter[MyLeadsType.Leads]}
                        searchPlaceHolder="My Leads"
                        onFilterChange={(e: ProductFilterModel) => {
                          updateFilterByType(e);
                        }}
                        onFilterReset={resetFilter}
                        key="my-leads-filter"
                      />
                    ) : adsType === MyLeadsType.Favorite ? (
                      <ProductFilter
                        title="My Leads"
                        ref={filterRef}
                        defaultValues={initialFilter[MyLeadsType.Favorite]}
                        values={filter[MyLeadsType.Favorite]}
                        searchPlaceHolder="Favorites"
                        onFilterChange={(e: ProductFilterModel) => {
                          updateFilterByType(e);
                        }}
                        onFilterReset={resetFilter}
                        key="my-fav-filter"
                      />
                    ) : adsType === MyLeadsType.Enquired ? (
                      <ProductFilter
                        title="My Leads"
                        ref={filterRef}
                        defaultValues={initialFilter[MyLeadsType.Enquired]}
                        values={filter[MyLeadsType.Enquired]}
                        searchPlaceHolder="Inquired"
                        onFilterChange={(e: ProductFilterModel) => {
                          updateFilterByType(e);
                        }}
                        onFilterReset={resetFilter}
                        key="my-enq-filter"
                      />
                    ) : (
                      ''
                    )}
                    <nav className="tabs-header myaccount">
                      <div
                        className="nav nav-tabs d-flex"
                        id="nav-tab"
                        role="tablist"
                      >
                        <a
                          className={classNames(
                            'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                            { active: adsType === MyLeadsType.Leads }
                          )}
                          id="nav-home-tab"
                          data-toggle="tab"
                          onClick={() => setAdsType(MyLeadsType.Leads)}
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          Leads{' '}
                          {loaded && adLength > 0 && (
                            <span className="count">({adLength})</span>
                          )}
                        </a>
                        <a
                          className={classNames(
                            'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                            { active: adsType === MyLeadsType.Favorite }
                          )}
                          id="nav-profile-tab"
                          data-toggle="tab"
                          onClick={() => setAdsType(MyLeadsType.Favorite)}
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Favorites{' '}
                          {favoriteLoaded && favLength > 0 && (
                            <span className="count">({favLength})</span>
                          )}
                        </a>
                        <a
                          className={classNames(
                            'nav-item nav-link cursor-pointer col-4 col-lg-auto',
                            { active: adsType === MyLeadsType.Enquired }
                          )}
                          id="nav-profile-tab"
                          data-toggle="tab"
                          onClick={() => setAdsType(MyLeadsType.Enquired)}
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Inquired{' '}
                          {enquiredLoaded && enquiredLength > 0 && (
                            <span className="count">({enquiredLength})</span>
                          )}
                        </a>
                      </div>
                    </nav>
                    <div className="col-12 d-none">
                      <div className="row">
                        <div className="d-none mb-3 mob-package-block mt-3 py-3">
                          <div className="col-6">
                            <p className="font-base text-primary mb-0 font-weight-semibold">
                              Heavy Discount on Packages
                            </p>
                          </div>
                          <div className="col-6">
                            <a
                              className="btn btn-primary font-base w-100"
                              href=""
                            >
                              View Packages
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content  pt-4" id="nav-tabContent">
                      {/* {favoriteLoaded && loaded ? ( */}
                      <>
                        <div
                          className={`tab-pane fade show ${
                            adsType === MyLeadsType.Leads ? 'active' : ''
                          } `}
                          id="nav-ads"
                          role="tabpanel"
                        >
                          <div className="ads-item">
                            {loaded
                              ? renderProducts()
                              : renderContainerLoader()}
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            adsType === MyLeadsType.Favorite ? 'active' : ''
                          } `}
                          id="nav-favs"
                          role="tabpanel"
                        >
                          <div className="ads-item">
                            {favoriteLoaded
                              ? renderFavoriteProducts()
                              : renderContainerLoader()}
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            adsType === MyLeadsType.Enquired ? 'active' : ''
                          } `}
                          id="nav-favs"
                          role="tabpanel"
                        >
                          <div className="ads-item">
                            {enquiredLoaded
                              ? renderEnquiredProducts()
                              : renderContainerLoader()}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      {isChoosePlan && !isRedirectPayment && !isThankYou && (
        <ChoosePlan
          onPlanSelect={(data: SubscriptionPlan) =>
            data.isCustom ? requestCustomPlan(data) : payment(data)
          }
          productType={choosePlanProduct?.productType}
          categoryId={choosePlanProduct?.category?._id}
          subCategoryId={choosePlanProduct?.subcategory?._id}
          onBackClick={() => {
            setIsChoosePlan(false);
            // setShowSummary(true);
          }}
        />
      )}
      {isRedirectPayment && !isThankYou && (
        <Payment
          paymentType={PaymentType.Subscription}
          accountType={
            subscriptionPlanSelected?.accountType || AccountType.Individual
          }
          email={auth.profile?.email!}
          amount={subscriptionPlanSelected?.amount}
          subscriptionPlanId={subscriptionPlanSelected?._id}
          isReccuring={subscriptionPlanSelected?.isRecurring || false}
          successhandler={subscribe}
          planName={subscriptionPlanSelected?.name}
          period={subscriptionPlanSelected?.period}
          listingDays={subscriptionPlanSelected?.listingDays}
        ></Payment>
      )}
      {isThankYou && (
        <ThankYou
          productId={choosePlanProduct?._id}
          heading={thankYouHeading}
          message={thankYouMessage}
          productType={choosePlanProduct?.productType}
          isCustomPlan={subscriptionPlanSelected?.isCustom}
        />
      )}
    </>
  );
};
export default MyLeadsScreen;
