import * as React from 'react';
import api from '../../../api';
import config from '../../../config';
import useAuth from '../../../hooks/useAuth';
import { ContainerLoader } from '../../../components/loader';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkout-form"
declare var jQuery: any;
export enum PaymentType {
  Subscription = 'subscription',
  Purchase = 'purchase',
}
interface VerifyPost {
  isAuction?: boolean;
  isReccuring?: boolean;
  email: string;
  tokenId?: string;
  invoicenumber?: string;
  successhandler: (payment: any, stripePaymentToken?: any) => void;
  accountType: AccountType;
  amount?: number;
  subscriptionPlanId?: string;
  planName?: string;
  period?: string;
  listingDays?: number;
  isCustomPlan?: boolean;
  isSinglePayment?: boolean;
  productId?: string;
  paymentType: PaymentType;
}
interface VerifyPostParams {
  email: string;
  tokenId?: string;
  invoicenumber?: string;
  accountType: AccountType;
  amount?: number;
  subscriptionPlanId?: string;
  isCustomPlan?: boolean;
  paymentType: PaymentType;
  isSinglePayment?: boolean;
  productId?: string;
}
export enum AccountType {
  Individual = 'Individual',
  Business = 'Business',
}

const Payment: React.FunctionComponent<VerifyPost> = (props) => {
  const auth = useAuth();
  // const promise = loadStripe("pk_test_51JCxd1ArmAD97cuz0bifVOgkalgPsiWzb8ppexEOQjoPF8b9ZfZggmWOya7O4LE9WcKdk4iTnUJkOmKEqVEOt4dK00twPS1x0X");
  const promise = loadStripe("pk_test_51JBikmSGpQByZ2nrnpnM9WpZERBUT4Q81RfgLTDh32mApobjoAyOfhThqBiJyRemh3JKW4dQ4y5R1JuSKx2cvykq00NMENUZs0");
  const [email] = React.useState(props.email);
  const [amount, setAmount] = React.useState(0);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [accountType] = React.useState<AccountType>(props.accountType);
  const [isSinglePayment] = React.useState(props.isSinglePayment);
  const [productId] = React.useState(props.productId);
  const [subscriptionPlanId] = React.useState(props.subscriptionPlanId);
  const [productPrice] = React.useState(props.amount);
  const [isBooking, setIsBooking] = React.useState(false);
  const [isCustomPlan, setIsCustomPlan] = React.useState(props.isCustomPlan);
  const [paymentType] = React.useState<PaymentType>(props.paymentType);

  const submitPayment = async (params: VerifyPostParams, paymentId: string) => {
    await api.payment
      .updateToken(params.tokenId!, paymentId)
      .then(async (response: any) => {
        jQuery('#CenPosWidget').html('');
        jQuery('#submitCenPos').addClass('hide');
        setIsSubmit(true);
        setIsBooking(true);
        props.successhandler(paymentId);
      });
  };
  const getVerifyPost = async (params: VerifyPostParams) => {
    await api.payment.paymentVerifyPost(params).then((response: any) => {
      if (response.token.Message === 'Approved') {
        setIsLoaded(true);
        setAmount(response.amount);
        jQuery('#CenPosWidget').createWebpay({
          // See JQuery Plugin Parameters
          url:
            config.PAYMENT_URL || 'https://www.cenpos.net/simplewebpay/cards/',
          params: `email=${params.email || auth.profile?.email
            }&industry=ecommerce&isCvv=true&isEmail=${config.ISEMAIL
            }&onlyform=true&verifyingpost=${response.token.Data}`,
          width: '100%',
          height: 390,
          sessionToken: 'True',
          success: (msg: any) => {
            if (typeof msg !== 'object') {
              msg = JSON.parse(msg);
            }
            jQuery('#CenPosWidget').html('');
            jQuery('#submitCenPos').addClass('hide');
            setIsLoaded(false);
            if (
              msg.RecurringSaleTokenId !== null &&
              msg.RecurringSaleTokenId !== '' &&
              msg.RecurringSaleTokenId !== undefined
            ) {
              submitPayment(
                {
                  email,
                  accountType,
                  tokenId: msg.RecurringSaleTokenId,
                  amount: response.amount,
                  subscriptionPlanId,
                  productId,
                  isCustomPlan,
                  paymentType,
                },
                response.paymentId
              );
            }
          },
          cancel: (msg: any) => {
            jQuery('#submitCenPos').removeClass('hide');
          },
        });
      }
    });
    jQuery('#submitCenPos').click(() => {
      setIsSubmit(true);
      jQuery('#CenPosWidget').submitAction();
    });
  };
  React.useEffect(() => {
    setIsLoaded(true)
  }, []);

  if (!isLoaded) {
    return <ContainerLoader height={500}></ContainerLoader>;
  }
  const planNameDisplayText =
    paymentType === PaymentType.Purchase
      ? props.planName
      : `${props.planName} Plan`;
  return (
    <>
      <div className="container">
        <Elements stripe={promise}>
          <CheckoutForm
            isAuction={props.isAuction}
            isReccuring={props.isReccuring}
            email={email}
            accountType={accountType}
            subscriptionPlanId={subscriptionPlanId}
            amount={productPrice}
            productId={productId}
            isCustomPlan={isCustomPlan}
            paymentType={paymentType}
            successhandler={props.successhandler}
          />
        </Elements>

      </div>
    </>
  );
};
export default Payment;
