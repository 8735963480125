import React from 'react';
// import PropTypes from 'prop-types';

import '../Input.scss';

export interface TextInputProps<T> {
  default?: T;
  name?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  gap?: 'sm' | 'md' | 'lg' | 'no';
  size?: 'lg' | 'sm';
  withIcon?: string;
  smallIcon?: boolean;
  noBorder?: boolean;
  type?: 'text' | 'number';
  minValue?: string;
  maxValue?: string;
  maxLength?: number;
  onChange: (value: T) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  stickyStyles?: any;
  disableNumberScrolls?: boolean;
  showLoading?: boolean;
  step?: number;
}

interface CustomSate<T> {
  value?: T;
}

class TextInput<T extends string | number> extends React.Component<
  TextInputProps<T>,
  CustomSate<T>
> {
  static defaultProps: Partial<TextInputProps<string | number>> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    type: 'text',
    gap: 'md',
    smallIcon: false,
    minValue: '0',
    onChange: () => {
      return;
    },
    error: '',
    size: 'sm',
    withIcon: '',
    noBorder: false,
    disabled: false,
    stickyStyles: {},
    disableNumberScrolls: false,
  };

  constructor(props: TextInputProps<T>) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (this.props.default && this.state.value !== this.props.default) {
      this.setState({
        value: this.props.default ? this.props.default : undefined,
      });
    }
  }

  componentDidUpdate(prevProps: TextInputProps<T>) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    // console.log('2. this.props.default(TextInput): ', this.props.default);
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
    }
  }

  handleChange(e: { target: any }) {
    let { value } = e.target;
    if (this.props.type === 'number') {
      value = value ? Number(value) : 0;
    }

    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      size,
      noBorder,
      withIcon,
      disableNumberScrolls,
      name,
      label,
      placeholder,
      gap,
      stickyStyles,
      error,
      smallIcon,
      disabled,
      minValue,
      maxValue,
      maxLength,
      type,
      showLoading,
      onFocus,
      onBlur,
      id,
      step,
    } = this.props;
    const extraClassesInput = [];
    if (size === 'lg') extraClassesInput.push('Input__field--lg');
    if (noBorder) extraClassesInput.push('Input__field--noborder');
    if (withIcon || showLoading) extraClassesInput.push('Input__field--icon');
    if (disableNumberScrolls) {
      extraClassesInput.push('disable-number-scrolls');
    }
    if (disabled) {
      extraClassesInput.push('Input__field--disabled');
    }
    const extraClasses: string = extraClassesInput.join(' ');
    const inputIdCandidate = id || name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`Input Input--gap-${gap}`} style={stickyStyles}>
        {label && (
          <label className="Input__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <input
          id={inputId}
          className={`form-control bg-white ${extraClasses}`}
          type={type}
          step={step}
          placeholder={placeholder}
          onChange={this.handleChange}
          value={this.state.value}
          min={minValue}
          max={maxValue}
          maxLength={maxLength}
          disabled={disabled}
          onWheel={(e) => this.props.disableNumberScrolls && e.preventDefault()}
          onScroll={(e) =>
            this.props.disableNumberScrolls && e.preventDefault()
          }
          onKeyDown={(e) =>
            this.props.disableNumberScrolls &&
            (e.keyCode === 38 || e.keyCode === 40) &&
            e.preventDefault()
          }
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {withIcon && (
          <i className={`icon-${withIcon} ${smallIcon ? 'icon-sm' : ''}`} />
        )}
        {showLoading && (
          <div className="InputLoading">
            <span className="InputLoading__dot InputLoading__dotF"></span>
            <span className="InputLoading__dot InputLoading__dotM"></span>
            <span className="InputLoading__dot InputLoading__dotL"></span>
          </div>
        )}
        {error && <span className="Input__error error">{error}</span>}
      </div>
    );
  }
}

// TextInput.propTypes = {
//   default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   label: PropTypes.string,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   size: PropTypes.oneOf(['lg', 'sm']),
//   withIcon: PropTypes.string,
//   smallIcon: PropTypes.bool,
//   noBorder: PropTypes.bool,
//   type: PropTypes.oneOf(['text', 'number']),
//   minValue: PropTypes.string,
//   maxLength: PropTypes.string,
//   onChange: PropTypes.func,
//   error: PropTypes.string,
//   disabled: PropTypes.bool,
//   stickyStyles: PropTypes.shape({}),
//   disableNumberScrolls: PropTypes.bool,
// };

// TextInput.defaultProps = {
//   default: '',
//   name: '',
//   label: '',
//   placeholder: '',
//   type: 'text',
//   gap: 'md',
//   smallIcon: false,
//   minValue: '0',
//   maxLength: 'number',
//   onChange: () => {},
//   error: '',
//   size: 'sm',
//   withIcon: '',
//   noBorder: false,
//   disabled: false,
//   stickyStyles: {},
//   disableNumberScrolls: false,
// };

export default TextInput;
