import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router';
import { RouteKeys } from '../containers/routes/route-keys';

export const useScanner = () => {
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const verifyQr = async (data: any) => {
    if (data == null) {
      return;
    }

    const rawToken = data?.split('deliver/:');
    const token = rawToken?.length ? rawToken[1] : '';
    if (token) {
      setLoader(true);
      try {
        history.push(RouteKeys.DeliverProduct.replace(':token', token || ''));
      } catch (err) {
        toastr.error('Error', 'Something went wrong');
      } finally {
        setLoader(false);
      }
    }
  };

  return {
    loader,
    verifyQr,
  };
};
